import React from 'react';
import {
  Card,
  CardContent,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  Button,
  Box,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { Add } from '@material-ui/icons';

import { useState } from 'react';
import { ConfirmDialogModal } from '../confirm-dialog-modal';
import { useHistory } from 'react-router';
import { CameraCollection } from '../../api/isc-api';
import { cameraCollectionDetails } from '../helpers';
import { EmptyFallback } from '../empty-fallback';
import { AddItemModal } from '../add-item-modal';
import { useStyles } from './useStyles';

type CameraCollectionSubCollectionsProps = {
  subCollections: CameraCollection[];
  onDeleteSubCollection: (collection: CameraCollection) => void;
  onAddSubCollection: (collectionName: string) => void;
  supportsChildCollections: boolean;
};

export const CameraCollectionSubCollections = ({
  subCollections = [],
  onAddSubCollection,
  onDeleteSubCollection,
  supportsChildCollections,
}: CameraCollectionSubCollectionsProps) => {
  const [deleteCollection, setDeleteCollection] = useState(null);
  const [isAddingCollection, setIsAddingCollection] = useState(false);
  const history = useHistory();

  const handleSubCollectionNav = (cameraCollectionId: string) => {
    history.push(`/collection/${cameraCollectionId}/settings`);
  };

  const handleAddCollection = async (collectionName: string) => {
    onAddSubCollection(collectionName);
    setIsAddingCollection(false);
  };

  const handleDeleteCollection = async (collection: CameraCollection) => {
    if (collection !== null) {
      onDeleteSubCollection(collection);
    }

    setDeleteCollection(null);
  };

  const classes = useStyles();
  const showList = subCollections.length > 0;
  const showFallback = supportsChildCollections && subCollections.length === 0;
  const showBottomAddButton =
    supportsChildCollections &&
    subCollections.length > 0 &&
    subCollections.length <= 100;

  return (
    <React.Fragment>
      <Card>
        <CardContent>
          <Grid container className={classes.titleContainer}>
            <Grid item>
              <Typography variant="h1" className={classes.header}>
                SubCollections
              </Typography>
            </Grid>
            <Grid container item>
              <Grid container style={{ maxHeight: 500, overflowY: 'auto' }}>
                <Grid item style={{ width: '100%' }} className={classes.items}>
                  {showFallback && (
                    <EmptyFallback
                      text="No subcollections have been created."
                      onAction={() => setIsAddingCollection(true)}
                      buttonText="Add Subcollection"
                    />
                  )}
                  {showList && (
                    <List>
                      {subCollections.map(subCollection => {
                        return (
                          <ListItem
                            key={subCollection.cameraCollectionId}
                            className={classes.listItem}
                            onClick={() =>
                              handleSubCollectionNav(
                                subCollection.cameraCollectionId
                              )
                            }
                          >
                            <ListItemText
                              className={classes.listItemText}
                              primary={subCollection.cameraCollectionName}
                              secondary={cameraCollectionDetails(
                                subCollection.cameraCount,
                                subCollection.childCollectionCount
                              )}
                            />
                            <ListItemAvatar>
                              <IconButton>
                                <EditIcon
                                  onClick={() =>
                                    handleSubCollectionNav(
                                      subCollection.cameraCollectionId
                                    )
                                  }
                                />
                              </IconButton>
                            </ListItemAvatar>
                            <ListItemSecondaryAction>
                              <IconButton>
                                <DeleteIcon
                                  onClick={() => {
                                    setDeleteCollection(subCollection);
                                  }}
                                />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        );
                      })}
                    </List>
                  )}
                  {!supportsChildCollections && (
                    <Box
                      display="flex"
                      justifyContent="center"
                      flexDirection="column"
                      alignItems="center"
                      py={4}
                    >
                      <Typography>
                        Subcollections are not allowed more than 3 levels deep.
                      </Typography>
                    </Box>
                  )}
                </Grid>
              </Grid>
              {showBottomAddButton && (
                <Grid item className={classes.items}>
                  <Button
                    className={classes.addCollectionButton}
                    color="secondary"
                    variant="outlined"
                    startIcon={<Add className={classes.addIcon} />}
                    onClick={() => setIsAddingCollection(true)}
                    fullWidth
                    size="large"
                  >
                    Add Subcollection
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <ConfirmDialogModal
        open={deleteCollection}
        message={
          'Are you sure you want to delete this collection? This action will also delete any subcollections and detach any cameras and users associated with them.'
        }
        confirmText={'Delete'}
        value={deleteCollection}
        handleClose={handleDeleteCollection}
      />
      <AddItemModal
        title="Add Subcollection"
        heading="Enter a name for your new sub collection"
        open={isAddingCollection}
        handleClose={() => setIsAddingCollection(false)}
        handleSubmit={handleAddCollection}
      />
    </React.Fragment>
  );
};
