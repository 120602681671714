import React from 'react'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardActions from '@material-ui/core/CardActions'
import List from '@material-ui/core/List'
import CardContent from '@material-ui/core/CardContent'
import { makeStyles } from '@material-ui/core'

type AddUserStepProps = {
  subtitle: string
  isError?: boolean
  children: React.ReactNode
  actions: React.ReactNode
  onSubmit: () => void
  onCancel: () => void
}

const useStyles = makeStyles((theme) => ({
  cardHeader: {
    padding: theme.spacing(2, 3, 0)
  },
  cardContent: {
    ...theme.mixins.gutters(),
    "& li": {
      paddingLeft: "0",
      paddingRight: "0"
    }
  },
  actionContainer: {
    display: "flex",
    justifyContent: "flex-end"
  }
}))

export const AddUserStep = (props:AddUserStepProps) => {
  const classes = useStyles(props);
  return (
    <form onSubmit={ev => { props.onSubmit(); ev.preventDefault(); }}>
    <Card>
      <CardHeader
        title='Add a user'
        titleTypographyProps={{variant:'h6'}}
        subheader={props.subtitle}
        subheaderTypographyProps={{variant: 'subtitle2', color: props.isError ? 'secondary' : 'textSecondary'}}
        className={classes.cardHeader}
        />
      <CardContent className={classes.cardContent}>
        <List disablePadding>
          {props.children}
        </List>
      </CardContent>
      <CardActions className={classes.actionContainer}>
        {props.actions}
      </CardActions>
    </Card>
  </form>
  )
}