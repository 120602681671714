import { SettingsGroup, SettingsTextItem } from '../../../../../../schema';

export const warrantFields = {
  group: <SettingsGroup>{
    name: 'warrant_info',
    label: 'Warrant Info',
    items: [],
  },
  warrantNumber: <SettingsTextItem>{
    type: 'text',
    label: 'Warrant Number',
    name: 'warrantNumber',
  },
  originalWarrantNumber: <SettingsTextItem>{
    type: 'text',
    label: 'Original Warrant Number',
    name: 'originalWarrantNumber',
  },
  extendedWarrantNumber: <SettingsTextItem>{
    type: 'text',
    label: 'Extended Warrant Number',
    name: 'extendedWarrantNumber',
  },
  expirationDate: <SettingsTextItem>{
    type: 'text',
    label: 'Expiration Date & Time',
    name: 'expirationDate',
  },
  originalExpirationDate: <SettingsTextItem>{
    type: 'text',
    label: 'Original Expiration Date & Time',
    name: 'originalExpirationDate',
  },
  extendedExpirationDate: <SettingsTextItem>{
    type: 'text',
    label: 'Extended Expiration Date & Time',
    name: 'extendedExpirationDate',
  },
  internalReferenceNumber: <SettingsTextItem>{
    type: 'text',
    label: 'Internal Reference Number (optional)',
    name: 'internalReferenceNumber',
  },
  notes: <SettingsTextItem>{
    type: 'text',
    label: 'Warrant Notes',
    name: 'notes',
  },
  extensionNumber: <SettingsTextItem>{
    type: 'text',
    label: 'Warrant Extension Number',
    name: 'extensionNumber',
  },
  newExpirationDate: <SettingsTextItem>{
    type: 'text',
    label: 'New Expiration Date and Time',
    name: 'newExpirationDate',
  },
  extensionNotes: <SettingsTextItem>{
    type: 'text',
    label: 'Warrant Extension Notes',
    name: 'extensionNotes',
  },
};
