import React, {useContext} from 'react';
import { Link } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import { HelpCircle, Logout, UserPortal } from '../../../../components/mui-icons';

import List from '@material-ui/core/List';

//import Typography from '@material-ui/core/Typography';

import MenuIcon from '@material-ui/icons/Menu';

import clsx from 'clsx';
import { useHistory } from 'react-router';
import logoImage from '../../../../images/spotlight-text-white.svg';
import {
  hasPermission,
  UserPermissions,
  getOrganization,
  canManageUsersOrGroups,
} from '../../../../access';
import { useStyles } from './useStyles';
import { MainMenuItem } from './MainMenuItem';
import { AuthOrganizationsGetResponseData } from '../../../../api/isc-api';
import ChangeOrganizations from "../../../../components/mui-icons/ChangeOrganizations";
import AppContext, {AppContextType} from "../../../../context";

export type DesktopMenuProps = {
  drawerWidth: number;
  drawerOpen: boolean;
  organization: AuthOrganizationsGetResponseData | null;

  onToggleDrawer: () => void;
  onLogout: () => void;
};

export const DesktopMenu = (props: DesktopMenuProps) => {
  const classes = useStyles(props);
  const context = useContext<AppContextType>(AppContext);
  const history = useHistory();

  const goHome = () => {
    history.push('/');
  };

  const changeOrganizations = () => {
    context.organizationId = '';
  }

  const organization = getOrganization();

  if (!organization) {
    return null;
  }

  return (
    <AppBar
      position="absolute"
      className={clsx(classes.appBar, props.drawerOpen && classes.appBarShift)}
    >
      <Toolbar className={classes.toolbar}>
        <div className={classes.controlBar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={props.onToggleDrawer}
            className={clsx(
              classes.menuButton,
              props.drawerOpen && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <div className={classes.toolbarLogo} onClick={goHome}>
            <img
              src={logoImage}
              alt="Spotlight logo"
              className={classes.logo}
            />
          </div>
        </div>

        <List className={classes.menuList}>
          <MainMenuItem
            href="/"
            primary="Cameras"
            classes={classes}
            exact={true}
          ></MainMenuItem>
          <MainMenuItem
            href="/users"
            primary="Users"
            classes={classes}
            hide={!canManageUsersOrGroups()}
          ></MainMenuItem>
          <MainMenuItem
            href="/organization-settings"
            primary="Settings"
            classes={classes}
            hide={!hasPermission(UserPermissions.CanManageOrganization)}
          ></MainMenuItem>
          {/* <MainMenuItem
            href="/billing"
            primary="Billing"
            classes={classes}
          ></MainMenuItem> */}
        </List>

        <div className={classes.iconBar}>
          <Link to="/profile" className={classes.userButton}>
            <IconButton className={classes.userButton} title="My Profile">
              <UserPortal />
            </IconButton>
          </Link>
          <Link to="/help" className={classes.userButton}>
            <IconButton className={classes.userButton} title="Help">
              <HelpCircle />
            </IconButton>
          </Link>
          {context.organizations?.length > 1 ?
            <Link to="/login" className={classes.userButton} onClick={changeOrganizations}>
              <IconButton className={classes.userButton} title="Change Organizations">
                <ChangeOrganizations />
              </IconButton>
            </Link>
            : null
          }
          <IconButton
            className={classes.userButton}
            color="inherit"
            onClick={props.onLogout}
            title="Log Out"
          >
            <Logout />
          </IconButton>
        </div>
      </Toolbar>
    </AppBar>
  );
};
