import { useApiUpdate } from '../../../../../../hooks';
import { CameraRecognitionConfigurationCameraPutBody, Subject } from '../../../../../../api/isc-api';
import {
  CamerasAPI,
  TempCameraSettingsWithUserGroup,
  CameraSettingsApiResponse,
} from '../../../../../../api/cameras';
import {
  ImageRecognitionMode,
  RecognitionAPI,
} from '../../../../../../api/recognition';
import AppContext from '../../../../../../context';
import { useContext } from 'react';
import { SettingsContext } from '../../../../container/settings-context';
import { getProfileUpdateBody } from './helpers';

type SetCameraProfileArgs = {
  mode: ImageRecognitionMode;
};

export const useSetCameraProfile = ({
  mode
}: SetCameraProfileArgs) => {
  const {
    camera: { cameraId },
    fullSettings: { settings },
  } = useContext(SettingsContext);
  const context = useContext(AppContext);
  const [doUpdateCameraSettings] = useApiUpdate<
    CameraRecognitionConfigurationCameraPutBody,
    string
  >(body => RecognitionAPI.putCameraProfiles(cameraId, body));

  // Update selected profile
  const handleSetCameraProfile = async (
    profileList: Subject[],
    subjectIds: string[]
  ) => {
    try {
      // await doAttachProfileToCamera({
      //   ...settings,
      //   // API is still using `on_edge_analytics`, when  updated this will become `image_recognition`
      //   on_edge_analytics: {
      //     detection_mode: mode,
      //     subject_id: subjectId,
      //   },
      //   image_recognition: {
      //     detection_mode: mode,
      //     subject_id: subjectId,
      //   },
      // });

      await doUpdateCameraSettings(
        getProfileUpdateBody([], subjectIds)
      );

      context.onAlert('Image recognition profiles updated', 'success');
    } catch (e) {
      context.onAlert(e.message || e, 'error');
      throw e
    }
  };

  return handleSetCameraProfile;
};
