import { API as AmplifyAPI, Auth } from 'aws-amplify';
import { Endpoints } from './endpoints';
import {
  getAuthOptions,
  AuthOptions,
  getAmplifyError,
  printDebug,
} from './helpers';
import { EmptyException } from './domain';
import { AppContextType } from '../../context';
import {
  ISC_API_Interface,
  API_Options,
  BootstrapArgs,
  GenericAPIResponse,
  RequestType,
} from './types';
import { ENABLE_DEV_CONSOLE } from '../../util/globals';

class ISC_API implements ISC_API_Interface {
  private _isMock: boolean = false;
  private _endpoint: Endpoints;
  private _organizationId: string;
  private _authOptions: AuthOptions;
  private _lastRefresh: number = Date.now();
  private _refreshInterval: number = 5 * 60 * 1000;
  private _context: AppContextType;

  constructor({ isMock }: API_Options) {
    this._isMock = isMock;
    this._endpoint = isMock ? Endpoints.ISCPortalMock : Endpoints.ISCPortalAPI;
  }

  async bootStrap({ context, organizationId }: BootstrapArgs) {
    this._context = context;
    this._organizationId = organizationId;
    await this.refreshAuthOptions();
  }

  private async refreshAuthOptions() {
    try {
      this._authOptions = await getAuthOptions(this._organizationId);
      this._lastRefresh = Date.now();
    } catch {
      this._context.onLogout();
    }
  }

  private checkRefreshElapsed() {
    const elapsedTime = Date.now() - this._refreshInterval;

    // Only refresh interval has elapsed
    return this._lastRefresh < elapsedTime;
  }

  private async getOptions(options: any = {}) {
    if (this.checkRefreshElapsed()) {
      await this.refreshAuthOptions();
    }

    return {
      ...this._authOptions,
      ...options,
    };
  }

  async get<T>(path: string, options: any = {}): Promise<T> {
    // console.log('options');
    // console.log(await this.getOptions(options));

    try {
      const response: GenericAPIResponse = await AmplifyAPI.get(
        this._endpoint,
        path,
        await this.getOptions(options)
      );

      printDebug(RequestType.get, path, response);

      if (!response.data) {
        throw new EmptyException(path);
      }

      return response.data as T;
    } catch (error) {
      throw getAmplifyError(path, error);
    }
  }

  async post<T = void>(path: string, options: any = {}): Promise<T> {
    try {
      const response: GenericAPIResponse = await AmplifyAPI.post(
        this._endpoint,
        path,
        await this.getOptions(options)
      );

      printDebug(RequestType.post, path, response);

      if (!response?.data) {
        return;
      }

      return response.data as T;
    } catch (error) {
      throw getAmplifyError(path, error);
    }
  }

  async put<T = void>(path: string, options: any = {}): Promise<T> {
    try {
      const response: GenericAPIResponse = await AmplifyAPI.put(
        this._endpoint,
        path,
        await this.getOptions(options)
      );

      printDebug(RequestType.put, path, response);

      if (!response?.data) {
        return;
      }

      return response.data as T;
    } catch (error) {
      throw getAmplifyError(path, error);
    }
  }

  async patch<T = void>(path: string, options: any = {}): Promise<T> {
    try {
      const response: GenericAPIResponse = await AmplifyAPI.patch(
        this._endpoint,
        path,
        await this.getOptions(options)
      );

      printDebug(RequestType.patch, path, response);

      if (!response?.data) {
        return;
      }

      return response.data as T;
    } catch (error) {
      throw getAmplifyError(path, error);
    }
  }

  async del<T = void>(path: string, options: any = {}): Promise<T> {
    try {
      const response: GenericAPIResponse = await AmplifyAPI.del(
        this._endpoint,
        path,
        await this.getOptions(options)
      );

      printDebug(RequestType.del, path, response);

      if (!response?.data) {
        return;
      }

      return response.data as T;
    } catch (error) {
      throw getAmplifyError(path, error);
    }
  }
}

export const API = new ISC_API({ isMock: false });
export const API_MOCK = new ISC_API({ isMock: true });
