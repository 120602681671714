import { makeStyles } from '@material-ui/core';
export const useStyles = makeStyles(theme => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    overflowX: 'hidden',
  },
  container: {
    backgroundColor: 'transparent',
  },
  imageContainer: {
    height: '90%',
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
    background: '#000',
  },
  image: {
    height: '100%',
    width: '100%',
    objectFit: 'contain',
  },
  detailPanelTitle: {
    padding: '16px 16px 0 16px',
  },
  detailRow: {
    display: 'flex',
    borderBottom: '1px solid ' + theme.palette.grey[300],
    margin: '0 16px',
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  detailContent: {
    flexBasis: '50%',
    padding: '16px 16px 16px 0',
  },
  detailContentHeader: {
    fontWeight: 700,
  },
  actionContainer: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
      '& button': {
        marginTop: theme.spacing(2),
      },
    },
  },
  downloadLink: {
    textDecoration: 'none',
  },
  confidenceScore: {
    fontSize: '12px',
    color: 'rgba(18,18,18,0.74)',
  },
  confidenceScoreHelp: {
    marginLeft: theme.spacing(0.5),
    cursor: 'pointer',
  },
  subjectAddedMessage: {
    fontStyle: 'italic',
    color: theme.palette.grey[700],
    display: 'flex',
    alignItems: 'center',
    '& p': {
      paddingRight: theme.spacing(1),
    },
  },
}));
