import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import logoImage from '../../../images/spotlight-text-white.svg';
import { Button } from '@material-ui/core';
import { useStyles } from './useStyles';
import {LocalStorageKeys} from "../../../context";

export const MenuLoggedOut = () => {
  const classes = useStyles({});
  const [open, setOpen] = useState(false);
  const history = useHistory();

  const goToLogin = () => {
    window.localStorage.setItem(
      LocalStorageKeys.PathAfterLogin,
      `${window.location.pathname}${window.location.search}`
    );
    history.push('/login');
  };

  return (
    <AppBar
      position="absolute"
      className={clsx(classes.appBar, open && classes.appBarShift)}
    >
      <Toolbar className={classes.toolbar}>
        <div className={classes.controlBar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={() => setOpen(false)}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <div className={classes.toolbarLogo} onClick={goToLogin}>
            <img src={logoImage} alt="ISC logo" className={classes.logo} />
          </div>
        </div>
        <Button variant="contained" onClick={goToLogin}>Login</Button>
      </Toolbar>
    </AppBar>
  );
};
