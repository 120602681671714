import React from 'react';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

type PointsProps = {
  items: React.ReactChild[];
};

type PointItemProps = {
  color:
    | 'initial'
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'textPrimary'
    | 'textSecondary'
    | 'error';
  children: React.ReactNode;
  hide?: boolean;
};

const useStyle = makeStyles((theme) => ({
  separator: {
    paddingLeft: '7px',
    paddingRight: '7px',
    display: 'none',
    '&:last-child': {
      display: 'none',
    },
    [theme.breakpoints.up('sm')]: {
      display: 'inline-block',
      '&:last-child': {
        display: 'none',
      },
    },
  },
  typography: {
    display: 'block',
    [theme.breakpoints.up('sm')]: {
      display: 'inline-block',
    },
  },
}));

export const Points = (props: PointsProps) => {
  return (
    <React.Fragment>
      {props.items
        .filter((i) => i !== null)
        .map((item) =>
          typeof item === 'string' ? (
            <Points.Item color="inherit">{item}</Points.Item>
          ) : (
            item
          )
        )}
    </React.Fragment>
  );
};

Points.Item = (props: PointItemProps) => {
  const classes = useStyle({});

  return props.hide ? null : (
    <React.Fragment>
      <Typography
        className={classes.typography}
        color={props.color}
        variant="inherit"
        component="span"
      >
        {props.children}
      </Typography>
      <Box component="span" className={classes.separator}>
        •
      </Box>
    </React.Fragment>
  );
};
