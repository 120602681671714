import React from 'react';
import { PageHeader } from '../../components/page-header';
import UserGroupsTab from './groups/tab-groups';
import { useStyles } from './useStyles';
import { AppBar, Tabs, Tab } from '@material-ui/core';

export const GroupsTabOnly = () => {
  const classes = useStyles();

  return (
    <>
      <PageHeader title="Groups" breadcrumbs={[{ href: '/', label: 'Home' }]} />
      <div className={classes.tabWrapper}>
        <AppBar position="static">
          <Tabs value={0}>
            <Tab label="groups" disabled />
          </Tabs>
        </AppBar>
        <UserGroupsTab index={0} tabValue={0} />
      </div>
    </>
  );
};
