import React from 'react';
import { Help } from '@material-ui/icons';
import { TrainingImage } from './training-image';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { DropzoneArea } from 'material-ui-dropzone';
import { RecognitionAPI } from '../../../../../../api/recognition';
import { Typography } from '@material-ui/core';
import { ConfirmDialogModal } from '../../../../../../components/confirm-dialog-modal';
import { HelpMsg } from './help-message';
import { useStyles } from './index-styles';

interface TrainingImagesProps {
  subjectId: string;
  images: any[];
  onImagesUpdate?: (value: any) => void;
}

export const TrainingImages = (props: TrainingImagesProps) => {
  const classes = useStyles(props);
  const ref = React.useRef<HTMLElement>();
  const [open, setOpen] = React.useState(false);
  const [helpOpen, setHelpOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleAddTrainingImage = (files: any) => {
    if (files.length) {
      const reader = new FileReader();
      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = () => {
        const binaryStr = reader.result;
        return binaryStr;
      };

      RecognitionAPI.createTrainingImage(
        props.subjectId,
        reader.readAsBinaryString(files[0])
        // NOTE: these args are for the fake api, but may be needed for the real one
      ).then(data => {
        props.onImagesUpdate(true);
      });
    }
  };

  const handleRemoveTrainingImage = (value: boolean) => {
    props.onImagesUpdate(value);
  };

  return (
    <React.Fragment>
      <ListItem button onClick={handleClick}>
        <ListItemText
          primary="Training Images"
          primaryTypographyProps={{ variant: 'overline' }}
          secondary={`${props.images.length} Image${
            props.images.length === 1 ? '' : 's'
          }`}
          secondaryTypographyProps={{ variant: 'h6', color: 'primary' }}
          ref={ref}
        />
        <ListItemSecondaryAction>
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={open} unmountOnExit>
        <List component="div" disablePadding dense>
          {props.subjectId ? (
            <>
              <div className={classes.trainingImagesContainer}>
                <div className={classes.dropZoneContainer}>
                  <DropzoneArea
                    acceptedFiles={['image/*']}
                    onChange={files => handleAddTrainingImage(files)}
                    filesLimit={1}
                    dropzoneClass={classes.fileUpload}
                    dropzoneParagraphClass={classes.fileUploadText}
                    showPreviewsInDropzone={false}
                  />
                  <div className={classes.helpContainer}>
                    <p
                      className={classes.helpLabel}
                      onClick={() => setHelpOpen(true)}
                    >
                      <Help fontSize="inherit" /> Help
                    </p>
                  </div>
                </div>
                <div className={classes.imagesContainer}>
                  {props.images.map((image: any) => {
                    return (
                      <>
                        <TrainingImage
                          src={image.imageThumbnail}
                          alt={image.alt || 'training image'}
                          confidenceScore={image.confidenceScore}
                          trainingImageId={image.recognitionTrainingImageId}
                          onRemoveTrainingImage={handleRemoveTrainingImage}
                        />
                      </>
                    );
                  })}
                </div>
              </div>
              <ConfirmDialogModal
                open={helpOpen}
                handleClose={() => setHelpOpen(false)}
                dialogTitle="Upload Training Images"
                message={<HelpMsg />}
                confirmText="Close"
              />
            </>
          ) : (
            <Typography variant="body1" className={classes.noSubjectMessage}>
              Please create the subject before adding any training images.
            </Typography>
          )}
        </List>
      </Collapse>
    </React.Fragment>
  );
};
