import React, { useEffect, useRef, useState } from 'react';
import Fuse from 'fuse.js';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Fab,
  Divider,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { PageHeader } from '../../components/page-header';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import helpJson from './help-data.json';
import { ArrowUpward } from '@material-ui/icons';
import { SearchBox } from '../../components/search-box';
import { FuseHighlight } from './FuseHighlight';
const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiFormLabel-root': {
      color: '#fff', // or black
    },
  },
  h2: {
    padding: theme.spacing(3),
  },
  topicsList: {
    paddingBottom: '0',
    paddingTop: '0',
    '& li': {
      cursor: 'pointer',
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
      fontSize: '16px',
      paddingTop: '12px',
      paddingBottom: '12px',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  subhead: {
    fontWeight: 'bold',
    fontFamily: theme.typography.h2.fontFamily,
    textTransform: 'uppercase',
    fontSize: '1.15em',
  },
  expansionTableRoot: {
    boxShadow: 'none',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    '&:before': {
      display: 'none',
    },
  },
  expansionTableExpanded: {
    margin: '0 !important',
  },
  topicBoxFixed: {
    [theme.breakpoints.up('md')]: {
      position: 'fixed',
      top: '10px',
      marginBottom: theme.spacing(3),
      maxHeight: '100vh',
      paddingBottom: '2em',
      overflow: 'auto',
      scrollbarWidth: 'thin',
    },
  },
  topicBox: {
    marginBottom: theme.spacing(3),
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  helpTopic: {
    padding: theme.spacing(4),
    paddingTop: theme.spacing(0),
  },
  helpTitle: {
    fontSize: '1.2em',
    fontWeight: 'bold',
  },
  helpDetails: {
    '& img': {
      display: 'block',
      width: '100%',
      maxWidth: '40em',
      margin: '0 auto',
      '&.img-narrow': {
        maxWidth: '26em',
      },
    },
  },
  accordionTitle: {
    fontSize: '1.2em',
    '.Mui-expanded &': {
      fontWeight: 'bold',
    },
  },
  accordionDetails: {
    '& img': {
      display: 'block',
      width: '100%',
      maxWidth: '40em',
      margin: '0 auto',
      '&.img-narrow': {
        maxWidth: '26em',
      },
    },
  },
  searchContainer: {
    padding: '20px',
    '&.MuiFormLabel-root': {
      color: '#fff',
    },
  },

  headerSearchBar: {
    '& .MuiFormLabel-root': {
      color: '#000',
    },

    '& .MuiInputBase-root': {
      '&.Mui-focused': {
        transition: 'background 0.3s',
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
      },
      color: '#000',

      backgroundColor: 'rgba(255, 255, 255, 0.5)',
    },
  },
}));

type HelpItem = {
  title: string;
  description: string;
  image?: string;
  id?: string;
};

type HelpCategory = {
  topic: string;
  subhead?: boolean;
  items: HelpItem[];
};

export const HelpPage = () => {
  const { data } = helpJson;
  const fuseData = data
    .map(r => r.items)
    .reduce((flatten, arr) => [...flatten, ...arr]);
  const helpData = new Fuse(fuseData, {
    keys: ['title', 'description'],
    threshold: 0.02,
    minMatchCharLength: 2,
    includeMatches: true,
    findAllMatches: true,
    distance: 1500,
    useExtendedSearch: true,
  });
  const [scrolled, setScrolled] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const SCROLL_THRESHOLD = 105;
  const topicBoxRef = useRef(null);

  useEffect(() => {
    fixTopicsBarOnScroll();
    window.addEventListener('hashchange', handleHashChange);

    return function cleanup() {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);
  useEffect(() => {
    const query = searchQuery
      .split(' ')
      .map(s => "'" + s)
      .join(' ');
    let results = helpData.search(`${query}`);
    setSearchResults(results);
  }, [searchQuery]);

  const handleScroll = () => {
    const refWidth = topicBoxRef.current.clientWidth;
    const nextSibling = topicBoxRef.current.nextElementSibling;

    if (document.scrollingElement.scrollTop >= SCROLL_THRESHOLD) {
      nextSibling.style.maxWidth = `${refWidth}px`;
      setScrolled(true);
    } else {
      nextSibling.style.maxWidth = 'auto';
      setScrolled(false);
    }
  };

  const fixTopicsBarOnScroll = () => {
    if (searchQuery === '') {
      window.addEventListener('scroll', handleScroll);
    }
  };

  const onTopicClick = (topicId: string): void => {
    const topicBox = document.getElementById(topicId);
    document.scrollingElement.scrollTo({
      top: topicBox.offsetTop - 105,
      left: 0,
      behavior: 'smooth',
    });
  };

  const scrollToTop = () => {
    document.scrollingElement.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  const handleHashChange = () => {
    const hash = (location.hash ?? '').replace('#', '');
    const matchingEl = document.getElementById(hash);

    if (matchingEl) {
      matchingEl.click();
    }
  };

  const getCategorySlug = topic => {
    return topic.replace(/\s+/g, '-').toLowerCase();
  };

  const classes = useStyles();

  const handleSearch = searchValue => {
    setSearchQuery(searchValue);
  };

  const SearchView = () => {
    return (
      <React.Fragment>
        <Grid item sm={12}>
          <div ref={topicBoxRef}></div>
          <Box boxShadow={2} className={classes.topicBox}>
            <Typography className={classes.h2} variant="h2">
              Search Results
            </Typography>
            {searchResults.length === 0 && (
              <Box
                component="div"
                className={classes.helpTopic}
                id={'no-results'}
              >
                <Typography className={classes.helpDetails}>
                  No Results Found.
                </Typography>
              </Box>
            )}
            {searchResults.length > 0 &&
              searchResults.map((item: any, index: number) => {
                return (
                  <Box
                    component="div"
                    className={classes.helpTopic}
                    id={item.refIndex}
                  >
                    <Typography className={classes.helpTitle}>
                      {/* {item.title}  */}
                      {FuseHighlight(item, 'title')}
                    </Typography>

                    <Typography
                      className={classes.helpDetails}
                      dangerouslySetInnerHTML={{
                        __html: FuseHighlight(item, 'description'),
                      }}
                    ></Typography>
                    <Divider />
                  </Box>
                );
              })}
          </Box>
        </Grid>
      </React.Fragment>
    );
  };

  const DefaultView = () => (
    <>
      <Grid item xs={12} md={4}>
        <div ref={topicBoxRef}></div>
        <Box
          boxShadow={2}
          component="div"
          className={scrolled ? classes.topicBoxFixed : classes.topicBox}
        >
          <Typography className={classes.h2} variant="h2">
            Topics
          </Typography>
          <List className={classes.topicsList}>
            {data.map((helpCategory: HelpCategory) => (
              <ListItem
                onClick={() =>
                  onTopicClick(
                    helpCategory.topic.replace(/\s+/g, '-').toLowerCase()
                  )
                }
              >
                <Typography
                  className={helpCategory.subhead ? classes.subhead : ''}
                >
                  {helpCategory.topic}
                </Typography>
              </ListItem>
            ))}
          </List>
        </Box>
      </Grid>
      <Grid item xs={12} md={8}>
        {data.map((helpCategory: HelpCategory) => {
          const { items } = helpCategory;
          return (
            <Box
              boxShadow={2}
              component="div"
              className={classes.topicBox}
              id={getCategorySlug(helpCategory.topic)}
            >
              <Typography className={classes.h2} variant="h2">
                {helpCategory.topic}
              </Typography>

              {items.map((item: HelpItem, index: number) => {
                return (
                  <Accordion
                    classes={{
                      root: classes.expansionTableRoot,
                      expanded: classes.expansionTableExpanded,
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id={
                        item.id ||
                        `${getCategorySlug(helpCategory.topic)}-${index}`
                      }
                    >
                      <Typography className={classes.accordionTitle}>
                        {item.title}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography
                        className={classes.accordionDetails}
                        dangerouslySetInnerHTML={{ __html: item.description }}
                      ></Typography>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </Box>
          );
        })}
      </Grid>
    </>
  );
  return (
    <React.Fragment>
      <PageHeader
        title="Help"
        search={
          <SearchBox
            value={searchQuery}
            className={classes.headerSearchBar}
            onFilterChange={handleSearch}
            placeholderText={'Search'}
          />
        }
      />
      <Box className={classes.searchContainer}></Box>
      <Grid container spacing={4}>
        {searchQuery !== '' ? <SearchView /> : <DefaultView />}
      </Grid>
      <Fab
        className={classes.fab}
        color="primary"
        title="Back to Top"
        onClick={scrollToTop}
      >
        <ArrowUpward />
      </Fab>
    </React.Fragment>
  );
};
