export const transformPhone = (phone: string) => {
  let transformed = phone.replace(/[()\s-]*/g, '');

  // prepend `+1` if a country code is missing
  if (transformed[0] !== '+' && transformed.length === 10) {
    transformed = `+1${transformed}`;
  }

  // prepend `+` if missing
  if (transformed[0] === '1' && transformed.length === 11) {
    transformed = `+${transformed}`;
  }

  return transformed;
};

export const isPhoneValid = (phone: string) => {
  return new RegExp(/^\+\d{11}$/i).test(phone);
};

export function deepEqual(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      areObjects && !deepEqual(val1, val2) ||
      !areObjects && val1 !== val2
    ) {
      return false;
    }
  }
  return true;
}

export function isObject(object) {
  return object != null && typeof object === 'object';
}