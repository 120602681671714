import React from "react";
import { makeStyles } from "@material-ui/core";

type CarouselContentProps = {
  translate: string | number;
  transition: string | number;
  children: React.ReactNode;
  width: string | number
};

const useStyles = makeStyles(() => ({
  content: (props: CarouselContentProps) => ({
    transform: `translateX(-${props.translate}px)`,
    transition: `transform ease-out ${props.transition}s`,
    height: "100%",
    width: `${props.width}px`,
    maxWidth: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }),
}));

export const CarouselContent = (props: CarouselContentProps) => {
  const classes = useStyles(props);
  return (
    <div className={classes.content}>
      {props.children}
    </div>
  )
};
