import React from 'react';
import { Select, Text } from '../../../../../components/form-fields';
import { personProfileFields } from './person-profile-fields';
import { TrainingImages } from './training-images';
import { Divider } from '@material-ui/core';

type PersonProfileFormProps = {
  subjectName: string;
  handleSubjectName: (value: string) => void;
  subjectId: string;
  trainingImages: any[];
  handleUpdatedImages: (value: any) => void;
  subjectLocation: string;
  handleSubjectLocation: (value: string) => void;
  subjectNotes: string;
  handleSubjectNotes: (value: string) => void;
};

export const PersonProfileForm = ({
  subjectName,
  handleSubjectName,
  subjectId,
  trainingImages,
  handleUpdatedImages,
  subjectLocation,
  handleSubjectLocation,
  subjectNotes,
  handleSubjectNotes,
}: PersonProfileFormProps) => {
  return (
    <>
      <Text
        group={personProfileFields.group}
        item={personProfileFields.subjectName}
        value={subjectName}
        onValueChanges={handleSubjectName}
      />
      <Divider />
      <TrainingImages
        images={trainingImages}
        subjectId={subjectId}
        onImagesUpdate={handleUpdatedImages}
      />
      <Divider />
      <Select
        group={personProfileFields.group}
        item={personProfileFields.subjectLocation}
        value={subjectLocation}
        onValueChanges={handleSubjectLocation}
      />
      <Divider />
      <Text
        group={personProfileFields.group}
        item={personProfileFields.subjectNotes}
        value={subjectNotes}
        onValueChanges={handleSubjectNotes}
      />
    </>
  );
};
