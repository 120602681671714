import { makeStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles(theme => ({
  extendWarrantContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '10px 25px 20px',
    [theme.breakpoints.down('md')]: {
      alignItems: 'flex-start',
    },
  },
  expiredRow: {
    display: 'block',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'space-between',
      display: 'flex',
    },
  },
  extendRowMobileDivider: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
}));
