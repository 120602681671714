import { makeStyles } from '@material-ui/core';
export const useStyles = makeStyles(theme => ({
  listBox: {
    boxShadow: '0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2)',
    background: '#fff',
    width: '100%',
    '&::after': {
      content: '',
      display: 'block',
      height: '1px',
      background: 'red'
    }
  },
  listHeader: {
    textTransform: 'uppercase',
    fontSize: '18px',
    fontWeight: 'bolder'
  },
  progressBar: {
    width: '100%'
  },
  avatar: {
    maxWidth: '65px',
    height: '65px',
    objectFit: 'cover',
    marginRight: '1em',
  },
}));
