import React from "react";
export const HelpMsg = () => {
  return (
    <>
      <p>
        Uploading multiple, high-quality, Images of the people to be recognized
        increases the accuracy of the system, and photos showing the faces in
        different angles should be used.
      </p>
      <p>
        Upload images that contain only one Person. If multiple people are
        found, the system will only use the largest face.
      </p>
      <p>
        Supported image formats are JPEG, GIF, and PNG. The minimum size
        supported for detection of a face in the image is 40x40 pixels. For
        better results, use faces sized 100x100 pixels or higher. Images larger
        than 1920x1080 will be scaled to a size within those bounds while
        respecting aspect ratio.
      </p>

      <p>
        The more images of this subject you upload, the better your results will
        be. We recommend at least 3 images to create a profile.
      </p>
    </>
  );
};
