import { makeStyles } from '@material-ui/core';
export const useStyles = makeStyles(theme => ({
  root: {
    padding: '0',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
  titleGridAlternate: {
    '& *, & *:before, & *:after': {
      color: theme.palette.common.white + ' !important',
      borderColor: theme.palette.common.white + ' !important',
    },
  },
  cameraCollectionsAlternate: {
    marginTop: '150px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '100px',
    },
  },
  header: {
    color: theme.palette.common.black,
    margin: theme.spacing(0),
  },
  newButton: {
    marginLeft: theme.spacing(2),
  },
  default: {
    paddingLeft: theme.spacing(0),
  },
}));
