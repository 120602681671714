import { useState, useEffect } from 'react';
import { useLocation } from 'react-router';

export const useWarrantChecker = (): [
  boolean,
  React.Dispatch<React.SetStateAction<boolean>>
] => {
  const query = new URLSearchParams(useLocation().search);
  const [showWarrantModal, setShowWarrantModal] = useState(
    query.get('requiresWarrant') === 'true'
  );

  const setFromQuery = () => {
    if (query.get('requiresWarrant') === 'true') {
      setShowWarrantModal(true);
    }
  };

  useEffect(setFromQuery, []);

  return [showWarrantModal, setShowWarrantModal];
};
