import React from "react";
import { makeStyles } from "@material-ui/core";
import { Image } from '../../../components/image';

type CarouselSlideProps = {
  type: string;
  content: string;
  width: string | number;
};

//TODO: modify how this container looks to ensure the full image is captured - 
// likely dependent on business and/or technical rules for size of media from the cameras
const useStyles = makeStyles(() => ({
  slide: (props: CarouselSlideProps) => ({
    height: "100%",
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#000',
    "& img": {
      objectFit: "contain",
      height: "inherit",
      width: "inherit",
    },
    "& video": {
      objectFit: "contain",
      height: "inherit",
      width: "inherit",
    },
  }),
}));

export const CarouselSlide = (props: CarouselSlideProps) => {
  const classes = useStyles(props);
  return (
    <div className={classes.slide}>
      {props.type === "Image" && <Image src={props.content} alt="image" />}
      {props.type === "Video" && (
        <video controls>
          <source src={props.content} />
        </video>
      )}
    </div>
  );
};
