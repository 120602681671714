export const cameraCollectionDetails = (
  cameraCount: number,
  childCollectionCount: number
) => {
  let cameraText =
    cameraCount !== 1 ? `${cameraCount} cameras` : `${cameraCount} camera`;
  let childCollectionText =
    childCollectionCount !== 1
      ? `${childCollectionCount} sub-collections`
      : `${childCollectionCount} sub-collection`;
  let cameraCollectionText;

  if (cameraCount && childCollectionCount) {
    if (cameraCount === 0) cameraText = '${cameraText}';
    if (childCollectionCount === 0) childCollectionText = '';
    cameraCollectionText = `${cameraText}, ${childCollectionText}`;
  } else if (childCollectionCount && childCollectionCount > 0) {
    cameraCollectionText = `${childCollectionText}`;
  } else {
    cameraCollectionText = `${cameraText}`;
  }

  return cameraCollectionText;
};
