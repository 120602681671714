import React from 'react'

import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import ListItem from '@material-ui/core/ListItem'

import { AddUserStep } from "./step"

type Step1Props = {
  email: string
  onCancel: () => void
  onNext: (email: string) => void
}

export const Step1 = (props: Step1Props) => {
  const [email, setEmail] = React.useState(props.email)
  const [error, setError] = React.useState<string>(null)

  const handleNext = () => {
    const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    setError(isValid ? null : 'Please enter valid email address')
    if (isValid) {
      props.onNext(email)
    }
  }

  return <AddUserStep
    subtitle={'Please enter the new user\'s email.'}
    onCancel={props.onCancel}
    actions={
      <React.Fragment>
        <Button onClick={props.onCancel} size="small">Cancel</Button>
        <Button type="submit" color='primary' size="small">Next</Button>
      </React.Fragment>
    }
    onSubmit={handleNext}
    >
      <ListItem>
        <TextField
          label='Email' fullWidth autoFocus
          value={email} onChange={ev => setEmail(ev.target.value)}
          error={Boolean(error)} helperText={error}
        />
      </ListItem>
    </AddUserStep>
}
