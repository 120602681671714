import React from 'react'
import { Customer } from '../../api/billing'
import { Card, CardHeader, CardContent, CardActions, Button } from '@material-ui/core'
import { PaymentForm } from '.'

type PaymentCollectionProps = {
  customer: Customer
  buttonLabel?: string
  disabled: boolean

  onSubmit: () => void
}

export const PaymentCollection = (props: PaymentCollectionProps) => {

  const buttonLabel = props.buttonLabel || 'Submit'

  return props.customer.defaultPaymentMethod
    ? <Card>
        <CardHeader title='Payment Information' subheader='The following source will be used to process the payment' />
        <CardContent>
          {props.customer.defaultPaymentMethod.description}
        </CardContent>
        <CardActions>
          <Button
            color='primary'
            variant='contained'
            onClick={props.onSubmit}
            disabled={props.disabled}
          >
              {buttonLabel}
          </Button>
        </CardActions>
      </Card>
    : <PaymentForm
        customerId={props.customer.customerId}
        onSuccess={props.onSubmit}
        buttonLabel={buttonLabel}
        disabled={props.disabled}
      />
}
