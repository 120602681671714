import * as React from 'react';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import { ItemProps } from './types';
import DateMomentUtils from '@date-io/moment';
import moment from 'moment';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';

const useStyles = makeStyles(() => ({
  dateSelectorContainer: {
    padding: '8px',
  },
  slimContainer: {
    padding: '8px',
  },
  fieldLabel: {
    display: 'block',
  },
  textField: {
    minHeight: '60px',
  },
  slimTextField: {
    minHeight: '10px',
  },
}));

interface DateProps extends ItemProps {
  onChange?: (dateTime: string, name: string) => void;
  isError?: boolean;
  format?: string;
  helper?: string;
  disableFuture?: boolean;
  maxDate?: ParsableDate;
  minDate?: ParsableDate;
}

export const DatePicker = ({
  item,
  value,
  onChange,
  onKeyDown,
  readOnly = false,
  format = 'MM/DD/YYYY',
  helper = '',
  minDate = undefined,
  maxDate = undefined,
}: DateProps) => {
  const classes = useStyles();
  const [dateTime, setDateTime] = React.useState(null);

  React.useEffect(() => {
    setDateTime(!!value ? moment(value as string).format() : null);
  }, [value]);

  const handleDateTimeChange = (input: any | null) => {
    let newDate = !!input ? moment(input).format() : null;
    onChange(newDate, item.name);
  };

  const handleKeyDown = (ev: React.KeyboardEvent<HTMLDivElement>) => {
    onKeyDown && onKeyDown(ev);
  };

  return (
    <div className={ classes.dateSelectorContainer}>
      <MuiPickersUtilsProvider utils={DateMomentUtils}>
        <KeyboardDatePicker
          variant="dialog"
          id="date-picker"
          helperText={helper}
          format={format}
          label={item.label}
          value={dateTime}
          className={classes.textField}
          minDate={minDate}
          clearable
          autoOk
          maxDate={maxDate}
          onChange={handleDateTimeChange}
          onKeyDown={handleKeyDown}
          readOnly={readOnly}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
};

