import React from 'react';
import {
  Button, Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';

type ConfirmDialogModalProps = {
  open: boolean,
  dialogTitle?: string,
  message: string | JSX.Element,
  confirmText: string,
  value?: any,
  cancelText?: string,
  handleClose: (val: any) => void
}

export const ConfirmDialogModal = (props: ConfirmDialogModalProps) => {
  return (
    <React.Fragment>
      <Dialog
        open={props.open}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title">{props.dialogTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {props.message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              size="small"
              onClick={() => props.handleClose(null)}
            >
              {props.cancelText ? props.cancelText : "Cancel"}
            </Button>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              size="small"
              onClick={() => props.handleClose(props.value)}
            >
              {props.confirmText}
            </Button>
          </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}
