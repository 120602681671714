import { API } from './lib/api';

import {
  OrganizationSettingsSharingTestEmailPutBody,
  OrganizationSettingsSharingPutBody,
  OrganizationSettingsRecognitionPutBody,
  OrganizationSettingsSharingTestEmailPutResponse,
} from './isc-api';

/**
 * Typings are missing for the data attribute in the GET response
 * so we use these for now
 */
export type SmtpSettings = {
  host: string;
  username: string;
  password: string;
  port: number;
  from: string;
};

export const OrganizationSettingsAPI = {
  getSMTPSettings: () => API.get<SmtpSettings>('/organization/settings/smtp/'),

  updateSMTPSettings: (body: SmtpSettings) =>
    API.put<SmtpSettings>('/organization/settings/smtp/', { body }),

  updateMediaSharing: (body: OrganizationSettingsSharingPutBody) =>
    API.put<OrganizationSettingsSharingPutBody>(
      '/organization/settings/sharing/',
      { body }
    ),

  updateImageRecognition: (body: OrganizationSettingsRecognitionPutBody) =>
    API.put<OrganizationSettingsRecognitionPutBody>(
      '/organization/settings/recognition/',
      {
        body,
      }
    ),

  testEmail: (body: OrganizationSettingsSharingTestEmailPutBody) =>
    API.put<OrganizationSettingsSharingTestEmailPutResponse>(
      '/organization/settings/sharing/test/email/',
      { body }
    ),
};
