import React from 'react';
import {
  Typography,
  ListItemText,
  ListItemSecondaryAction,
  Paper,
  IconButton,
  ListItem,
  List,
  Grid,
  Button,
} from '@material-ui/core';
import {
  CameraCollection as CameraCollectionAPI,
  CollectionChildrenGetResponseDataCameras as CameraAPI,
} from '../../api/isc-api';
import CameraListItem from '../camera-list-item';
import { useStyles } from './camera-collection-list-styles';
import { BreadcrumbToolbar } from './breadcrumb-toolbar';
import { UnassignedListItem } from './list-item-unassigned';
import { CollectionListItem } from './list-item-collection';
import { EmptyFallback } from '../empty-fallback';
import { useHistory } from 'react-router';
import {hasPermission, UserPermissions} from "../../access";

type CameraCollectionProps = {
  currentCollection: CameraCollectionAPI;
  parentCollection: CameraCollectionAPI;
  collections: CameraCollectionAPI[];
  cameras: CameraAPI[];
  unassignedCameraCount: number;
  isSearch: boolean;
  onCollectionChange: (e: MouseEvent, collection: CameraCollectionAPI) => void;
};

export const CameraCollectionList = (props: CameraCollectionProps) => {
  const classes = useStyles();
  const history = useHistory();

  const showUnassignedListItem =
    !props.currentCollection && props.unassignedCameraCount > 0 && !props.isSearch;

  const hasCollections = props.collections?.length > 0;
  const hasCameras = props.cameras?.length > 0;
  const showFallback = !hasCollections && !hasCameras && !props.unassignedCameraCount;

  const getFallbackText = () => {
    if (props.isSearch) {
      return 'No matching cameras were found for this search term.';
    } else if (!props.currentCollection) {
      return 'No Cameras or Sub-collections are visible to you.'
    } else {
      return 'No Cameras or Sub-collections have been added to this collection.';
    }
  }

  const onManage = () => {
    history.push(
      `/collection/${props.currentCollection.cameraCollectionId}/settings`
    );
  };

  return (
    <>
      {props.currentCollection && (
        <BreadcrumbToolbar
          currentCollection={props.currentCollection}
          parentCollection={props.parentCollection}
        />
      )}

      <Paper>
        <List className={classes.collectionList}>
          {hasCollections &&
            props.collections.map((collection: CameraCollectionAPI) => (
              <CollectionListItem
                collection={collection}
                onCollectionChange={props.onCollectionChange}
              />
            ))}

          {showUnassignedListItem && (
            <UnassignedListItem
              unassignedCameraCount={props.unassignedCameraCount}
            />
          )}

          {hasCameras &&
            props.cameras.map((nextCamera: CameraAPI) => (
              <CameraListItem camera={nextCamera} />
            ))}
        </List>

        {showFallback && (
          <EmptyFallback
            icon="settings"
            onAction={onManage}
            buttonText="Manage Settings"
            text={getFallbackText()}
            showButton={!props.isSearch && props.currentCollection && hasPermission(UserPermissions.CanManageCameras)}
          />
        )}
      </Paper>
    </>
  );
};
