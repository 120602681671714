import React from 'react';
import moment from 'moment';
import { Typography } from '@material-ui/core';
import { DateTimeDetail } from '../../api/isc-api';
import { useStyles } from './details-styles';

type DetailsDateProps = {
  date: DateTimeDetail;
};
export const DetailsDate = ({ date }: DetailsDateProps) => {
  const classes = useStyles();

  if (!date) return null;

  const timezoneAbbr = new Date().toLocaleDateString(undefined, {day:'2-digit',timeZoneName: 'short' }).substring(4)

  return (
    <div className={classes.detailRow}>
    <div className={classes.detailContent}>
      <Typography
        variant="body1"
        className={classes.detailContentHeader}
      >
        Date:
      </Typography>
      <Typography>
        {moment(date.dateTimeLocal).format('dddd')}
      </Typography>
      <Typography>
        {moment(date.dateTimeLocal).format('MMMM DD, YYYY')}
      </Typography>
    </div>
    <div className={classes.detailContent}>
      <Typography
        variant="body1"
        className={classes.detailContentHeader}
      >
        Time:
      </Typography>
      <Typography>
        {moment(date.dateTimeLocal).format('hh:mm:ssa')} {timezoneAbbr}
      </Typography>
    </div>
  </div>
  );
};
