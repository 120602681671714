import React, {useEffect, useState, forwardRef} from 'react';
import {
  ImageRecognitionResults,
  LicensePlateRecognitionResult,
  VehicleRecognitionResult
} from "../../../api/isc-api";
import {Paper, Typography} from "@material-ui/core";
import {LicensePlateRecognitionResults} from "./license-plate-recognition-results";
import {LocalOffer} from "@material-ui/icons";
import {VehicleRecognitionResults} from "./vehicle-recognition-results";
import {RecognitionResultsDetail} from "./recognition-results-detail";
import {useStyles} from "./recognition-results-styles";

type RecognitionResultsProps = {
  recognitionResults: ImageRecognitionResults
}

export enum SupportedRecognitionTypes {
  LICENSE_PLATES = 'licensePlates',
  VEHICLES = 'vehicles'
}

export const RecognitionResults = forwardRef(({recognitionResults}: RecognitionResultsProps, ref) => {
  const [recognitionResultsTypes, setRecognitionResultsTypes] = useState<[string, any][]>([]);

  const classes = useStyles();

  const [detailOpen, setDetailOpen] = useState(false);
  const [detailType, setDetailType] = useState<SupportedRecognitionTypes>();
  const [detailResult, setDetailResult] = useState<VehicleRecognitionResult|LicensePlateRecognitionResult>();

  const onOpenDetail = (detailResult: LicensePlateRecognitionResult|VehicleRecognitionResult) => {
    setDetailResult(detailResult);
    if (detailResult.hasOwnProperty('plate')) {
      setDetailType(SupportedRecognitionTypes.LICENSE_PLATES);
    } else {
      setDetailType(SupportedRecognitionTypes.VEHICLES);
    }
    setDetailOpen(true);
  }
  const onCloseDetail = () => {
    setDetailResult(null);
    setDetailOpen(false);
  }

  useEffect(() => {
    setRecognitionResultsTypes(Object.entries(recognitionResults));
  }, [recognitionResults]);

  const renderResultsType = ([typeName, typeData]: [string, any]) => {
    switch (typeName) {
      case SupportedRecognitionTypes.LICENSE_PLATES:
        return <LicensePlateRecognitionResults results={typeData as LicensePlateRecognitionResult[]} onOpenDetail={onOpenDetail} />
      case SupportedRecognitionTypes.VEHICLES:
        return <VehicleRecognitionResults results={typeData as VehicleRecognitionResult[]} onOpenDetail={onOpenDetail} />
    }
  }

  return (
    <Paper className={classes.recognitionCard} ref={ref}>
      <div className={classes.detailPanelTitle}>
        <Typography variant="h2" className={classes.iconHeading}>
          <LocalOffer /> Image Metadata</Typography>
      </div>
      <div className={classes.detailContent}>
        {recognitionResultsTypes.map(resultsType => renderResultsType(resultsType))}
        <RecognitionResultsDetail open={detailOpen} onClose={onCloseDetail} type={detailType}
                                  recognitionResult={detailResult} />
      </div>
    </Paper>
  )
})
