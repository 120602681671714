import { ItemProps } from './item';
import UserGroupsComponent from './UserGroupsComponent';
import ImageRecognitionComponent from './ImageRecognitionComponent';
import { SettingsCustomItem } from '../../../schema';
import React from 'react';

const customComponentMap = {
  UserGroupsComponent,
  ImageRecognitionComponent,
};

export const CustomItem = (props: ItemProps) => {
  const customItem = props.item as SettingsCustomItem;
  
  return React.createElement(
    customComponentMap[customItem.componentName],
    props
  );
};
