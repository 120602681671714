import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { DashboardSearchBox } from '../dashboard-search-box';
import { UserGroupsAPI } from '../../api/user-groups';
import { useApi } from '../../hooks';
import { CollectionCameraBasicSearchGetResponseDataCameras, UserGroup } from '../../api/isc-api';
import { ProgressBar } from '../progress-bar';
import { useStyles } from './add-modal-styles';
import { getgroups } from 'process';
import { Alert } from '@material-ui/lab';
import { useAddItemModal } from "../add-camera-modal/useAddItemModal";

type AddUserGroupModalProps = {
  open: boolean;
  showAdminToggle: boolean;
  existingUserGroupIds: string[];
  handleClose: () => void;
  handleSave: (UserGroups: UserGroup[]) => void;
};

export default function AddUserGroupModal(props: AddUserGroupModalProps) {
  const classes = useStyles();
  const {
    chipData,
    updateChipData,
    filter,
    isAllSelected,
    setIsAllSelected,
    setAllSelected,
    handleFilterChange,
    reset,
    handleChangeItem,
    handleDeleteItem,
    handleFormSubmit,
    isItemChecked
  } = useAddItemModal({
    handleClose: props.handleClose
  })

  const [data, , isLoading] = useApi(() => UserGroupsAPI.list());
  const [filteredUserGroups, setFilteredUserGroups] = useState<UserGroup[]>([]);
  const [selectedUserGroups, setSelectedUserGroups] = useState<UserGroup[]>([]);

  useEffect(() => {
    if (data && !filter) {
      const ug = (el: UserGroup) => {
        return !props.existingUserGroupIds.includes(el.userGroupId);
      };

      const groupsNotAdded = data.filter(ug);
      setFilteredUserGroups(groupsNotAdded);
    }
  }, [data, props.existingUserGroupIds]);

  useEffect(() => {
    if (data) {
      if (filter) {
        const query = new RegExp(filter, "igm")
        const filterUserGroup = (el: any) => {
          return el.name.match(query);
        };
        const loadFilteredData = async () => {
          setFilteredUserGroups(filteredUserGroups.filter(filterUserGroup));
        };

        void loadFilteredData();
      } else {
        const ug = (el: UserGroup) => {
          return !props.existingUserGroupIds.includes(el.userGroupId);
        };

        const groupsNotAdded = data.filter(ug);
        setFilteredUserGroups(groupsNotAdded);
      }
    }
  }, [filter]);

  useEffect(() => {
    updateChipData(selectedUserGroups, 'userGroupId', 'name');
    setIsAllSelected(selectedUserGroups.length === filteredUserGroups.length && selectedUserGroups.length > 0);
  }, [selectedUserGroups]);

  const handleAdminChange = (userGroupId: string) => {
    const elementIndex = selectedUserGroups.findIndex(
      userGroup => userGroup.userGroupId === userGroupId
    );
    const newUserGroups = JSON.parse(JSON.stringify(selectedUserGroups));
    newUserGroups[elementIndex] = {
      ...newUserGroups[elementIndex],
      isGroupAdmin: !newUserGroups[elementIndex].isGroupAdmin,
    };
    setSelectedUserGroups(newUserGroups);
  };

  const groupItems = () => (
    <Table stickyHeader>
      <TableHead>
        <TableRow>
          <TableCell className={classes.headerCell}>
            Select
            <div className={classes.selectAll}>
              {isAllSelected ?
                <a href="#" onClick={e => setAllSelected(e, false, setSelectedUserGroups)}>(Deselect All)</a> :
                <a href="#" onClick={e => setAllSelected(e, true, setSelectedUserGroups, filteredUserGroups)}>(Select All)</a>
              }
            </div>
          </TableCell>
          <TableCell className={classes.headerCell}>User Group</TableCell>
          {props.showAdminToggle && (
            <TableCell className={classes.headerCell}>Set as Admin</TableCell>
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        {filteredUserGroups &&
          filteredUserGroups.map(userGroup => (
            <TableRow
              key={userGroup.userGroupId}
              className={classes.item}
              onClick={() => handleChangeItem(
                !isItemChecked(userGroup, selectedUserGroups, ['userGroupId', 'name']),
                userGroup,
                selectedUserGroups,
                setSelectedUserGroups,
                ['userGroupId', 'name']
              )}
            >
              <TableCell>
                <Checkbox
                  checked={isItemChecked(userGroup, selectedUserGroups, ['userGroupId', 'name'])}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </TableCell>
              <TableCell>
                <Typography>{userGroup.name}</Typography>
              </TableCell>
              {props.showAdminToggle && (
                <TableCell>
                  <Switch
                    disabled={!isItemChecked(userGroup, selectedUserGroups, ['userGroupId', 'name'])}
                    onClick={(e) => { handleAdminChange(userGroup.userGroupId); e.stopPropagation(); }}
                  />
                </TableCell>
              )}
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );

  const getEmpty = () => (
    <Alert severity="info" style={{ margin: '0 20px' }}>
      No remaining groups to add.
    </Alert>
  );

  const getContent = () =>
    filteredUserGroups && filteredUserGroups.length > 0
      ? groupItems()
      : getEmpty();

  return (
    <>
      <Dialog
        open={props.open}
        aria-labelledby="form-dialog-title"
        maxWidth={'sm'}
        fullWidth={true}
      >


        <DialogContent className={classes.dialogContent}>
          <Grid container className={classes.panelHeader}>
            <Grid item xs={12} md={8}>
              <Typography variant="h2">Add User Group</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <DashboardSearchBox
                placeholderText={'Search'}
                value={''}
                onFilterChange={handleFilterChange}
              />
            </Grid>
          </Grid>
          <form action="#" onSubmit={(ev: any) => handleFormSubmit(ev)}>
            <Grid xs={12} md={12} item>
              <Paper component="ul" className={classes.root}>
                {chipData.map(cData => {
                  return (
                    <li key={cData.key}>
                      <Chip
                        size="small"
                        label={cData.label}
                        onDelete={() => {
                          handleDeleteItem(cData, selectedUserGroups, setSelectedUserGroups, 'userGroupId', 'name')
                        }}
                      />
                    </li>
                  );
                })}
              </Paper>
            </Grid>
          </form>
          <Grid item style={{ maxHeight: 500, overflow: 'auto' }}>
            {isLoading ? <ProgressBar /> : getContent()}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              reset(setSelectedUserGroups);
              props.handleClose();
            }}
            size="small"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            size="small"
            disabled={!selectedUserGroups.length}
            onClick={() => {
              props.handleSave(selectedUserGroups);
              reset(setSelectedUserGroups);
            }}
          >
            Save
          </Button>
        </DialogActions>

      </Dialog>
    </>
  );
}
