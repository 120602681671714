import { makeStyles } from '@material-ui/core';
export const useStyles = makeStyles(theme => ({
  removeButton: {
    color: theme.palette.common.white,
    background: theme.palette.grey[600],
    border: '1px solid ' + theme.palette.grey[600],
    '&:hover': {
      background: theme.palette.grey[700],
      border: '1px solid ' + theme.palette.grey[700],
    },
  },
  userDetailCard: {
    padding: 0,
  },
  progressContainer: {
    padding: theme.spacing(3),
    position: 'fixed',
    bottom: '0',
    left: '50vw',
  },
  cardHeader: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));
