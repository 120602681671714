import React from "react";
import Typography from "@material-ui/core/Typography";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";

type DeleteModalProps = {
  open: boolean;
  handleClose: (UserGroup: string) => void;
  title: any;
};

export const DeleteModal = (props: DeleteModalProps) => {
  return (
    <React.Fragment>
      <Dialog
        open={props.open}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <Typography>Are you sure?</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            size="small"
            onClick={() => props.handleClose(null)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            size="small"
            onClick={() => props.handleClose("test")}
          >
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
