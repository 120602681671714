import React from 'react';
import { hasPermission, UserPermissions } from '../../access';
import { GroupsTabOnly } from './groups-only';
import { UsersGroupsTabs } from './users-groups';

export const UsersPage = () => {
  if (!hasPermission(UserPermissions.CanManageUsers)) {
    return <GroupsTabOnly />
  }

  return <UsersGroupsTabs />
};

