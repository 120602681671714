import { ServerValidationErrors } from '../api/lib/domain';
import { Box, Typography, Divider } from '@material-ui/core';
import React from 'react';

type FormErrorsProps = {
  errors: Record<string, string[]>
}

export const FormErrors = ({ errors }: FormErrorsProps) => {
  if (!errors || Object.keys(errors).length === 0) return null;

  return (
    <Box>
      <Divider />
      <Typography variant="overline" display="block" gutterBottom>
        Form Errors:
      </Typography>
      <ul>
        {Object.keys(errors).map(key => (
          <li key={key}>{errors[key][0]}</li>
        ))}
      </ul>
    </Box>
  );
};
