import React from 'react';
import {ImageProps} from "../../schema";
import {withImageFallback} from "./withImageFallback";
import {TransformComponent, TransformWrapper} from "react-zoom-pan-pinch";
import {useStyles} from "./useStyles";
import {CenterFocusWeak, ZoomOut, ZoomIn} from "@material-ui/icons";
import {IconButton} from "@material-ui/core";

export const ZoomImage = (props: ImageProps) => {
  const classes = useStyles();

  return withImageFallback(props, (
    <TransformWrapper
      initialScale={0.25}
      minScale={0.25}
      maxScale={2}
      centerZoomedOut={true}
      centerOnInit={true}
    >
      {({ zoomIn, zoomOut, resetTransform }) => (
        <div className={classes.zoomContainer}>
          <TransformComponent wrapperClass={classes.zoomImageWrapper}>
            <img
              {...props}
              onError={props.onError}
            />
          </TransformComponent>
          <div className={classes.zoomTools}>
            <IconButton onClick={() => zoomIn()} className={classes.zoomButton} color="default" size="small">
              <ZoomIn />
            </IconButton>
            <IconButton onClick={() => zoomOut()} className={classes.zoomButton} color="default" size="small">
              <ZoomOut />
            </IconButton>
            <IconButton onClick={() => resetTransform()} className={classes.zoomButton} color="default" size="small">
              <CenterFocusWeak />
            </IconButton>
          </div>
        </div>
      )}
    </TransformWrapper>
  ));
};
