import { useState } from 'react';
import { Auth } from 'aws-amplify';
import { AppContextType } from '../../context';
import { UserDetails } from '../../api/isc-api';

type SignedInUser = {
  username: string;
};

export const useSendConfirmationCode = (
  onSuccess?: () => void,
  onError?: (err: Error) => void
) => {
  const [sendingCode, setSendingCode] = useState(false);

  const handleSendCode = () => {
    setSendingCode(true);
    Auth.currentAuthenticatedUser()
      .then(async (user: SignedInUser) => {
        await Auth.forgotPassword(user.username);
        setSendingCode(false);
        onSuccess && onSuccess();
      })
      .catch((error: Error) => {
        setSendingCode(false);
        onError && onError(error);
      });
  };

  return { sendingCode, handleSendCode };
};
