import { makeStyles } from '@material-ui/core';
export const useStyles = makeStyles(theme => ({
  recognitionCard: {
    marginTop: '16px'
  },
  dialog: {
    "& .MuiDialog-paper": {
      maxHeight: '90vh',
      ["@media (min-height: 40em)"]: {
        maxHeight: '40em'
      },
    }
  },
  dialogTitle: {
    "& .MuiTypography-root": {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
  closeButton: {
    position: 'relative',
    top: '-0.25em',
    right: '-0.5em',
    "& .MuiSvgIcon-root": {
      fontSize: '0.9em',
      color: theme.palette.common.black,
    },
  },
  detailPanelTitle: {
    padding: '16px 16px 0 16px',
  },
  iconHeading: {
    display: 'flex',
    alignItems: 'center',
    "& .MuiSvgIcon-root": {
      marginRight: '0.25em',
      fontSize: '18px'
    },
  },
  detailRow: {
    display: 'flex',
    borderBottom: '1px solid ' + theme.palette.grey[300],
    margin: '0 16px',
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  detailContent: {
    padding: '16px',
  },
  detailContentHeader: {
    fontWeight: 700,
    marginTop: '0.75em',
    "&:first-child": {
      marginTop: 0,
    },
  },
  summaryListItem: {
    paddingLeft: 0,
    borderTop: '1px solid ' + theme.palette.grey[300],
    display: 'flex',
    alignItems: 'center',
    "& .MuiListItemText-root": {
      color: theme.palette.grey[800],
    }
  },
  detailList: {
    width: `calc(100% + 48px)`,
    marginLeft: '-24px',
  },
  detailListItem: {
    padding: '4px 24px',
    borderBottom: '1px solid ' + theme.palette.grey[300],
    display: 'flex',
    alignItems: 'center',
    ".MuiList-root:last-child .MuiListItem-root:last-child &": {
      borderBottom: 'none'
    },
    "& .MuiListItemText-root": {
      color: theme.palette.grey[800],
    },
    "& strong": {
      color: theme.palette.common.black,
    }
  },
  detailListItemTitle: {
    fontWeight: 'bold',
    color: theme.palette.common.black,
    display: 'inline-block',
    marginRight: '0.5em',
  },
  detailListItemBestMatch: {
    "& .MuiListItemText-primary": {
      fontWeight: 'bold',
      color: theme.palette.common.black,
    },
  },
  detailListItemIcon: {
    fontSize: '1em',
    marginRight: '0.25em'
  },
  recognitionTypeHeading: {
    fontSize: '22px',
    fontFamily: '"Roboto Condensed", "Helvetica", "Arial", sans-serif',
    fontWeight: 'bold'
  },
  recognitionTypeSubhead: {
    marginTop: '0.75em',
    fontSize: '14px',
    fontWeight: 'bold',
    letterSpacing: '1px'
  },

}));
