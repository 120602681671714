import { makeStyles, Theme, ToolbarProps } from '@material-ui/core';
export const useStyles = makeStyles<Theme, ToolbarProps>((theme) => ({
  appBar: {
    display: 'flex',
    boxShadow: 'none',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  toolbar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: '35px',
    paddingRight: '35px',
  },
  menuButton: {
    paddingLeft: '0px',
    paddingRight: '0px',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  menuList: {
    display: 'none',
    alignSelf: 'stretch',
    padding: '0px',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  menuListItem: {
    color: theme.palette.grey[500],
    position: 'relative',
    borderBottom: '2px solid transparent',
    marginLeft: '20px',
    marginRight: '20px',
    paddingLeft: '10px',
    paddingRight: '10px',
    cursor: 'pointer',
    textTransform: 'uppercase',
    fontWeight: 500,
    fontSize: '0.85rem',
    display: 'flex',
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    '&:hover': {
      color: theme.palette.primary.contrastText,
      borderBottom: '2px solid white',
    },
  },
  menuListItemSelected: {
    borderBottom: '2px solid white',
    color: '#FFFFFF',
  },
  menuTextItem: {
    lineHeight: '1',
    alignSelf: 'flex-end',
    marginBottm: '0px',
    color: 'inherit',
    textTransform: 'inherit',
  },
  iconBar: {
    display: 'none',
    flexDirection: 'row',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center',
    },
  },
  controlBar: {
    display: 'flex',
    flexDirection: 'row',
  },
  menuButtonHidden: {
    display: 'none',
  },
  toolbarLogo: {
    display: 'none',
    alignItems: 'center',
    width: '75px',
    cursor: 'pointer',
    ...theme.mixins.toolbar,
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      width: 'auto',
    },
  },
  logo: {
    maxHeight: '35px',
  },
  userButton: {
    color: theme.palette.primary.contrastText,
    '& svg': {
      fontSize: '0.8em',
      marginTop: theme.spacing(1),
    },
  },
  adminButton: {
    color: theme.palette.primary.contrastText,
    '& svg': {
      fontSize: '1.4em',
      margin: theme.spacing(1, 0, 0),
    },
  },
  adminMenuLink: {
    display: 'flex',
    alignItems: 'center',
    '&, &:hover, &:active': {
      color: 'inherit',
      textDecoration: 'none',
    },
  },
  loginButton: {
    textDecoration: 'none'
  }
}));
