import React from "react";
import { IconButton, makeStyles } from "@material-ui/core";
import {ChevronLeft, ChevronRight, Fullscreen, LocalOffer} from "@material-ui/icons";

type ArrowProps = {
  direction: string;
  handleClick: () => void;
};

type FullScreenButtonProps = {
  onClick: () => void;
};

type MetadataButtonProps = {
  onClick: () => void;
};

const useStyles = makeStyles((theme) => ({
  arrow: (props: ArrowProps) => ({
    display: "flex",
    position: "absolute",
    top: "50%",
    right: `${props.direction === "right" && "16px"}`,
    left: `${props.direction !== "right" && "16px"}`,
    justifyContent: "center",
    background: theme.palette.grey[200],
    borderRadius: "50%",
    cursor: "pointer",
  }),
  fullScreenButton: {
    position: "absolute",
    top: theme.spacing(2) - 2,
    right: theme.spacing(2),
    padding: '0.1em',
    color: '#fff',
    background: theme.palette.grey[900] + '33', // rgba
    "&:hover": {
      background: theme.palette.grey[800],
    },
    "& .MuiSvgIcon-root": {
      fontSize: "24px",
    }
  },
  metadataButton: {
    position: "absolute",
    top: theme.spacing(2),
    left: theme.spacing(2),
    padding: '0.25em',
    color: '#fff',
    background: theme.palette.grey[900] + '33', // rgba
    "&:hover": {
      background: theme.palette.grey[800],
    },
    "& .MuiSvgIcon-root": {
      fontSize: "20px",
    }
  }
}))

export const Arrow = (props: ArrowProps) => {
  const classes = useStyles(props);
  return (
    <div
      onClick={props.handleClick}
      className={classes.arrow}
    >
      {props.direction === 'right' ? <IconButton size="small"><ChevronRight /></IconButton> : <IconButton size="small"><ChevronLeft /></IconButton>}
    </div>
  )
}

export const FullScreenButton = (props: FullScreenButtonProps) => {
  const classes = useStyles(props);
  return (
    <IconButton onClick={props.onClick} className={classes.fullScreenButton} color="default" size="small">
      <Fullscreen />
    </IconButton>
  )
}

export const MetadataButton = (props: MetadataButtonProps) => {
  const classes = useStyles(props);
  return (
    <IconButton onClick={props.onClick} className={classes.metadataButton} color="default" size="small">
      <LocalOffer />
    </IconButton>
  )
}
