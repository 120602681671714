import {
  SettingsOption,
  SettingsSingleOption,
  SettingsRangeOption,
  SettingsDependency,
} from '.';
import {AuthOrganizationsGetResponseData, CameraSettingsSettings} from '../api/isc-api';
import {hasPermission, UserPermissions} from "../access";
import UserPermissionsEnum = AuthOrganizationsGetResponseData.UserPermissionsEnum;

export const flattenOptions = (
  source: SettingsOption[]
): SettingsSingleOption[] =>
  [].concat.apply(
    [],
    source.map(item => {
      const option = item as SettingsSingleOption;
      if (typeof option.label != 'undefined')
        return option.hide ? [] : [option];

      const range = item as SettingsRangeOption;
      if (typeof range.min != 'undefined') {
        const step = range.step || 1;
        const count = Math.floor((range.max - range.min) / step) + 1;
        return new Array(count).map((_, index) => {
          const value = range.min + step * index;
          return {
            label: value.toString(),
            value,
          };
        });
      }

      return {
        label: item.toString(),
        value: item,
      };
    })
  );

export const nestedProperty = (o: { [key: string]: any }, path: string[]): any => {
  return path.length === 1
    ? o[path[0]]
    : nestedProperty(o[path[0]], path.slice(1));
};

export const dependencyChecker =
  (
    settings: CameraSettingsSettings
  ): ((dependencies: SettingsDependency, depPermissions?: string|string[]) => boolean) =>
    (dependencies, depPermissions) => {
      if (!dependencies && !depPermissions) return true;

      let dependenciesMatch = true;
      let depPermissionsMatch = true;

      if (dependencies) {
        dependenciesMatch = Object.entries(dependencies).every(([key, dep]) => {
          try {
            const value = nestedProperty(
              settings,
              key.split('.')
            );
            return Array.isArray(dep)
              ? (dep as Array<any>).includes(value)
              : dep == value;
          } catch {
            console.error('Dependency check failed for', key, dep);
            return false;
          }
        });
      }

      if (depPermissions) {
        const permissions = Array.isArray(depPermissions) ? depPermissions : [depPermissions];

        depPermissionsMatch = permissions.every(permission => {
          try {
            return hasPermission(permission as UserPermissions);
          } catch {
            console.error('Permission dependency check failed for', permission);
            return false;
          }
        });
      }

      return dependenciesMatch && depPermissionsMatch
  };
