import { makeStyles } from '@material-ui/core';
export const useStyles = makeStyles(theme => ({
  root: {
    width: '320px',
    margin: '0 auto',
    padding: theme.spacing(1, 0),
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));
