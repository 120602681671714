import { AvailableOrganizationUserRole } from '.';
import { useState, useEffect } from 'react';
import { UserRole } from '../../api/isc-api';

export const useOrganizationRoles = (
  _availableRoles: AvailableOrganizationUserRole[]
) => {
  const [availableRoles, setAvailableRoles] =
    useState<AvailableOrganizationUserRole[]>(_availableRoles);
  const [selectedRoles, setSelectedRoles] = useState<UserRole[]>();
  const [originalRoles] = useState(_availableRoles);

  const handleRoleSelection = (
    role: AvailableOrganizationUserRole,
    isSelected: boolean
  ) => {
    const modifiedRoles = updateAvailableRoles(
      availableRoles,
      role,
      isSelected
    );

    setAvailableRoles(modifiedRoles);
  };

  const resetRoles = () => {
    setAvailableRoles(originalRoles);
  };

  useEffect(() => {
    setSelectedRoles(getSelectedRoles(availableRoles));
  }, [availableRoles]);

  return { resetRoles, selectedRoles, availableRoles, handleRoleSelection };
};

/**
 * Returns selected organization roles as an array of UserRole
 */
function getSelectedRoles(availableRoles: AvailableOrganizationUserRole[]) {
  const usersSelectedRoles: UserRole[] = [];

  for (const role of availableRoles) {
    if (role.isMember) {
      usersSelectedRoles.push({
        userRoleId: role.userRoleId,
        name: role.name,
      });
    }
  }

  return usersSelectedRoles;
}

/**
 * Returns organizations available roles with the state of the user's access
 *
 * @param availableRoles
 * @param role
 * @param isSelected
 */
function updateAvailableRoles(
  availableRoles: AvailableOrganizationUserRole[],
  role: AvailableOrganizationUserRole,
  isSelected: boolean
) {
  const modifiedRoles: AvailableOrganizationUserRole[] = [...availableRoles];

  const result = modifiedRoles.findIndex(
    ({ userRoleId }) => userRoleId === role.userRoleId
  );

  modifiedRoles[result] = {
    ...modifiedRoles[result],
    isMember: isSelected,
  };

  return modifiedRoles;
}
