import { processResponse } from "./lib/helpers";
import { Pagination } from "./lib/domain";

const apiURL = process.env.API_URL + '/app';

export type CameraImei = {
  cameraId: string
  imei: string
}

export type Customer = {
  customerId: string
  defaultPaymentMethod: {
    id: string
    type: string
    description: string
    card: {
      card_brand: string
      card_number: string
      card_type: string
      expiry: string
    }
  }
}

export type InvoiceStatus = 'created' | 'processing' | 'paid'

export type Invoice = {
  invoiceId: string
  accountId: string /// TODO unsure if new model uses orgId, missing definition in Swagger
  organizationId: string
  subscriptionId?: string

  invoiceNo: number
  description: string

  created: Date
  due: Date
  paid: Date

  amount: number
  status: InvoiceStatus
}

export type InvoiceItem = {
  itemId: string
  invoiceId: string
  cameraId?: string
  price: number
  qty: number
  note: string
}

export type PaymentStatus = 'processing' | 'successful' | 'failed'

export type Payment = {
  paymentId: string
  accountId: string /// TODO unsure if new model uses orgId, missing definition in Swagger
  organizationId: string
  amount: number
  created: string
  status: PaymentStatus
  payloadPaymentId?: string
  source?: string
}

export type SubscriptionRequest = {
  cameraIds: string[]
  subscriptionType: string
}

export default function() {

  const options = {
    headers: {
      'Authorization': 'Bearer '
    }
  }

  const optionsPost = {
    method: 'POST',
    headers: {
      'Authorization': 'Bearer ',
      'Content-Type': 'application/json',
    }
  }

  return {
    newCameras: async ():Promise<{cameras:CameraImei[]}> => {
      const response = await fetch(`${apiURL}/billing/new-cameras`, options)
      return processResponse(response)
    },

    customer: async ():Promise<{customer:Customer}> => {
      const response = await fetch(`${apiURL}/billing/customer`, options)
      return processResponse(response)
    },

    pricing: async ():Promise<{monthly: number, yearly: number}> => {
      const response = await fetch(`${apiURL}/billing/pricing`, options)
      return processResponse(response)
    },

    subscribe: async (request:SubscriptionRequest):Promise<string> => {
      const response = await fetch(`${apiURL}/billing/subscribe`, {
        ...optionsPost,
        body: JSON.stringify(request)
      })
      const result:any = await processResponse(response)
      return result.invoice.invoiceId
    },

    getInvoice: async(invoiceId: string):Promise<{invoice: Invoice, items: InvoiceItem[], payment: Payment}> => {
      const response = await fetch(`${apiURL}/billing/invoices/${invoiceId}`, options)
      return processResponse(response)
    },

    getInvoices: async(page = 0):Promise<{ invoices: Invoice[], pagination: Pagination}> => {
      const response = await fetch(`${apiURL}/billing/invoices?page=${page}`, options)
      return processResponse(response)
    },

    payInvoice: async(invoiceId: string):Promise<void> => {
      const response = await fetch(`${apiURL}/billing/invoices/${invoiceId}/pay`, optionsPost)
      return processResponse(response)
    },

  }
}
