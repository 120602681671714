import { useApiUpdate } from '../../../../../../hooks';
import {Dispatch, SetStateAction} from "react";
import {
  RecognitionSettingsLprBody,
  RecognitionSettingsLprSubjectPutResponseData,
  Subject,
} from '../../../../../../api/isc-api';
import { RecognitionAPI } from '../../../../../../api/recognition';
import { AppContextType } from '../../../../../../context';

type CreateProfileArgs = {
  context: AppContextType;
  handleSetCameraProfile: (
    profileList: Subject[],
    subjectIds: string[]
  ) => Promise<void>;
  resetSubjectWithId: Dispatch<SetStateAction<string>>;
  resetAppliedProfiles: Dispatch<SetStateAction<string[]>>;
};

// Missing type
type TempCreateReponse = RecognitionSettingsLprSubjectPutResponseData & {
  subjectProfile: {
    subjectId: string;
  };
};

export const useCreateProfile = ({
  context,
  handleSetCameraProfile,
  resetSubjectWithId,
  resetAppliedProfiles,
}: CreateProfileArgs) => {
  const [doCreateLprProfile] = useApiUpdate<
    RecognitionSettingsLprBody,
    RecognitionSettingsLprSubjectPutResponseData
  >(body => RecognitionAPI.createLprProfile(body));

  /**
   * On profile creation there are 2 steps:
   *
   * 1- Create new profile entity
   * 2- Set selected camera profile to the new profile entity
   * @param body
   */
  const handleCreateProfile = async (
    body: RecognitionSettingsLprBody,
    profileList: Subject[],
    appliedProfiles: string[]
  ) => {
    let profile: TempCreateReponse;

    // 1- Create profile entity
    try {
      profile = (await doCreateLprProfile(body)) as TempCreateReponse;
    } catch (e) {
      console.log(e);
      context.onAlert(
        'Sorry, there was a problem trying to create this profile.',
        'error'
      );
    }

    // Bail and display an errror
    if (!profile) {
      context.onAlert(
        'Profile was not returned from server, cannot attach to camera.',
        'error'
      );

      return;
    }

    const updatedAppliedProfiles = [...appliedProfiles, ...[profile.subjectProfile.subjectId]];

    resetSubjectWithId(profile.subjectProfile.subjectId);
    resetAppliedProfiles(updatedAppliedProfiles);

    // 2- Set selected camera profiles by appending the new ID to the previously-applied profiles
    await handleSetCameraProfile(profileList, updatedAppliedProfiles);
    context.onAlert('Profile was created successfully.');
  };

  return handleCreateProfile;
};
