import React, { useState, useEffect } from 'react';
import { Date, Text } from '../../../../../../components/form-fields';
import { Button, Typography, Divider } from '@material-ui/core';
import { useStyles } from './useStyles';
import { warrantFields } from './warrant-fields';
import {
  Warrant,
  RecognitionSettingsLprBodyWarrant,
  RecognitionSettingsWarrantExtensionBody,
} from '../../../../../../api/isc-api';
import { resolveExpirationDate, resolveExtendedWarrantNumber } from './helpers';

export type WarrantFormKeys = keyof typeof warrantFields | string;
export type WarrantFormErrors = Record<WarrantFormKeys, string>;

type WarrantFormProps = {
  warrant: Warrant;
  onValueChanges: (warrant: RecognitionSettingsLprBodyWarrant) => void;
  setWarrantExtension: React.Dispatch<
    React.SetStateAction<RecognitionSettingsWarrantExtensionBody>
  >;
  errors?: WarrantFormErrors;
  isWarrantExpired: boolean;
};

const WarrantFormComponent = ({
  warrant,
  isWarrantExpired,
  onValueChanges,
  setWarrantExtension,
  errors,
}: WarrantFormProps) => {
  const classes = useStyles();
  const [warrantNumber, setWarrantNumber] = useState(
    warrant?.warrantNumber || ''
  );
  const [expirationDate, setExpirationDate] = useState(
    (warrant?.expirationDate?.dateTimeLocal as unknown as string) || ''
  );
  const [internalReferenceNumber, setInternalReferenceNumber] = useState(
    warrant?.internalReferenceNumber || ''
  );
  const [notes, setNotes] = useState(warrant?.notes || '');
  const [isExtendingWarrant, setIsExtendingWarrant] = useState(false);
  const [extensionNumber, setExtensionNumber] = useState('');
  const [newExpirationDate, setNewExpirationDate] = useState('');
  const [extensionNotes, setExtensionNotes] = useState('');
  const [formErrors, setErrors] = useState<WarrantFormErrors>(errors);

  const isExistingWarrant = warrant !== null;
  const hasExtensions = warrant?.extensions?.length > 0;

  // The warrant number field will vary in text based on if
  // we're looking at a warrant that has been extended or not
  const fieldWarrantNumber = hasExtensions
    ? warrantFields.extendedWarrantNumber
    : warrantFields.warrantNumber;

  const fieldExtensionDate = hasExtensions
    ? warrantFields.extendedExpirationDate
    : warrantFields.expirationDate;

  const originalExpirationDate =
    (warrant?.expirationDate?.dateTimeLocal as unknown as string) || '';

  const originalWarrantNumber = warrant?.warrantNumber || '';

  // Returns most recent extended warrant # or original when extensions.len = 0
  const resolvedExtensionNumber = resolveExtendedWarrantNumber(warrant);

  const extendedDate = resolveExpirationDate(warrant);

  const handleWarrantNumber = (value: string) => {
    setWarrantNumber(value);
  };

  const handleWarrantExpirationDate = (value: string) => {
    // Disable for existing warrants
    if (isExistingWarrant) return;

    setExpirationDate(value);
  };

  const handleInternalReferenceNumber = (value: string) => {
    setInternalReferenceNumber(value);
  };

  const handleNotes = (value: string) => {
    setNotes(value);
  };

  const handleExtensionNumber = (value: string) => {
    setExtensionNumber(value);
  };

  const handleNewExpirationDate = (value: string) => {
    setNewExpirationDate(value);
  };

  const handleExtensionNotes = (value: string) => {
    setExtensionNotes(value);
  };

  const runWarrantFormValidation = () => {
    // Ensure dirty form
    if (
      warrant &&
      warrant.warrantNumber === warrantNumber &&
      (warrant.expirationDate.dateTimeLocal as unknown as string) ===
        expirationDate &&
      warrant.internalReferenceNumber === internalReferenceNumber &&
      warrant.notes === notes
    ) {
      return false;
    }

    // Ensure not empty
    if (!warrantNumber && !expirationDate) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    // Validation
    if (!runWarrantFormValidation()) {
      return;
    }

    onValueChanges({
      warrantNumber,
      expirationDate,
      internalReferenceNumber,
      notes,
    });
  }, [warrantNumber, expirationDate, internalReferenceNumber, notes]);

  const runExtensionFormValidation = () => {
    if (!extensionNumber || !newExpirationDate || !extensionNotes) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (!runExtensionFormValidation()) {
      return;
    }

    setWarrantExtension({
      extensionNumber,
      expirationDate: newExpirationDate,
      notes: extensionNotes,
    });
  }, [extensionNumber, newExpirationDate, extensionNotes]);

  // Clear form
  useEffect(() => {
    if (warrant !== null) return;

    setWarrantNumber('');
    setInternalReferenceNumber('');
    setExpirationDate('');
    setNotes('');
  }, [warrant]);

  // TODO:
  // - API is not preventing submission of faulty data, I can set expirations in the past
  // - Reset

  return (
    <div>
      {/* Display original number/date when warrant has extension  */}
      {hasExtensions && (
        <>
          <Text
            group={warrantFields.group}
            item={warrantFields.originalWarrantNumber}
            value={originalWarrantNumber}
            readOnly={true}
          />
          <Divider />
          <Date
            group={warrantFields.group}
            item={warrantFields.originalExpirationDate}
            value={originalExpirationDate}
            readOnly={true}
          />
          <Divider />
        </>
      )}

      {/* Warrent number field can handle both "original" / "extended" treatments  */}
      <Text
        group={warrantFields.group}
        item={fieldWarrantNumber}
        value={warrantNumber}
        onValueChanges={handleWarrantNumber}
        readOnly={isExistingWarrant}
      />

      <Divider />

      <div className={classes.expiredRow}>
        {hasExtensions || isExistingWarrant ? (
          /* Read only extended/existing date, item prop set dynamically */
          <Date
            group={warrantFields.group}
            item={fieldExtensionDate}
            value={resolveExpirationDate(warrant)}
            isError={isWarrantExpired}
            readOnly={true}
          />
        ) : (
          /* Adding */
          <Date
            group={warrantFields.group}
            item={warrantFields.expirationDate}
            value={expirationDate}
            onValueChanges={handleWarrantExpirationDate}
            readOnly={false}
          />
        )}

        {/* Displays the extend warrant button */}
        {isWarrantExpired && (
          <>
            <Divider className={classes.extendRowMobileDivider} />
            <div className={classes.extendWarrantContainer}>
              <Typography
                color="error"
                style={{ paddingBottom: '4px', textAlign: 'center' }}
              >
                Warrant has expired.
              </Typography>
              {!isExtendingWarrant && (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setIsExtendingWarrant(true)}
                >
                  Extend Warrant
                </Button>
              )}
            </div>
          </>
        )}
      </div>

      {/* Warrant extension form fields */}
      {isExtendingWarrant && (
        <>
          <Text
            group={warrantFields.group}
            item={warrantFields.extensionNumber}
            value={extensionNumber}
            onValueChanges={handleExtensionNumber}
          />
          <Divider />
          <Date
            group={warrantFields.group}
            item={warrantFields.newExpirationDate}
            value={newExpirationDate}
            onValueChanges={handleNewExpirationDate}
          />
          <Divider />
          <Text
            group={warrantFields.group}
            item={warrantFields.extensionNotes}
            value={extensionNotes}
            onValueChanges={handleExtensionNotes}
          />
        </>
      )}
      <Divider />

      {/* Remaining form fields  */}
      <Text
        group={warrantFields.group}
        item={warrantFields.internalReferenceNumber}
        value={internalReferenceNumber}
        onValueChanges={handleInternalReferenceNumber}
        readOnly={isExistingWarrant}
      />
      <Divider />
      <Text
        group={warrantFields.group}
        item={warrantFields.notes}
        value={notes}
        onValueChanges={handleNotes}
      />
      <Divider />
    </div>
  );
};

export const WarrantForm = React.memo(WarrantFormComponent);
