import React, { useState } from 'react';
import { useApi } from '../../hooks';
import { UserGroupsAPI } from '../../api/user-groups';
import { Grid, makeStyles } from '@material-ui/core';
import { UsersSubgroupsPanel } from './users-subgroups-panel';
import { CameraPanel } from './camera-panel';
import { UserGroupDetails, UserGroup } from '../../api/isc-api';
import { hasPermission, UserPermissions } from '../../access';
import { GroupTitle } from './title-group';
import { AlertSnackPortal } from '../../components/alert-snack-portal';
const useStyles = makeStyles(theme => ({
  deleteButton: {
    color: theme.palette.common.white,
    background: theme.palette.grey[600],
    border: '1px solid ' + theme.palette.grey[600],
    '&:hover': {
      background: theme.palette.grey[700],
      border: '1px solid ' + theme.palette.grey[700],
    },
  },
}));

export type ParentsProps = {
  userGroups?: UserGroup[];
};

export const GroupPage = (props: any) => {
  const classes = useStyles(props);
  const userGroupId = props.match.params.userGroupId;
  const [userGroup, setUserGroup] = useApi<UserGroupDetails>(
    () => UserGroupsAPI.get(userGroupId),
    '/users/groups',
    [userGroupId]
  );
  const [groupParents] = useApi<ParentsProps>(
    () => UserGroupsAPI.getParents(userGroupId),
    { redirect: '/group/' + userGroupId, message: '' },
    [userGroupId]
  );

  const [isLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(null);
  const isAdmin = hasPermission(UserPermissions.CanManageOrganization);

  if (userGroup === null) return null;

  return (
    <>
      <GroupTitle
        groupParents={groupParents?.userGroups}
        userGroup={userGroup}
        isAdmin={isAdmin}
        classes={classes}
        setUserGroup={setUserGroup}
        setShowAlert={setShowAlert}
      />
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <UsersSubgroupsPanel
            setUserGroup={setUserGroup}
            userGroup={userGroup}
            isLoading={isLoading}
            setShowAlert={setShowAlert}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <CameraPanel
            setUserGroup={setUserGroup}
            userGroup={userGroup}
            isLoading={isLoading}
            setShowAlert={setShowAlert}
          />
        </Grid>
      </Grid>
      <AlertSnackPortal alert={showAlert} onClose={() => setShowAlert(null)} />
    </>
  );
};
