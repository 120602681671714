import { usStates } from './select-states';
import {
  SettingsGroup,
  SettingsSelectItem,
  SettingsTextItem,
} from '../../../../../schema';

export const lprProfileFields = {
  group: <SettingsGroup>{
    name: 'subject_profile',
    label: 'Subject Profile',
  },
  subjectLocation: <SettingsSelectItem>{
    type: 'select',
    name: 'state',
    label: 'Subject State',
    options: usStates,
  },
  licensePlateNumber: <SettingsTextItem>{
    type: 'text',
    name: 'License Plate Number',
    label: 'License Plate Number',
  },
  notes: <SettingsTextItem>{
    type: 'text',
    name: 'Notes (optional)',
    label: 'Subject Notes',
  },
};

