import React, { useContext, Fragment, useEffect, useState } from 'react';
import { useApi } from '../../../hooks';
import {
  OrganizationSettingsAPI,
  SmtpSettings,
} from '../../../api/organization-settings';
import AppContext from '../../../context';
import { Text, Select } from '../../../components/form-fields';
import {
  Card,
  CardContent,
  CardActions,
  Button,
  List,
} from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import isEqual from 'lodash/isEqual';
import { ProgressBar } from '../../../components/progress-bar';
import { useStyles } from './smtp-form-styles';

export const SMTPForm = (props: any) => {
  const { setFormDirtyState } = props;

  const context = useContext(AppContext);

  const classes = useStyles();
  const [data, , isLoading] = useApi(() =>
    OrganizationSettingsAPI.getSMTPSettings()
  );

  const [isSaving, setIsSaving] = useState(false);
  const [form, setForm] = useState<SmtpSettings>();

  const doSave = async (form: SmtpSettings) => {    
    try {
      setIsSaving(true);
      await OrganizationSettingsAPI.updateSMTPSettings(form);
      setFormDirtyState(false);
      await context.refreshOrgData();
      context.onAlert('SMTP settings updated.', 'success');
    } catch (e) {
      context.onAlert(e.detail, 'error');
    }
    setIsSaving(false);
  };

  const updateForm = (form: SmtpSettings) => {
    setForm(form);

    if (isEqual(form, data)) {
      setFormDirtyState(false);
    } else {
      setFormDirtyState(true);
    }
  };

  const handleCancel = () => {
    updateForm(data);
  };

  useEffect(() => {
    setForm(data);
  }, [data]);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    doSave(form);
  };

  const getCardContent = () => (
    <List dense disablePadding>
      <Text
        group={{ name: 'smtp', label: 'SMTP', items: [] }}
        item={{ label: 'Host name', name: 'host-name', type: 'text' }}
        value={form.host}
        onValueChanges={(value: any) => updateForm({ ...form, host: value })}
      />
      <Text
        group={{ name: 'smtp', label: 'SMTP', items: [] }}
        item={{ label: 'User name', name: 'user-name', type: 'text' }}
        value={form.username}
        onValueChanges={(value: any) =>
          updateForm({ ...form, username: value })
        }
      />
      <Text
        group={{ name: 'smtp', label: 'SMTP', items: [] }}
        item={{ label: 'Password', name: 'password', type: 'text' }}
        value={form.password}
        onValueChanges={(value: any) =>
          updateForm({ ...form, password: value })
        }
      />
      <Text
        group={{ name: 'smtp', label: 'SMTP', items: [] }}
        item={{ label: 'From address', name: 'from', type: 'text' }}
        value={form.from}
        onValueChanges={(value: any) => updateForm({ ...form, from: value })}
      />
      <Select
        group={{ name: 'smtp', label: 'SMTP', items: [] }}
        item={{
          label: 'Transfer Protocol',
          name: 'transfer-protocol',
          type: 'select',
          options: [
            { label: 'TLS (port 587)', value: 587 },
            { label: 'SSL (port 465)', value: 465 },
          ],
        }}
        value={form.port}
        onValueChanges={(value: any) =>
          updateForm({ ...form, port: parseInt(value) })
        }
      />
    </List>
  );

  return (
    <form action="" onSubmit={onSubmit}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          {isLoading ? <ProgressBar /> : getCardContent()}
        </CardContent>
        <Divider />
        <CardActions className={classes.cardActions}>
          <Button onClick={() => doSave(form)} color="primary">
            {isSaving ? 'Saving...' : 'Save'}
          </Button>
          <Button onClick={handleCancel}>Cancel</Button>
        </CardActions>
      </Card>
    </form>
  );
};
