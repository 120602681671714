import { CameraLocation } from '../../api/isc-api';
import React from 'react';
import { Typography } from '@material-ui/core';
import { OpenInNew } from '@material-ui/icons';
import { useStyles } from './details-styles';

type DetailsLocationProps = {
  location: CameraLocation;
};
export const DetailsLocation = ({ location }: DetailsLocationProps) => {
  const classes = useStyles();

  if (!location || !location.coordinates) return null;

  const mapsLink = `https://maps.google.com/?q=${location?.coordinates.latitude},${location?.coordinates.longitude}`;

  return (
    <div className={classes.detailRow}>
      <div className={classes.detailContent}>
        <Typography variant="body1" className={classes.detailContentHeader}>
          Coordinates:
        </Typography>
        <Typography variant="body1">
          {location.coordinates.latitude},
        </Typography>
        <Typography variant="body1">
          {location.coordinates.longitude}
        </Typography>
      </div>
      <div className={classes.detailContent}>
        <Typography variant="body1" className={classes.detailContentHeader}>
          Location:
        </Typography>
        <a href={mapsLink} target="_blank" rel="noreferrer">
          View on Map <OpenInNew fontSize="inherit" />
        </a>
      </div>
    </div>
  );
};
