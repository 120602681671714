import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  IconButton,
} from '@material-ui/core';
import { CarouselContent } from './carousel-content';
import { CarouselSlide } from './carousel-slide';
import {Arrow, FullScreenButton, MetadataButton} from './carousel-buttons';
import {Close} from '@material-ui/icons';
import { useStyles } from './carousel-styles';
import {ZoomImage} from "../../../components/image/zoom-image";

type CarouselProps = {
  slides: any[];
  onSlideChange: (activeIndex: number) => void;
  onGotoMetadata: () => void;
};

export const Carousel = (props: CarouselProps) => {
  const classes = useStyles(props);
  let carouselRef = React.useRef(null);
  const [width, setWidth] = useState(0);
  const [isFullscreenOpen, setIsFullscreenOpen] = useState(false);
  const [state, setState] = useState({
    activeIndex: 0,
    translate: 0,
    transition: 0.45,
  });

  const { translate, transition, activeIndex } = state;

  const nextSlide = () => {
    if (activeIndex === props.slides.length - 1) {
      return setState({
        ...state,
        translate: 0,
        activeIndex: 0,
      });
    }

    setState({
      ...state,
      activeIndex: activeIndex + 1,
      translate: (activeIndex + 1) * width,
    });
  };

  const prevSlide = () => {
    if (activeIndex === 0) {
      return setState({
        ...state,
        translate: (props.slides.length - 1) * width,
        activeIndex: props.slides.length - 1,
      });
    }

    setState({
      ...state,
      activeIndex: activeIndex - 1,
      translate: (activeIndex - 1) * width,
    });
  };

  const gotoMetadata = () => {
    props.onGotoMetadata();
  }

  useEffect(() => {
    props.onSlideChange(activeIndex);
  }, [activeIndex]);

  useEffect(() => {
    function debounce(fn, ms) {
      let timer;
      return function (this) {
        clearTimeout(timer);
        timer = setTimeout(() => {
          timer = null;
          fn.apply(this, arguments);
        }, ms);
      };
    }

    const debouncedHandleResize = debounce(function handleResize() {
      if (carouselRef.current) {
        setWidth(parseInt(carouselRef.current.offsetWidth));
      }
    }, 200);

    // setWidth(Math.min(carouselRef.current.offsetWidth - 48, 608));
    setWidth(carouselRef.current.offsetWidth);

    window.addEventListener('resize', debouncedHandleResize);
    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, []);

  return (
    <div className={classes.slider} id="test" ref={carouselRef}>
      <CarouselContent
        translate={translate}
        transition={transition}
        width={width * props.slides.length}
      >
        {props.slides.map((slide, i) => (
          <CarouselSlide
            key={slide.type + i}
            content={slide.url}
            type={slide.type}
            width={width}
          />
        ))}
      </CarouselContent>
      <FullScreenButton onClick={() => setIsFullscreenOpen(true)} />
      {props.slides[activeIndex]?.hasMetadata ?
        <MetadataButton onClick={() => gotoMetadata()} /> : null
      }
      <Dialog
        open={isFullscreenOpen}
        onClose={() => setIsFullscreenOpen(false)}
        fullWidth
        maxWidth="md"
        className={classes.dialog}
      >
        <DialogContent className={classes.dialogContent}>
          {props.slides[activeIndex]?.type === 'Image' && (
            <ZoomImage src={props.slides[activeIndex].originalUrl ?? props.slides[activeIndex].url} />
          )}
          {props.slides[activeIndex]?.type === 'Video' && (
            <video controls>
              <source src={props.slides[activeIndex].url} />
            </video>
          )}
        </DialogContent>
        <IconButton
          onClick={() => setIsFullscreenOpen(false)}
          className={classes.closeButton}
          size="small"
        >
          <Close />
        </IconButton>
      </Dialog>
      {props.slides.length > 1 && (
        <>
          <Arrow direction="left" handleClick={prevSlide} />
          <Arrow direction="right" handleClick={nextSlide} />
        </>
      )}
    </div>
  );
};
