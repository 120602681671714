import { API } from './lib/api';
import {
  UserGroupDetails,
  UserGroupSummary,
  UserGroup,
  UserGroupDetailsPostBody,
  UserGroupDetailsPutBody,
} from './isc-api';
import { ParentsProps } from '../pages/group';

export interface UserGroupUpdateBody {
  name?: string;
  users?: {
    userId?: string;
    isAdmin?: boolean;
  }[];
  subGroups?: string[];
  cameraIds: string[];
}

export const UserGroupsAPI = {
  list: () => API.get<UserGroupSummary[]>('/users/groups/list'),

  get: (userGroupID: string) =>
    API.get<UserGroupDetails>(`/users/groups/details/${userGroupID}/`),
  getParents: (userGroupID: string) =>
    API.get<ParentsProps>(`/users/groups/parents/${userGroupID}/`),

  update: (userGroupID: string, body: UserGroupDetailsPutBody) =>
    API.put<UserGroupDetails>(`/users/groups/details/${userGroupID}/`, {
      body,
    }),

  create: (body: UserGroupDetailsPostBody) =>
    API.post<UserGroupDetails>(`/users/groups/details`, { body }),

  delete: (userGroupId: string) =>
    API.del(`/users/groups/details/${userGroupId}/`),
};
