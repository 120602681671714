import React, { useEffect, useState } from 'react';
import { ConfirmDialogModal } from '../../../components/confirm-dialog-modal';
import {
  UserGroupDetails,
  UserGroupSummary,
} from '../../../api/isc-api';
import { buildUserGroupUpdateBody } from '../hooks/buildUserGroupUpdateBody';
import { UserGroupsAPI } from '../../../api/user-groups';
import { TabPanel } from '../../../components/tab-panel';
import { SubgroupsTabDisplay } from './tab-subgroups-display';
import { useHistory } from 'react-router';
import { AlertSnackPortal } from '../../../components/alert-snack-portal';
import { AddItemModal } from '../../../components/add-item-modal';

type SubgroupsTabProps = {
  userGroup: UserGroupDetails;
  isLoading: boolean;
  tabValue: number;
  index: number;
  setUserGroup: React.Dispatch<React.SetStateAction<UserGroupDetails>>;
  setShowAlert: React.Dispatch<React.SetStateAction<any>>;
};
export const SubgroupsTab = ({
  userGroup,
  isLoading,
  tabValue,
  index,
  setUserGroup,
  setShowAlert
}: SubgroupsTabProps) => {

  const history = useHistory();
  const [groupToDelete, setGroupToDelete] = useState('');

  // takes in userGroup.subgroups as a default list
  const [subgroupList, setSubgroupList] = useState(userGroup?.subGroups);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [newSubgroupID, setNewSubgroupID] = useState(null);

  useEffect(() => {
    if (userGroup.subGroups) {
      setSubgroupList(userGroup.subGroups)
    }

  }, [userGroup.subGroups])

  // launches the confirmation modal - modal shows if groupToDelete !== ""
  const confirmDeleteSubgroup = (groupId: string) => {
    setGroupToDelete(groupId);
  };

  // handles the selection of the confirmation modal
  const handleDeleteSubgroup = (groupId: string) => {
    // only update subgroup if select delete
    // groupId will be null if cancel is selected
    if (groupId) {
      handleSubgroupsUpdate(
        subgroupList.filter(g => g.userGroupId !== groupId), 'Subgroup deleted.', 'Sorry, there was a problem trying to update the sub group for this user group.'
      )
    }
    // hides delete confirmation dialog
    setGroupToDelete('');


  };
  // closing the add subgroup modal
  const handleCloseAddModal = async () => {
    setAddModalOpen(false);
  };

  // a check for submitting empty subgroup
  const handleSubmitNewSubgroup = (itemName: string) => {
    if (!!itemName) {
      handleAddSubgroup(itemName)
    } else {
      setAddModalOpen(false);
      setShowAlert({ message: 'Sorry, can not submit empty name for subgroup', type: 'error' });
    }
  }

  //  handles submission of new subgroup from AddItemModal
  const handleAddSubgroup = async (itemName: string) => {

    try {
      const response: UserGroupDetails = await UserGroupsAPI.create({
        name: itemName,
        parentUserGroupId: userGroup.userGroupId,
      });

      // when we have a response update the subgroup list
      if (response?.userGroupId) {
        let subgroups = [...subgroupList];
        let newSubgroup = {
          userGroupId: response.userGroupId,
          name: response.name,
          userCount: 0,
          subGroupCount: 0,
          cameraCount: 0
        }
        // close modal
        setAddModalOpen(false);
        // update list
        let newGroup = { ...userGroup };
        newGroup.subGroups = [...subgroups, newSubgroup];
        setUserGroup(newGroup);
        // setSubgroupList([...subgroups, newSubgroup])
        // flag for new subgroup edit/stay here confirmation modal
        setNewSubgroupID(response.userGroupId);


      } else {
        throw new Error();
      }
    } catch (e) {
      setAddModalOpen(false);
      setShowAlert({ message: 'Sorry, there was a problem trying to add this subgroup.', type: 'error' });
    }


  };

  const handleSubgroupsUpdate = async (subGroups: UserGroupSummary[], onSucessAlert: any, onErrorAlert: any) => {
    const userGroupUpdateObject = buildUserGroupUpdateBody({
      ...userGroup,
      subGroups,
    });

    try {
      const response: UserGroupDetails = await UserGroupsAPI.update(
        userGroup.userGroupId,
        userGroupUpdateObject
      );
      // when we have a response update the subgroup list with new subgroups
      if (response) {
        setUserGroup(response);
        // setSubgroupList([...subGroups])
        setShowAlert({ message: onSucessAlert, type: "success" });
      } else {
        throw new Error();
      }
    } catch (e) {
      setShowAlert({ message: onErrorAlert, type: "error" });

    }
  };

  const handleChangeGroup = (val: boolean) => {
    if (val) {
      history.push(`/group/${newSubgroupID}`);
    }
    setNewSubgroupID(null)
  };



  return (
    <>
      <TabPanel tabValue={tabValue} index={index}>
        <SubgroupsTabDisplay
          subGroups={subgroupList}
          setAddModalOpen={setAddModalOpen}
          handleDeleteSubgroup={confirmDeleteSubgroup}
          isLoading={isLoading}
        />
      </TabPanel>

      <AddItemModal
        open={addModalOpen}
        title="Add new Subgroup"
        heading="Enter a name for your subgroup"
        handleSubmit={handleSubmitNewSubgroup}
        handleClose={handleCloseAddModal}
      />

      <ConfirmDialogModal
        open={groupToDelete !== ""}
        value={groupToDelete}
        handleClose={handleDeleteSubgroup}
        dialogTitle="Remove Subgroup"
        message="Are you sure you want to delete this subgroup? This will remove all cameras, users, and subgroups attached to the subgroup."
        confirmText="Remove"
      />

      <ConfirmDialogModal
        open={!!newSubgroupID}
        message={'New subgroup created. Would you like to edit the new group?'}
        confirmText={'Edit Subgroup'}
        cancelText={'Stay Here'}
        value={true}
        handleClose={handleChangeGroup}
      />


    </>
  );
};
