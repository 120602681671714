import React from 'react';
import { UserPermissions, hasPermission } from './index';

export function withAdminAccountCheck<T>(
  Component: React.FunctionComponent<T>
) {
  return function withAdminAccountCheck(props: T) {
    const checked = hasPermission(UserPermissions.CanManageOrganization);
    return checked ? <Component {...props} /> : null;
  };
}

export function withPermission<T>(
  permission: UserPermissions,
  Component: React.FunctionComponent<T>
) {
  return function withPermission(props: T) {
    const checked = hasPermission(permission);
    return checked ? <Component {...props} /> : null;
  };
}
