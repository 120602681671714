import React, { useContext } from 'react';
import AppContext, { AuthenticationFlow } from '../../context';
import { MenuLoggedIn } from './menu-logged-in';
import { MenuLoggedOut } from './menu-logged-out';

export const MenuSwitch = () => {
  const context = useContext(AppContext);

  switch (context.authStatus) {
    case AuthenticationFlow.LoggedIn:
      return <MenuLoggedIn />;
    case AuthenticationFlow.LoggedOut:
      return <MenuLoggedOut />;
    default:
      return null;
  }
};
