import { makeStyles, useMediaQuery } from '@material-ui/core';
import theme from '../../../theme';

export const themeBreakPoints = theme.breakpoints;

const carouselLargeBreakPoint = themeBreakPoints.values.lg;

export const breakPoints = [
  { width: 1, itemsToShow: 1, itemsToScroll: 1 },
  { width: themeBreakPoints.values.sm, itemsToShow: 2, itemsToScroll: 2 },
  { width: themeBreakPoints.values.md, itemsToShow: 3, itemsToScroll: 3 },
  { width: carouselLargeBreakPoint, itemsToShow: 4 },
];

// If there's only one entry we don't want Elastic carousel
// attempting to size it.
export const getBreakPoints = (isSingle: boolean) => {
  if (isSingle) {
    return [breakPoints[0]];
  } else {
    return breakPoints;
  }
};

export const getShouldShowControls = (items: number) => {
  let show = null;

  for (let bp of [...breakPoints].reverse()) {
    const matches = useMediaQuery(theme.breakpoints.up(bp.width));
    if (matches && show === null) {
      show = items > bp.itemsToShow;
    }
  }

  return show;
};

export const getShouldForceSinglePage = (isSingle: boolean) => {
  return isSingle;
};

export const useStyles = makeStyles(() => ({
  carousel: {
    fontFamily: 'sans-serif',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    zIndex: 1,
    [theme.breakpoints.down(400)]: {
      margin: '0 -10px',
    },
  },
  h1NoControls: {
    marginLeft: 0,
  },
  h1WithControls: {
    marginLeft: '2.1em',
  },
  h1: {
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(0, 0, 2),
      textAlign: 'center',
    },
  },
  pinned: {
    overflow: 'visible',
  },
}));
