import React from 'react'
import { FormControl, InputLabel } from '@material-ui/core'

type PaymentMethodInputProps = {
  id: string
  focused: boolean
  error?: boolean
  label: string
}

export const PaymentMethodInput = (props: PaymentMethodInputProps) => {

  return <FormControl fullWidth>
      <InputLabel focused={props.focused} shrink={props.focused} error={props.error}>{props.label}</InputLabel>
      <div
        className={
          `MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-formControl MuiInput-formControl ${props.error ? 'Mui-error' : ''}`
        }
        style={{width:'100%'}}
        id={props.id}
      >
        <div className="MuiInputBase-input MuiInput-input" pl-input={props.id}/>
      </div>
    </FormControl>
}
