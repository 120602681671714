import React, {useEffect} from 'react';
import {LicensePlateRecognitionResult, VehicleRecognitionResult} from "../../../api/isc-api";
import {IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Typography} from "@material-ui/core";
import {useStyles} from "./recognition-results-styles";
import {getVehicleBestMatchSummary} from "./helpers";
import {Info} from "@material-ui/icons";

export type VehicleRecognitionResultsProps = {
  results: VehicleRecognitionResult[],
  onOpenDetail: (VehicleRecognitionResult) => void
}

export const VehicleRecognitionResults = ({results, onOpenDetail}: VehicleRecognitionResultsProps) => {
  const classes = useStyles();

  return (
    results.length ?
      <>
        <Typography className={classes.recognitionTypeSubhead}>
          {results.length} Vehicle{results.length === 1 ? '' : 's'} Detected
        </Typography>
        <List>
          {results.map((result, index) => (
            <ListItem key={index} className={classes.summaryListItem}>
              <ListItemText disableTypography primary={
                <>
                  <strong className={classes.detailListItemTitle}>{index + 1}:</strong>
                  {getVehicleBestMatchSummary(result)}
                </>}
              />
              <ListItemSecondaryAction>
                <IconButton color="primary" edge="end" onClick={() => onOpenDetail(result)}>
                  <Info/>
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </>
      : null
  )
}
