import { makeStyles } from '@material-ui/core';
export const useStyles = makeStyles(theme => ({
  collectionHeader: {
    margin: theme.spacing(2, 0),
    alignItems: 'center',
  },
  headerParentCollectionName: {
    fontSize: '1em',
    margin: theme.spacing(0, 0, 0.25, 2),
  },
  headerCollectionName: {
    margin: theme.spacing(0.5, 0, 2, 2),
  },
  manageContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  manageLink: {
    '&, &:hover, &:active, &:focus': {
      color: 'inherit',
      textDecoration: 'none',
    },
  },
}));
