import React, { Fragment, useState } from 'react';
import { makeStyles, Box, Grid, Typography, List } from '@material-ui/core';
import CameraListItem from '../../components/camera-list-item';
import { DashboardSearchBox } from '../../components/dashboard-search-box';
import { CollectionCameraDetailSearchGetResponseData } from '../../api/isc-api';
import { CamerasAPI } from '../../api/cameras';

const useStyles = makeStyles(theme => ({
  pageBox: {
    marginBottom: theme.spacing(6),
  },
  collectionList: {
    padding: 0,
  },
  pageHeading: {
    alignSelf: 'center',
  },
  noResults: {
    padding: theme.spacing(3),
  },
}));

type CameraListProps = {
  data: CollectionCameraDetailSearchGetResponseData;
};

const CameraList = (props: CameraListProps) => {
  const classes = useStyles();
  const { data } = props;
  const [cameras, setCameras] = useState(data);
  const [search, setSearch] = useState(false);

  //Search query handler
  const handleFilterChange = async (filter: string) => {
    const searchResults = await CamerasAPI.searchCameras({
      onlyIncludeCamerasWithoutCollection: 'true',
      limit: 100,
      filter,
    });

    //Update cameras component state model with search results
    filter === '' ? setSearch(false) : setSearch(true);
    setCameras(searchResults);
  };

  return (
    <Fragment>
      <Box boxShadow={2} className={classes.pageBox}>
        <Box px={3} pt={3}>
          <Grid container>
            <Grid item xs={12} sm={8} container>
              <Typography
                variant="h2"
                component="h2"
                className={classes.pageHeading}
              >
                {search ? 'Search Results' : 'Cameras not in collections'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <DashboardSearchBox
                value={''}
                onFilterChange={handleFilterChange}
              />
            </Grid>
          </Grid>
        </Box>
        <Grid>
          <Grid item xs={12}>
            <List className={classes.collectionList}>
              {cameras.cameras.length > 0 ? (
                cameras.cameras.map((camera: any) => (
                  <CameraListItem camera={camera} />
                ))
              ) : (
                <Typography component="h3" className={classes.noResults}>
                  There are no cameras matching your search query.
                </Typography>
              )}
            </List>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default CameraList;
