// TODO: update typing in this file based on real API responses when available
import { API } from './lib/api';
import {
  RecognitionSettingsLprSubjectGetResponseData,
  RecognitionSettingsPersonSubjectGetResponseData,
  Warrant,
  Subject,
  CameraRecognitionCameraGetResponseData,
  RecognitionSettingsPersonSubjectPutResponseData,
  RecognitionSettingsLprBody,
  RecognitionSettingsPersonBody,
  RecognitionSettingsLprSubjectPutResponseData,
  RecognitionSettingsWarrantExtensionBody,
  RecognitionSettingsPersonTrainingSubjectPatchResponseData,
  RecognitionSettingsPersonTrainingDeleteResponse,
  RecognitionSettingsPersonTrainingPatchBody,
  CameraRecognitionConfigurationCameraPutBody,
} from './isc-api';

export type RecognitionType = 'lpr' | 'person';

export enum ImageRecognitionMode {
  None = 'None',
  LPR = 'LPR',
  Person = 'Person',
}

const imageModeToTypePath: Record<
  Exclude<ImageRecognitionMode, ImageRecognitionMode.None>,
  RecognitionType
> = {
  [ImageRecognitionMode.LPR]: 'lpr',
  [ImageRecognitionMode.Person]: 'person',
};

export interface RecognitionSearchParams {
  filter?: string;
  start?: number;
  limit?: number;
}

export const RecognitionAPI = {
  getPersonProfile: (subjectId: string) =>
    API.get<RecognitionSettingsPersonSubjectGetResponseData>(
      `/recognition/settings/person/${subjectId}`
    ),

  getLprProfile: (subjectId: string) =>
    API.get<RecognitionSettingsLprSubjectGetResponseData>(
      `/recognition/settings/lpr/${subjectId}`
    ),

  getCameraProfiles: (cameraId: string) =>
    API.get<CameraRecognitionCameraGetResponseData>(
      `/cameras/recognition/${cameraId}/`
    ),

  putCameraProfiles: (
    cameraId: string,
    body: CameraRecognitionConfigurationCameraPutBody
  ) => API.put<string>(`/cameras/recognition/${cameraId}/`, { body }),

  /**
   * Both `person` and `lpr` profiles return a `Subject` type
   * so we can handle both here.
   */
  searchRecognitionProfiles: (
    mode: ImageRecognitionMode,
    params: RecognitionSearchParams = {}
  ) =>
    API.get<Subject[]>(
      `/search/recognition/settings/${imageModeToTypePath[mode]}`,
      {
        queryStringParameters: params,
      }
    ),

  updatePersonProfile: (
    subjectId: string,
    body: RecognitionSettingsPersonBody
  ) =>
    API.put<RecognitionSettingsPersonSubjectPutResponseData>(
      `/recognition/settings/person/${subjectId}/`,
      { body }
    ),

  updateLprProfile: (subjectId: string, body: RecognitionSettingsLprBody) =>
    API.put<RecognitionSettingsLprSubjectPutResponseData>(
      `/recognition/settings/lpr/${subjectId}/`,
      { body }
    ),

  createPersonProfile: (body: RecognitionSettingsPersonBody) =>
    API.put<RecognitionSettingsPersonSubjectPutResponseData>(
      `/recognition/settings/person/`,
      { body }
    ),

  createLprProfile: (body: RecognitionSettingsLprBody) =>
    API.post<RecognitionSettingsLprSubjectPutResponseData>(
      `/recognition/settings/lpr/`,
      { body }
    ),

  extendWarrant: (
    warrantId: string,
    body: RecognitionSettingsWarrantExtensionBody
  ) =>
    API.patch<Warrant>(`/recognition/settings/warrant/${warrantId}/`, { body }),

  createTrainingImage: (subjectId: string, file: any) =>
    API.post<RecognitionSettingsPersonTrainingSubjectPatchResponseData>(
      `/recognition/settings/person/training/`,
      {
        headers: {
          'x-subject-id': subjectId,
        },
        body: {
          file,
        },
      }
    ),

  removeTrainingImage: (trainingImageId: string) =>
    API.del<RecognitionSettingsPersonTrainingDeleteResponse>(
      `/recognition/settings/person/training/${trainingImageId}`
    ),

  addMediaImage: (
    subjectId: string,
    body: RecognitionSettingsPersonTrainingPatchBody
  ) =>
    API.patch<RecognitionSettingsPersonTrainingSubjectPatchResponseData>(
      `/recognition/settings/person/training/${subjectId}`,
      { body }
    ),

  /**
   * Missing from API, untype
   */
  deleteProfile: async (mode: ImageRecognitionMode, subjectId: string) =>
    API.del<any>(
      `/recognition/settings/${imageModeToTypePath[mode]}/${subjectId}/`
    ),
};
