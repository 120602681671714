import React, { useState } from 'react';
import { CloseRounded, Star, StarBorder } from '@material-ui/icons';
import { DeleteModal } from '../../../../../../components/delete-modal';
import { useStyles } from './training-image-styles';

interface TrainingImageProps {
  src: string;
  alt: string;
  confidenceScore: number;
  trainingImageId: string;

  onRemoveTrainingImage?: (value: any) => void;
}

export const TrainingImage = (props: TrainingImageProps) => {
  const classes = useStyles(props);
  const [isPendingDelete, setIsPendingDelete] = useState(false);

  const handleDeleteImage = async () => {
    // RecognitionAPI
    //   .removeTrainingImage(props.trainingImageId)
    //   .then((data) => {
    //     if(data.ok) {
    //       props.onRemoveTrainingImage(true)
    //     }
    //   });
    console.log('image deleted.');
    setIsPendingDelete(false);
  };

  const range = (start: number, stop: number, step: number) =>
    Array.from(
      { length: (stop - start) / step + 1 },
      (_, i) => start + i * step
    );

  const stars = range(1, props.confidenceScore / 20, 1);

  const emptyStars = range(1, 5 - stars.length, 1);

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ position: 'relative' }}>
        <img
          src={props.src}
          alt={props.alt}
          className={classes.trainingImage}
        />
        <div
          className={classes.deleteIcon}
          onClick={() => setIsPendingDelete(true)}
        >
          <CloseRounded fontSize="small" />
        </div>
      </div>
      <div>
        {stars.map(star => {
          return <Star fontSize="small" key={star} />;
        })}
        {emptyStars.map(emptyStar => {
          return <StarBorder fontSize="small" key={emptyStar} />;
        })}
      </div>
      <DeleteModal
        open={isPendingDelete}
        handleClose={handleDeleteImage}
        title="Delete Image"
      />
    </div>
  );
};
