import React, { useContext, Fragment, useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  CardActions,
  Button,
  List,
  Divider,
} from '@material-ui/core';
import isEqual from 'lodash/isEqual';
import { Toggle } from '../../../components/form-fields';
import { OrganizationSettingsAPI } from '../../../api/organization-settings';
import AppContext from '../../../context';
import { getOrganization } from '../../../access';
import {
  OrganizationSettingsRecognitionPutBody,
  Features,
} from '../../../api/isc-api';
import { useStyles } from './image-recognition-form-styles';

type ImageRecognitionFormProps = {
  setFormDirtyState: React.Dispatch<React.SetStateAction<boolean>>;
};

/**
 * Partial type for just image recognition subset used in form
 */
type ImageRecognitionSettings = Pick<
  Features,
  'facialRecognition' | 'licensePlateRecognition'
>;

export const ImageRecognitionForm = ({
  setFormDirtyState,
}: ImageRecognitionFormProps) => {
  const classes = useStyles();
  const context = useContext(AppContext);

  const [isSaving, setIsSaving] = useState(false);

  const {
    organizationFeatures: { facialRecognition, licensePlateRecognition },
  } = getOrganization();

  const imageRecognitionSettings: ImageRecognitionSettings = {
    facialRecognition,
    licensePlateRecognition,
  };

  const [form, setForm] = useState<ImageRecognitionSettings>(
    imageRecognitionSettings
  );

  const doSave = async (form: ImageRecognitionSettings) => {
    const body: OrganizationSettingsRecognitionPutBody = {
      // Facial recognition is disabled in this release,
      // we'll just re-use previous values
      facialRecognition:
        imageRecognitionSettings.facialRecognition.organizationAdminSettings,

      // This is the update key we'd use in the next release
      // facialRecognition: form.facialRecognition.organizationAdminSettings,

      licensePlateRecognition:
        form.licensePlateRecognition.organizationAdminSettings,
    };
    try {
      setIsSaving(true);
      const response = await OrganizationSettingsAPI.updateImageRecognition(
        body
      );
      setFormDirtyState(false);
      // setForm(response as ImageRecognitionSettings);
      await context.refreshOrgData();
      context.onAlert('Image recognition settings updated.', 'success');
    } catch (e) {
      context.onAlert(e.detail, 'error');
    }
    setIsSaving(false);
  };

  const updateForm = (form: ImageRecognitionSettings) => {
    setForm(form);

    if (isEqual(form, imageRecognitionSettings)) {
      setFormDirtyState(false);
    } else {
      setFormDirtyState(true);
    }
  };

  const handleCancel = () => {
    updateForm(imageRecognitionSettings);
    setIsSaving(false);
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    doSave(form);
  };

  return (
    <form action="" onSubmit={onSubmit}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <List disablePadding>
            {/*
  <Toggle group={{ name: "image-recognition", label: "Image Recognition", items: [] }} item={{ label: "Enable Facial Recognition", name: "enable-fr", type: "toggle" }} value={form.facialRecognition.isEnabled} onValueChanges={(value: any) => updateForm({ ...form, facialRecognition: { requiresWarrant: form.facialRecognition.requiresWarrant, isEnabled: value } })} />
  <Divider/>
  <Toggle group={{ name: "image-recognition", label: "Image Recognition", items: [] }} item={{ label: "Require a Warrant to create a Facial recognition profile", name: "require-warrant-facial", type: "toggle" }} value={form.facialRecognition.requiresWarrant} onValueChanges={(value: any) => updateForm({ ...form, facialRecognition: { isEnabled: form.facialRecognition.isEnabled, requiresWarrant: value } })} />
  <Divider /> 
  */}
            <Toggle
              group={{
                name: 'image-recognition',
                label: 'Image Recognition',
                items: [],
              }}
              item={{
                label: 'Enable license plate recognition',
                name: 'enable-lpr',
                type: 'toggle',
              }}
              value={
                form.licensePlateRecognition.organizationAdminSettings.isEnabled
              }
              onValueChanges={(isEnabled: boolean) =>
                updateForm({
                  ...form,
                  licensePlateRecognition: {
                    organizationAdminSettings: {
                      requiresWarrant:
                        form.licensePlateRecognition.organizationAdminSettings
                          .requiresWarrant,
                      isEnabled,
                    },
                  },
                })
              }
            />
            <Divider />
            <Toggle
              group={{
                name: 'image-recognition',
                label: 'Image Recognition',
                items: [],
              }}
              item={{
                label:
                  'Require a Warrant to Create a License Plate Recognition Profile',
                name: 'require-warrant-lpr',
                type: 'toggle',
              }}
              value={
                form.licensePlateRecognition.organizationAdminSettings
                  .requiresWarrant
              }
              onValueChanges={(requiresWarrant: boolean) =>
                updateForm({
                  ...form,
                  licensePlateRecognition: {
                    organizationAdminSettings: {
                      isEnabled:
                        form.licensePlateRecognition.organizationAdminSettings
                          .isEnabled,
                      requiresWarrant,
                    },
                  },
                })
              }
            />
          </List>
        </CardContent>
        <Divider />
        <CardActions className={classes.cardActions}>
          <Button onClick={() => doSave(form)} color="primary">
            {isSaving ? 'Saving...' : 'Save'}
          </Button>
          <Button onClick={handleCancel}>Cancel</Button>
        </CardActions>
      </Card>
    </form>
  );
};
