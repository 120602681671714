import { AppContextType } from '../../context';
import { ENABLE_DEV_CONSOLE } from '../../util/globals';

export type BootstrapArgs = { context: AppContextType; organizationId: string };

export interface ISC_API_Interface {
  bootStrap(args: BootstrapArgs): Promise<void>;

  /**
   * Make a GET request
   * @param {string} apiName  - The api name of the request
   * @param {string} path - The path of the request
   * @param {json} [init] - Request extra params
   * @return {Promise} - A promise that resolves to an object with response status and JSON data, if successful.
   */
  get<T>(path: string, options?: any): Promise<any>;
  /**
   * Make a POST request
   * @param {string} apiName  - The api name of the request
   * @param {string} path - The path of the request
   * @param {json} [init] - Request extra params
   * @return {Promise} - A promise that resolves to an object with response status and JSON data, if successful.
   */
  post(path: string, options?: any): Promise<any>;
  /**
   * Make a PUT request
   * @param {string} apiName  - The api name of the request
   * @param {string} path - The path of the request
   * @param {json} [init] - Request extra params
   * @return {Promise} - A promise that resolves to an object with response status and JSON data, if successful.
   */
  put(path: string, options?: any): Promise<any>;
  /**
   * Make a PATCH request
   * @param {string} apiName  - The api name of the request
   * @param {string} path - The path of the request
   * @param {json} [init] - Request extra params
   * @return {Promise} - A promise that resolves to an object with response status and JSON data, if successful.
   */
  //  patch(path: string, options?: any): Promise<any>;
  /**
   * Make a DEL request
   * @param {string} apiName  - The api name of the request
   * @param {string} path - The path of the request
   * @param {json} [init] - Request extra params
   * @return {Promise} - A promise that resolves to an object with response status and JSON data, if successful.
   */
  del(path: string, options?: any): Promise<any>;
  /**
   * Make a HEAD request
   * @param {string} apiName  - The api name of the request
   * @param {string} path - The path of the request
   * @param {json} [init] - Request extra params
   * @return {Promise} - A promise that resolves to an object with response status and JSON data, if successful.
   */
  //  head(path: string, options?: any): Promise<any>;
  /**
   * Checks to see if an error thrown is from an api request cancellation
   * @param {any} error - Any error
   * @return {boolean} - A boolean indicating if the error was from an api request cancellation
   */
}

export type GenericAPIResponse = {
  data?: any;
  debug?: any;
};

export type API_Options = {
  isMock: boolean;
};

export enum RequestType {
  get = 'GET',
  put = 'PUT',
  post = 'POST',
  patch = 'PATCH',
  del = 'DELETE',
}

