import theme from "../../theme";

export const mediaThumbnailSizes = {
  sm: {
    width: 142,
    height: 100,
    containerHeight: 110,
  },
  lg: {
    width: 198,
    height: 142,
    containerHeight: 156
  }
}

export const getMediaThumbnailSize = (propName: 'width'|'height'|'containerHeight') => {
  return (window.innerWidth <= theme.breakpoints.values.sm) ? mediaThumbnailSizes.sm[propName] : mediaThumbnailSizes.lg[propName];
}
