import React from 'react';
import { Card, CardContent, Typography } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import { BatteryEmpty, SdCardEmpty } from './mui-icons';
import clsx from 'clsx';
import theme from '../theme';
import fromNow from 'from-now';
import { MediaImage, OperationStatusSummary } from '../api/isc-api';
import { useStyles } from '../pages/dashboard/pinned-cameras/camera-styles';
import { Image } from './image';

type CameraCardProps = {
  cameraName: string;
  collectionName?: string;
  cameraThumbnail: MediaImage;
  operationStatus: OperationStatusSummary;
  onClick?: () => void;
  showChevron?: boolean;
};

export const CameraCard = ({
  cameraName,
  collectionName = 'No Collection',
  cameraThumbnail,
  operationStatus,
  onClick,
  showChevron = true,
}: CameraCardProps) => {
  const classes = useStyles();

  const hasClick = typeof onClick !== 'undefined';

  // Eliminates a bug where a camera timestamp slightly ahead of local time causes fromNow() to bomb out
  const getElapsedTime = (date) => {
    let elapsedTime;
    try {
      elapsedTime = fromNow(cameraThumbnail.date.dateTimeLocal);
    } catch(e) {
      elapsedTime = 'A few seconds';
    }

    return elapsedTime;
  }

  const timestamp = cameraThumbnail?.date?.dateTimeLocal
    ? `${getElapsedTime(cameraThumbnail.date.dateTimeLocal)} ago`
    : 'No recent images';

  return (
    <Card
      className={clsx(hasClick && classes.hasClick, classes.cardWrapper)}
      onClick={onClick}
    >
      <div className={classes.cardMediaWrapper}>
        <Image src={cameraThumbnail?.url} className={classes.photoContainer} />
        <div className={classes.timestamp}>{timestamp}</div>
      </div>
      <CardContent className={classes.contentContainer}>
        <div className={classes.metaContainer}>
          <Typography className={classes.cameraName} noWrap={false}>
            {cameraName}
          </Typography>
          <Typography className={classes.cameraCollectionName}>
            {collectionName}
          </Typography>
        </div>
        <div className={classes.iconsContainer}>
          {operationStatus && (
            <>
              {operationStatus?.battery?.isCritical && (
                <div title={operationStatus.battery.status}>
                  <BatteryEmpty style={{ color: theme.palette.error.main }} />
                </div>
              )}
              {operationStatus?.storage?.isCritical && (
                <div title={operationStatus.storage.status}>
                  <SdCardEmpty style={{ color: theme.palette.error.main }} />
                </div>
              )}
            </>
          )}
          {showChevron && (
            <ChevronRight
              color="primary"
              style={{
                margin: theme.spacing(0, 1),
                marginRight: '0',
                marginLeft: '0',
                right: '-5px',
                position: 'relative',
              }}
            />
          )}
        </div>
      </CardContent>
    </Card>
  );
};
