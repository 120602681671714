import React, { ReactNode } from 'react';
import { Box, Typography } from '@material-ui/core';

export interface ITabPanel {
  tabValue: number;
  index: number;
}

type TabPanelProps = {
  children?: ReactNode;
  padding?: number;
} & ITabPanel;

export const TabPanel = (props: TabPanelProps) => {
  const { children, tabValue, index, ...other } = props;
  const padding = typeof props.padding === 'number' ? props.padding : 3;

  return (
    <div
      role="tabpanel"
      hidden={tabValue !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {tabValue === index && (
        <Box p={padding}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

export function a11yProps(index: any) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}
