import React, { useState, useContext } from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  TextField,
  Typography,
  CardHeader,
} from '@material-ui/core';
import { SharedItemGetResponseData } from '../../../api/isc-api';
import { SharingAPI } from '../../../api/sharing';
import AppContext from '../../../context';
import { useStyles } from './useStyles';
import { useWarrantChecker } from './useWarrantChecker';
export { useWarrantChecker };

type WarrantCheckerProps = {
  shareId: string;
  processResponse: (response: SharedItemGetResponseData) => void;
  handleExpired: () => void;
};

export const WarrantChecker = ({
  shareId,
  processResponse,
  handleExpired
}: WarrantCheckerProps) => {
  const classes = useStyles();
  const [invalidWarrantNumber, setInvalidWarrantNumber] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [enteredWarrantNumber, setEnteredWarrantNumber] = useState('');

  const handleFormSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    setIsLoading(true);
    SharingAPI.get(shareId, enteredWarrantNumber)
      .then(response => {
        setInvalidWarrantNumber(false);
        setIsLoading(false);
        processResponse(response);
      })
      .catch(e => {
        console.log({ e }, e.message);

        if (e.status === 403) {
          setInvalidWarrantNumber(true);
        } else if (e.status === 404) {
          handleExpired();
        }

        setIsLoading(false);
      });
  };

  return (
    <>
      <form action="#" onSubmit={handleFormSubmit}>
        <Card className={classes.root}>
          <CardContent>
            <Typography variant="h2">Enter Warrant Number</Typography>
            <TextField
              required
              fullWidth
              autoFocus
              id="warrantNumber"
              name="warrantNumber"
              onChange={(ev: any) => setEnteredWarrantNumber(ev.target.value)}
              error={invalidWarrantNumber}
              helperText={invalidWarrantNumber && 'Incorrect warrant number.'}
            />
          </CardContent>
          <CardActions className={classes.actionContainer}>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              size="small"
              disabled={!enteredWarrantNumber || isLoading}
            >
              {isLoading ? 'Loading...' : 'Check'}
            </Button>
          </CardActions>
        </Card>
      </form>
    </>
  );
};
