import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { DashboardSearchBox } from '../dashboard-search-box';
import { useApi } from '../../hooks';
import {
  CollectionCameraBasicSearchGetResponseDataCameras,
  CollectionSettingsGetResponseDataCameras,
  CollectionCameraDetailSearchGetResponseDataCameras,
} from '../../api/isc-api';
import { ProgressBar } from '../progress-bar';
import { CamerasAPI, CameraSearchParams } from '../../api/cameras';
import { useStyles } from './useStyles';
import {useAddItemModal} from "./useAddItemModal";

type AddCameraModalProps = {
  open: boolean;
  excludeCameraIds?: string[];
  excludeExistsInOtherCollections?: boolean;
  handleClose: () => void;
  handleSave: (cameras?: CollectionSettingsGetResponseDataCameras[]) => void;
};

export const AddCameraModalComponent = ({
  open,
  excludeCameraIds = [],
  excludeExistsInOtherCollections = false,
  handleClose,
  handleSave
}: AddCameraModalProps) => {
  const classes = useStyles();
  const [filteredCameras, setFilteredCameras] = useState<CollectionCameraBasicSearchGetResponseDataCameras[]>([]);
  const [selectedCameras, setSelectedCameras] = useState<CollectionSettingsGetResponseDataCameras[]>([]);

  const options: CameraSearchParams = {
    start: 0,
    limit: 100,
    onlyIncludeCamerasWithoutCollection: 'true',
  };

  const {
    chipData,
    filter,
    isAllSelected,
    handleFormSubmit,
    reset,
    handleFilterChange,
    setAllSelected,
    setIsAllSelected,
    isItemChecked,
    updateChipData,
    handleChangeItem,
    handleDeleteItem
  } = useAddItemModal({
    handleClose
  });

  const [data, , isLoading] = useApi(() =>
    CamerasAPI.searchCamerasBasic(options)
  );

  const excludeFilter = (
    c: CollectionCameraDetailSearchGetResponseDataCameras
  ) => {
    let include = !excludeCameraIds.includes(c.camera.cameraId);
    if (excludeExistsInOtherCollections) {
      include = include && !c.cameraCollection?.cameraCollectionId
    }
    return include
  }

  const loadFilteredData = async () => {
    options.filter = filter ?? '';
    const filteredData = await CamerasAPI.searchCamerasBasic(options);
    setFilteredCameras(filteredData.cameras.filter(excludeFilter));
  };

  useEffect(() => {
    if (!data) return;
    if (open) {
      void loadFilteredData();
    }
  }, [data, filter, open]);

  useEffect(() => {
    updateChipData(selectedCameras, 'camera.cameraId', 'camera.cameraName');
    setIsAllSelected(selectedCameras.length === filteredCameras.length && selectedCameras.length > 0);
  }, [selectedCameras]);

  const cameras = () => (
    <Table stickyHeader>
      <TableHead>
        <TableRow>
          <TableCell>
            Select
            <div className={classes.selectAll}>
              { isAllSelected ?
                <a href="#" onClick={e => setAllSelected(e, false, setSelectedCameras)}>(Deselect All)</a> :
                <a href="#" onClick={e => setAllSelected(e, true, setSelectedCameras, filteredCameras)}>(Select All)</a>
              }
            </div>
          </TableCell>
          <TableCell>Cameras</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {filteredCameras.length ?
          (
            (filteredCameras as CollectionCameraBasicSearchGetResponseDataCameras[]).map(
              (nextCamera: CollectionCameraBasicSearchGetResponseDataCameras) => (
                <TableRow
                  key={nextCamera.camera.cameraId}
                  className={classes.item}
                  onClick={() => {
                    handleChangeItem(
                      !isItemChecked(nextCamera, selectedCameras, ['camera.cameraId', 'camera.cameraName']),
                      nextCamera,
                      selectedCameras,
                      setSelectedCameras,
                      ['camera.cameraId', 'camera.cameraName']
                    );
                  }}
                >
                  <TableCell>
                    <Checkbox
                      checked={isItemChecked(nextCamera, selectedCameras, ['camera.cameraId', 'camera.cameraName'])}
                      inputProps={{
                        'aria-label': 'primary checkbox',
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <Typography variant={'h3'}>
                      {nextCamera.camera.cameraName}
                    </Typography>
                    <Typography className={classes.cameraImei}>IMEI: {nextCamera.camera.imei}</Typography>
                  </TableCell>
                </TableRow>
              )
            )
          ) : (
            <Typography variant="body1" className={classes.noCameraText}>
              {filter?.length
                ? 'No cameras match the search parameters.'
                : 'There are no cameras available to add.'}
            </Typography>
          )

        }
      </TableBody>
    </Table>
  );

  return (
    <React.Fragment>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        maxWidth={'sm'}
        fullWidth={true}
      >
        <form action="#" onSubmit={(ev: any) => handleFormSubmit(ev)}>
          <DialogContent>
            <Grid container>
              <Grid item xs={12} md={8} className={classes.titleContainer}>
                <Typography variant="h1" className={classes.header}>
                  Add Camera
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <DashboardSearchBox
                  placeholderText={'Search'}
                  value={''}
                  onFilterChange={handleFilterChange}
                />
              </Grid>
            </Grid>
            <Grid xs={12} md={12} item>
              <Paper component="ul" className={classes.root}>
                {chipData.map(cData => {
                  return (
                    <li key={cData.key}>
                      <Chip
                        size="small"
                        label={cData.label}
                        onDelete={() => {
                          handleDeleteItem(cData, selectedCameras, setSelectedCameras, 'camera.cameraId', 'camera.cameraName');
                        }}
                      />
                    </li>
                  );
                })}
              </Paper>
            </Grid>
            <Grid item style={{ maxHeight: 500, overflow: 'auto' }}>
              {isLoading && <ProgressBar />}
              {!isLoading && filteredCameras && cameras()}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              size="small"
              onClick={() => {
                reset(setSelectedCameras);
                handleClose();
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              size="small"
              disabled={selectedCameras.length === 0}
              onClick={() => {
                handleSave(selectedCameras);
                reset(setSelectedCameras);
              }}
            >
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
};

export const AddCameraModal = React.memo(AddCameraModalComponent);

