import React, { Fragment } from 'react';
import { PageHeader } from '../../components/page-header';
import { useApi } from '../../hooks';
import { CamerasAPI } from '../../api/cameras';
import CameraList from './camera-list';

export const UnassignedCamerasPage = () => {
  const [data] = useApi(() =>
    CamerasAPI.searchCameras({
      onlyIncludeCamerasWithoutCollection: 'true',
      limit: 100,
    })
  );

  const breadcrumbs = [
    {
      label: 'Cameras',
      href: '/',
    },
  ];

  if (!data) return null;

  return (
    <>
      <PageHeader title="Unassigned Cameras" breadcrumbs={breadcrumbs} />
      <CameraList data={data} />
    </>
  );
};
