import React from 'react'
import billing, { Invoice } from '../../../api/billing'
import { useApi, useApiUpdate } from '../../../hooks'
import { PaymentCollection } from '../../../components/payment-form/payment-collection'

type PayNowCardProps = {
  invoice: Invoice
  onComplete: () => void
}

export const PayNowCard = (props: PayNowCardProps) => {

  const [customer] = useApi(
    () => billing().customer().then(r => r.customer)
  )

  const [handlePayNow, isProcessing] = useApiUpdate<void>(
    () => billing().payInvoice(props.invoice.invoiceId),
    props.onComplete
  )

  if (customer === null) {
    return null
  }

  return <PaymentCollection
    customer={customer}
    buttonLabel='Pay now'
    onSubmit={() => handlePayNow()}
    disabled={isProcessing}
  />
}
