import { makeStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles(() => ({
  trainingImage: {
    height: "150px",
    width: "150px",
    objectFit: "cover"
  },
  deleteIcon: {
    cursor: "pointer",
    position: "absolute",
    top: "0",
    right: "0",
    backgroundColor: "#f3f3f3",
    height: "25px",
    width: "25px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
