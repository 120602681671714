import React, { useState, useEffect, useContext } from 'react';
import Alert from '@material-ui/lab/Alert';
import { UserGroup } from '../../../../api/isc-api';
import { UserGroupPanel } from '../../../../components/user-group-panel';
import { SettingsContext } from '../../container/settings-context';
import { deleteGroups, addGroups } from './service';
import AppContext from '../../../../context';
import { useStyles } from './useStyles';
// import { PendingRows } from './pending-rows';

type UserGroupProps = {
  onValueChanges: (userGroups: UserGroup[]) => void;
};

export enum UserGroupChangeStatus {
  Add = 'Add',
  Delete = 'Delete',
}
export type UserGroupPending = UserGroup & {
  changeStatus: UserGroupChangeStatus;
};

const UserGroupComponent = ({ onValueChanges }: UserGroupProps) => {
  const {
    updateCount,
    pendingUserGroups,
    setPendingUserGroups,
    filteredUserGroups,
    setFilteredUserGroups,
    camera,
  } = useContext(SettingsContext);
  const classes = useStyles();
  const context = useContext(AppContext);
  const [isAlertOpen, setIsAlertOpen] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleUpdateUserGroups = async (userGroupsUpdate: UserGroup[]) => {
    const existingPendingGroups = pendingUserGroups.filter(
      pg => pg.changeStatus === UserGroupChangeStatus.Add
    );

    const updatedAndPendingAdditions = [
      ...userGroupsUpdate,
      ...existingPendingGroups,
    ];

    const newGroupsToAdd = updatedAndPendingAdditions
      .filter(
        updateGroup =>
          !filteredUserGroups.some(
            existingGroup =>
              existingGroup.userGroupId === updateGroup.userGroupId
          )
      )
      .map((g: UserGroup) => ({
        ...g,
        changeStatus: UserGroupChangeStatus.Add,
      }));

    // Get groups pending deletion by diffing existing groups vs groups passed
    const newGroupsToDelete = filteredUserGroups
      .filter(
        existingGroup =>
          !userGroupsUpdate.some(
            updateGroup => updateGroup.userGroupId === existingGroup.userGroupId
          ) &&
          !pendingUserGroups.some(
            pendingGroup =>
              pendingGroup.userGroupId === existingGroup.userGroupId
          )
      )
      .map((g: UserGroup) => ({
        ...g,
        changeStatus: UserGroupChangeStatus.Delete,
      }));

    if (newGroupsToDelete.length > 0) {
      try {
        setIsProcessing(true);
        await deleteGroups(camera.cameraId, newGroupsToDelete);
        context.onAlert(
          `Successfully deleted user group.`,
          'success'
        );

        setFilteredUserGroups(updatedAndPendingAdditions);
        setIsProcessing(false);
      } catch (e) {
        context.onAlert(e.message || e, 'error');
      }
    }

    if (newGroupsToAdd.length) {
      const groupText = `group${newGroupsToAdd.length > 1 ? 's' : ''}`
      try {
        setIsProcessing(true);
        await addGroups(camera.cameraId, newGroupsToAdd);
        context.onAlert(
          `Successfully added ${newGroupsToAdd.length} ${groupText}.`,
          'success'
        );

        setFilteredUserGroups(updatedAndPendingAdditions);
        setIsProcessing(false);
      } catch (e) {
        context.onAlert(e.message || e, 'error');
      }
    }

    // Add existing pending changes + add/delete queued for deletion
    // setPendingUserGroups([
    //   ...pendingUserGroups,
    //   ...newGroupsToAdd,
    //   ...newGroupsToDelete,
    // ]);


    // Publish updated changes with existing (addition) pending changes
    // This is disabled for now as we handled updates from within the panel
    // onValueChanges(updatedAndPendingAdditions);
  };

  const alertComponent = isAlertOpen && (
    <Alert severity="info" onClose={() => setIsAlertOpen(false)}>
      Changes to User Groups will take effect immediately (no camera restart is
      required).
    </Alert>
  );

  const excludeGroupIdsFromAdd = pendingUserGroups.map(g => g.userGroupId);
  // const postListContent = <PendingRows userGroups={pendingUserGroups} />

  return (
    <div className={isProcessing && classes.isProcessing}>
      <UserGroupPanel
        target="camera"
        showAdminToggle={false}
        userGroups={filteredUserGroups}
        isLoading={false}
        onUpdate={handleUpdateUserGroups}
        preContent={alertComponent}
        excludeGroupIdsFromAdd={excludeGroupIdsFromAdd}
        updateKey={updateCount}
      />
    </div>
  );
};

export default UserGroupComponent;
