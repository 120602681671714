import { makeStyles } from '@material-ui/core/styles';
import { drawerWidth } from '../constants';

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    backgroundColor: 'transparent',
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '40px 25px 20px',
    marginBottom: '20px',
    ...theme.mixins.toolbar,
    '&::before': {
      content: '""',
      opacity: '0.9',
      zIndex: '-1',
      position: 'absolute',
      left: '0',
      right: '0',
      top: '-20px',
      height: '125px',
      background: theme.palette.grey[200],
      transform: 'skewY(-8deg)',
      transformOrigin: 'top',
      transition: 'opacity: 0.3s',
    },
  },
  iconColor: {
    htmlColor: '#fff',
    color: '#fff',
  },
  logo: {
    maxHeight: '40px',
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    background: theme.palette.common.white,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
}));
