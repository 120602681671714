import * as React from 'react';

import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';

import {
  SettingsGroup,
  GenericSettingsGroup,
  GenericSettingsValue,
  SettingsDependency, SettingsAutoUpdater,
} from '../../../schema';
import { Item } from './item';
import { SettingsContext } from '../container/settings-context';

interface GroupProps {
  item: SettingsGroup;
  value: GenericSettingsGroup;
  isActive: boolean;
  cameraId?: string;
  checkDependency: (dep: SettingsDependency, depPermissions?: string|string[]) => boolean;
  onValueChanges: (key: string, value: GenericSettingsValue, updates?: SettingsAutoUpdater) => void;
  onToggleGroup: () => void;
}

const useStyles = makeStyles(theme => ({
  item: {
    padding: theme.spacing(1),
  },
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    maxWidth: '90vw',
  },
}));

export const Group = (props: GroupProps) => {
  const { updateCount } = React.useContext(SettingsContext);
  const setValue = (name: string, value: GenericSettingsValue, updates?: SettingsAutoUpdater) => {
    props.onValueChanges(name, value, updates);
  };

  const classes = useStyles(props);

  return (
    <>
      <Paper className={classes.paper}>
        <List dense disablePadding>
          {props.item.items
            .filter(i => props.checkDependency(i.dependsOn, i.dependsOnPermission))
            .map((i, idx) => {
              return (
                <>
                  {idx > 0 && <Divider />}
                  <Item
                    item={i}
                    key={`${updateCount}-settings-${idx}`}
                    cameraId={props.cameraId}
                    group={props.item}
                    value={props.value && props.value[i.name]}
                    updateKey={`${updateCount}-settings-${idx}`}
                    onValueChanges={(value: GenericSettingsValue, updates?: SettingsAutoUpdater) => {
                      setValue(i.name, value, updates)
                    }}
                    updates={i.updates}
                  />
                </>
              );
            })}
        </List>
      </Paper>
    </>
  );
};
