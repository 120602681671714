import { useContext } from 'react';
import {
  AuthOrganizationsGetResponseData,
  UserDetails,
  UsersListGetResponseDataUsers,
} from '../api/isc-api';
import AppContext from '../context';
import { getDefaultSettings } from 'http2';

export const userType = (
  user: UserDetails | UsersListGetResponseDataUsers,
  owner?: string
) => {
  // UserDetail model from user detail page has a userLabel property
  if (user.hasOwnProperty('userLabel')) {
    return (<UserDetails>user).userLabel;
  }

  // If we're on the list page, we use custom logic
  // -> mimics backend for now as a stop gap, should probably update /users/list/ endpoint
  if (owner === user.userId) {
    return 'Owner';
  }

  if (
    user.organizationalRoles.some(role => role.name === 'Organization Admin')
  ) {
    return 'Administrator';
  }

  return 'User';
};

export const getOrganization = (id?: string) => {
  const context = useContext(AppContext);

  const organizationId = id || context.organizationId;

  if (!context.organizations || context.organizations?.length === 0)
    return null;

  const organization = context.organizations.find(
    o => o.organizationId === organizationId
  );

  if (!organization) {
    throw new OrganizationNotFoundException();
  }

  return organization;
};

export const hasPermission = (permission: UserPermissions): boolean => {
  const organization = getOrganization();

  if (!organization) {
    return false;
  }

  // Type hint
  const userPermission =
    permission as unknown as AuthOrganizationsGetResponseData.UserPermissionsEnum;
  return organization.userPermissions.includes(userPermission);
};

export const canManageUsersOrGroups = () => {
  const organization = getOrganization();

  return (
    hasPermission(UserPermissions.CanManageUsers) ||
    organization.userGroupAdminGroupIds.length > 0
  );
};

export enum UserPermissions {
  CanManageUsers = 'CanManageUsers',
  CanManageCameras = 'CanManageCameras',
  CanManageBilling = 'CanManageBilling',
  CanManageOrganization = 'CanManageOrganization',
  CanManageAllOrganizations = 'CanManageAllOrganizations',
  CanShareWithOthers = 'CanShareWithOthers',
}

export class OrganizationNotFoundException extends Error {
  constructor() {
    super('Organization not found.');
  }
}
