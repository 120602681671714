import React, { useState, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { PageHeader } from '../../components/page-header';
import { AppBar, Tabs, Tab } from '@material-ui/core';
import { UsersTab } from './users/tab-users';
import { useStyles } from './useStyles';
import UserGroupsTab from './groups/tab-groups';

const tabRoutes = {
  '/users': 0,
  '/users/groups': 1,
};

export const UsersGroupsTabs = () => {
  const { path } = useRouteMatch();
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(tabRoutes[path]);
  const history = useHistory();

  const handleChange = (
    _event: React.ChangeEvent<unknown>,
    newValue: number
  ) => {
    history.push(getPath(newValue));
    setTabValue(tabRoutes[path]);
  };

  useEffect(() => {
    setTabValue(tabRoutes[path]);
  }, [path]);

  return (
    <>
      <PageHeader title="Users" breadcrumbs={[{ href: '/', label: 'Home' }]} />
      <div className={classes.tabWrapper}>
        <AppBar position="static">
          <Tabs value={tabValue} onChange={handleChange}>
            <Tab label="users" />
            <Tab label="groups" />
          </Tabs>
        </AppBar>
        <UsersTab index={0} tabValue={tabValue} />
        <UserGroupsTab index={1} tabValue={tabValue} />
      </div>
    </>
  );
};

const getPath = (tabValue: number) =>
  Object.keys(tabRoutes).find(key => tabRoutes[key] === tabValue);
