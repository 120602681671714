import React from 'react';
import { Typography, Box, Link } from '@material-ui/core';
import { useStyles } from './settings-panel-styles';
import { displayValue } from './helpers';
import { SettingsPanelProps } from './settings-panel';
import { SettingsGroup } from '../../../schema';

type SettingsPanelItemProps = Pick<SettingsPanelProps, 'updatedValues'> & {
  settingsGroup: SettingsGroup;
};

export const SettingsPanelItem = ({
  settingsGroup,
  updatedValues,
}: SettingsPanelItemProps) => {
  const classes = useStyles();
  return (
    <Box className={classes.updatedGroup} key={settingsGroup.name}>
      <Typography variant="h6" color="primary">
        {settingsGroup.label}
      </Typography>
      <Box className={classes.updatedItems}>
        {settingsGroup.items.map(item => (
          <Typography variant="body1" color="primary" key={item.name}>
            {item.label}:{' '}
            <Typography component="span" variant="body1" color="primary">
              {displayValue(item, updatedValues[settingsGroup.name][item.name])}
            </Typography>
          </Typography>
        ))}
      </Box>
    </Box>
  );
};
