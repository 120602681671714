import { SessionInfo, SessionUser } from '../context';
import { ApiException } from './lib/domain';
import {
  getAuthOptions,
  processResponse,
  getAmplifyError,
} from './lib/helpers';
import { Auth, API } from 'aws-amplify';

import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import { AuthOrganizationsGetResponse } from './isc-api';
import { Endpoints } from './lib/endpoints';
import { ENABLE_DEV_CONSOLE } from '../util/globals';

const authURL = process.env.API_URL + '/app/auth';

export type CodeInfo = {
  name: string;
  phone: string;
};

export type ActivateRequest = {
  activationCode: string;
  password: string;
  challengeId: string;
  challengeCode: string;
};

export type ResetCodeRequest = {
  email: string;
  method: 'sms' | 'email';
};

export type ResetCodeResponse = {
  challengeId: string;
  destination: string;
};

const session = async (): Promise<SessionInfo> => {
  const options = await getAuthOptions();
  const orgsResponse: AuthOrganizationsGetResponse = await API.get(
    Endpoints.ISCPortalAPI,
    '/auth/organizations',
    options
  ).catch(error => {
    throw getAmplifyError('/auth/organizations', error);
  });

  const cognitoSession: CognitoUserSession = await Auth.currentSession();
  const user: { [id: string]: any } = cognitoSession
    .getIdToken()
    .decodePayload();

  const sessionInfo: SessionInfo = {
    organizationId: '',
    organizations: orgsResponse.data,
    user: user as SessionUser,
  };

  return sessionInfo;
};

let accessLogCount = 0;

export const AuthAPI = {
  getAuthOptions,
  session,
  loginOidc: async (user: CognitoUser): Promise<SessionInfo> => {
    const accessToken = user
      .getSignInUserSession()
      .getAccessToken()
      .getJwtToken();

    if (accessToken) {
      if (ENABLE_DEV_CONSOLE && accessLogCount++ === 0) {
        console.log('AccessToken:');
        console.log(accessToken);
      }

      return await session();
    } else {
      throw new ApiException(400, 'No Access token provided');
    }
  },

  codeInfo: async (code: string): Promise<{ info: CodeInfo }> => {
    const response = await fetch(authURL + '/code/' + code);
    return processResponse(response);
  },

  sendSmsCode: async (code: string): Promise<{ challenge: string }> => {
    const response = await fetch(authURL + '/send-sms', {
      method: 'POST',
      body: JSON.stringify({
        activationCode: code,
      }),
      headers: {
        'content-type': 'application/json',
      },
    });
    return processResponse(response);
  },

  activate: async (request: ActivateRequest): Promise<SessionInfo> => {
    const response = await fetch(authURL + '/activate', {
      method: 'POST',
      body: JSON.stringify(request),
      headers: {
        'content-type': 'application/json',
      },
    });
    return processResponse(response);
  },

  resetCode: async (request: ResetCodeRequest): Promise<ResetCodeResponse> => {
    console.log(request);
    const response = await fetch(authURL + '/reset-code', {
      method: 'POST',
      body: JSON.stringify(request),
      headers: {
        'content-type': 'application/json',
      },
    });
    return processResponse(response);
  },
};
