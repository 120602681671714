import React from 'react'

import { Step1 } from './step1'
import { Step2 } from './step2'
import { Step3 } from './step3'
import { getOrganization } from '../../access'
import { UserDetailsGetResponseData } from '../../api/isc-api'

type UserAddProps = {
  onCancel: () => void
  onSaved: () => void
}

export const AddUser = (props: UserAddProps) => {
  const [step, setStep] = React.useState(1)
  const organization = getOrganization()

  const [email, setEmail] = React.useState('')
  const [user, setUser] = React.useState<UserDetailsGetResponseData>(null)

  const handleStep1Next = (email: string) => {
    setEmail(email)
    setStep(2)
  }

  const handleStep2Back = () => {
    setStep(1)
  }

  const handleStep2Next = (user:UserDetailsGetResponseData) => {
    setUser(user)
    setStep(3)
  }

  const handleStep3Back = () => {
    setStep(2)
  }

  switch (step) {
    case 1:
      return <Step1
        email={email || ''}
        onCancel={props.onCancel}
        onNext={handleStep1Next}
      />
    case 2:
      return <Step2
        onCancel={props.onCancel}
        organizationId={organization.organizationId}
        email={email}
        onBack={handleStep2Back}
        onNext={handleStep2Next}
      />
    case 3:
      return <Step3
        userData={user}
        organizationId={organization.organizationId}
        onCancel={props.onCancel}
        onBack={handleStep3Back}
        onSaved={props.onSaved}
      />
  }
}
