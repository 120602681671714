import React, {useEffect, useState} from "react";
import {nestedProperty} from "../../schema/helpers";

export interface ChipData {
  key: string;
  label: string;
}

export type UseAddCameraModelProps = {
  handleClose: () => void
}

export const useAddItemModal = ({
  handleClose
}: UseAddCameraModelProps) => {
  const [chipData, setChipData] = useState<ChipData[]>([]);
  const [filter, setFilter] = useState('');
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);

  const updateChipData = (items: any[], keyField: string, labelField: string) => {
    const chipData = [];
    for (const next of items) {
      const keyFieldVal = nestedProperty(next, keyField.split('.'));
      const labelFieldVal = nestedProperty(next, labelField.split('.'));

      chipData.push({
        key: keyFieldVal,
        label: labelFieldVal
      });
    }

    setChipData(chipData);
  };

  const handleFormSubmit = (e: Event) => {
    e.preventDefault();
    handleClose();
  };

  const reset = (itemListSetter: React.Dispatch<any[]>) => {
    itemListSetter([]);
    setFilter('');
  };

  const handleChangeItem = (
    checked: boolean,
    item: any,
    items: any[],
    setItems: React.Dispatch<any>,
    compareFields: any[],
    transformFunction?: (item: any) => any
  ) => {
    if (checked) {
      addSelectedItem(item, items, setItems, transformFunction);
    } else {
      const foundIndex = items.findIndex(el => {
        for (const field of compareFields) {
          try {
            const elField = nestedProperty(el, field.split('.'));
            const itemField = nestedProperty(item, field.split('.'));
            if (elField === itemField) {
              return true;
            }
          } catch(_e) {
            return false;
          }
        }

        return false;
      });

      if (foundIndex > -1) {
        removeSelectedItemAtIndex(foundIndex, items, setItems);
      }
    }
  };

  const addSelectedItem = (item: any, items: any[], setSelectedItems: React.Dispatch<any>, transformFunction?: (item: any) => any) => {
    const clonedItems = JSON.parse(JSON.stringify(items));

    if (transformFunction && typeof(transformFunction) === 'function') {
      item = transformFunction(item);
    }
    clonedItems.push(item);
    setSelectedItems(clonedItems);
  }

  const removeSelectedItemAtIndex = (index, items: any[], setSelectedItems: React.Dispatch<any>) => {
    const clonedItems = JSON.parse(JSON.stringify(items));
    clonedItems.splice(index, 1);
    setSelectedItems(clonedItems);
  }

  const handleDeleteItem = (chipToDelete: ChipData, items: any[], setItems: React.Dispatch<any>, compareKey: string, compareValue: string) => {
    const foundIndex = items.findIndex(el => {
      try {
        const compareKeyVal = nestedProperty(el, compareKey.split('.'));
        const compareValueVal = nestedProperty(el, compareValue.split('.'));

        return (
          compareKeyVal === chipToDelete.key &&
          compareValueVal === chipToDelete.label
        );
      } catch(_e) {
        return false;
      }
    });

    if (foundIndex > -1) {
      removeSelectedItemAtIndex(foundIndex, items, setItems);
    }
  };

  const handleFilterChange = (filter: string) => {
    setFilter(filter);
  };

  const setAllSelected = (e, val, selectedListSetter, completeList?) => {
    e.preventDefault();
    val ? selectedListSetter(completeList) : selectedListSetter([]);
  }

  const isItemChecked =  (item: any, items: any[], compareFields: any[]) => {
    return (items).find(c => {
      let matches = true;
      for (const field of compareFields) {
        try {
          const cField = nestedProperty(c, field.split('.'));
          const itemField = nestedProperty(item, field.split('.'));
          matches = matches && (cField === itemField);
        } catch(_e) {
          matches = false
        }
      }

      return matches;
    }) !== undefined;
  }

  return {
    chipData,
    filter,
    setFilter,
    isAllSelected,
    setIsAllSelected,
    updateChipData,
    handleFormSubmit,
    reset,
    handleFilterChange,
    setAllSelected,
    handleChangeItem: handleChangeItem,
    addSelectedItem,
    removeSelectedItemAtIndex,
    handleDeleteItem,
    isItemChecked
  }
}
