import { API } from './lib/api';
import {
  CollectionCameraBasicSearchGetResponseData,
  CollectionCameraDetailSearchGetResponseData,
  UserGroupMin,
  CameraSettingsSettings,
  CameraBasic,
  CameraSettingsSettingsImageRecognition,
  AlertListItem,
  AlertsLogGetResponseData,
  CameraMediaLogGetResponseData,
  CameraMediaSetDetailsGetResponseData,
  AlertOperationStatusGetResponseData,
  Incident,
} from './isc-api';
import { SettingsSchema } from '../schema';
import AlertTypeEnum = AlertListItem.AlertTypeEnum;

export type Camera = {
  cameraId: string;
  name: string;
  imei: string;
  lastSeen?: string;
  lastStatus?: {
    batteryLevel: number;
    storageCapacity: number;
    wifiSignal: number;
    lteSignal: number;
    posted: string;
  };
  subscriptionStatus: 'new' | 'active' | 'inactive';
};

export type CameraLong = Camera & {
  sim: {
    imsi: string;
    iccid: string;
  }[];
  activeSim: number;
};

export interface CameraSearchParams {
  filter?: string;
  excludeUserGroupId?: string;
  onlyIncludeCamerasWithoutCollection?: string;
  start?: number;
  limit?: number;
}

type TempsWithUserGroupsKey = {
  // the api is responding w/ this camelCased, all others in this obj are snake_case however
  userGroups?: Array<UserGroupMin>;
  // the api is still using `on_edge_analytics`, we need this for our PUT
  on_edge_analytics?: CameraSettingsSettingsImageRecognition;
};

export type TempCameraSettingsWithUserGroup = CameraSettingsSettings &
  TempsWithUserGroupsKey;

export interface CameraSettingsApiResponse {
  settings: TempCameraSettingsWithUserGroup;
  schema: SettingsSchema;
  camera: CameraBasic;
}

export type CameraGetMediaLogProps = {
  start: number;
  limit: number;
};

export const CamerasAPI = {
  get: (cameraId: string) =>
    API.get<CameraSettingsApiResponse>(`/cameras/${cameraId}/settings/`),

  getMediaLog: (
    cameraId: string,
    queryStringParameters: CameraGetMediaLogProps = { start: 0, limit: 25 }
  ) =>
    API.get<CameraMediaLogGetResponseData>(`/cameras/media/log/${cameraId}`, {
      queryStringParameters,
    }),

  getMediaSet: (mediaSetId: string) =>
    API.get<CameraMediaSetDetailsGetResponseData>(
      `/cameras/media/set/${mediaSetId}`
    ),

  getAlertLog: (
    cameraId: string,
    queryStringParameters: CameraGetMediaLogProps = { start: 0, limit: 25 }
  ) =>
    API.get<AlertsLogGetResponseData>('/alerts/Camera/log/', {
      queryStringParameters: {
        fCameraId: cameraId,
        ...queryStringParameters,
      },
    }),

  getAlertDetails: (alertType: string, alertId: string) => {
    let alertTypeKey;
    if (alertType === AlertTypeEnum.Incident.toString()) {
      alertTypeKey = 'incidents';
    } else {
      alertTypeKey = 'operations';
    }

    return API.get<
      AlertOperationStatusGetResponseData | Incident
    >(`/alerts/${alertTypeKey}/${alertId}`);
  },

  searchCameras: (queryStringParameters: CameraSearchParams = {}) =>
    API.get<CollectionCameraDetailSearchGetResponseData>(
      `/search/cameras/detail/`,
      { queryStringParameters }
    ),

  searchCamerasBasic: (queryStringParameters: CameraSearchParams = {}) =>
    API.get<CollectionCameraBasicSearchGetResponseData>(
      `/search/cameras/basic/`,
      { queryStringParameters }
    ),

  updateSettings: async (
    cameraId: string,
    body: TempCameraSettingsWithUserGroup
  ) =>
    API.put<CameraSettingsApiResponse>(`/cameras/${cameraId}/settings/`, {
      body,
    }),
};
