import React from 'react';
import { Link } from 'react-router-dom';
import {
  ListItem,
  ListItemText,
  Typography,
  ListItemSecondaryAction,
  Button,
} from '@material-ui/core';
import { cameraCollectionDetails } from '../helpers';
import { People } from '@material-ui/icons';
import { useStyles } from './camera-collection-list-styles';

type UnassignedListItemProps = {
  unassignedCameraCount: number;
};

export const UnassignedListItem = ({
  unassignedCameraCount,
}: UnassignedListItemProps) => {
  const classes = useStyles();

  return (
    <ListItem className={classes.collectionListItem}>
      <Link
        to="/collection/unassigned/settings"
        className={classes.collectionLink}
      >
        <ListItemText>
          <Typography className={classes.collectionName}>
            Unassigned Cameras
          </Typography>
          <Typography className={classes.collectionCount}>
            {cameraCollectionDetails(unassignedCameraCount, 0)}
          </Typography>
        </ListItemText>
        <ListItemSecondaryAction>
          <Button
            size="small"
            variant="outlined"
            disableElevation
            startIcon={<People />}
          >
            Manage
          </Button>
        </ListItemSecondaryAction>
      </Link>
    </ListItem>
  );
};
