import React, {useContext, useEffect, useState} from 'react';
import {useHistory} from 'react-router';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActionArea from '@material-ui/core/CardActionArea';
import {Points} from '../../../components/points';
import {Button} from '@material-ui/core';
import {Add} from '@material-ui/icons';
import {ITabPanel, TabPanel} from '../../../components/tab-panel';
import {getOrganization, hasPermission, UserPermissions} from '../../../access';
import {UserGroupDetails, UserGroupSummary} from '../../../api/isc-api';
import {CreateUserGroupModal} from './add-user-group-modal';
import {useApi} from '../../../hooks';
import {UserGroupsAPI} from '../../../api/user-groups';
import {useStyles} from '../useStyles';
import AppContext from '../../../context';
import {ProgressBar} from '../../../components/progress-bar';
import {EmptyFallback} from '../../../components/empty-fallback';

export default function UserGroupsTab({
  tabValue,
  index,
}: ITabPanel) {
  const classes = useStyles();
  const context = useContext(AppContext);
  const history = useHistory();
  const organization = getOrganization();
  const hasPermissions = hasPermission(UserPermissions.CanManageUsers);
  const isAdmin = hasPermission(UserPermissions.CanManageOrganization) || hasPermission(UserPermissions.CanManageAllOrganizations);
  const [groups, , isLoading] = useApi<UserGroupSummary[]>(() =>
    UserGroupsAPI.list()
  );
  const [filteredGroups, setFilteredGroups] = useState(null);
  const [isCreatingUserGroup, setIsCreatingUserGroup] = useState(false);

  useEffect(() => {
    if (groups && groups?.length) {
      const tmpGroups = [...groups];
      let tmpFilteredGroups = [];

      if (isAdmin) {
        setFilteredGroups(tmpGroups);
      } else {
        tmpGroups.forEach(group => {
          if (organization.userGroupAdminGroupIds.indexOf(group.userGroupId) > -1) {
            tmpFilteredGroups.push(group);
          }
        });

        setFilteredGroups(tmpFilteredGroups);
      }
    }
  }, [groups]);

  const handleGroupClick = (groupId: string) => {
    history.push(`/group/${groupId}`)
  }

  const handleCreateUserGroup = async (userGroupName: string) => {
    if (userGroupName !== null) {
      try {
        const response: UserGroupDetails = await UserGroupsAPI.create({
          name: userGroupName,
        });
        if (Object.prototype.hasOwnProperty.call(response, 'userGroupId')) {
          const userGroupUrl = `/group/${response.userGroupId}`;
          history.push(userGroupUrl);
        } else {
          throw new Error();
        }
      } catch (e) {
        console.log(e);
        context.onAlert(
          'Sorry, there was a problem trying to add this user group.',
          'error'
        );
      }
    }
    setIsCreatingUserGroup(false);
  };

  const groupItems = () =>
    filteredGroups?.length ? (
      filteredGroups.map(group => (
        <Grid key={group.userGroupId} item xs={12} md={6}>
          <Card>
            <CardActionArea
              onClick={() => handleGroupClick(group.userGroupId)}
            >
              <CardHeader
                // TODO: if this avatar isn't here, it messes with the css of the title. need to figure out why and a good solution
                avatar={' '}
                title={<Points items={[group.name]} />}
                subheader={
                  <>
                    <Points items={[`${group.userCount} members`]} />
                    <Points items={[`${group.subGroupCount} sub groups`]} />
                    <Points items={[`${group.cameraCount} cameras`]} />
                  </>
                }
              />
            </CardActionArea>
          </Card>
        </Grid>
      ))
    ) : (
      <Grid item style={{ width: '100%' }}>
        <EmptyFallback
          text={
            hasPermissions
              ? 'No user groups have been created.'
              : 'No user groups are available to you.'
          }
          onAction={() => setIsCreatingUserGroup(true)}
          showButton={hasPermissions}
        />
      </Grid>
    );

  const progressBar = () => (
    <Grid item xs={12}>
      <ProgressBar />
    </Grid>
  );

  return (
    <TabPanel tabValue={tabValue} index={index}>
      {hasPermissions && (
        <div className={classes.actionContainer}>
          <Button
            variant="outlined"
            startIcon={<Add />}
            onClick={() => setIsCreatingUserGroup(true)}
          >
            Add Group
          </Button>
        </div>
      )}
      <Grid container spacing={2}>
        {isLoading ? progressBar() : groupItems()}
      </Grid>
      {hasPermissions && (
        <div className={classes.actionContainer}>
          <Button
            variant="outlined"
            startIcon={<Add />}
            onClick={() => setIsCreatingUserGroup(true)}
          >
            Add Group
          </Button>
        </div>
      )}
      <CreateUserGroupModal
        open={isCreatingUserGroup}
        handleClose={handleCreateUserGroup}
      />
    </TabPanel>
  );
}
