import { SettingsItem, GenericSettingsValue } from '../../../schema';
import { flattenOptions } from '../../../schema/helpers';
import { UserGroup } from '../../../api/isc-api';
import React from 'react';
export const displayValue = (
  item: SettingsItem,
  value: GenericSettingsValue
) => {
  switch (item.type) {
    case 'toggle':
      return value ? 'Enabled' : 'Disabled';
    case 'range':
      switch (item.variation) {
        case 'milliseconds':
          return ((value as number) / 1000).toFixed(1);
        default:
          return value;
      }
    case 'select':
      const options = flattenOptions(item.options);
      return options.find(o => o.value === value).label;
    case 'custom':
      return item.name === 'user_groups'
        ? formatUserGroups(value as unknown as UserGroup[])
        : value.toString();
    default:
      return formatByItemName(item, value);
  }
};

const formatByItemName = (item: SettingsItem, value: GenericSettingsValue) => {
  switch (item.name) {
    case 'to_addr':
      return formatEmailList(value.toString());
    default:
      return value.toString();
  }
};

const formatUserGroups = (value: UserGroup[]) => {
  return value.map(ug => ug.name).join(', ');
};

const formatEmailList = (emailCSV: string) => {
  let formatted = [];
  emailCSV.split(',').forEach(email => {
    formatted.push(<div>{email.trim()}</div>);
  });
  return formatted;
};
