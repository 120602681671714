import React from 'react'
import { PaymentFormComponentProps } from './payment-form-wrapper'
import { Grid, TextField } from '@material-ui/core'
import { PaymentMethodInput } from './payment-method-input'

export const CardFormComponent = (props: PaymentFormComponentProps) => {

  const input = (label: string, id: string) =>
    <PaymentMethodInput
      label={label}
      id={id}
      focused={props.focused === id}
      error={props.errors[id]}
    />

  return <Grid container spacing={1}>
    <Grid item xs={8}>{input('Card Number', 'card_number')}</Grid>
    <Grid item xs={2}>{input('Expires', 'expiry')}</Grid>
    <Grid item xs={2}>{input('CVC', 'card_code')}</Grid>
    <Grid item xs={12}>
      <TextField
        inputProps={{"pl-input": "account_holder"}}
        fullWidth
        error={props.errors['account_holder']}
        label='Account Holder'
      />
    </Grid>
  </Grid>
}
