import React, { useState, useEffect } from 'react';
import { ImageRecognitionMode } from '../../../../../api/recognition';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { useSetCameraProfile } from './services/useSetCameraProfile';
import { Subject } from '../../../../../api/isc-api';
import { ListSubheader } from "@material-ui/core";
import {useStyles} from "./select-profile-styles";
import {NewSubjectId, UndefinedSubjectId} from "./index";

type SelectedProfile = {
  subjectId: string;
  name: string;
};

type EditProfileProps = {
  onValueChanges: (subjectId: string) => void;
  subjectId: string;
  appliedProfiles: string[];
  profileList: Subject[];
  mode: ImageRecognitionMode;
  profilesLoaded: boolean;
};

export const EditProfile = ({
  onValueChanges,
  subjectId,
  appliedProfiles,
  profileList,
  profilesLoaded,
  mode
}: EditProfileProps) => {
  const classes = useStyles();
  const ref = React.useRef<HTMLElement>();
  const [activeProfiles, setActiveProfiles] = useState<Subject[]>([]);
  const [inactiveProfiles, setInactiveProfiles] = useState<Subject[]>([]);

  const [selectedProfile, setSelectedProfile] = useState<SelectedProfile>(null);
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleClearSelection = () => {
    setSelectedProfile({
      subjectId: UndefinedSubjectId,
      name: 'Select',
    });

    onValueChanges(UndefinedSubjectId);
    setOpen(false);
  };

  const handleCreateNewSelection = () => {
    setSelectedProfile({
      subjectId: NewSubjectId,
      name: 'Create New',
    });

    onValueChanges(NewSubjectId);
    setOpen(false);
  };

  const handleProfileSelection = async ({ subjectId, name }: Subject) => {
    setSelectedProfile({
      subjectId,
      name,
    });

    onValueChanges(subjectId);
    setOpen(false);
  };

  const isProfileApplied = (profile: Subject) => {
    return appliedProfiles.indexOf(profile.subjectId) > -1;
  }

  const getProfileLabel = (profile: Subject) => {
    return `${profile.name} ${isProfileApplied(profile) ? '(Currently Active)' : ''}`;
  }

  useEffect(() => {
    setSelectedProfile(getInitialProfile(subjectId, profileList));
    setActiveProfiles([...profileList].filter(el => el.isWarrantExpired === false));
    setInactiveProfiles([...profileList].filter(el => el.isWarrantExpired === true));

  }, [profilesLoaded, profileList, subjectId]);

  if (!profilesLoaded) return null;

  return (
    <>
      <ListItem button onClick={handleClick}>
        <ListItemText
          primary="Create or Edit Profile"
          primaryTypographyProps={{ variant: 'overline' }}
          secondary={selectedProfile.name}
          secondaryTypographyProps={{ variant: 'h6', color: 'primary' }}
          ref={ref}
        />
        <ListItemSecondaryAction className={classes.secondaryAction}>
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemSecondaryAction>
      </ListItem>

      <Dialog open={open} onClose={handleClick}>
        <DialogTitle>Select Profile</DialogTitle>
        <DialogContent dividers>
          <MenuList style={{ height: '100%' }}>
            <MenuItem
              button
              key="CreateNew"
              className={classes.profileList}
              onClick={() => handleCreateNewSelection()}
              selected={selectedProfile.subjectId === NewSubjectId}
            >
              Create New
            </MenuItem>
            <MenuItem
              button
              key="Select"
              className={classes.profileList}
              onClick={() => handleClearSelection()}
            >
              Cancel
            </MenuItem>
            {activeProfiles.length ?
              <ListSubheader disableSticky={true}>Active Profiles</ListSubheader>
              : null
            }
            {activeProfiles.map(profile => (
              <MenuItem
                button
                key={profile.subjectId}
                className={`${classes.profileList} ${isProfileApplied(profile) ? classes.profileListActive : ''}`}
                onClick={() => handleProfileSelection(profile)}
                selected={profile.subjectId === selectedProfile.subjectId}
              >
                {getProfileLabel(profile)}
              </MenuItem>
            ))}
            {inactiveProfiles.length ?
              <ListSubheader disableSticky={true}>Inactive Profiles</ListSubheader>
              : null
            }
            {inactiveProfiles.map(profile => (
              <MenuItem
                button
                key={profile.subjectId}
                className={`${classes.profileList} ${isProfileApplied(profile) ? classes.profileListActive : ''}`}
                onClick={() => handleProfileSelection(profile)}
                selected={profile.subjectId === selectedProfile.subjectId}
              >
                {getProfileLabel(profile)}
              </MenuItem>
            ))}
          </MenuList>
        </DialogContent>
      </Dialog>
    </>
  );
};

const getInitialProfile = (
  initialSubjectId: string,
  profileList: Subject[]
) => {
  const selectProfile = {
    subjectId: UndefinedSubjectId,
    name: 'Select',
  };

  const createNewProfile = {
    subjectId: NewSubjectId,
    name: 'Create New',
  };

  if (initialSubjectId === NewSubjectId) {
    return createNewProfile;
  } else if (!initialSubjectId || profileList.length === 0) {
    return selectProfile;
  }

  let profile = profileList.find(
    profile => profile.subjectId === initialSubjectId
  );

  if (!profile) {
    return selectProfile;
  }

  return {
    subjectId: profile.subjectId,
    name: profile.name,
  };
};

