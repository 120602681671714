import React from 'react';
import { Typography } from '@material-ui/core';
import { MyPinnedCamera } from '../../../api/isc-api';
import Carousel from 'react-elastic-carousel';
import { CameraCard } from '../../../components/camera-card';
import './carousel-styles.css';
import {
  useStyles,
  getBreakPoints,
  getShouldShowControls,
  getShouldForceSinglePage,
} from './index-styles';
import clsx from 'clsx';
import { useHistory } from 'react-router';

export type PinnedCamera = {
  label: string;
  imgPath: string;
};

type PinnedCamerasProps = {
  pinnedCameras: MyPinnedCamera[];
};

export const PinnedCameras = ({ pinnedCameras }: PinnedCamerasProps) => {
  const classes = useStyles();
  const history = useHistory();
  const showControls = getShouldShowControls(pinnedCameras.length);
  const isSingle = pinnedCameras.length === 1;
  const breakPoints = getBreakPoints(isSingle);
  const forceSingplePage = getShouldForceSinglePage(isSingle);

  const onCardClick = (id: string) => {
    history.push(`/camera/${id}`);
  };

  return (
    <>
      <Typography
        variant="h1"
        className={clsx(
          showControls ? classes.h1WithControls : classes.h1NoControls,
          classes.h1
        )}
      >
        Pinned Cameras
      </Typography>
      <div
        className={clsx(
          forceSingplePage && 'force-single-page',
          classes.carousel
        )}
      >
        <Carousel
          itemsToScroll={4}
          itemsToShow={4}
          breakPoints={breakPoints}
          showArrows={showControls}
          pagination={showControls}
        >
          {pinnedCameras.map(pin => (
            <CameraCard
              key={pin.camera.cameraId}
              cameraName={pin.camera.cameraName}
              collectionName={
                pin.cameraCollection?.cameraCollectionName || 'No Collection'
              }
              cameraThumbnail={pin.cameraThumbnail}
              operationStatus={pin.operationStatus}
              onClick={() => onCardClick(pin.camera.cameraId)}
            />
          ))}
        </Carousel>
      </div>
    </>
  );
};
