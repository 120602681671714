import { UserDetailsGetResponseData } from '../../api/isc-api';
import { useApi } from '../../hooks';
import { UsersAPI } from '../../api/users';
import { NotFoundException } from '../../api/lib/domain';

export const useExistingUser = (email: string): [UserDetailsGetResponseData, boolean] => {
  const [existingUser, , isLookingUp] = useApi<UserDetailsGetResponseData>(
    async () =>
      UsersAPI.find(email)
        .then(async (users) => {
          if (users.length === 0) {
            return null;
          }
          return await UsersAPI.get(users[0].userId);
        })
        .catch(err => {
          if (err instanceof NotFoundException) {
            return null;
          }
          throw err;
        }),
    null,
    [email]
  );

  return [existingUser, isLookingUp]
};
