import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  Checkbox,
  Box,
  IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { DatePicker } from '../../components/form-fields/datepicker';
import { styles } from '@material-ui/pickers/views/Calendar/Calendar';

const useStyles = makeStyles(theme => ({
  list: {
    width: '100%',

    backgroundColor: theme.palette.background.paper,
    color: theme.palette.common.black,
  },
  content: {
    padding: '0px 8px 8px 8px',
  },
  category: {
    paddingLeft: theme.spacing(0),
  },
  nested: {
    paddingLeft: theme.spacing(2),
  },
  dates: {
      display: "flex",
      flex: 1,
      flexDirection: "column"

 
  },
  listGroup: {
    color: theme.palette.common.black,
    fontWeight: 600,
    paddingLeft: theme.spacing(2),
  },
  nextGroup: {
    color: theme.palette.common.black,
    fontWeight: 600,
    paddingLeft: theme.spacing(2),
  },
  listItem: {
    color: theme.palette.common.black,
    fontWeight: 500,
  },
  slimTextField: {
    minHeight: '10px',
  },
  dateContainerLeft: {
    paddingRight: '16px',
  },
  dateContainerRight: {
    paddingLeft: '8px',
  },
}));

export type AlertFilterProps = {
  open: boolean;
  handleClose: () => void;
  handleSubmit: (filters: any) => void;
  filters: any;
  menuType?: string;
};

export const AlertFilter = ({
  open,
  handleClose,
  handleSubmit,
  filters,
  menuType = 'alert',
}: AlertFilterProps) => {
  const classes = useStyles();
  const [checked, setChecked] = useState({ ...filters });
  const [errors, setErrors] = useState([]);

  const handleToggle = (name: string, value: any) => {
    let newChecked = { ...checked };
    console.log(' UPDATE ');
    if (!!value) {
      console.log(`${name} updated value is ${value}`);
      newChecked[name] = value;
    } else if (newChecked[name]) {
      console.log(`${name} deleted because value is ${value}`);
      delete newChecked[name];
    }

    setChecked(newChecked);
  };

  const isChecked = (name: string) => {
    return !!checked[name];
  };

  const checkListItem = ({ label, name }) => {
    return (
      <ListItem className={classes.nested} id={name}>
        <ListItemText
          primary={label}
          primaryTypographyProps={{ className: classes.listItem }}
        />
        <ListItemSecondaryAction>
          <Checkbox
            edge={'end'}
            onChange={() => handleToggle(name, !isChecked(name))}
            checked={isChecked(name)}
          />
        </ListItemSecondaryAction>
      </ListItem>
    );
  };

  const onClose = () => {
    setChecked({});
    handleClose();
  };

  const handleReset = () => {
    setChecked({});
  };

  const handleApplyFilters = () => {
    handleClose();
    const filter = { ...checked };

    handleSubmit(filter);
  };
  const checkList = [
    {
      category: 'Incidents',
      items: [
        { label: 'License Recognition', name: 'fIncidentLpr' },
        { label: 'License Profile Match', name: 'fIncidentLprMatch' },
        /*{ label: 'Facial Recognition', name: 'fIncidentFr' },*/
        { label: 'Motion Detection', name: 'fIncidentMotion' },
      ],
    },
    {
      category: 'Camera Status',
      items: [
        { label: 'Battery Low or Critical', name: 'fStatusBattery' },
        { label: 'Storage Low or Critical', name: 'fStatusStorage' },
        { label: 'Wifi or Cellular Signal Weak', name: 'fStatusSignal' },
        { label: 'Camera Online', name: 'fStatusCameraOnline' },
      ],
    },
  ];
  const errorIndex = (name: string) => {
    let errorList = errors.findIndex(error => error.name === name);

    return errorList;
  };

  return (
    <div>
      <Dialog
        maxWidth={'xl'}
        open={open}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <Box display="flex">
            <Box flexGrow={1}>SELECT FILTERS</Box>
            <Box>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <List className={classes.list}>
            {menuType === 'alert' ? (
              <>
                {checkList.map(group => (
                  <>
                    <ListItem className={classes.category}>
                      <ListItemText
                        primary={group.category}
                        primaryTypographyProps={{
                          className: classes.listGroup,
                        }}
                      />
                    </ListItem>
                    <Divider />
                    {group.items.map(item => checkListItem(item))}
                  </>
                ))}
              </>
            ) : null}

            <ListItem className={classes.category}>
              <ListItemText
                primary="Date Range"
                primaryTypographyProps={{ className: classes.listGroup }}
              />
            </ListItem>
            <Divider />
            <ListItem className={classes.dates}>
              <DatePicker
                group={null}
                item={{ name: 'startDate', label: 'Start', type: 'custom' }}
                value={checked.startDate}
                maxDate={checked.endDate ? checked.endDate : undefined}
                helper={
                  errorIndex('startDate') !== -1
                    ? errors[errorIndex('startDate')].error
                    : null
                }
                onChange={date => handleToggle('startDate', date)}
                readOnly={false}
            
              />
              <DatePicker
                group={null}
                item={{ name: 'endDate', label: 'End', type: 'custom' }}
                value={checked.endDate}
                minDate={checked.startDate ? checked.startDate : undefined}
                onChange={date => handleToggle('endDate', date)}
                readOnly={false}

              />
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleReset} color="secondary">
            Reset
          </Button>
          <Button onClick={handleApplyFilters} color="primary">
            Apply Filter
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

