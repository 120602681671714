import React from "react";
import { Dialog } from "@material-ui/core";
import { AddUser } from "../../../components/add-user";
import { UserPermissions } from "../../../access";
import { withPermission } from "../../../access/withPermission";

type CreateUserModalProps = {
  open: boolean;
  handleSave: (id?: string) => void;
  handleCancel: () => void;
};

export const CreateUserModal = withPermission(
  UserPermissions.CanManageUsers,
  (props: CreateUserModalProps) => {
    return (
      <React.Fragment>
        <Dialog
          open={props.open}
          aria-labelledby="form-dialog-title"
          maxWidth="sm"
          fullWidth
        >
          <AddUser
            onCancel={props.handleCancel}
            onSaved={props.handleSave}
          />
        </Dialog>
      </React.Fragment>
    );
  }
);
