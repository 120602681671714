import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { EditableTitle } from '../../../components/editable-title';
import { buildUserGroupUpdateBody } from '../hooks/buildUserGroupUpdateBody';
import { UserGroupsAPI } from '../../../api/user-groups';
import { AlertSnackPortal } from '../../../components/alert-snack-portal';
import { ConfirmDialogModal } from '../../../components/confirm-dialog-modal';
import { AlertDialogModal } from '../../../components/alert-dialog-modal';
import DeleteIcon from '@material-ui/icons/Delete';
import { PageHeader } from '../../../components/page-header';
import { Button } from '@material-ui/core';
import { UserGroupDetails, UserGroup } from '../../../api/isc-api';
export type GroupTitleProps = {
  groupParents?: UserGroup[];
  userGroup: any;
  isAdmin: Boolean;
  classes: any;
  setUserGroup: React.Dispatch<React.SetStateAction<UserGroupDetails>>;
  setShowAlert: React.Dispatch<React.SetStateAction<any>>;
};

export const GroupTitle = (props: GroupTitleProps) => {
  const { isAdmin, userGroup, classes, setShowAlert, groupParents } = props;
  const [title, setTitle] = useState(userGroup?.name);
  // const [showAlert, setShowAlert] = useState(null);
  const [isDeleteAlert, setIsDeleteAlert] = useState(false);
  const [isDeletingUserGroup, setIsDeletingUserGroup] = useState(false);
  const [breadcrumbs, setBreadcrumbs] = useState([
    {
      label: 'Groups',
      href: '/users/groups',
    },
  ]);
  const history = useHistory();

  useEffect(() => {
    if (groupParents && groupParents.length > 0) {
      let newBreadcrumbs = [...breadcrumbs];
      groupParents.map(parent =>
        newBreadcrumbs.push({
          label: parent.name,
          href: `/group/${parent.userGroupId}`,
        })
      );
      setBreadcrumbs(newBreadcrumbs);
    }
  }, [groupParents]);
  useEffect(() => {
    if (userGroup.users) {
      setTitle(userGroup.users);
    }
  }, [userGroup.users]);

  const handleDeleteUserGroup = () => {
    props.setUserGroup;
    if (userGroup.users.length || userGroup.cameras.length) {
      setIsDeleteAlert(true);
    } else {
      setIsDeletingUserGroup(true);
    }
  };

  const handleConfirmDeleteUserGroup = async (confirmed: boolean) => {
    if (confirmed) {
      try {
        await UserGroupsAPI.delete(userGroup.userGroupId);
        history.replace('/users/groups');
        setShowAlert({
          message: 'Successfully deleted user group.',
          type: 'success',
        });
      } catch (e) {
        setShowAlert({ message: e.detail, type: 'error' });
      }
    }
    setIsDeletingUserGroup(false);
  };

  const getDeleteAlertErrorMessage = () => {
    return `The user group you are trying to delete still has 
              ${userGroup?.cameras?.length ? 'cameras' : ''} 
              ${
                userGroup?.cameras?.length && userGroup?.users?.length
                  ? 'and'
                  : ''
              } 
              ${userGroup?.users?.length ? 'users' : ''}  
              in it. Please remove them and try again.`;
  };

  const handleTitleChange = async (title: string) => {
    const userGroupUpdateObject = buildUserGroupUpdateBody({
      ...userGroup,
      name: title,
    });
    try {
      const response: any = await UserGroupsAPI.update(
        userGroup.userGroupId,
        userGroupUpdateObject
      );
      if (response) {
        // setTitle(response.name)
        props.setUserGroup(response);
        setShowAlert({ message: 'Title updated', type: 'success' });
      } else {
        throw new Error();
      }
    } catch (e) {
      setShowAlert({ message: e.detail, type: 'error' });
    }
  };
  return (
    <>
      <PageHeader
        editableTitle={
          <EditableTitle title={title} onTitleChange={handleTitleChange} />
        }
        breadcrumbs={breadcrumbs}
        button={
          isAdmin ? (
            <Button
              className={classes.deleteButton}
              variant="contained"
              onClick={handleDeleteUserGroup}
            >
              <DeleteIcon />
              Delete User Group
            </Button>
          ) : null
        }
      />

      <AlertDialogModal
        open={isDeleteAlert}
        message={getDeleteAlertErrorMessage()}
        handleClose={() => setIsDeleteAlert(false)}
      />
      <ConfirmDialogModal
        open={isDeletingUserGroup}
        message={'Are you sure you want to delete this user group?'}
        confirmText={'Delete'}
        value={true}
        handleClose={handleConfirmDeleteUserGroup}
      />
    </>
  );
};

