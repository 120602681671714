import {MediaSetItem, RecognitionResultBase, VehicleRecognitionResult} from "../../../api/isc-api";

export const VehicleRecognitionPropertyLabels = {
  color: 'Color',
  make: 'Make',
  make_model: 'Make / Model',
  body_type: 'Body Type',
  orientation: 'Orientation',
  year: 'Year Range'
}

export function getVehiclePropertyLabel(key: string) {
  return VehicleRecognitionPropertyLabels.hasOwnProperty(key) ? VehicleRecognitionPropertyLabels[key] : key;
}

export function getVehicleBestMatchSummary(vehicle: VehicleRecognitionResult) {
  const vehicleProps = Object.entries(vehicle);
  const summary = [];

  for (let [propName, propVal] of vehicleProps) {
    propVal = <RecognitionResultBase>propVal;
    if (propVal?.bestMatch) {
      const {bestMatch} = propVal;
      const value = bestMatch.name;
      summary.push(`${value} (${bestMatch.confidence.toFixed(2)}% confidence)`);
    }
  }

  return summary.join(', ');
}

export function getVehiclePropList(vehicle: VehicleRecognitionResult) {
  return Object.entries(vehicle);
}

export function hasRecognitionResults(item: MediaSetItem) {
  return item.recognitionResults?.vehicles?.length > 0 || item.recognitionResults?.licensePlates?.length > 0;
}
