import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  TextField,
} from "@material-ui/core";

type CreateUserGroupModalProps = {
  open: boolean,
  handleClose: (userGroupName: string) => void
}

const useStyles = makeStyles((theme) => ({
  title: {
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px 0`
  },
  cardContent: {
    padding: "0"
  },
  actionContainer: {
    paddingTop: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-end"
  }
}))


export const CreateUserGroupModal = (props: CreateUserGroupModalProps) => {
  const classes = useStyles(props);
  const [userGroupName, setUserGroupName] = useState('');

  const handleFormSubmit = (ev: Event) => {
    ev.preventDefault();
    props.handleClose(userGroupName);
  }

  return (
    <React.Fragment>
      <Dialog
        open={props.open}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle className={classes.title} id="form-dialog-title">Create a New User Group</DialogTitle>
        <form action="#" onSubmit={(ev: any) => handleFormSubmit(ev)}>
          <DialogContent>
            <DialogContentText>
              Enter a name for your new user group
            </DialogContentText>
            <TextField
              required
              fullWidth
              autoFocus
              id="userGroupName"
              name="userGroupName"
              onChange={(ev: any) => setUserGroupName(ev.target.value)}
            />
          </DialogContent>
          <DialogActions className={classes.actionContainer}>
            <Button
              size="small"
              onClick={() => props.handleClose(null)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              size="small"
              disabled={!userGroupName}
            >
              Create
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  )
}
