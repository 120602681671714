import React, { Fragment, useState, ChangeEvent } from "react";
import { Prompt } from "react-router";
import PropTypes from "prop-types";
import { PageHeader } from "../../components/page-header";
import { AppBar, Tabs, Tab, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { SMTPForm } from "./forms/smtp-form";
import { MediaSharingForm } from "./forms/media-sharing-form";
import { ImageRecognitionForm } from "./forms/image-recognition-form";
import { ConfirmDialogModal } from "../../components/confirm-dialog-modal";
import { getOrganization } from "../../access";

const useStyles = makeStyles(theme => ({
  tabWrapper: {
    padding: "0",
    backgroundColor: theme.palette.background.paper,
    marginBottom: "20px",
    boxShadow:
      "0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2)"
  }
}));

type TabPanelProps = {
  value: number;
  index: number;
  children: string | JSX.Element;
};

function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`organization-tabpanel-${index}`}
      aria-labelledby={`organization-tab-${index}`}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index: any) {
  return {
    id: `organization-tab-${index}`,
    "aria-controls": `organization-tabpanel-${index}`
  };
}

const getTabDisplaySettings = () => {
  const {
    organizationFeatures: {
      sharing,
      facialRecognition,
      licensePlateRecognition
    }
  } = getOrganization();

  const displaySettings = {
    mediaSharing: false,
    imageRecognition: false
  };

  if (sharing?.subscriptionSettings?.isSupported) {
    displaySettings.mediaSharing = true;
  }

  if (
    facialRecognition?.subscriptionSettings?.isSupported ||
    licensePlateRecognition?.subscriptionSettings?.isSupported
  ) {
    displaySettings.imageRecognition = true;
  }

  return displaySettings;
};

export const OrganizationSettingsPage = () => {
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);
  const [isDirty, setIsDirty] = useState(false);
  const [displayDirtyModal, setDisplayDirtyModal] = useState(false);
  const [tempTabValue, setTempTabValue] = useState(0);
  const displaySettings = getTabDisplaySettings();

  // Dynamically increments tab indexes that are shown conditionally
  const tabIndexes = {};
  let tabIndex = 0;
  const getTabIndex = (key: string) => {
    if (typeof tabIndexes[key] === "undefined") {
      tabIndexes[key] = tabIndex++;
    }

    return tabIndexes[key];
  };

  //Listens for tab changes, if child form in tab has unsaved changes, prompt the user otherwise set newly active tab
  const handleTabChange = (event: ChangeEvent<{}>, newValue: number) => {
    event.preventDefault();
    setTempTabValue(newValue);

    if (isDirty) {
      setDisplayDirtyModal(true);
    } else {
      setTabValue(newValue);
    }
  };

  //Display confirmation modal when forms are dirty
  const handleNavigateAway = (confirmed: boolean) => {
    setDisplayDirtyModal(false);
    if (confirmed) {
      setTabValue(tempTabValue);
      setIsDirty(false);
    }
  };

  return (
    <Fragment>
      {/* TODO: Look into replacing Prompt with a custom alert */}
      <Prompt
        when={isDirty}
        message="You have made changes without saving, are you sure you want to continue?"
      />
      <PageHeader title="Organization Settings" />

      <AppBar position="static">
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="Organization Setting Tabs"
        >
          <Tab label="SMTP Settings" {...a11yProps(getTabIndex("smtp"))} />

          {displaySettings.mediaSharing && (
            <Tab
              label="Media Sharing"
              {...a11yProps(getTabIndex("mediaSharing"))}
            />
          )}

          {displaySettings.imageRecognition && (
            <Tab
              label="Image Recognition"
              {...a11yProps(getTabIndex("imageRecognition"))}
            />
          )}
        </Tabs>
      </AppBar>

      <TabPanel value={tabValue} index={getTabIndex("smtp")}>
        <Box className={classes.tabWrapper}>
          <SMTPForm setFormDirtyState={setIsDirty} />
        </Box>
      </TabPanel>

      {displaySettings.mediaSharing && (
        <TabPanel value={tabValue} index={getTabIndex("mediaSharing")}>
          <Box className={classes.tabWrapper}>
            <MediaSharingForm setFormDirtyState={setIsDirty} />
          </Box>
        </TabPanel>
      )}

      {displaySettings.imageRecognition && (
        <TabPanel value={tabValue} index={getTabIndex("imageRecognition")}>
          <Box className={classes.tabWrapper}>
            <ImageRecognitionForm setFormDirtyState={setIsDirty} />
          </Box>
        </TabPanel>
      )}

      <ConfirmDialogModal
        open={displayDirtyModal}
        message={
          "You have made changes without saving, are you sure you want to continue?"
        }
        confirmText={"Continue without saving"}
        cancelText="Go Back"
        value={true}
        handleClose={handleNavigateAway}
      />
    </Fragment>
  );
};
