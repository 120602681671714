import { makeStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles(theme => ({
  actionContainer: {
    padding: theme.spacing(4),
    display: 'flex',
    justifyContent: 'space-between',
  },
  saveResetButtonContainer: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  saveButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      marginBottom: theme.spacing(2),
    },
  },
  deleteIcon: {
    marginRight: theme.spacing(0.5),
  },
}));
