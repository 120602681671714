import { makeStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles(theme => ({
  settingsDrawerOpen: {
    zIndex: `${theme.zIndex.snackbar + 2} !important` as any
  },
  updatedGroup: {
    marginTop: theme.spacing(4),
  },
  updatedGroupError: {
    marginTop: theme.spacing(4),
  },
  updatedItems: {
    paddingLeft: theme.spacing(2),
  },
  drawer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    height: '100%',
    width: '400px',
    maxWidth: '80vw',
  },
  drawerHeader: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  itemLink: {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  drawerActionContainer: {
    marginTop: theme.spacing(4),
  },
  '@keyframes uploadEffect': {
    '0%': {
      transform: 'translateY(0)',
    },
    '50%': {
      transform: 'translateY(-15%)',
    },
    '100%': {
      transform: 'translateY(0)',
    },
  },
}));
