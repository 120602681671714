import React, { useState } from 'react';
import { useHistory } from 'react-router';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActionArea from '@material-ui/core/CardActionArea';
import { Button } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import { Add } from '@material-ui/icons';
import {
  userType,
  getOrganization,
  hasPermission,
  UserPermissions,
} from '../../../access';
import { Points } from '../../../components/points';
import { TabPanel, ITabPanel } from '../../../components/tab-panel';
import { CreateUserModal } from './add-user-modal';
import { useStyles } from '../useStyles';
import { UsersAPI } from '../../../api/users';
import { useApi } from '../../../hooks';
import { ProgressBar } from '../../../components/progress-bar';
import { EmptyFallback } from '../../../components/empty-fallback';

export function UsersTab({ tabValue, index }: ITabPanel) {
  const classes = useStyles();
  const organization = getOrganization();
  const [isCreatingUser, setIsCreatingUser] = useState(false);
  const hasPermissions = hasPermission(UserPermissions.CanManageUsers);
  const history = useHistory();

  const [users, , isLoading] = useApi(() => UsersAPI.list());

  const handleUserClick = (userId: string) => {
    history.push(`/user/${userId}`);
  };

  const handleCreateUser = (id: string) => {
    setIsCreatingUser(false);
    history.push(`/user/${id}`);
  };

  const userItems = () =>
    users ? (
      users.map(user => (
        <Grid key={user.hash} item xs={12} md={6}>
          <Card>
            <CardActionArea onClick={() => handleUserClick(user.userId)}>
              <CardHeader
                className={classes.cardHeader}
                avatar={
                  <Avatar>
                    <img
                      src={`https://www.gravatar.com/avatar/${user.hash}?s=40&d=mp`}
                    />
                  </Avatar>
                }
                title={
                  <Points
                    items={[
                      user.name,
                      <Points.Item key={0} hide={user.isVerified} color="error">
                        Not activated yet
                      </Points.Item>,
                      <Points.Item
                        key={1}
                        hide={!user.isLockedOut}
                        color="error"
                      >
                        Locked
                      </Points.Item>,
                    ]}
                  />
                }
                subheader={
                  <Points
                    items={[
                      userType(user, organization.organizationOwner),
                      user.email,
                      user.phone,
                    ]}
                  />
                }
              />
            </CardActionArea>
          </Card>
        </Grid>
      ))
    ) : (
      <Grid item style={{ width: '100%' }}>
        <EmptyFallback
          text={
            hasPermissions
              ? 'No users have been created.'
              : 'No users are available to you.'
          }
          onAction={() => setIsCreatingUser(true)}
          showButton={hasPermissions}
        />
      </Grid>
    );

  const progressBar = () => (
    <Grid item xs={12}>
      <ProgressBar />
    </Grid>
  );

  return (
    <TabPanel tabValue={tabValue} index={index}>
      {hasPermissions && (
        <div className={classes.actionContainer}>
          <Button
            variant="outlined"
            startIcon={<Add />}
            onClick={() => setIsCreatingUser(true)}
          >
            Add User
          </Button>
        </div>
      )}
      <Grid container spacing={2}>
        {isLoading ? progressBar() : userItems()}
      </Grid>
      {hasPermissions && (
        <div className={classes.actionContainer}>
          <Button
            variant="outlined"
            startIcon={<Add />}
            onClick={() => setIsCreatingUser(true)}
          >
            Add User
          </Button>
        </div>
      )}
      <CreateUserModal
        open={isCreatingUser}
        handleCancel={() => setIsCreatingUser(false)}
        handleSave={id => handleCreateUser(id)}
      />
    </TabPanel>
  );
}
