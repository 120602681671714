import React from 'react'
import { Card, CardHeader, CardContent, Grid, RadioGroup, FormControlLabel, Radio, CardActions, Button } from '@material-ui/core'
import { PaymentFormWrapper } from './payment-form-wrapper'
import { CardFormComponent } from './card-form-component'
import { BankFormComponent } from './bank-form-component'

type PaymentFormProps = {
  customerId: string
  buttonLabel: string
  disabled: boolean

  onSuccess?: () => void
  onError?: (err: any) => void
}

export const PaymentForm = (props: PaymentFormProps) => {

  const [sourceType, setSourceType] = React.useState<string>('card')
  const [paymentMethodSubmit, setPaymentMethodSubmit] = React.useState<() => void>(null)

  return (
    <Card>
      <CardHeader title='Payment Information'/>
      <CardContent>
        <Grid container spacing={1} justify='flex-start'>
          <Grid item xs={12}>
            <RadioGroup value={sourceType} onChange={(_,value) => setSourceType(value)}>
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <FormControlLabel value="bank" control={<Radio />} label="Bank account" />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControlLabel value="card" control={<Radio />} label="Credit card" />
                </Grid>
              </Grid>
            </RadioGroup>
          </Grid>
          <Grid item xs={12}>
            <PaymentFormWrapper
              customerId={props.customerId}
              component={sourceType==='bank' ? BankFormComponent : CardFormComponent}
              formKey={sourceType}
              onSetCallback={fn => setPaymentMethodSubmit(() => fn)}
              onError={props.onError || (() => {})}
              onSuccess={props.onSuccess || (() => {})}
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Button
          color='primary'
          variant='contained'
          onClick={paymentMethodSubmit}
          disabled={props.disabled}
        >{props.buttonLabel}</Button>
      </CardActions>
    </Card>
  )
}