import React from 'react';
import Button from '@material-ui/core/Button';
import { useApiUpdate } from '../../hooks';
import { UsersAPI } from '../../api/users';
import { AddUserStep } from './step';
import {
  UserDetailsGetResponseData,
  UserDetailsPostPutBody,
} from '../../api/isc-api';
import { UserRolesCheckboxList } from '../user-role-checkbox-list';
import { useOrganizationRoles } from '../user-role-checkbox-list/useOrganizationRoles';

type Step3Props = {
  userData: UserDetailsGetResponseData;
  organizationId: string;

  onCancel: () => void;
  onBack: () => void;
  onSaved: (id?: string) => void;
};

type UpdateBody = Partial<UserDetailsPostPutBody>;

export const Step3 = ({
  userData: { userDetails, availableOrganizationalRoles },
  onCancel,
  onBack,
  onSaved,
}: Step3Props) => {
  const [doSave, isSaving] = useApiUpdate<
    UpdateBody,
    UserDetailsGetResponseData
  >(
    user => {
      return UsersAPI.update(userDetails.userId, {
        ...user,
      });
    },
    () => onSaved(userDetails.userId)
  );

  const handleSave = () => {
    const updateObject: Partial<UserDetailsPostPutBody> = {
      organizationalRoleIds: selectedRoles.map(r => r.userRoleId),
      ...userDetails,
    };
    doSave(updateObject);
  };

  const { availableRoles, selectedRoles, handleRoleSelection } =
    useOrganizationRoles(availableOrganizationalRoles);

  return (
    <AddUserStep
      subtitle="Set permissions for the new user"
      onCancel={onCancel}
      onSubmit={handleSave}
      actions={
        <React.Fragment>
          <Button onClick={onBack}>Back</Button>
          <Button type="submit" color="primary" disabled={isSaving}>
            {isSaving ? 'Saving...' : 'Save'}
          </Button>
        </React.Fragment>
      }
    >
      <UserRolesCheckboxList
        availableRoles={availableRoles}
        handleRoleSelection={handleRoleSelection}
      />
    </AddUserStep>
  );
};
