import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, Typography } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { DashboardSearchBox } from '../dashboard-search-box';

type PanelHeaderProps = {
  title: string;
  buttonText?: string;
  placeholderText: string;
  isDisabled?: boolean;
  titleSize?: any;
  filterSize?: any;
  onAddNew: () => void;
  onFilterChange?: (filter: string) => void;
};

const useStyles = makeStyles(theme => ({
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  header: {
    color: theme.palette.common.black,
    margin: theme.spacing(0),
  },
  newButton: {
    marginLeft: theme.spacing(2),
  },
}));

export const PanelHeader = (props: PanelHeaderProps) => {
  const { title, buttonText, placeholderText, onAddNew, onFilterChange } =
    props;

  const classes = useStyles();
  return (
    <Grid container item spacing={2}>
      <Grid
        item
        xs={12}
        md={props.titleSize ? props.titleSize : 8}
        className={classes.titleContainer}
      >
        <Typography variant="h1" className={classes.header}>
          {title}
        </Typography>
        {onAddNew && (
          <Button
            size="small"
            variant="outlined"
            disableElevation
            disabled={props.isDisabled}
            className={classes.newButton}
            startIcon={<Add />}
            onClick={onAddNew}
          >
            {buttonText || 'New'}
          </Button>
        )}
      </Grid>
      <Grid item xs={12} md={props.filterSize ? props.filterSize : 4}>
        {onFilterChange && (
          <DashboardSearchBox
            value=""
            placeholderText={placeholderText}
            onFilterChange={onFilterChange}
          />
        )}
      </Grid>
    </Grid>
  );
};
