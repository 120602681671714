import * as React from 'react';
import { Range, Select, Text, Toggle } from '../../../components/form-fields';
import { Subhead } from './subhead';
import {
  SettingsItem,
  GenericSettingsValue,
  SettingsGroup, SettingsAutoUpdater,
} from '../../../schema';
import { CustomItem } from './item-custom';

export interface ItemProps {
  componentName?: string;
  item: SettingsItem;
  value: GenericSettingsValue;
  group: SettingsGroup;
  cameraId?: string;
  onValueChanges: (value: GenericSettingsValue, updates?: SettingsAutoUpdater) => void;
  updates?: SettingsAutoUpdater;
  updateKey?: string | number;
}

export function Item({
  group,
  item,
  value,
  cameraId,
  onValueChanges,
  updates,
  updateKey = 0,
}: ItemProps) {
  switch (item.type) {
    case 'range':
      return (
        <Range
          group={group}
          item={item}
          value={value}
          updates={updates}
          onValueChanges={onValueChanges}
        />
      );
    case 'select':
      return (
        <Select
          group={group}
          item={item}
          value={value}
          updates={updates}
          onValueChanges={e => {
            onValueChanges(e, updates);
          }}
        />
      );
    case 'toggle':
      return (
        <Toggle
          group={group}
          item={item}
          value={value}
          updates={updates}
          onValueChanges={onValueChanges}
        />
      );
    case 'text':
      return (
        <Text
          group={group}
          item={item}
          value={value}
          updates={updates}
          onValueChanges={onValueChanges}
        />
      );
    case 'subhead':
      return <Subhead>{item.label}</Subhead>;
    case 'custom':
      return (
        <CustomItem
          updateKey={updateKey}
          cameraId={cameraId}
          group={group}
          item={item}
          value={value}
          updates={updates}
          onValueChanges={onValueChanges}
        />
      );
  }
  throw new Error('Unexpected item type');
}
