import { API } from './lib/api';
import { SharedItemGetResponseData } from './isc-api';

export const SharingAPI = {
  get: (shareId: string, warrantNumber?: string) => {
    const options: { headers?: Record<string, string> } = {};

    if (warrantNumber) {
      options.headers = {
        'x-warrant-number': warrantNumber,
      };
    }
    
    return API.get<SharedItemGetResponseData>(`/sharing/${shareId}/`, options);
  },
};
