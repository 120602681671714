import {
  UserGroupDetailsPutBody,
  UserGroupDetails,
} from '../../../api/isc-api';

export const buildUserGroupUpdateBody = (
  update: UserGroupDetails
): UserGroupDetailsPutBody => {
  const cleanedUsers = [];
  for (const user of update.users) {
    cleanedUsers.push({
      userId: user.userId,
      isGroupAdmin: user.isGroupAdmin,
    });
  }

  return {
    name: update.name || '',
    users: cleanedUsers || [],
    subGroups: update?.subGroups?.map(el => el.userGroupId) || [],
    cameraIds: update?.cameras?.map(c => c.cameraId) || [],
  };
};
