import { makeStyles } from '@material-ui/core';
export const useStyles = makeStyles(theme => ({
  pendingHeader: {
    width: '100%',
    paddingLeft: theme.spacing(3),

    [theme.breakpoints.down('sm')]: {
      paddingBottom: '0',
      border: '0',
      width: '80%',
      order: '1',
    },
  },
  isProcessing: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
}));
