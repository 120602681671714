import React, { createRef, useContext, useEffect, useState } from 'react';
import AppContext from '../../context';
import {
  Card,
  Container,
  CssBaseline,
  Grid,
  IconButton,
} from '@material-ui/core';
import { PageHeader } from '../../components/page-header';
import { Details } from './details';
import { Carousel } from './carousel/carousel';
import { useStyles } from './index-styles';
import { AlertSnack } from '../../components/alert-snack';
import { ProgressBar } from '../../components/progress-bar';
import { Alert } from '@material-ui/lab';
import { MenuSwitch } from '../page/menu-switch';
import { RecognitionResults } from './recognition-results';
import { hasRecognitionResults } from './recognition-results/helpers';
import { useMediaAlertsDataAdapter } from './useMediaAlertsDataAdapter';
import { WarrantChecker } from './warrant-checker';
import {
  useHistory,
  useParams,
  useLocation,
  useRouteMatch,
} from 'react-router';
import {
  Incident,
  LicensePlateRecognitionResult,
  SharedItemGetResponseData,
} from '../../api/isc-api';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import clsx from 'clsx';
import IncidentTypeEnum = Incident.IncidentTypeEnum;

export type MediaAlertsProps = {
  match: {
    params: {
      shareId?: string;
      mediaId?: string;
      alertId?: string;
      alertType?: string;
    };
  };
};

export const MediaAlerts = (props: MediaAlertsProps) => {
  const classes = useStyles(props);
  const [sharedMediaIndex, setSharedMediaIndex] = useState(0);
  const [addedImages, setAddedImages] = useState([]);
  const context = useContext(AppContext);
  const [confidenceScore, setConfidenceScore] = useState(null);
  const [hasProfile, setHasProfile] = useState(false);
  const [profileName, setProfileName] = useState(null);

  const {
    getData,
    processResponse,
    setIsLoading,
    isLoading,
    isLinkExpired,
    setIsLinkExpired,
    data,
    media,
    downloadMedia,
    sharedMedia,
    showWarrantModal,
    setShowWarrantModal,
    pageTitle,
    incidentType,
    camera,
  } = useMediaAlertsDataAdapter();

  const recognitionResults = createRef();
  let history = useHistory();
  let params = useParams();
  let location = useLocation();

  React.useEffect(() => {
    props.match.params = params;
    setIsLoading(true);
    if (!context.authChecked) {
      return;
    }

    void getData(props);
  }, [location]);
  const onGotoRecognitionResults = () => {
    (recognitionResults.current as HTMLElement).scrollIntoView({
      behavior: 'smooth',
    });
  };

  const handlePreviousNextClick = (url: string) => {
    const pathname = location.pathname;
    const path = pathname.slice(0, pathname.lastIndexOf('/'));
    history.push(`${path}/${url}`);
  };
  const handleExpiredWarrant = () => {
    setIsLinkExpired(true);
    setShowWarrantModal(false);
  };

  const getBreadcrumb = () => {
    if (camera) {
      return [
        {
          href: `/camera/${camera.cameraId}`,
          label: camera.cameraName,
        },
      ];
    }

    return null;
  };

  // If the user status has been checked and data hasn't been loaded
  // We also attempt to load data from warrant checker modal if the
  // user needs to enter a warrant number.
  useEffect(() => {
    if (!context.authChecked || data || showWarrantModal) {
      return;
    }

    void getData(props);
  }, [context.organizationId, context.authChecked, context.authStatus]);

  useEffect(() => {
    try {
      const media = sharedMedia[sharedMediaIndex];

      if (
        incidentType === IncidentTypeEnum.LicensePlateRecognitionProfileMatch
      ) {
        setHasProfile(true);
        if (data.hasOwnProperty('sharedAlert')) {
          const sharedAlert = (data as SharedItemGetResponseData).sharedAlert;
          setProfileName(sharedAlert.subject?.name);
        } else if (media.hasOwnProperty('recognitionResults')) {
          const licensePlates = media.recognitionResults?.licensePlates;
          if (licensePlates && licensePlates.length) {
            const plate = licensePlates[0] as LicensePlateRecognitionResult;
            try {
              setProfileName(`${plate.region.toUpperCase()} ${plate.plate}`);
            } catch {}
          }
        }
      }

      if (media?.recognitionResults) {
        if (media.recognitionResults?.licensePlates?.length) {
          const plate = media.recognitionResults.licensePlates[0];
          if (plate.confidence && !isNaN(plate.confidence)) {
            const confidence = plate.confidence.toFixed(2);
            setConfidenceScore(confidence);
          }
        }
      }
    } catch (e) {
      console.log('Unable to get media / recognition metadata');
    }
  }, [sharedMedia, sharedMediaIndex]);

  const handleAddImage = mediaId => setAddedImages([...addedImages, mediaId]);

  const sharedMediaProp =
    sharedMedia?.length > 0 ? sharedMedia[sharedMediaIndex] : null;
  const mediaProp = media.length > 0 ? media[sharedMediaIndex] : null;
  const downloadMediaProp =
    downloadMedia.length > 0 ? downloadMedia[sharedMediaIndex] : null;

  const progressBar = () => (
    <Grid item xs={12}>
      <ProgressBar />
    </Grid>
  );

  const getContent = () => {
    if (isLinkExpired) {
      return (
        <Grid item xs={6} style={{ margin: '0 auto' }}>
          <Alert severity="info">The link to this resource has expired.</Alert>
        </Grid>
      );
    }

    if (showWarrantModal) {
      return (
        <Grid item xs={12}>
          <WarrantChecker
            shareId={props.match.params.shareId}
            processResponse={processResponse}
            handleExpired={handleExpiredWarrant}
          />
        </Grid>
      );
    }

    if (!data || sharedMedia.length === 0) {
      return (
        <Grid item xs={3} style={{ margin: '0 auto' }}>
          <Alert severity="warning">This alert has no media.</Alert>
        </Grid>
      );
    }

    return (
      <div className={classes.cameraMediaContainer}>
        {' '}
        <div className={classes.prevNextContainer}>
          <IconButton
            className={clsx(
              classes.nextButton,
              !sharedMediaProp.next && classes.hideButton
            )}
            onClick={() => handlePreviousNextClick(sharedMediaProp.next)}
          >
            <ChevronLeft />
          </IconButton>{' '}
          <IconButton
            className={clsx(
              classes.previousButton,

              !sharedMediaProp.previous && classes.hideButton
            )}
            onClick={() => handlePreviousNextClick(sharedMediaProp.previous)}
          >
            <ChevronRight />
          </IconButton>
        </div>
        <Grid container>
          <Grid item sm={6} xs={12}>
            <div className={classes.imageContainer}>
              <Carousel
                slides={media}
                onSlideChange={value => setSharedMediaIndex(value)}
                onGotoMetadata={() => onGotoRecognitionResults()}
              />
            </div>
            <p style={{ margin: '4px 0 0 0' }}>
              {sharedMediaIndex + 1} / {media.length}
            </p>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Card>
              <Details
                sharedMedia={sharedMediaProp}
                media={mediaProp}
                downloadMedia={downloadMediaProp}
                incidentType={incidentType}
                warrantNumber={data['warrant']?.warrantNumber}
                hasProfile={hasProfile}
                name={profileName}
                // subjectId={data.sharedAlert?.incident?.subject.subjectId}
                subjectId={null}
                score={confidenceScore}
                // mediaOrganization={data.organization}
                mediaOrganization={null}
                onSuccessfulAdd={handleAddImage}
                addedImages={addedImages}
              />
              {hasRecognitionResults(sharedMediaProp) ? (
                <RecognitionResults
                  ref={recognitionResults}
                  recognitionResults={sharedMediaProp.recognitionResults}
                />
              ) : null}
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  };

  return (
    <>
      <CssBaseline />
      <MenuSwitch />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <PageHeader
            title={!showWarrantModal ? pageTitle : ''}
            breadcrumbs={getBreadcrumb()}
          />
          <Grid container spacing={2}>
            {isLoading ? progressBar() : getContent()}
          </Grid>
        </Container>
        <AlertSnack
          message={context.alert?.message}
          type={context.alert?.type}
          onClose={context.onAlert}
        />
      </main>
    </>
  );
};
