import {
  CameraRecognitionConfigurationCameraPutBody,
  Subject,
} from '../../../../../../api/isc-api';

export const getProfileUpdateBody = (
  existingProfiles: Subject[],
  subjectIds: string[]
) => {
  const body: CameraRecognitionConfigurationCameraPutBody = {
    personSubjectIds: [],
    licensePlateSubjectIds: [],
  };

  if (subjectIds) {
    body.licensePlateSubjectIds = subjectIds;
  }

  return body;
};
