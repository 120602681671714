import { makeStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  loginGlobalContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    width: '100%',
  },
  loginBackground: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: -1,
    objectFit: 'cover',
  },
  loginContainer: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 4,
    margin: '0 auto',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '90vw',
    },
  },
  spotlightLogo: {
    maxWidth: '37vw',
    minWidth: '220px',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  paper: {
    margin: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  signInContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    margin: theme.spacing(2, 0, 8),
  },
  chooseOrganizationContainer: {
    margin: theme.spacing(2, 1),
  },
  actions: {
    margin: theme.spacing(2, -1, 1),
  },
  label: {
    margin: theme.spacing(1, 0),
  },
  progress: {
    margin: theme.spacing(2, 0),
    display: 'block',
    width: '80%',
  },
  submit: {
    margin: theme.spacing(1, 0, 3),
  },
}));
