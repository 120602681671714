import { makeStyles } from '@material-ui/core';
export const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  listItem: {
    paddingLeft: 0,
  },
  emailField: {
    width: '90%',
    [theme.breakpoints.down('md')]: {
      width: '65%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '95%',
    },
  },
  textField: {
    [theme.breakpoints.down('md')]: {
      width: '65%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '95%',
    },
  },
  formSection: {
    paddingLeft: 0,
  },
  formSectionTitle: {
    paddingTop: theme.spacing(3),
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    '& button:not(:first-child)': {
      marginLeft: theme.spacing(1),
    },
  },
  resetPasswordContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  }
}));
