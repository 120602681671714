import React, { useEffect, useState } from 'react';
import moment from 'moment';

export const useTrackInfiniteListOffset = ({
  cameraId,
  session,
  limit,
  handleApi,
}) => {
  const [listItems, setListItems] = useState([]);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [isNextPageLoading, setIsNextPageLoading] = useState(false);
  const [scrollToItem, setScrollToItem] = useState(null);
  const [offset, setOffset] = useState(0);
  const [isRestoringScroll, setIsRestoringScroll] = useState(false);
  const [filters, setFilters] = useState({});
  const [loadToItem, setLoadToItem] = useState(25);

  const getSessionLog = () => {
    let mediaLog = localStorage.getItem(session);
    const log = JSON.parse(mediaLog);
    return log;
  };
  useEffect(() => {
    const log = getSessionLog();

    if (log && log.cameraId === cameraId) {
      restoreComponent(log).then(newLog => {
        console.log({ offset, new: newLog.offset });
      });
    } else {
      localStorage.removeItem(session);
    }
  }, []);

  useEffect(() => {
    const log = getSessionLog();
    // let mediaLog = localStorage.getItem(session)
    // let log = JSON.parse(mediaLog);

    const item = {
      filters,
      ...log,
      cameraId,
      offset,
      alertsLoaded: listItems.length,
    };

    if (offset !== 0 && listItems.length > 0) {
      !!offset ? (item.offset = offset) : (item.offset = 0);
      localStorage.setItem(session, JSON.stringify(item));
    }
  }, [offset]);

  // we need to define the object we are saving
  type SessionProps = {
    alertsLoaded: number;
    cameraId: string;
    filters: object;
    offset: number;
  };

  const restoreComponent = async (log: SessionProps) => {
    try {
      setFilters(log.filters);
      if (log.alertsLoaded === 0) {
        log.alertsLoaded = 26;
      }
      setOffset(log.offset);
      setLoadToItem(log.alertsLoaded);
      setIsRestoringScroll(true);
      return log;
    } catch (err) {
      console.log(err);
    }
  };

  const createQuery = (query: any) => {
    if (!!query.startDate) {
      query.startDate = moment(query.startDate)
        .startOf('day')
        .format('YYYY-MM-DDTHH:mm:ss');
    }

    if (!!query.endDate) {
      query.endDate = moment(query.endDate)
        .endOf('day')
        .format('YYYY-MM-DDTHH:mm:ss');
    }
    // get the timezone offset of the user
    let offset = moment().utcOffset();

    query.timeZone = moment().utcOffset(offset).format('Z');

    return { ...query };
  };

  const fetchLog = async (start: number, limit: number, updateState = true) => {
    const newQuery = { ...filters };
    const query = createQuery(newQuery);
    let mediaLog = await handleApi({
      start,
      limit,
      ...query,
    });

    if (updateState) {
      let media = [...listItems];
      if (mediaLog?.items?.length > 0) {
        media = [...media, ...mediaLog.items];
        setListItems(media);
      }

      if (!mediaLog?.next || mediaLog?.items?.length <= 0) {
        setHasNextPage(false);
      }
    }

    return mediaLog;
  };
  // there is a limit of 100 items that can be loaded from the API at once
  // if we have loaded more than 100 items we will need to break these calls up

  const handleRestoreScroll = async () => {
    let numberOfRecordsToLoad = loadToItem;
    const maxRecordsToLoad = 100;

    if (numberOfRecordsToLoad > maxRecordsToLoad) {
      let mediaList = [...listItems];
      let next = true;
      const numberOfPages = Math.ceil(numberOfRecordsToLoad / maxRecordsToLoad);

      for (let page = 1; page <= numberOfPages; page++) {
        let trimStart = (page - 1) * maxRecordsToLoad;
        let trimEnd = trimStart + maxRecordsToLoad;

        if (trimEnd > numberOfRecordsToLoad) {
          trimEnd = trimStart + (numberOfRecordsToLoad - trimStart);
        }

        const log = await fetchLog(trimStart, trimEnd - trimStart, false);

        if (log?.items?.length > 0) {
          mediaList = [...mediaList, ...log.items];
        }
        if (!log?.next || log?.items?.length <= 0) {
          next = false;
        }
      }
      setListItems(mediaList);
      setHasNextPage(next);
    } else {
      await fetchLog(0, loadToItem);
    }
  };

  // this function gets called when by infinite loader when we need to load more recoresd
  const loadNextPage = async (startIndex: number, stopIndex: number) => {
    let recordsCount = limit;
    setIsNextPageLoading(true);
    if (isRestoringScroll) {
      await handleRestoreScroll().then(() => {
        setScrollToItem(offset);
        setIsRestoringScroll(false);
      });
    } else {
      await fetchLog(startIndex, recordsCount);
    }

    setIsNextPageLoading(false);
  };

  const handleApplyFilters = (newFilters: any) => {
    setFilters(newFilters);
    setHasNextPage(true);
    setListItems([]);
    setScrollToItem(0);
    const log = getSessionLog();
    if (log) {
      log.filters = newFilters;
      log.offset = 0;
      localStorage.setItem(session, JSON.stringify(log));
    } else {
      localStorage.setItem(session, JSON.stringify({ cameraId, filters }));
    }
  };

  const handleScroll = ({ scrollOffset }: any) => {
    setOffset(scrollOffset);
  };

  return {
    items: listItems,
    hasNextPage,
    isNextPageLoading,
    loadNextPage,
    handleScroll,
    scrollToItem,
    handleApplyFilters,
    filters,
  };
};

