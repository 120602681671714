import { makeStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: "none",
  },
  cardContent: {
    padding: "0",
  },
  cardActions: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  listItem: {
    padding: 0,
    display: "block",
  },
  displayBlock: {
    display: "block",
  },
  fieldsWrapper: {
    marginTop: theme.spacing(2),
  },
  verifyEmail: {
    [theme.breakpoints.up("xs")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: "0",
      paddingBottom: theme.spacing(2)
    },
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: "0",
      paddingBottom: "0"
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: "0",
      paddingRight: theme.spacing(2),
    },
  }
}));
