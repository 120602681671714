import { makeStyles } from '@material-ui/core';
export const useStyles = makeStyles(theme => ({
  default: {
    paddingLeft: theme.spacing(0),
  },
  listItem: {
    // backgroundColor:'#f3f3f3',
    backgroundColor: theme.palette.grey[100],
    transition: 'background-color 0.33s ease',
    cursor: 'pointer',
    marginBottom: theme.spacing(2),
    'li:last-child &': {
      marginBottom: 0,
    },
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
  listItemText: {
    '& .MuiListItemText-primary': {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 700,
      fontSize: '1.05em',
      lineHeight: 1,
      color: theme.palette.common.black,
    },
    '& .MuiListItemText-secondary': {
      color: theme.palette.grey[600],
      fontSize: '0.9em',
    },
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
  header: {
    color: '#000',
    margin: theme.spacing(0),
  },
  items: {
    flexGrow: 1,
    marginTop: 10,
  },
  addIcon: {
    fontSize: 16,
    color: theme.palette.grey[500],
    marginRight: '10px',
  },
  addCollectionButton: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  noCollectionsText: {
    marginBottom: theme.spacing(4),
  },
}));
