import { makeStyles } from '@material-ui/core';
export const useStyles = makeStyles(theme => ({
  root: {
    padding: '0',
  },
  header: {
    color: '#000',
    margin: theme.spacing(2, 0, 0),
  },
  default: {
    paddingLeft: theme.spacing(0),
  },
  collectionBorder: {
    borderTop: '1px solid rgba(64,60,61,0.38)',
    borderBottom: '1px solid rgba(64,60,61,0.38)',
    margin: '0 !important',
  },
  collectionList: {
    padding: 0,
  },
  collectionName: {
    textTransform: 'uppercase',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 700,
    fontSize: '1.05em',
    lineHeight: 1,
    color: theme.palette.common.black,
    maxWidth: 'calc(100% - 2em)',
  },
  collectionCount: {
    color: theme.palette.grey[600],
    fontSize: '0.9em',
  },
  collectionListItem: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderBottom: '1px solid rgba(64,60,61,0.24)',
    transition: 'background-color 0.33s ease',
    '&:last-child': {
      borderBottom: 'none',
    },
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  collectionLink: {
    display: 'block',
    width: '100%',
    textDecoration: 'none',
    '&:hover, &:focus, &:active': {
      textDecoration: 'none',
    },
  },
}));
