import React from 'react'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

type PasswordFormProps = {
  classes: Record<'form' | 'submit' | 'welcomeBox', string>
  isWorking: boolean

  onSubmit: (code: string, password: string) => void
}

export const PasswordForm = (props:PasswordFormProps) => {
  const [code, setCode] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [confirmation, setConfirmation] = React.useState('')

  const [passwordError, setPasswordError] = React.useState<string>(null)
  const [confirmationError, setConfirmationError] = React.useState<string>(null)

  const handleSubmit = () => {
    const passwordValid = /[0-9]+/.test(password)
      && /[A-Z]+/.test(password)
      && /[a-z]+/.test(password)
      && password.length >= 8

    setPasswordError(passwordValid ? null : 'Password doesn\'t meet the requirements.')

    const passwordConfirmed = password === confirmation
    setConfirmationError(passwordConfirmed ? null : 'Password fields do not match.')

    if (passwordValid && passwordConfirmed) {
      props.onSubmit(code, password)
    }
  }

  return <Box className={props.classes.form}>
    <Typography className={props.classes.welcomeBox}>
      Please enter the code you received on your phone, and choose a password. Your password should be at least 8 characters
      long and include digits, as well as both upper and lower case letters.
    </Typography>
    <TextField
      variant="outlined"
      margin="normal"
      required
      fullWidth
      id="code"
      label="SMS code"
      name="code"
      value={code} onChange={ev => setCode(ev.target.value)}
      autoFocus
    />
    <TextField
      variant="outlined"
      margin="normal"
      required
      fullWidth
      name="password"
      label="Password"
      type="password"
      error={Boolean(passwordError)}
      helperText={passwordError}
      value={password} onChange={ev => setPassword(ev.target.value)}
    />
    <TextField
      variant="outlined"
      margin="normal"
      required
      fullWidth
      name="password2"
      label="Confirm Password"
      type="password"
      error={Boolean(confirmationError)}
      helperText={confirmationError}
      value={confirmation} onChange={ev => setConfirmation(ev.target.value)}
    />
    <Button
      type="submit"
      fullWidth
      variant="contained"
      color="primary"
      disabled={props.isWorking}
      className={props.classes.submit}
      onClick={handleSubmit}
    >
      Activate account
    </Button>
  </Box>
}