import React, {useContext, useEffect, useState} from 'react';
import {RecognitionForm, UndefinedSubjectId} from './recognition-form';
import { SettingsContext } from '../../container/settings-context';
import {
  getOrganization
} from '../../../../access';
import {
  FacialRecognitionFeature,
  LicensePlateRecognitionFeature, Subject,
} from '../../../../api/isc-api';
import {ImageRecognitionMode, RecognitionAPI} from '../../../../api/recognition';
import AppContext from "../../../../context";

export default () => {
  const {
    camera: {
      cameraId
    },
    fullSettings: {
      settings: { on_edge_analytics },
    },
  } = useContext(SettingsContext);

  const mode =
    (on_edge_analytics?.detection_mode as ImageRecognitionMode) ||
    ImageRecognitionMode.None;

  const [subjectId, setSubjectId] = useState(UndefinedSubjectId);
  const [appliedProfiles, setAppliedProfiles] = useState<string[]>([]);
  const [profileList, setProfileList] = useState<Subject[]>([]);
  const organization = getOrganization();

  const context = useContext(AppContext);

  useEffect(() => {
    async function getActiveProfilesForCamera() {
      const activeProfiles = await RecognitionAPI.getCameraProfiles(cameraId);
      if (activeProfiles?.profiles?.length) {
        setAppliedProfiles(activeProfiles.profiles.map(el => el.subjectProfile.subjectId));
      }
    };

    void getActiveProfilesForCamera();
  }, []);

  const handleWarrantExtended = (subjectId) => {
    const profiles = [...profileList];
    const matchingProfile = profiles.find(el => el.subjectId === subjectId);
    if (matchingProfile) {
      matchingProfile.isWarrantExpired = false;
    }

    setProfileList(profiles);
  }

  /**
   * Loads camera profiles for the select box. We need to do this
   * here so we can pass to create/update profiles when saving
   *
   * We also need to grab all profiles so we can get epxired or not
   * on profile selection
   */
  useEffect(() => {
    if (mode !== ImageRecognitionMode.None) {
      RecognitionAPI.searchRecognitionProfiles(mode)
        .then(data => {
          setProfileList(data);
        })
        .catch(err => {
          context.onAlert(err.message || err, 'error');
        });
    }
  }, [mode, subjectId]);

  const {
    organizationFeatures: { facialRecognition, licensePlateRecognition },
  } = organization;

  if (
    showFeature(licensePlateRecognition) || showFeature(facialRecognition)
  ) {
    return (
      <div>
        {mode !== ImageRecognitionMode.None && (
          <RecognitionForm
            mode={mode}
            key={subjectId}
            profileList={profileList}
            resetSubjectWithId={setSubjectId}
            resetAppliedProfiles={setAppliedProfiles}
            initialSubjectId={subjectId}
            appliedProfiles={appliedProfiles}
            supportsLicensePlateWarrant={
              licensePlateRecognition.subscriptionSettings.isSupported
            }
            requiresLicensePlateWarrant={
              licensePlateRecognition.organizationAdminSettings.requiresWarrant
            }
            supportsFacialWarrant={
              facialRecognition.subscriptionSettings.supportsWarrant
            }
            requiresFacialWarrant={
              facialRecognition.organizationAdminSettings.requiresWarrant
            }
            onWarrantExtended={handleWarrantExtended}
          />
        )}
      </div>
    );
  } else {
    return null;
  }
};

const showFeature = (
  feature: FacialRecognitionFeature | LicensePlateRecognitionFeature
) => {
  return (
    feature.organizationAdminSettings.isEnabled &&
    feature.subscriptionSettings.isSupported
  );
};
