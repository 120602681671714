import { makeStyles } from '@material-ui/core';
export const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(4, 0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'center',
    }
  },
  status: {
    margin: '10px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px 0',
    flex: '1 0',
    flexGrow: 1,
    justifyContent: 'space-around',
    [theme.breakpoints.up('md')]: {
      padding: '30px 0',
      borderTop: '1px solid #ccc',
      borderBottom: '1px solid #ccc',
      flexDirection: 'row',
      marginLeft: '30px',
    }
  },
  statusIcons: {
    display: 'flex',
    margin: '15px 0',
    [theme.breakpoints.up('sm')]: {
      margin: '0 0 0 auto',
    },
  },
  icon: {
    margin: '0 10px',
    fontSize: '16px',
    [theme.breakpoints.up('lg')]: {
      fontSize: '20px',
    },
  },
  cta: {
    marginTop: '10px',
    [theme.breakpoints.up('md')]: {
      marginLeft: 'auto',
    }
  },
  button: {
    textDecoration: 'none!important'
  }
}));
