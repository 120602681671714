import React, { useState, useEffect, useContext } from 'react';
import { useStyles } from './index-styles';
import { Tab, Grid, AppBar, Tabs, Fab, Fade } from '@material-ui/core';
import { wrapSettings, SettingsProps } from './container';
import { CloudUpload } from '@material-ui/icons';
import { PageHeader } from '../../components/page-header';
import { SettingsPanel } from './container/settings-panel';
import { Group } from './item-components/group';
import { TabPanel, a11yProps } from '../../components/tab-panel';
import { SettingsContext } from './container/settings-context';
import {SettingsAutoUpdater} from "../../schema";

export const Settings = wrapSettings((props: SettingsProps) => {
  const classes = useStyles(props);
  const { updateCount } = useContext(SettingsContext);
  const [tabValue, setTabValue] = useState(0);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [showFab, setShowFab] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  const handleLink = (name: string) => {
    // TODO: This needs to be reworked and made much more robust, every setting change is handled on a case-by-case basis
    if (name === 'wifi' || name === 'lte') {
      setTabValue(3);
    }

    if (name === 'smtp') {
      if (props.settings.general.network_selection === 2) {
        setTabValue(3);
      } else {
        setTabValue(4);
      }
    }

    toggleDrawer();
  };

  useEffect(() => {
    if (!props.hasUpdatedSettings && isDrawerOpen) {
      setTimeout(() => {
        setIsDrawerOpen(false);
        setTabValue(0);
      }, 1000);
    }
  }, [props.hasUpdatedSettings]);

  useEffect(() => {
    if (props.hasUpdatedSettings) {
      setShowFab(true);
    } else {
      setTimeout(() => {
        setShowFab(false);
      }, 400);
    }
  }, [props.hasUpdatedSettings]);

  const getTabItems = () => {
    return props.schema.groups
      .filter(group => props.checkDependency(group.dependsOn, group.dependsOnPermission))
      .map((group, index) => (
        <Tab key={index} label={group.label} {...a11yProps(index)} />
      ));
  }

  const getTabPanels = () =>
    props.schema.groups
      .filter(group => props.checkDependency(group.dependsOn, group.dependsOnPermission))
      .map((group, index) => (
        <TabPanel tabValue={tabValue} index={index} key={`tab-${updateCount}-${index}`}>
          <Group
            item={group}
            cameraId={props.camera ? props.camera.cameraId : ''}
            value={props.settings[group.name]}
            isActive={props.activeGroup == group.name}
            checkDependency={props.checkDependency}
            onValueChanges={(key, v, updates?: SettingsAutoUpdater) =>
              props.onSetGroupValue(group.name, key, v, updates)
            }
            onToggleGroup={() =>
              props.onGroupSelected(
                props.activeGroup == group.name ? '' : group.name
              )
            }
          />
        </TabPanel>
      ));

  return (
    <>
      <PageHeader
        title={`${props.camera ? props.camera.cameraName : ''}`}
        breadcrumbs={[{ href: '/', label: 'Cameras & Collections' }]}
      />

      <Grid container spacing={1}>
        <Grid item xs={12}>
          <div className={classes.tabWrapper}>
            <AppBar position="static">
              {/* Begin tabbed nav using group name as tab label */}
              <Tabs
                value={tabValue}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="on"
                indicatorColor="primary"
                textColor="primary"
                aria-label="Scrollable camera settings tabs"
              >
                {getTabItems()}
              </Tabs>
            </AppBar>
            {getTabPanels()}
          </div>
          {showFab && (
            <div className={classes.fabWrapper}>
              <Fade in={props.hasUpdatedSettings}>
                <Fab
                  variant="extended"
                  title="Save setting changes"
                  onClick={toggleDrawer}
                  className={classes.fab}
                >
                  <CloudUpload className={classes.fabIcon} />
                  Pending Changes
                </Fab>
              </Fade>
            </div>
          )}
        </Grid>
      </Grid>
      <SettingsPanel
        isDrawerOpen={isDrawerOpen}
        toggleDrawer={toggleDrawer}
        handleLink={handleLink}
        errors={props.errors}
        updatedGroups={props.updatedGroups}
        updatedValues={props.updatedValues}
        isSaving={props.isSaving}
        onSaveSettings={props.onSaveSettings}
        onReset={props.onReset}
      />
    </>
  );
});
