import * as React from 'react';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Slider from '@material-ui/core/Slider';
import Box from '@material-ui/core/Box';

import { SettingsRangeItem, GenericSettingsValue, SettingsAutoUpdater } from '../../schema';
import { ItemProps } from './types';

export interface RangeProps extends ItemProps {
  item: SettingsRangeItem;
  onValueChanges: (value: GenericSettingsValue, updates?: SettingsAutoUpdater) => void;
}

const useStyles = makeStyles(() => ({
  textField: {
    minHeight: '60px',
  },
  trackerValueLabel: {
    top: -20,
    left: 'calc(-50% + 0px)',

    '& > span': {
      width: 24,
      height: 24,
    },
  },
  valueDisplay: {
    fontSize: '1.25rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 700,
    lineHeight: '1.6',
    letterSpacing: '0.0075em',
  },
  descriptionDisplay: {
    textAlign: "left",

  },
  listItem: {
    flexDirection: "row",
    display: "flex",
    flexWrap: "wrap",
  },
  listItemDiscription: {
    display: "flex",
    justifyContent: "flex-end"
  }
}));

export const Range = (props: RangeProps) => {
  const toInternal =
    props.item.variation === 'milliseconds'
      ? (v: number) => v / 1000
      : (a: number) => a;
  const toExternal =
    props.item.variation === 'milliseconds'
      ? (v: number) => Math.floor(v * 1000)
      : (a: number) => a;

  const min = toInternal(props.item.min || 0);
  const max = toInternal(props.item.max || 100);
  const extStep = props.item.step || 1;
  const step = toInternal(props.item.step || 1);

  const value = toInternal(props.value as number);
  const [open, setOpen] = React.useState(false);
  const [currentValue, setCurrentValue] = React.useState(value);
  const [textValue, setTextValue] = React.useState(value.toString());
  const classes = useStyles(props);

  const handleFocus = () => {
    setCurrentValue(value);
    setOpen(true);
  };

  const handleBlur = () => {
    if (currentValue !== value) {
      props.onValueChanges(toExternal(currentValue), props.updates);
    }
    setOpen(false);
  };

  const handleTextChange = (value: string) => {
    const numValue = parseFloat(value);
    setTextValue(value);
    if (
      numValue >= min &&
      numValue <= max &&
      toExternal(numValue - min) % extStep === 0
    ) {
      setCurrentValue(numValue);
    }
  };

  const handleSliderChange = (value: number) => {
    setCurrentValue(value);
    setTextValue(value.toString());
  };

  const handleKeyDown = (ev: React.KeyboardEvent<HTMLDivElement>) => {
    switch (ev.keyCode) {
      case 13: {
        handleBlur();
        break;
      }
      case 27: {
        setOpen(false);
        break;
      }
    }
  };

  return (
    <ListItem button onFocus={handleFocus} onBlur={handleBlur} className={classes.listItem}>
      {open ? (
        <Grid container spacing={4} alignItems="center">
          <Grid item sm={3} xs={12}>
            <TextField
              label={props.item.label}
              value={textValue}
              className={classes.textField}
              onChange={ev => handleTextChange(ev.target.value)}
              onKeyDown={handleKeyDown}
              fullWidth
              autoFocus
            />
          </Grid>
          <Grid item sm={9} xs={12}>
            <Box style={{ height: 60, paddingTop: 25 }}>
              <Slider
                classes={{ valueLabel: classes.trackerValueLabel }}
                value={currentValue as number}
                min={min}
                max={max}
                step={step}
                valueLabelDisplay="on"
                marks={[
                  { value: min, label: min.toString() },
                  { value: max, label: max.toString() },
                ]}
                onChange={(_, value) => handleSliderChange(value as number)}
              />
            </Box>
          </Grid>
        </Grid>
      ) : (
        <>
          <ListItemText
   
            primary={props.item.label}
            primaryTypographyProps={{ variant: 'overline' }}
            secondary={value || 'Not set'}
            secondaryTypographyProps={{
              className: classes.valueDisplay,
              color: 'primary',
            }}
          />
          {props.item.description &&
            <ListItemText primary={props.item.description} primaryTypographyProps={{
              className: classes.descriptionDisplay,
            }}        className={classes.listItemDiscription}/>
          }</>
      )}
    </ListItem>
  );
};
