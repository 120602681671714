// components/FuseHighlight.jsx
import React from 'react';

// Finds `obj[path][to][key]` from `path.to.key`
const resolveAttribute = (obj, key) =>
  key.split('.').reduce((prev, curr) => prev?.[curr], obj);

// Recursively builds JSX output adding `<mark>` tags around matches
const highlightDesc = (value, indices = [], i = 1) => {
  const pair = indices[indices.length - i];
  return !pair
    ? value
    : `${highlightDesc(
        value.substring(0, pair[0]),
        indices,
        i + 1
      )}<mark>${value.substring(pair[0], pair[1] + 1)}</mark>${value.substring(
        pair[1] + 1
      )}`;
};
const highlightTitle = (value, indices = [], i = 1) => {
  const pair = indices[indices.length - i];
  return !pair ? (
    value
  ) : (
    <>
      {highlightTitle(value.substring(0, pair[0]), indices, i + 1)}
      <mark>{value.substring(pair[0], pair[1] + 1)}</mark>
      {value.substring(pair[1] + 1)}
    </>
  );
};

const removeMatchesWithinHTML = (value, indices) => {
  let matchArray = indices;
  const startValue = value.search(/<figure>/);
  const endValue = value.search(/<\/figure>/);
  if (!matchArray) return;

  return matchArray.filter(indexGroup => {
    const isBetweenFigureTags =
      indexGroup[0] > startValue && indexGroup[0] < endValue;
    if (!isBetweenFigureTags) {
      return indexGroup;
    }
  });
};

// FuseHighlight component
export const FuseHighlight = (hit, attribute) => {
  const matches =
    typeof hit.item === 'string'
      ? hit.matches?.[0]
      : hit.matches?.find(m => m.key === attribute);
  const fallback =
    typeof hit.item === 'string'
      ? hit.item
      : resolveAttribute(hit.item, attribute);
  if (attribute === 'description') {
    let newInd = removeMatchesWithinHTML(
      matches?.value || fallback,
      matches?.indices
    );
    const dec = highlightDesc(matches?.value || fallback, newInd);
    return dec;
  } else {
    return highlightTitle(matches?.value || fallback, matches?.indices);
  }
};

