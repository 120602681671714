import * as React from 'react';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Radio from '@material-ui/core/Radio';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';

import { SettingsSelectItem, GenericSettingsValue, SettingsAutoUpdater } from '../../schema';
import { flattenOptions } from '../../schema/helpers';
import { ItemProps } from './types';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

interface SelectProps extends ItemProps {
  item: SettingsSelectItem;
  onValueChanges: (value: GenericSettingsValue, updates?: SettingsAutoUpdater) => void;
}

const useStyles = makeStyles(theme => ({
  options: {
    paddingLeft: theme.spacing(4),
  },
  valueDisplay: {
    fontSize: '1.25rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 700,
    lineHeight: '1.6',
    letterSpacing: '0.0075em',
  },
  arrow: {
    pointerEvents: 'none'
  },
  descriptionDisplay: {
    textAlign: "left",
  },
  listItem: {
    flexDirection: "row",
    display: "flex",
    flexWrap: "wrap",
  },
  listItemDiscription: {
    display: "flex",
    justifyContent: "flex-end"
  }
}));

export const Select = (props: SelectProps) => {
  const classes = useStyles(props);
  const [open, setOpen] = React.useState(false);
  const ref = React.useRef<HTMLElement>();
  const options = flattenOptions(props.item.options);
  const currentOption = options.find(o => o.value == props.value);
  const label = currentOption ? currentOption.label : '';

  const handleClick = () => {
    setOpen(!open);
  };

  const handleMenuItemClick = (value: GenericSettingsValue) => {
    props.onValueChanges(value, props.updates);
    setOpen(false);
  };

  const getDescription = (item : any) => {
    return (
      item.description &&
        <ListItemText className={classes.listItemDiscription} primary={item.description} primaryTypographyProps={{
          className: classes.descriptionDisplay,
        }} />
    )
  }
  const getCollapse = () => (
    <Collapse in={open} unmountOnExit>
      <List component="div" disablePadding dense>
        {options.map(o => (
          <ListItem
            button
            key={o.value}
            className={classes.options + " " + classes.listItem}
            onClick={() => handleMenuItemClick(o.value)}
          >
            <FormControlLabel
              control={<Radio checked={o.value === props.value} />}
              label={o.label}
            ></FormControlLabel>
 
           {getDescription(o)}

          </ListItem> 
        ))}
      </List>
    </Collapse>
  );

  const getDialog = () => (
    <Dialog open={open} onClose={handleClick}>
      <DialogTitle>Select {props.item.label}</DialogTitle>
      <DialogContent dividers>
        <MenuList style={{ height: '100%' }}>
          {options.map(o => (
            <MenuItem
              button
              key={o.value}
              onClick={() => handleMenuItemClick(o.value)}
              selected={o.value === props.value}
            >
              {o.label}
            </MenuItem>
          ))}
        </MenuList>
      </DialogContent>
    </Dialog>
  );

  return (
    <>
      <ListItem button onClick={handleClick} className={classes.listItem}>
        <ListItemText
          primary={props.item.label}
          primaryTypographyProps={{ variant: 'overline' }}
          secondary={label || 'Not Set'}
          // secondaryTypographyProps={{ variant: 'h6', color: 'primary' }}
          secondaryTypographyProps={{
            className: classes.valueDisplay,
            color: 'primary',
          }}
          ref={ref}
        />
        {getDescription(props.item)}
        <ListItemSecondaryAction className={classes.arrow}>
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemSecondaryAction>

      </ListItem>
      {options.length <= 5 ? getCollapse() : getDialog()}
    </>
  );
};
