import { useEffect } from 'react';
import {
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router';
import {
  AppContextType,
  AuthenticationFlow,
  LocalStorageKeys,
  SessionStorageKeys,
} from '../../context';
import { AuthAPI } from '../../api/auth';
import { ENABLE_DEV_CONSOLE } from '../../util/globals';

const approvedAnonymousRoutes = ['/login', '/sharing/:shareId'];

/**
 * Auth Listener is reactive, and looks to our context for clues
 * about the current session state.
 *
 * This functionality is responsible for redirecting when a session
 * is missing and attempting to refresh a session on tab reload/activation
 *
 * - Redirect to /login when we don't detect a session
 * - Attempt to refresh the session and handle unprotected routes
 */

export const useAuthListener = (context?: AppContextType) => {
  const history = useHistory();
  const isUnprotectedRoute =
    useRouteMatch(approvedAnonymousRoutes)?.isExact ?? false;

  const isOrgOverride: any = useRouteMatch({
    path: ['/camera/:cameraId/:orgId', '/user/:userId/:orgId'],
  });

  const setPathAndRedirect = () => {
    // If we don't have a post-login URL saved
    if (!window.localStorage.getItem(LocalStorageKeys.PathAfterLogin)) {
      window.localStorage.setItem(
        LocalStorageKeys.PathAfterLogin,
        history.location.pathname
      );
    }

    history.push('/login');
  };

  useEffect(() => {
    // Redirects to login when context is missing org id

    if (!context.organizationId && !!isOrgOverride) {
      window.localStorage.setItem(
        SessionStorageKeys.OrganizationId,
        isOrgOverride.params.orgId
      );
    }
    if (!context.organizationId) {
      ENABLE_DEV_CONSOLE &&
        console.log(
          `[AuthListener] setAuthChecked(true), isUnprotectedRoute: ${isUnprotectedRoute}`
        );

      context.setAuthChecked(true);
      !isUnprotectedRoute && setPathAndRedirect();
      return;
    }

    // If we have an org id but we're missing session info, try to grab an existing session.
    // Typical use case is on reload our opening a new tab.
    //
    // If we're on an unprotected route we'll attempt to login/set auth options
    // but do nothing on failure.
    if (
      !context.sessionInfo &&
      context.authStatus === AuthenticationFlow.LoggedOut
    ) {
      ENABLE_DEV_CONSOLE &&
        console.log(`[AuthListener] Attempting session refresh`);

      context.setAuthStatus(AuthenticationFlow.AttemptingSessionRefresh);
      AuthAPI.session()
        .then(sessionInfo => {
          context.onLogin({
            ...sessionInfo,
            organizationId: context.organizationId,
          });
        })
        .catch(() => {
          if (isUnprotectedRoute) {
            return;
          }

          context.onLogout();
          setPathAndRedirect();
        });
      // If we're not in the middle of attempting a refresh, set auth checked to true
    } else {
      context.setAuthChecked(true);
    }
  }, [context.organizationId, context.sessionInfo]);
};
