import { makeStyles } from '@material-ui/core';
export const useStyles = makeStyles(theme => ({
  slider: {
    position: 'relative',
    height: '100%',
    width: '100%',
    margin: '0 auto',
    overflow: 'hidden',
  },
  dialog: {
    '& .MuiPaper-root': {
      height: '80%',
    },
  },
  dialogContent: {
    padding: '0',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
    '&.MuiDialogContent-root:first-child': {
      padding: '0',
    },
    background: '#000',
    '& img': {
      objectFit: 'contain',
      height: 'inherit',
      width: 'inherit',
    },
    '& video': {
      objectFit: 'contain',
      height: 'inherit',
      width: 'inherit',
    },
  },
  closeButton: {
    position: 'absolute',
    top: '16px',
    right: '16px',
    background: theme.palette.grey[200],
    '&:hover': {
      background: theme.palette.grey[300],
    },
  },
}));
