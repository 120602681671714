import React from 'react';
import ReactDOM from 'react-dom';
import { AlertSnack } from './alert-snack';

export type AlertTypes = 'success' | 'warning' | 'info' | 'error';

 type AlertProps = {
    message: string;
    type?: AlertTypes;
 }
type AlertSnackPortalProps = {
    alert: AlertProps;
    onClose: () => void;
  
};

export const AlertSnackPortal = ({ alert, onClose }: AlertSnackPortalProps) => {
    return ReactDOM.createPortal(
        <AlertSnack message={alert?.message} onClose={onClose} type={alert?.type} />,
        document.getElementById("alert-snack-portal")
    );
};
