import { useApiUpdate } from '../../../../../../hooks';
import {
  RecognitionSettingsLprBody,
  RecognitionSettingsLprSubjectPutResponseData,
  RecognitionSettingsWarrantExtensionBody,
  Warrant,
  CameraRecognitionCameraGetResponseDataProfiles,
} from '../../../../../../api/isc-api';
import { RecognitionAPI } from '../../../../../../api/recognition';
import { AppContextType } from '../../../../../../context';

type UpdateProfileArgs = {
  subjectId: string;
  context: AppContextType;
  warrant: Warrant;
  setWarrantExtension: React.Dispatch<
    React.SetStateAction<RecognitionSettingsWarrantExtensionBody>
  >;
};

export const useUpdateProfile = ({
  subjectId,
  context,
  warrant,
  setWarrantExtension,
}: UpdateProfileArgs) => {
  const [doWarrantExtension] = useApiUpdate<
    RecognitionSettingsWarrantExtensionBody,
    Warrant
  >(
    body => RecognitionAPI.extendWarrant(warrant.warrantId, body),
    () => {
      context.onAlert('Profile has been saved.', 'success');
      setWarrantExtension(null);
    }
  );

  const [doUpdateLprProfile] = useApiUpdate<
    RecognitionSettingsLprBody,
    RecognitionSettingsLprSubjectPutResponseData
  >(body => RecognitionAPI.updateLprProfile(subjectId, body));

  const handleUpdateProfile = async (
    body: RecognitionSettingsLprBody,
    warrantExtension?: RecognitionSettingsWarrantExtensionBody
  ) => {
    try {
      const response = await doUpdateLprProfile(body);

      if (warrantExtension && response && response?.warrant?.warrantId) {
        doWarrantExtension(warrantExtension).catch(e =>
          context.onAlert(e, 'error')
        );
      } else {
        context.onAlert('Profile has been saved.', 'success');
      }
    } catch (e) {
      console.log(e);
      context.onAlert(
        'Sorry, there was a problem trying to update this profile.',
        'error'
      );
    }
  };

  return handleUpdateProfile;
};
