import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    overflowX: 'hidden',
  },
  container: {
    backgroundColor: 'transparent',
  },
  cameraMediaContainer: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  prevNextContainer: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    // backgroundColor: '#007bff',
    height: '10%',

    top: '25%',
    ['@media (max-width:600px)']: {
      top: '15%',
    },
  },
  previousButton: {
    backgroundColor: '#ccc',
    // backgroundColor: '#000',
    // color: '#fff',
    display: 'relative',
    zIndex: 1000,
    top: 0,
    right: '4px',

    ['@media (min-width:1400px)']: {
      right: '-90px',
    },
    '&:hover': {
      backgroundColor: '#ccc',
    },
    '&.hide': {},
  },
  nextButton: {
    backgroundColor: '#ccc',
    // color: '#fff',
    display: 'absolute',
    zIndex: 1000,
    top: 0,
    left: '4px',

    ['@media (min-width:1400px)']: {
      left: '-90px',
    },
    '&:hover': {
      backgroundColor: '#ccc',
    },
  },
  showButton: {},
  hideButton: {
    visibility: 'hidden',
  },
  imageContainer: {
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
    background: '#000',
  },
  image: {
    height: '100%',
    width: '100%',
    objectFit: 'contain',
  },
  detailPanelTitle: {
    padding: '16px 16px 0 16px',
  },
  detailRow: {
    display: 'flex',
    justifyContent: 'space-around',
    borderBottom: '1px solid grey',
    margin: '0 16px',
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  detailContent: {
    flexBasis: '50%',
    padding: '16px 16px 16px 0',
  },
  detailContentHeader: {
    fontWeight: 700,
  },
  actionContainer: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
  },
}));
