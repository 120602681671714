import * as React from 'react';
import { match, useHistory, Prompt } from 'react-router';
import {
  SettingsDependency,
  GenericSettingsValue,
  SettingsGroup,
  SettingsSchema, SettingsAutoUpdater,
} from '../../../schema';
import {
  CamerasAPI,
  CameraSettingsApiResponse,
  TempCameraSettingsWithUserGroup,
} from '../../../api/cameras';
import { History } from 'history';
import { dependencyChecker } from '../../../schema/helpers';
import { useApiUpdate } from '../../../hooks';
import AppContext from '../../../context';
import {
  CameraBasic,
  CameraSettingsSettings,
  UserGroup,
} from '../../../api/isc-api';
import { useEffect } from 'react';
import { SettingsContext } from './settings-context';
import { ProgressBar } from '../../../components/progress-bar';
import { UserGroupPending } from '../item-components/UserGroupsComponent';

export interface SettingsProps {
  isLoading: boolean;
  hasUpdatedSettings: boolean;
  changeCount: number;
  camera: CameraBasic;
  schema: SettingsSchema;
  settings: CameraSettingsSettings;
  updatedValues: CameraSettingsSettings;
  errors: SettingsGroup[];
  updatedGroups: SettingsGroup[];
  activeGroup: string;
  isSaving: boolean;

  checkDependency: (dependency: SettingsDependency, depPermission?: string|string[]) => boolean;

  onSaveSettings: () => void;
  setIsSaving: (value: boolean) => void;
  onSetGroupValue: (
    group: string,
    name: string,
    value: GenericSettingsValue,
    updates?: SettingsAutoUpdater
  ) => void;
  onGroupSelected: (name: string) => void;
  onReset: () => void;
}

interface WrappedSettingsParams {
  cameraId: string;
}

interface WrappedSettingsProps {
  match: match<WrappedSettingsParams>;
  history: History;
}

export const wrapSettings = (Settings: React.FC<SettingsProps>) => {
  return (props: WrappedSettingsProps) => {
    const { cameraId } = props.match.params;
    const [camera, setCamera] = React.useState<CameraBasic>(null);
    const [originalSettings, setOriginalValues] =
      React.useState<CameraSettingsSettings>(null);
    const [settings, setSettings] =
      React.useState<CameraSettingsSettings>(null);
    const [schema, setSchema] = React.useState<SettingsSchema>(null);
    const [activeGroup, setActiveGroup] = React.useState<string>('');
    const [updatedValues, setUpdatedValues] =
      React.useState<CameraSettingsSettings>({});
    const [isSaving, setIsSaving] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(true);
    const [hasUpdatedSettings, setHasUpdatedSettings] = React.useState(false);
    const [changeCount, setChangeCount] = React.useState(0);
    const [updateCount, setUpdateCount] = React.useState(0);
    const [fullSettings, setFullSettings] =
      React.useState<CameraSettingsApiResponse>(null);
    const [pendingUserGroups, setPendingUserGroups] = React.useState<
      UserGroupPending[]
    >([]);
    const [filteredUserGroups, setFilteredUserGroups] = React.useState<
      UserGroup[]
    >([]);
    const [displayDirtyModal, setDisplayDirtyModal] = React.useState(false);
    const checkDependency = dependencyChecker(settings);
    const context = React.useContext(AppContext);
    const history = useHistory();

    const handleReset = () => {
      setSettings(originalSettings);
      setUpdatedValues({});
      setHasUpdatedSettings(false);
      setChangeCount(0);
      setPendingUserGroups([]);
    };

    const updatedGroups = schema
      ? schema.groups
          .filter(group => checkDependency(group.dependsOn, group.dependsOnPermission))
          .filter(group => typeof updatedValues[group.name] !== 'undefined')
          .map(group => ({
            ...group,
            items: group.items
              // .filter(i => checkDependency(i.dependsOn))
              // This filter is commented out because it causes cascading property updates (triggered by an "updates"
              // clause on another key) to fail to show and apply
              .filter(
                i => typeof updatedValues[group.name][i.name] !== 'undefined'
              ),
          }))
      : null;

    const errors = schema
      ? schema.groups
          .filter(group => checkDependency(group.dependsOn, group.dependsOnPermission))
          .map(group => ({
            ...group,
            items: group.items.filter(
              item =>
                checkDependency(item.dependsOn, item.dependsOnPermission) &&
                item.type == 'text' &&
                item.required &&
                !settings[group.name][item.name]
            ),
          }))
          .filter(group => group.items.length > 0)
      : null;

    const getSettings = () => {
      setIsLoading(true);
      CamerasAPI.get(cameraId)
        .then(data => {
          setCamera(data.camera);
          setSettings(data.settings);
          setFullSettings(data);
          setOriginalValues(data.settings);
          setSchema(data.schema);
          setFilteredUserGroups(
            data.settings.user_groups || data.settings.userGroups || []
          );
          setIsLoading(false);
        })
        .catch(e => {
          console.log(e);
          setIsLoading(false);
          context.onAlert('Error getting camera settings', 'error');
          history.push('/');
        });
    };

    useEffect(getSettings, [cameraId]);

    // Save settings
    const [doSaveSettings] = useApiUpdate<
      TempCameraSettingsWithUserGroup,
      CameraSettingsApiResponse
    >(
      body =>
        CamerasAPI.updateSettings(cameraId, body).finally(() =>
          setIsSaving(false)
        ),
      () => {
        context.onAlert('Profile has been saved.', 'success');
        setIsSaving(false);
        setOriginalValues(settings);
        setUpdatedValues({});
        getSettings();
        setUpdateCount(updateCount + 1);
      }
    );

    const handleSaveSettings = () => {
      setIsSaving(false);
      doSaveSettings(settings);
      setHasUpdatedSettings(false);
      setChangeCount(0);
      setPendingUserGroups([]);
    };

    const handleSetValue = (
      group: string,
      key: string,
      newValue: GenericSettingsValue,
      updates?: SettingsAutoUpdater
    ) => {
      let tmpSettings = {
        ...settings
      };
      let tmpUpdatedValues = {
        ...updatedValues
      };

      const updateList = [{
        group,
        key,
        newValue
      }];

      if (updates) {
        const whenValues = typeof (updates.when) === 'string' ? [updates.when] : updates.when;

        if ((whenValues as GenericSettingsValue[]).includes(newValue)) {
          const [group, key] = updates.target.split('.');

          updateList.push({
            group,
            key,
            newValue: updates.value
          });
        }
      }

      updateList.forEach(({group, key, newValue}) => {
        tmpSettings = {
          ...tmpSettings,
          [group]: {
            ...tmpSettings[group],
            [key]: newValue,
          }
        };

        tmpUpdatedValues = {
          ...tmpUpdatedValues,
          [group]: {
            ...tmpUpdatedValues[group],
            [key]: newValue,
          }
        };
      });

      setSettings(tmpSettings);
      setUpdatedValues(tmpUpdatedValues);
    };

    useEffect(() => {
      let count = 0;
      for (const group in updatedValues) {
        count = count + Object.keys(updatedValues[group]).length;
      }
      setChangeCount(count);
      setHasUpdatedSettings(count > 0);
    }, [updatedValues]);

    const updateSettings = (settings: CameraSettingsApiResponse) => {
      setFullSettings(settings);
    };

    if (!fullSettings || isLoading) {
      return <ProgressBar />;
    }

    return (
      <SettingsContext.Provider
        value={{
          fullSettings,
          camera,
          updateSettings,
          updateCount,
          pendingUserGroups,
          setPendingUserGroups,
          filteredUserGroups,
          setFilteredUserGroups,
          hasUpdatedSettings,
          setHasUpdatedSettings,
        }}
      >
        <Settings
          key={updateCount}
          schema={schema}
          settings={settings}
          hasUpdatedSettings={hasUpdatedSettings}
          changeCount={changeCount}
          camera={camera}
          updatedGroups={updatedGroups}
          updatedValues={updatedValues}
          isLoading={isLoading}
          activeGroup={activeGroup}
          isSaving={isSaving}
          errors={errors}
          checkDependency={checkDependency}
          onSaveSettings={handleSaveSettings}
          setIsSaving={setIsSaving}
          onSetGroupValue={handleSetValue}
          onGroupSelected={setActiveGroup}
          onReset={handleReset}
        />

        <Prompt
          when={hasUpdatedSettings}
          message={location =>
            `You have made changes without saving, are you sure you want to continue?`
          }
        />
      </SettingsContext.Provider>
    );
  };
};
