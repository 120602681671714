import React from 'react';
import { Grid } from '@material-ui/core';
import AppContext from '../../context';
import { ProfilePersonalInfo } from './personal-info';
import { PageHeader } from '../../components/page-header';
import { UsersAPI } from '../../api/users';
import { UserDetailsGetResponseData } from '../../api/isc-api';
import { useApi } from '../../hooks';
import { ProgressBar } from '../../components/progress-bar';
import { ProfileChangePassword } from './change-password';

export const ProfilePage = () => {
  const context = React.useContext(AppContext);
  const [user, , isLoading] = useApi<UserDetailsGetResponseData>(
    () => UsersAPI.get(context.user['custom:userId']),
    {
      message: 'Fatal error loading user profile, please contact tech support.',
      redirect: '/',
    }
  );

  return (
    <>
      <PageHeader title="My Profile" />
      {isLoading && <ProgressBar />}
      {user && !isLoading && (
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <ProfilePersonalInfo user={user.userDetails} />
          </Grid>
          <Grid item xs={12} md={6}>
            <ProfileChangePassword email={user.userDetails.email} />
          </Grid>
        </Grid>
      )}
    </>
  );
};
