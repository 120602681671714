import React, { useContext, useState } from 'react';
import { Auth } from 'aws-amplify';
import {
  Card,
  CardHeader,
  CardContent,
  List,
  ListItem,
  TextField,
  CardActions,
  Button,
} from '@material-ui/core';
import { useApiUpdate } from '../../hooks';
import { UsersAPI } from '../../api/users';
import {
  UserDetails,
  UserDetailsPostPutBody,
  UserDetailsGetResponseData,
} from '../../api/isc-api';
import { isPhoneValid, transformPhone } from '../../util/validations';
import AppContext from '../../context';

type ProfilePersonalInfoProps = {
  user: UserDetails;
};

export const ProfilePersonalInfo = ({ user }: ProfilePersonalInfoProps) => {
  const context = useContext(AppContext);
  const [name, setName] = useState(user.name);
  const [nameError, setNameError] = useState<string>(null);
  const [email, setEmail] = useState(user.email);
  const [emailError, setEmailError] = useState<string>(null);
  const [phone, setPhone] = useState(user.phone);
  const [phoneError, setPhoneError] = useState<string>(null);

  const [doSave, isSaving] = useApiUpdate<
    Partial<UserDetailsPostPutBody>,
    UserDetailsGetResponseData
  >(
    body => UsersAPI.update(user.userId, body),
    () => {
      context.onAlert('Your personal information has been updated.', 'success');
    }
  );

  const handleSave = (ev: React.SyntheticEvent) => {
    ev.preventDefault();
    const transformedPhone = transformPhone(phone);
    setPhone(transformedPhone);

    const emailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    const nameValid = name.trim() !== '';
    const phoneValid = isPhoneValid(transformedPhone);

    setEmailError(emailValid ? null : 'Please enter valid email address');
    setNameError(nameValid ? null : 'Name cannot be empty');
    setPhoneError(
      phoneValid
        ? null
        : 'Please enter valid phone number including country code'
    );

    if (nameValid && emailValid && phoneValid) {
      doSave({
        name,
        email,
        phone: transformedPhone,
        userGroups: user.userGroups,
        organizationalRoleIds: user.organizationalRoles.map(r => r.userRoleId),
      });
    }
  };

  return (
    <form onSubmit={handleSave}>
      <Card>
        <CardHeader title="Personal Information" />
        <CardContent>
          <List disablePadding>
            <ListItem>
              <TextField
                label="Name"
                fullWidth
                value={name}
                onChange={ev => setName(ev.target.value)}
                error={Boolean(nameError)}
                helperText={nameError}
              />
            </ListItem>
            <ListItem>
              <TextField
                label="Email"
                fullWidth
                value={email}
                onChange={ev => setEmail(ev.target.value)}
                error={Boolean(emailError)}
                helperText={emailError}
              />
            </ListItem>
            <ListItem>
              <TextField
                label="Phone"
                fullWidth
                value={phone}
                onBlur={ev => {
                  setPhone(transformPhone(ev.target.value));
                }}
                onChange={ev => setPhone(ev.target.value)}
                error={Boolean(phoneError)}
                helperText={phoneError}
              />
            </ListItem>
          </List>
        </CardContent>
        <CardActions style={{ justifyContent: 'space-between' }}>
          <Button color="primary" type="submit" disabled={isSaving}>
            {isSaving ? 'Saving...' : 'Save'}
          </Button>
        </CardActions>
      </Card>
    </form>
  );
};
