import React from 'react';
import {
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { UserRole } from '../../api/isc-api';

export type AvailableOrganizationUserRole = UserRole & {
  isMember?: boolean;
};

type UserRolesCheckboxListProps = {
  handleRoleSelection: (selected: AvailableOrganizationUserRole, isSelected: boolean) => void;
  availableRoles: AvailableOrganizationUserRole[];
};

export const UserRolesCheckboxList = ({
  handleRoleSelection,
  availableRoles,
}: UserRolesCheckboxListProps) => {

  return (
    <FormGroup>
      {availableRoles.map(role => {
        return (
          <FormControlLabel
            key={role.userRoleId}
            control={
              <Checkbox
                checked={role.isMember === true}
                onChange={ev => handleRoleSelection(role, ev.target.checked)}
                name={role.name}
              />
            }
            label={role.name}
          />
        );
      })}
    </FormGroup>
  );
};
