import React, { useState } from 'react';
import { CamerasAPI } from '../../api/cameras';
import {
  Hidden,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  IconButton,
  Badge,
} from '@material-ui/core';
import {
  AlertListItem,
  AlertsLogGetResponseData,
  Incident,
} from '../../api/isc-api';
import AlertTypeEnum = AlertListItem.AlertTypeEnum;
import { InfiniteListBox } from './infinite-list-box';
import { useStyles } from './camera-details-styles';
import { ItemText } from './item-text';
import { useAppContext } from '../app/useAppContext';
import { AlertLogIcon } from './alert-log-icon';
import { Image } from '../../components/image';
import { IncidentTypeLabels } from '../../schema';
import { FilterList } from '@material-ui/icons';
import { AlertFilter } from './alert-filter';
import { useTrackInfiniteListOffset } from './useTrackOffset';
import IncidentTypeEnum = Incident.IncidentTypeEnum;

type AlertLogProps = {
  limit: number;
  cameraId: string;
};

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

const AlertLogComponent = ({ limit, cameraId }: AlertLogProps) => {
  const classes = useStyles();
  const context = useAppContext();

  const [showFilter, setShowFilter] = useState(false);

  type AlertLogProps = {
    limit: number;
    start: number;
		timeZone?: string;
    startDate?: string;
    endDate?: string;
    fIncidentLpr?: boolean;
    fIncidentLprMatch?: boolean;
    fIncidentFr?: boolean;
    fIncidentMotion?: boolean;
    fStatusBattery?: boolean;
    fStatusStorage?: boolean;
    fStatusSignal?: boolean;
    fStatusCameraOnline?: boolean;
  };

  const handleApi = async (body: AlertLogProps) => {
    let alertLog: AlertsLogGetResponseData;
    try {
      alertLog = await CamerasAPI.getAlertLog(cameraId, body);

      return { items: alertLog.alerts, next: alertLog.next };
    } catch (err) {
      context.onAlert(
        `Unable to load alerts for camera is ${cameraId}`,
        'error'
      );
    }
  };

  const {
    items,
    hasNextPage,
    isNextPageLoading,
    loadNextPage,
    handleScroll,
    scrollToItem,
    handleApplyFilters,
    filters,
  } = useTrackInfiniteListOffset({
    cameraId,
    session: 'alertLog',
    limit,
    handleApi,
  });

  const getCameraThumbnail = (alert: any) => {
    if (!alert?.cameraThumbnail?.url) {
      return null;
    }

    return (
      <ListItemSecondaryAction>
        <Image
          className={classes.thumbnail}
          src={alert.cameraThumbnail.url}
          width={85}
          height={60}
        />
      </ListItemSecondaryAction>
    );
  };

  const getAlertLabel = (alert: AlertListItem) => {
    return IncidentTypeLabels[alert.incidentType] ?? 'Unknown Alert Type';
  };

  const itemContents = (alert: AlertListItem) => (
    <>
      <Hidden xsDown>
        <ListItemAvatar>
          <div className={classes.incident}>
            <AlertLogIcon type={alert.incidentType || alert.title} />
          </div>
        </ListItemAvatar>
      </Hidden>
      <ItemText
        dateTime={alert.date.dateTimeLocal.toString()}
        primary={getAlertLabel(alert)}
        secondary={
          alert.incidentType ===
          String(IncidentTypeEnum.LicensePlateRecognitionProfileMatch)
            ? alert.title
            : null
        }
      />
      {getCameraThumbnail(alert)}
    </>
  );

  const renderItem = (
    alert: AlertListItem,
    isItemLoaded: boolean,
    style: object
  ) => {
    if (!isItemLoaded) {
      return <ListItem style={style}>Loading...</ListItem>;
    }

    if (alert.alertType == AlertTypeEnum.Incident) {
      return (
        <ListItemLink
          style={style}
          href={`/alert/${alert.alertType}/${alert.alertId}`}
        >
          {itemContents(alert)}
        </ListItemLink>
      );
    }

    return <ListItem style={style}>{itemContents(alert)}</ListItem>;
  };

  const header = (
    <>
      <ListItem className={classes.listHeader}>
        {' '}
        <ListItemText
          primaryTypographyProps={{
            className: classes.listHeaderText,
            color: 'primary',
          }}
        >
          Recent Alerts
        </ListItemText>
        <IconButton edge="end" onClick={() => setShowFilter(!showFilter)}>
          <Badge badgeContent={Object.keys(filters).length} color="primary">
            <FilterList />{' '}
          </Badge>{' '}
        </IconButton>
      </ListItem>
      <AlertFilter
        open={showFilter}
        handleClose={() => setShowFilter(!showFilter)}
        filters={filters}
        handleSubmit={handleApplyFilters}
      />
    </>
  );

  const listProps = {
    header,
    renderItem,
    emptyText: 'No Alerts Found',
    items,
    hasNextPage,
    isNextPageLoading,
    loadNextPage,
    handleScroll,
    scrollToItem,
    itemSize: 75,
    headerSize: 75,
  };

  return <InfiniteListBox {...listProps} />;
};

export const AlertLog = AlertLogComponent;
