import React from 'react';
import { Link } from 'react-router-dom';
import fromNow from 'from-now';
import {
  Grid,
  Typography,
  ListItem,
  ListItemText,
  Hidden,
  IconButton,
  Button,
} from '@material-ui/core';
import { Settings } from '@material-ui/icons';
import { CollectionChildrenGetResponseDataCameras } from '../../api/isc-api';
import { StatusIcons } from './status-icons';
import { Image } from '../image';
import { useStyles } from './useStyles';

type CameraListItemProps = {
  camera: CollectionChildrenGetResponseDataCameras;
};

const CameraListItem = ({ camera }: CameraListItemProps) => {
  const classes = useStyles();

  return (
    <ListItem className={classes.collectionListItem}>
      <Link
        to={`/camera/${camera.camera.cameraId}`}
        className={classes.collectionLink}
      >
        <ListItemText>
          <Grid container spacing={3} className={classes.cameraCard}>
            <Hidden xsDown>
              <Grid item sm={2}>
                <Image
                  src={camera?.cameraThumbnail?.url}
                  alt="Camera Snapshot"
                />
              </Grid>
            </Hidden>
            <Grid item xs={10} sm={8} md={5}>
              <Typography className={classes.cameraName}>
                {camera.camera.cameraName}
              </Typography>
              <Typography className={classes.cameraIMEI}>
                IMEI {camera.camera.imei}
              </Typography>
              <Typography className={classes.cameraThumbnailDate}>
                {camera.cameraThumbnail?.date ?
                  fromNow(camera.cameraThumbnail.date.dateTimeLocal) :
                  'No recent images'
                }
              </Typography>
            </Grid>
            <Hidden mdUp>
              <Grid item xs={2}>
                <Link to={`/camera/${camera.camera.cameraId}/settings`}>
                  <IconButton>
                    <Settings />
                  </IconButton>
                </Link>
              </Grid>
            </Hidden>
            <Grid item xs={12} md={3}>
              {camera.operationStatus ?
                <StatusIcons camera={camera} />
                : <Typography className={classes.cameraThumbnailDate}>Camera status unknown</Typography> }
            </Grid>
            <Hidden smDown>
              <Grid item sm={2} className={classes.justifyRight}>
                <Link to={`/camera/${camera.camera.cameraId}/settings`}>
                  <Button
                    size="small"
                    variant="outlined"
                    disableElevation
                    startIcon={<Settings />}
                  >
                    Settings
                  </Button>
                </Link>
              </Grid>
            </Hidden>
          </Grid>
        </ListItemText>
      </Link>
    </ListItem>
  );
};

export default CameraListItem;
