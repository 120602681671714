import React, {
  PropsWithChildren,
  useState,
  useEffect,
  useContext,
} from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import { useLocation, useRouteMatch } from 'react-router-dom';
import clsx from 'clsx';
import AppContext, { AuthenticationFlow } from '../../context';
import { AlertSnack } from '../../components/alert-snack';
import { getOrganization } from '../../access';
import { useStyles } from './useStyles';
import { MenuSwitch } from './menu-switch';
import { MenuLoggedIn } from './menu-logged-in';

export const Page = (props: PropsWithChildren<Record<string, unknown>>) => {
  const classes = useStyles(props);
  const location = useLocation();
  const testIsHome = useRouteMatch({
    path: ['/', '/collection/:cameraCollectionId'],
    exact: true,
  })?.isExact;
  const [isHome, setIsHome] = useState(testIsHome);
  const [fadeHomeShape, setFadeHomeShape] = useState(!isHome);

  const context = useContext(AppContext);

  let organization;
  try {
    organization = getOrganization();
  } catch {
    context.onLogout();
  }

  useEffect(() => {
    if (isHome !== testIsHome) {
      setIsHome(testIsHome);
      setFadeHomeShape(!testIsHome);
    }
  }, [location.pathname]);

  const getMenu = () =>
    context.authStatus === AuthenticationFlow.LoggedIn && <MenuLoggedIn />;

  return (
    <div className={classes.root}>
      <CssBaseline />
      {getMenu()}

      <main
        id="main-content"
        className={clsx({
          [classes.content]: true,
          [classes.contentHome]: isHome,
          [classes.contentHomeShapeFade]: fadeHomeShape,
        })}
      >
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          {organization ? props.children : null}
        </Container>
      </main>
      <div id="alert-snack-portal"/>
      <AlertSnack
        message={context.alert?.message}
        type={context.alert?.type}
        onClose={context.onAlert}
      />
    </div>
  );
};
