import React from 'react'

import { Grid } from '@material-ui/core'
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

import { PageProps } from '../../types'
import { useApi } from '../../../hooks'
import billing from '../../../api/billing'
import { InvoiceCard } from './invoice-card'
import { PayNowCard } from './pay-now-card'
import { PaymentCard } from './payment-card'
import { getOrganization } from '../../../access';

export const BillingInvoicePage = (props: PageProps<'invoiceId'>) => {

  const organization = getOrganization()

  const [data,,, reload] = useApi(
    () => billing().getInvoice(props.match.params.invoiceId)
  )

  if (data === null) {
    return null
  }

  const paymentSection = () => {
    if (data.invoice.status === 'paid' && data.payment) {
      return <PaymentCard payment={data.payment} />
    }

    if (data.invoice.status === 'created' && organization.organizationId === data.invoice.organizationId) {
      return <PayNowCard invoice={data.invoice} onComplete={() => reload()} />
    }

    return null
  }

  return (
    <Grid container spacing={2}>      
      <Grid item xs={12}>        
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" href="/billing">Billing &gt;</Link>
        </Breadcrumbs>        
        <Typography variant="h1">Invoice Details</Typography>
        <InvoiceCard invoice={data.invoice} items={data.items} />
      </Grid>
      <Grid item xs={12}>
        {paymentSection()}
      </Grid>
    </Grid>
  )
}
