import React, { useContext, useState, useEffect } from 'react';
import { Grid, Fade } from '@material-ui/core';
import {
  CameraCollection as CameraCollectionAPI,
  MyPinnedCamera,
  CollectionChildrenGetResponseDataCameras,
} from '../../api/isc-api';
import { CameraCollectionList } from './camera-collection-list';
import { DashboardAPI } from '../../api/dashboard';
import { CamerasAPI, CameraSearchParams } from '../../api/cameras';
import { CameraCollectionsAPI } from '../../api/camera-collections';
import { AddItemModal } from '../add-item-modal';
import AppContext from '../../context';
import { useHistory } from 'react-router';
import { PanelHeader } from '../panel/panel-header';
import { ProgressBar } from '../progress-bar';
import { useStyles } from './index-styles';
import {hasPermission, UserPermissions} from "../../access";

export type CameraCollectionsProps = {
  cameraCollectionId?: string;
  setPinnedCameras: React.Dispatch<React.SetStateAction<MyPinnedCamera[]>>;
  setIsFirstLoad: React.Dispatch<React.SetStateAction<boolean>>;
  firstLoad: boolean;
};

type SearchScratchData = {
  cameras: CollectionChildrenGetResponseDataCameras[];
  collections: CameraCollectionAPI[];
  currentCollection: CameraCollectionAPI;
  parentCollection: CameraCollectionAPI;
};

export const CameraCollections = ({
  cameraCollectionId,
  setPinnedCameras,
  setIsFirstLoad,
  firstLoad,
}: CameraCollectionsProps) => {
  const classes = useStyles();
  const context = useContext(AppContext);
  const history = useHistory();

  const [cameras, setCameras] =
    useState<CollectionChildrenGetResponseDataCameras[]>(null);
  const [collections, setCollections] = useState<CameraCollectionAPI[]>();

  const [alternateHeader, setAlternateHeader] = useState(false);
  const [unassignedCameraCount, setUnassignedCameraCount] = useState(0);

  const [currentCollection, setCurrentCollection] =
    useState<CameraCollectionAPI>(null);
  const [parentCollection, setParentCollection] =
    useState<CameraCollectionAPI>(null);

  const [isSearch, setIsSearch] = useState(false);
  const [searchScratchData, setSearchScratchData] = useState<SearchScratchData>();

  const [isAddingCollection, setIsAddingCollection] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [collectionListTitle, setCollectionListTitle] = useState('All Cameras & Collections');

  const doFetch = async () => {
    setIsLoading(true);
    if (cameraCollectionId) {
      await fetchAndSetCollection();
      await fetchChildCollections();
    } else {
      await fetchDashboard();
    }
    setIsLoading(false);
    setIsFirstLoad(false);
  };

  useEffect(() => {
    void doFetch();
  }, [cameraCollectionId]);

  useEffect(() => {
    if (isSearch) {
      setCollectionListTitle('Search Results');
    } else if (currentCollection) {
      setCollectionListTitle('Cameras in Collection')
    } else {
      setCollectionListTitle('All Cameras & Collections');
    }
  }, [currentCollection, isSearch]);

  const fetchDashboard = async () => {
    setCameras(null);
    const data = await DashboardAPI.getDashboard();
    setCollections(data.collections);
    setPinnedCameras(data.myPinnedCameras);
    setAlternateHeader(!data.hasPinnedCameras);
    setUnassignedCameraCount(data.unassignedCameraCount);
    setCurrentCollection(null);
    setParentCollection(null);
  };

  const fetchChildCollections = async () => {
    const data = await DashboardAPI.getCollectionChildren(cameraCollectionId);
    setCollections(data.collections || null);
    // setCurrentCollection(data.)
    setCameras(data.cameras || null);
    setPinnedCameras([]);
    setAlternateHeader(true);
    setUnassignedCameraCount(0);
  };

  const fetchAndSetCollection = async () => {
    const parent = await DashboardAPI.getParent(cameraCollectionId);
    // if we  have a parent, get collection from children[] of parent
    if (parent?.cameraCollection) {
      const data = await DashboardAPI.getCollectionChildren(
        parent.cameraCollection.cameraCollectionId
      );
      setCurrentCollection(
        data.collections.find(c => c.cameraCollectionId === cameraCollectionId)
      );
    }
    // if we're one level deeper than dashboard, get list from there
    else {
      const data = await DashboardAPI.getDashboard();
      setCurrentCollection(
        data.collections.find(c => c.cameraCollectionId === cameraCollectionId)
      );
    }
    setParentCollection(parent?.cameraCollection || null);
  };

  const handleFilterChange = async (filter: string) => {
    const options: CameraSearchParams = {
      filter: filter,
      start: 0,
      limit: 100,
    };

    if (!filter && searchScratchData) {
      setCameras(searchScratchData.cameras);
      setCollections(searchScratchData.collections);
      setCurrentCollection(searchScratchData.currentCollection);
      setParentCollection(searchScratchData.parentCollection);
      setIsSearch(false);
    } else {
      setSearchScratchData({
        cameras,
        collections,
        currentCollection,
        parentCollection
      })
      try {
        setIsLoading(true);
        const searchResults = await CamerasAPI.searchCameras(options);
        setIsLoading(false);
        if (searchResults && searchResults.cameras) {
          setIsSearch(true);
          setCurrentCollection(null);
          setParentCollection(null);
          setCollections(null);
          setCameras(searchResults.cameras);
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleCollectionChange = async (
    e: MouseEvent,
    collection: CameraCollectionAPI,
    isPop = false
  ) => {
    e.preventDefault();
    if (!collection) {
      history.push(`/`);
    } else {
      history.push(`/collection/${collection.cameraCollectionId}`);
    }
  };

  const handleAddCollection = async (collectionName: string) => {
    try {
      const response: CameraCollectionAPI =
        await CameraCollectionsAPI.addCollection(
          collectionName,
          currentCollection?.cameraCollectionId
        );
      if (response.hasOwnProperty('cameraCollectionId')) {
        const collectionSettingsUrl = `/collection/${response.cameraCollectionId}/settings`;
        context.onAlert('Camera collection added successfully', 'success');
        history.push(collectionSettingsUrl);
      } else {
        throw new Error();
      }
    } catch (e) {
      console.log(e);
      context.onAlert(
        'Sorry, there was a problem trying to add this collection.',
        'error'
      );
    }

    setIsAddingCollection(false);
  };

  
  const getAddCollectionTitle = () => {
    if (!currentCollection) return 'Add new Collection';
    return 'Add new Sub Collection'
  };
  const getAddCollectionHeading = () => {
    if (!currentCollection) return 'Enter a name for your new collection.';
    return `This collection will be added inside of the ${currentCollection.cameraCollectionName} camera collection.`;
  };

  const getCollectionDisplay = () => (
    <Fade in={!isLoading}>
      <CameraCollectionList
        currentCollection={currentCollection}
        parentCollection={parentCollection}
        collections={collections}
        cameras={cameras}
        unassignedCameraCount={unassignedCameraCount}
        isSearch={isSearch}
        onCollectionChange={handleCollectionChange}
      ></CameraCollectionList>
    </Fade>
  );

  if (firstLoad) return null;

  return (
    <>
      <Grid container spacing={2}>
        <Grid
          container
          item
          spacing={2}
          className={alternateHeader ? classes.titleGridAlternate : null}
        >
          <PanelHeader
            title={collectionListTitle}
            placeholderText="Camera Name or IMEI"
            onAddNew={(!isSearch && hasPermission(UserPermissions.CanManageCameras)) ? () => {
              setIsAddingCollection(true);
            } : null}
            onFilterChange={handleFilterChange}
          />
        </Grid>
        <Grid
          item
          xs={12}
          className={
            alternateHeader ? classes.cameraCollectionsAlternate : null
          }
        >
          {isLoading ? <ProgressBar /> : getCollectionDisplay()}
        </Grid>
      </Grid>

      <AddItemModal
        title={getAddCollectionTitle()}
        heading={getAddCollectionHeading()}
        open={isAddingCollection}
        handleClose={() => setIsAddingCollection(false)}
        handleSubmit={handleAddCollection}
      />
    </>
  );
};
