import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 0, 2, 4),
  },
}));

export const Subhead = (props: any) => {
  const classes = useStyles(props);

  return (
    <Typography className={classes.root} variant="h5">
      {props.children}
    </Typography>
  );
};
