import React, {useCallback, useState} from 'react'
import {FormControl, IconButton, Input, InputAdornment, InputLabel} from '@material-ui/core';
import {Clear, Search} from "@material-ui/icons";
import {debounce} from 'ts-debounce';

type DashboardSearchBoxProps = {
  value: string,
  placeholderText?: string,
  onFilterChange: (filter: string) => void
}

export const DashboardSearchBox = (props: DashboardSearchBoxProps) => {
  const [value, setValue] = useState(props.value);

  const debouncedUpdate = useCallback(
    debounce((input: HTMLInputElement) => {
      props.onFilterChange(input.value);
    }, 500),
    []
  );

  const handleValueChange = (ev: KeyboardEvent) => {
    setValue((ev.target as HTMLInputElement).value);
    debouncedUpdate(ev.target as HTMLInputElement);
  }

  const handleClear = () => {
    setValue('');
    props.onFilterChange('');
  }

  return (
    <FormControl fullWidth={true}>
      <InputLabel htmlFor="searchField">{props.placeholderText || 'Camera Name or IMEI'}</InputLabel>
      <Input
        id="searchField"
        type="text"
        fullWidth={true}
        value={value}
        onChange={(ev: any) => {handleValueChange(ev)}}
        endAdornment={
          <InputAdornment position="end">
            { value ?
              <IconButton onClick={handleClear}>
                <Clear/>
              </IconButton>
              : <Search/>
            }
          </InputAdornment>
        }
      />
    </FormControl>
  )
}
