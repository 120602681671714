import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { DashboardSearchBox } from '../../../components/dashboard-search-box';
import { useApi } from '../../../hooks';
import {
  CameraBasic,
  CollectionCameraDetailSearchGetResponseDataCameras,
} from '../../../api/isc-api';
import { useStyles } from './add-camera-modal-styles';
import { ProgressBar } from '../../../components/progress-bar';
import { CamerasAPI } from '../../../api/cameras';
import { useAddItemModal } from "../../../components/add-camera-modal/useAddItemModal";

type AddCameraModalProps = {
  open: boolean;
  existingCameras: CameraBasic[];
  handleClose: () => void;
  handleSave: (
    cameras: CollectionCameraDetailSearchGetResponseDataCameras[]
  ) => void;
};

export default function AddCameraModal(props: AddCameraModalProps) {
  const classes = useStyles();
  const [filteredCameras, setFilteredCameras] = useState<CollectionCameraDetailSearchGetResponseDataCameras[]>([]);
  const [selectedCameras, setSelectedCameras] = useState<CollectionCameraDetailSearchGetResponseDataCameras[]>([]);
  const [searchableCameras, setSearchableCameras] = useState<
    CollectionCameraDetailSearchGetResponseDataCameras[]
  >([]);

  const [data, , isLoading] = useApi(() =>
    CamerasAPI.searchCameras().then(response => response.cameras)
  );

  const {
    chipData,
    filter,
    handleFormSubmit,
    reset,
    handleDeleteItem,
    handleFilterChange,
    handleChangeItem,
    isItemChecked,
    isAllSelected,
    setAllSelected,
    updateChipData,
    setIsAllSelected
  } = useAddItemModal({
    handleClose: props.handleClose
  });

  useEffect(() => {
    updateChipData(selectedCameras, 'camera.cameraId', 'camera.cameraName');
    setIsAllSelected(selectedCameras.length === filteredCameras.length && selectedCameras.length > 0);
  }, [selectedCameras]);

  useEffect(() => {
    if (data && !filter) {
      const existingCameraIds = props.existingCameras.map((camera: CameraBasic) => camera.cameraId);
      const existing = (el: any) => {
        return !existingCameraIds.includes(el.camera.cameraId);
      };
      const camerasNotAdded = data.filter(existing);
      setSearchableCameras(camerasNotAdded);
      setFilteredCameras(camerasNotAdded);
    }
  }, [data, props.existingCameras]);

  useEffect(() => {
    if (data) {
      let cameraList = [...searchableCameras];

      if (filter) {
        const query = new RegExp(filter, "igm")
        const filterCamera = (el: any) => {
          return el.camera.cameraName.match(query) || el.camera.imei.toString().match(query)
        };
        const loadFilteredData = async () => {
          setFilteredCameras(cameraList.filter(filterCamera));
        };

        loadFilteredData();
      } else {
        setFilteredCameras(cameraList);
      }
    }
  }, [filter]);

  const cameraItems = () => (
    <>
      <Grid xs={12} md={12} item>
        <Paper component="ul" className={classes.root}>
          {chipData.map(cData => {
            return (
              <li key={cData.key}>
                <Chip
                  size="small"
                  label={cData.label}
                  onDelete={() => {
                    handleDeleteItem(cData, selectedCameras, setSelectedCameras, 'camera.cameraId', 'camera.cameraName');
                  }}
                />
              </li>
            );
          })}
        </Paper>
      </Grid>
      <Grid item style={{ maxHeight: 500, overflow: 'auto' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell className={classes.headerCell}>
                Select
                <div className={classes.selectAll}>
                  {isAllSelected ?
                    <a href="#" onClick={e => setAllSelected(e, false, setSelectedCameras)}>(Deselect All)</a> :
                    <a href="#" onClick={e => setAllSelected(e, true, setSelectedCameras, filteredCameras)}>(Select All)</a>
                  }
                </div>
              </TableCell>
              <TableCell className={classes.headerCell}>Cameras</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredCameras.length ? (
              filteredCameras.map(
                (
                  nextCamera: CollectionCameraDetailSearchGetResponseDataCameras,
                  idx: number
                ) => (
                  <TableRow
                    key={idx}
                    className={classes.item}
                    onClick={() =>
                      handleChangeItem(
                        !isItemChecked(nextCamera, selectedCameras, ['camera.cameraId', 'camera.cameraName']),
                        nextCamera,
                        selectedCameras,
                        setSelectedCameras,
                        ['camera.cameraId', 'camera.cameraName']
                      )
                    }
                  >
                    <TableCell key={0}>
                      <Checkbox
                        checked={isItemChecked(nextCamera, selectedCameras, ['camera.cameraId', 'camera.cameraName'])}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    </TableCell>
                    <TableCell key={1}>
                      <Typography className={classes.cameraName}>
                        {nextCamera.camera.cameraName}<br />
                      </Typography>
                      <Typography className={classes.cameraImei}>
                        IMEI {nextCamera.camera.imei}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )
              )
            ) : (
              <Typography variant="body1" className={classes.noCameraText}>
                {filter?.length
                  ? 'No cameras match the search parameters.'
                  : 'There are no cameras available to add.'}
              </Typography>
            )}
          </TableBody>
        </Table>
      </Grid>
    </>
  );

  return (
    <React.Fragment>
      <Dialog
        open={props.open}
        aria-labelledby="form-dialog-title"
        maxWidth={'sm'}
        fullWidth={true}
      >


        <DialogContent className={classes.dialogContent}>
          <Grid container className={classes.panelHeader}>
            <Grid item xs={12} md={8}>
              <Typography variant="h2">Add Camera</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <DashboardSearchBox
                placeholderText={'Search'}
                value={''}
                onFilterChange={handleFilterChange}
              />
            </Grid>
          </Grid>


          <form action="#" onSubmit={(ev: any) => handleFormSubmit(ev)}>
            <Grid item style={{ maxHeight: 500, overflow: 'auto' }}>
              {isLoading ? <ProgressBar /> : cameraItems()}
            </Grid> </form>
        </DialogContent>
        <DialogActions className={classes.actionContainer}>
          <Button
            size="small"
            onClick={() => {
              reset(setSelectedCameras);
              props.handleClose();
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            size="small"
            disabled={!data?.length || !selectedCameras.length}
            onClick={() => {
              props.handleSave(selectedCameras);
              reset(setSelectedCameras);
            }}
          >
            Save
          </Button>
        </DialogActions>


      </Dialog>
    </React.Fragment>
  );
}
