import React, {useEffect} from 'react';
import {useStyles} from "./recognition-results-styles";
import {
  LicensePlateRecognitionResult,
  RecognitionResultBase,
  RecognitionResultPropertyBase,
  VehicleRecognitionResult
} from "../../../api/isc-api";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography
} from "@material-ui/core";
import {SupportedRecognitionTypes} from "./index";
import {getVehiclePropertyLabel, getVehiclePropList} from "./helpers";
import {CheckCircleOutline} from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";

export type RecognitionResultsDetailProps = {
  open: boolean,
  type: SupportedRecognitionTypes,
  recognitionResult: LicensePlateRecognitionResult|VehicleRecognitionResult,
  onClose: () => void
}

export const RecognitionResultsDetail = (({open, type, recognitionResult, onClose}: RecognitionResultsDetailProps) => {
  const classes = useStyles();

  const getPropValueSummary = (result: RecognitionResultPropertyBase, isBestMatch = false) => {
    return (
      <>
        {isBestMatch ? <CheckCircleOutline className={classes.detailListItemIcon} /> : null}
        <strong>{result.name}</strong> ({result.confidence.toFixed(2)}% confidence)
      </>
    )
  }

  const getPropValueList = (result: RecognitionResultBase) => {
    return {
      bestMatch: result.bestMatch || null,
      matches: result.matches
    }
  }

  const vehiclePropList = (vehicle: VehicleRecognitionResult) => {
    const propList = getVehiclePropList(vehicle);

    return (
      propList.map(prop => {
        const [name, result] = prop;
        if (!result || Array.isArray(result) && result.length < 1) {
          return null;
        }

        const propValueList = getPropValueList(result as RecognitionResultBase);
        return (
          <>
            <Typography variant="h3" className={classes.detailContentHeader}>{getVehiclePropertyLabel(name)}</Typography>
            <List dense={true} className={classes.detailList}>
              {propValueList.bestMatch ?
                <ListItem className={classes.detailListItem}>
                  <ListItemText primary={getPropValueSummary(propValueList.bestMatch, true)} />
                </ListItem>
                : null}
              {propValueList.matches && propValueList.matches.map((value: RecognitionResultPropertyBase) => {
                return (
                  <ListItem className={classes.detailListItem}>
                    <ListItemText primary={getPropValueSummary(value)} />
                  </ListItem>
                )
              })}
            </List>
          </>
        )
      })
    )
  }

  return (
    recognitionResult ?
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth={true} className={classes.dialog}>
        <DialogTitle className={classes.dialogTitle}>
          Details
          <IconButton onClick={onClose} className={classes.closeButton}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div>
            {type === SupportedRecognitionTypes.LICENSE_PLATES ?
              <>
                <Typography variant="h3" className={classes.detailContentHeader}>License Plate</Typography>
                <List dense={true} className={classes.detailList}>
                  {(recognitionResult as LicensePlateRecognitionResult).candidates?.map(candidate => (
                    <ListItem className={classes.detailListItem}>
                      <ListItemText primary={
                        <>
                          <strong>{candidate.plate}</strong> ({candidate.confidence.toFixed(2)}% confidence)
                        </>
                      } />
                    </ListItem>
                  ))}
                </List>
                {(recognitionResult as LicensePlateRecognitionResult).vehicle ?
                  <>
                    {vehiclePropList((recognitionResult as LicensePlateRecognitionResult).vehicle)}
                  </>
                  : null}
              </>
              : null}

            {type === SupportedRecognitionTypes.VEHICLES ?
              <>
                {vehiclePropList(recognitionResult as VehicleRecognitionResult)}
              </>
              : null}
          </div>
        </DialogContent>
      </Dialog> : null
  )
})
