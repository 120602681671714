import React from 'react';
import { Link } from 'react-router-dom';
import {
  ListItem,
  ListItemText,
  Typography,
  ListItemSecondaryAction,
  Button,
  IconButton,
} from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import { CameraCollection } from '../../api/isc-api';
import { cameraCollectionDetails } from '../helpers';
import { useStyles } from './camera-collection-list-styles';

type CollectionListItemProps = {
  collection: CameraCollection;
  onCollectionChange: (e: MouseEvent, collection: CameraCollection) => void;
};

export const CollectionListItem = ({
  collection,
  onCollectionChange,
}: CollectionListItemProps) => {
  const classes = useStyles();

  return (
    <ListItem className={classes.collectionListItem}>
      <Link
        to="#"
        className={classes.collectionLink}
        onClick={(ev: any) => onCollectionChange(ev, collection)}
      >
        <ListItemText>
          <Typography className={classes.collectionName}>
            {collection.cameraCollectionName}
          </Typography>
          <Typography className={classes.collectionCount}>
            {cameraCollectionDetails(
              collection.cameraCount,
              collection.childCollectionCount
            )}
          </Typography>
        </ListItemText>
        <ListItemSecondaryAction>
          <IconButton>
            <ChevronRight color="primary" />
          </IconButton>
        </ListItemSecondaryAction>
      </Link>
    </ListItem>
  );
};
