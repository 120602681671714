import React from 'react';
import { UserGroupUserItem } from '../../../api/isc-api';
import { Panel } from '../../../components/panel/panel';
import {
  Typography,
  Switch,
  Collapse,
  Button,
  TableCell,
  makeStyles,
  TableRow,
  Table,
} from '@material-ui/core';
import { Link } from 'react-router-dom';

type InheritedUsersProps = {
  users: UserGroupUserItem[];
};

const useStyles = makeStyles(theme => ({
  collapseRow: {
    padding: theme.spacing(2, 0),
    borderTop: '1px solid ' + theme.palette.grey[300],
    borderBottom: '1px solid ' + theme.palette.grey[300],
    width: '100%',
    textAlign: 'center',
  },
  deleteSpacer: {
    paddingRight: '80px',
    borderBottom: '1px solid ' + theme.palette.grey[300],
  },
}));

export const InheritedUsersComponent = ({ users }: InheritedUsersProps) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const userItems = () =>
    users.map((user, index) => {
      const groupLink = `/group/${user.inheritedFrom?.userGroupId}`;
      return (
        <Panel.BodyRow key={user.userId}>
          <Panel.BodyCell name="tcellName" index={index} length={users.length}>
            <Typography>{user.name}</Typography>
            <em>
              Inherited from{' '}
              <Link to={groupLink}>
                <strong>{user.inheritedFrom?.name}</strong>
              </Link>
            </em>
          </Panel.BodyCell>
          <Panel.BodyCellToggle
            title="Admin"
            index={index}
            length={users.length}
          >
            <Switch
              value={user.isGroupAdmin}
              checked={user.isGroupAdmin}
              name={user.name}
              disabled={true}
            />
          </Panel.BodyCellToggle>
          <td className={classes.deleteSpacer}></td>
        </Panel.BodyRow>
      );
    });

  const collapseText = expanded ? (
    'Hide Inherited Users'
  ) : (
    <span>
      Show <strong>{users.length}</strong> Inherited Users
    </span>
  );

  const handleCollapseToggle = () => setExpanded(!expanded);

  return (
    <>
      <div className={classes.collapseRow}>
        <Button onClick={handleCollapseToggle}>{collapseText}</Button>
      </div>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Table>{userItems()}</Table>
      </Collapse>
    </>
  );
};

export const InheritedUsers = React.memo(InheritedUsersComponent);
