import React from 'react';
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Table,
  TableHead,
  TableRow,
  TableCell,
} from '@material-ui/core';
import { useApi } from '../../../hooks';
import billing from '../../../api/billing';
import { PaymentForm } from '../../../components/payment-form';
import { formatDate } from '../../../formats';
import { Link } from 'react-router-dom';
import { PageHeader } from '../../../components/page-header';

export const BillingDashboardPage = () => {
  const [customer] = useApi(() =>
    billing()
      .customer()
      .then(r => r.customer)
  );

  const [invoiceData] = useApi(() => billing().getInvoices());

  return (
    <React.Fragment>
      <PageHeader title="Billing" />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {customer && (
            customer.defaultPaymentMethod ? (
              <Card>
                <CardHeader
                  title="Payment method"
                  subheader="This method will be used for all subsequent automatic paymets related to your account."
                />
                <CardContent>
                  {customer.defaultPaymentMethod.description}
                </CardContent>
              </Card>
            ) : (
              <PaymentForm
                customerId={customer.customerId}
                buttonLabel={'Submit'}
                disabled={false}
              />
            )
          )}
        </Grid>
        <Grid item xs={12}>
          {invoiceData && invoiceData.pagination.total > 0 && (
            <Card>
              <CardHeader title="Invoices" />
              <CardContent>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Invoice No</TableCell>
                      <TableCell>Note</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Due</TableCell>
                      <TableCell align="right">Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  {invoiceData.invoices.map(invoice => (
                    <TableRow key={invoice.invoiceId}>
                      <TableCell key={0}>
                        <Link to={`/billing/invoice/${invoice.invoiceId}`}>
                          {invoice.invoiceNo}
                        </Link>
                      </TableCell>
                      <TableCell key={1}>
                        <Link to={`/billing/invoice/${invoice.invoiceId}`}>
                          {invoice.description}
                        </Link>
                      </TableCell>
                      <TableCell key={2}>{invoice.status}</TableCell>
                      <TableCell key={3}>{formatDate(invoice.due)}</TableCell>
                      <TableCell key={4} align="right">
                        ${invoice.amount}
                      </TableCell>
                    </TableRow>
                  ))}
                </Table>
              </CardContent>
            </Card>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
