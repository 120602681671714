import React from 'react'
import { Card, CardHeader, CardContent, Table, TableHead, TableRow, TableCell, TableBody, makeStyles } from '@material-ui/core'
import { Points } from '../../../components/points'

import { formatDate, formatMoney } from '../../../formats'
import { Invoice, InvoiceItem } from '../../../api/billing'
import moment from 'moment'

type InvoiceCardProps = {
  invoice: Invoice
  items: InvoiceItem[]
}

const useStyles = makeStyles(() => ({
  bottomRow: {
    fontWeight: 'bold',
    borderBottom: 'none'
  }
}))

export const InvoiceCard = (props: InvoiceCardProps) => {
  const classes = useStyles()

  const isPastDue = props.invoice.status === 'created' && moment(props.invoice.due).isBefore()

  return (
    <Card>
      <CardHeader
        title={`Invoice #${props.invoice.invoiceNo}`}
        subheader={
          <Points items={[
            <Points.Item color={isPastDue ? 'error' : 'inherit'}>Due {formatDate(props.invoice.due)}</Points.Item>,
            props.invoice.status === 'paid' ? `Paid ${formatDate(props.invoice.paid)}` : null
          ]} />
        }
      />
      <CardContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align='right'>Item #</TableCell>
              <TableCell>Description</TableCell>
              <TableCell align='right'>Qty</TableCell>
              <TableCell align='right'>Price per item</TableCell>
              <TableCell align='right'>Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.items.map((item, idx) =>
              <TableRow>
                <TableCell align='right'>{idx + 1}.</TableCell>
                <TableCell>{item.note}</TableCell>
                <TableCell align='right'>{item.qty}</TableCell>
                <TableCell align='right'>{formatMoney(item.price)}</TableCell>
                <TableCell align='right'>{formatMoney(item.qty * item.price)}</TableCell>
              </TableRow>
            )}
            <TableRow>
            <TableCell className={classes.bottomRow}>{' '}</TableCell>
            <TableCell className={classes.bottomRow}>Invoice total</TableCell>
            <TableCell className={classes.bottomRow}>{' '}</TableCell>
            <TableCell className={classes.bottomRow}>{' '}</TableCell>
            <TableCell className={classes.bottomRow} align='right'>{formatMoney(props.invoice.amount)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
}