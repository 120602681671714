import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, makeStyles
} from '@material-ui/core';
import {Warning} from "@material-ui/icons";

const useStyles = makeStyles(() => ({
  dialogContent: {
    display: 'flex'
  },
  dialogIcon: {
    fontSize: '3rem',
    marginRight: '0.5em'
  }
}));

type AlertDialogModalProps = {
  open: boolean,
  dialogTitle?: string,
  okTitle?: string,
  message: string,
  handleClose: () => void
}

export const AlertDialogModal = (props: AlertDialogModalProps) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Dialog
        open={props.open}
        aria-labelledby="form-dialog-title"
        maxWidth="xs"
      >
        <DialogTitle id="form-dialog-title">{props.dialogTitle}</DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <Warning className={classes.dialogIcon} />
            <DialogContentText>
              {props.message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => props.handleClose()}
            >
              {props.okTitle || 'OK'}
            </Button>
          </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}
