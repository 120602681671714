import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  card: {
    padding: 0,
  },
  deleteButton: {
    cursor: 'pointer',
  },
  panelHeader: {
    ...theme.mixins.gutters(),
  },
  headerRow: {
    height: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  headerCell: {
    paddingLeft: theme.spacing(3),
  },
  columnTitle: {
    fontWeight: 700,
  },
  noItemsText: {
    ...theme.mixins.gutters(),
  },
  dataCount: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      marginTop: theme.spacing(4),
      fontStyle: 'italic',
      paddingLeft: theme.spacing(3),
      paddingBottom: theme.spacing(1),
      borderBottom: '1px solid rgb(224, 224, 224)',
    },
  },
  bodyRow: {
    height: theme.spacing(10),
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexWrap: 'wrap',
      height: 'inherit',
      alignItems: 'center',
    },
  },
  noBottomBorder: {
    borderBottom: 'none',
  },
  tcellName: {
    width: '100%',
    paddingLeft: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '0',
      border: '0',
      width: '80%',
      order: '1',
    },
  },
  tcellToggle: {
    width: '50%',
    paddingLeft: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      paddingTop: '0px',
      display: 'flex',
      flex: '0 1 100%',
      order: '3',
      alignItems: 'center',
    },
  },
  tcellToggleText: {
    display: 'none',
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  tcellDelete: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0',
      paddingRight: theme.spacing(0.5),
      paddingBottom: '0',
      border: '0',
      order: '2',
      width: '20%',
    },
  },
}));
