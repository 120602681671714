import React from 'react';
import { useHistory } from 'react-router';
import {
  ListItem,
  ListItemText,
  ListItemAvatar,
  IconButton,
  ListItemSecondaryAction,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { cameraCollectionDetails } from '../../../components/helpers';
import { UserGroupSummary } from '../../../api/isc-api';
import { useStyles } from './useStyles';
import { Points } from '../../../components/points';

type GroupListItemProps = {
  group: UserGroupSummary;
  onDelete: (id: string) => void;
};

export const GroupListItem = ({ group, onDelete }: GroupListItemProps) => {
  const classes = useStyles();
  const history = useHistory();

  const handleGroupNav = (id: string) => {
    console.log({ id }, "CLICK")
    history.push(`/group/${id}`);
  };

  const subText = (group: UserGroupSummary) => (
    <>
      <Points items={[`${group.userCount} members`]} />
      <Points items={[`${group.cameraCount} cameras`]} />
      <Points items={[`${group.subGroupCount} sub groups`]} />
    </>
  );

  return (
    <ListItem
      key={group.userGroupId}
      className={classes.listItem}
      onClick={() => handleGroupNav(group.userGroupId)}
    >
      <ListItemText
        className={classes.listItemText}
        primary={group.name}
        secondary={subText(group)}
      />
      <ListItemAvatar>
        <IconButton onClick={() => {

          handleGroupNav(group.userGroupId)}}> 
          <EditIcon />
        </IconButton>
      </ListItemAvatar>
      <ListItemSecondaryAction>
        <IconButton onClick={() => {

              onDelete(group.userGroupId);
            }}>
          <DeleteIcon
          />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};
