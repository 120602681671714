export type SettingsDependency = {
  [key: string]: GenericSettingsValue | GenericSettingsValue[];
};

export type SettingsAutoUpdater = {
  when: GenericSettingsValue | GenericSettingsValue[];
  target: string;
  value: GenericSettingsValue
}

export interface SettingsSchema {
  readOnly: boolean;
  groups: Array<SettingsGroup>;
}

export interface SettingsGroup {
  name: string;
  label: string;
  icon?: string;
  items: Array<SettingsItem>;
  dependsOn?: SettingsDependency;
  dependsOnPermission?: string|string[];
}

export type SettingsItem =
  | SettingsTextItem
  | SettingsRangeItem
  | SettingsToggleItem
  | SettingsSelectItem
  | SettingsSubheadItem
  | SettingsCustomItem;

export interface SettingsCustomItem {
  type: 'custom';
  name?: string;
  label?: string;
  componentName?: string;
  dependsOn?: string;
  dependsOnPermission?: string|string[];
  updates?: SettingsAutoUpdater;
}
export interface SettingsSubheadItem {
  type: 'subhead';
  name?: string;
  label: string;
  dependsOn?: any;
  dependsOnPermission?: string|string[];
  updates?: SettingsAutoUpdater;
}
export interface SettingsTextItem {
  type: 'text';
  name: string;
  label: string;
  required?: boolean;
  placeholder?: string;
  dependsOn?: SettingsDependency;
  dependsOnPermission?: string|string[];
  updates?: SettingsAutoUpdater;
}

export interface SettingsToggleItem {
  type: 'toggle';
  name: string;
  label: string;
  dependsOn?: SettingsDependency;
  dependsOnPermission?: string|string[];
  updates?: SettingsAutoUpdater;
}

export interface SettingsRangeItem {
  type: 'range';
  name: string;
  label: string;
  min: number;
  max: number;
  step?: number;
  variation?: 'milliseconds';
  description?: string;
  dependsOn?: SettingsDependency;
  dependsOnPermission?: string|string[];
  updates?: SettingsAutoUpdater;
}

export interface SettingsSelectItem {
  type: 'select';
  name: string;
  label: string;
  options: Array<SettingsOption>;
  description?: string;
  dependsOn?: SettingsDependency;
  dependsOnPermission?: string|string[];
  updates?: SettingsAutoUpdater;
}

export type SettingsOption =
  | string
  | SettingsRangeOption
  | SettingsSingleOption;

export interface SettingsRangeOption {
  min: number;
  max: number;
  step?: number;
}

export interface SettingsSingleOption {
  label: string;
  value: string | number;
  description?: string;
  hide?: boolean;
}

export interface GenericSettingsContainer {
  [key: string]: GenericSettingsGroup;
}

export interface GenericSettingsGroup {
  [key: string]: GenericSettingsValue;
}

export type GenericSettingsValue = number | boolean | string;

export enum IncidentTypeLabels {
  FacialRecognition = <any> 'Facial Recognition',
  LicensePlateRecognition = <any> 'License Recognition',
  LicensePlateRecognitionProfileMatch = <any> 'License Profile Match',
  Motion = <any> 'Motion Detection',
  MediaCaptured = <any> 'Media Captured',
  BatteryLow = <any> 'Battery Low',
  BatteryCritical = <any> 'Battery Critical',
  StorageLow = <any> 'Storage Low',
  StorageCritical = <any> 'Storage Critical',
  WeakWifiSignal = <any> 'Weak Wi-fi',
  WeakWiFiSignal = <any> 'Weak Wi-fi',
  WeakCellularSignal = <any> 'Weak Cellular',
  CameraOnline = <any> 'Camera Online'
}

export type ImageProps = {
  src: string;
  width?: number;
  height?: number;
  alt?: string;
  className?: string;
  onError?: () => void;
};
