import { API } from './lib/api';
import {
  CollectionChildrenGetResponseData,
  DashboardGetResponseData,
  CollectionParentGetResponseData,
} from './isc-api';

export const DashboardAPI = {
  getDashboard: async () => {
    const queryStringParameters = {
      pinLimit: 30
    };

    return await API.get<DashboardGetResponseData>(
      '/dashboard',
      { queryStringParameters }
    )
  },

  getParent: (cameraCollectionId: string) =>
    API.get<CollectionParentGetResponseData>(
      `/collections/parent/${cameraCollectionId}`
    ),

  getCollectionChildren: async (cameraCollectionId: string) => {
    const queryStringParameters = {
      cameraLimit: 100,
      collectionLimit: 100,
    };

    return await API.get<CollectionChildrenGetResponseData>(
      `/dashboard/collections/children/${cameraCollectionId}/`,
      { queryStringParameters }
    );
  },
};
