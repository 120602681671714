import React from 'react'
import { PaymentFormComponentProps } from './payment-form-wrapper'
import { Grid, TextField, MenuItem } from '@material-ui/core'
import { PaymentMethodInput } from './payment-method-input'

export const BankFormComponent = (props: PaymentFormComponentProps) => {

  const input = (label: string, id: string) =>
    <PaymentMethodInput
      label={label}
      id={id}
      focused={props.focused === id}
      error={props.errors[id]}
    />

  return <Grid container spacing={1}>
    <Grid item xs={12} md={7}>{input('Account Number', 'account_number')}</Grid>
    <Grid item xs={12} md={5}>{input('Routing Number', 'routing_number')}</Grid>
    <Grid item xs={12} md={5}>
      <TextField
        select
        inputProps={{"pl-input": "account_type"}}
        fullWidth
        error={props.errors['account_type']}
        label='Account Type'
      >
        <MenuItem value='checking'>Checking</MenuItem>
        <MenuItem value='savings'>Savings</MenuItem>
      </TextField>
    </Grid>
    <Grid item xs={12} md={7}>
      <TextField
        inputProps={{"pl_input": "account_holder"}}
        fullWidth
        error={props.errors['account_holder']}
        label='Account Holder'
      />
    </Grid>
  </Grid>
}