import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Typography, Button } from '@material-ui/core';
import { People } from '@material-ui/icons';
import { CameraCollection as CameraCollectionAPI } from '../../api/isc-api';
import { useStyles } from './breadcrumb-toolbar-styles';
import {hasPermission, UserPermissions} from "../../access";

type BreadcrumbToolbarProps = {
  currentCollection: CameraCollectionAPI;
  parentCollection?: CameraCollectionAPI;
};

export const BreadcrumbToolbar = ({
  parentCollection,
  currentCollection,
}: BreadcrumbToolbarProps) => {
  const classes = useStyles();
  const parentLink = parentCollection
    ? `/collection/${parentCollection.cameraCollectionId}`
    : '/';
  const parentLinkText = parentCollection
    ? parentCollection.cameraCollectionName
    : ' All Cameras and Collections';

  return (
    <Grid container className={classes.collectionHeader}>
      <Grid item xs={12} sm={8}>
        <Typography className={classes.headerParentCollectionName}>
          <Link to={parentLink}>
            &lt;
            {parentLinkText}
          </Link>
        </Typography>
        <Typography variant="h2" className={classes.headerCollectionName}>
          {currentCollection.cameraCollectionName}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={4} className={classes.manageContainer}>
        {hasPermission(UserPermissions.CanManageCameras) ?
          <Link
            className={classes.manageLink}
            to={`/collection/${currentCollection.cameraCollectionId}/settings`}
          >
            <Button
              size="small"
              variant="outlined"
              disableElevation
              startIcon={<People/>}
            >
              Manage
            </Button>
          </Link>
          : null
        }
      </Grid>
    </Grid>
  );
};
