import {
  Warrant,
  RecognitionSettingsWarrantExtensionBody,
  RecognitionSettingsLprBodyWarrant,
} from '../../../../../../api/isc-api';

const getLastExtension = (warrant: Warrant) =>
  warrant.extensions[warrant.extensions.length - 1];

export const resolveExpirationDate = (warrant: Warrant) => {
  if (warrant?.extensions?.length > 0) {
    return (
      (getLastExtension(warrant).expirationDate
        .dateTimeLocal as unknown as string) || ''
    );
  }
  return (warrant?.expirationDate?.dateTimeLocal as unknown as string) || '';
};

export const resolveExtendedWarrantNumber = (warrant: Warrant) => {
  if (warrant?.extensions?.length > 0) {
    return getLastExtension(warrant).extensionNumber;
  }
  return warrant?.expirationDate?.dateTimeLocal.toString();
};

export const getWarrantUpdateBody = (
  updatedWarrant: RecognitionSettingsLprBodyWarrant,
  warrantExtension: RecognitionSettingsWarrantExtensionBody
) => {
  return {
    ...updatedWarrant,
    notes: resolveNotes(updatedWarrant?.notes, warrantExtension),
  };
};

export const resolveNotes = (
  notes: string,
  warrantExtension: RecognitionSettingsWarrantExtensionBody
) => {
  if (notes && warrantExtension?.notes) {
    return notes.slice(-1).search(/\w/) === -1
      ? `${notes} ${warrantExtension.notes}`
      : `${notes}. ${warrantExtension.notes}`;
  }

  return notes || warrantExtension?.notes || '';
};

// export const combineNotes = (
//   warrant: Warrant
// ) => {
//   const notes = [warrant.notes];

//   if (warrant?.extensions?.length > 0) {
//     console.log(...warrant.extensions.map(ext => ext.notes))
//     notes.push(...warrant.extensions.map(ext => ext.notes));
//   }

//   console.log('notes');
//   console.log(notes);

//   return (
//     notes
//       // Adds a '.' period before joining notes.
//       .map(n => (n.slice(-1).search(/\w/) === -1 ? n : `${n}.`))
//       .join(' ')
//   );
// };
