import React, { useEffect, useRef } from 'react';
import { ListItem, Divider } from '@material-ui/core';
import { FixedSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import AutoSizer from 'react-virtualized-auto-sizer';
import { ProgressBar } from '../../components/progress-bar';
import { withAdminAccountCheck } from '../../access/withPermission';
import { useStyles } from './infinite-list-box-styles';

export type ListBoxProps = {
  header?: string | React.ReactNode;
  renderItem?: (
    alert: any,
    isItemLoaded: boolean,
    style: object,
    index: 1
  ) => React.ReactNode;
  items: any[];
  emptyText: string;
  scrollToItem: any;
  hasNextPage: boolean;
  handleScroll?: ({
    scrollDirection,
    scrollOffset,
    scrollUpdateWasRequested,
  }: any) => void;
  isNextPageLoading: boolean;
  loadNextPage: (startIndex: number, stopIndex: number) => Promise<void>;
  itemSize?: number;
  headerSize?: number;
};

const ListBoxComponent = ({
  header,
  renderItem,
  items,
  emptyText,
  scrollToItem,
  hasNextPage,
  isNextPageLoading,
  handleScroll,
  loadNextPage,
  itemSize = 50,
  headerSize = 50,
}: ListBoxProps) => {
  const classes = useStyles();

  const virtualLoaderRef = useRef(null);

  useEffect(() => {
    if (!!scrollToItem) {
      virtualLoaderRef.current._listRef.scrollTo(scrollToItem);
    }
  }, [scrollToItem]);

  // If there are more items to be loaded then add an extra row to hold a loading indicator.
  const itemCount = hasNextPage ? items.length + 1 : items.length;

  // Every row is loaded except for our loading indicator row.
  const isItemLoaded = (index: number) => {
    return !hasNextPage || index < items.length;
  };

  const Item = ({ index, style }) => {
    // Show progress bar on the last item
    if (!isItemLoaded(index)) {
      return (
        <ListItem style={style}>
          <ProgressBar className={classes.progressBar} delay={50} />
        </ListItem>
      );
    }
    // Otherwise just render the list item
    return renderItem(items[index], isItemLoaded(index), style, index);
  };

  const getList = (width: number, _height: number) => {
    // Subtract header from list height
    const height = _height - headerSize - 1;

    return (
      <InfiniteLoader
        ref={virtualLoaderRef}
        isItemLoaded={isItemLoaded}
        itemCount={itemCount}
        loadMoreItems={loadNextPage}
      >
        {({ onItemsRendered, ref }) => (
          <List
            itemCount={itemCount}
            itemSize={itemSize}
            onScroll={handleScroll}
            onItemsRendered={onItemsRendered}
            ref={ref}
            width={width}
            height={height}
          >
            {Item}
          </List>
        )}
      </InfiniteLoader>
    );
  };

  const isEmpty = !hasNextPage && !isNextPageLoading && items.length === 0;

  const getEmpty = () => <ListItem>{emptyText}</ListItem>;

  const getHeader = () => {
    if (typeof header === 'string') {
      return (
        <ListItem>
          <div className={classes.listHeader}>{header}</div>
        </ListItem>
      );
    }

    return header;
  };

  return (
    <AutoSizer>
      {({ width, height }) => (
        <div className={classes.listBox} style={{ width, height }}>
          {getHeader()}
          <Divider />
          {isEmpty ? getEmpty() : getList(width, height)}
        </div>
      )}
    </AutoSizer>
  );
};

export const InfiniteListBox = ListBoxComponent;
