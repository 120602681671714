import React from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Radio,
  FormControlLabel,
  RadioGroup,
  Divider,
} from '@material-ui/core';
import { PageProps } from '../../types';
import qs from 'query-string';
import { useApi, useApiUpdate } from '../../../hooks';
import billing from '../../../api/billing';
import { useHistory } from 'react-router';
import { PaymentCollection } from '../../../components/payment-form/payment-collection';

export const BillingActivatePage = (props: PageProps) => {
  const selectedIds = qs
    .parse(props.location.search)
    .cameras.toString()
    .split(',');

  const [cameras] = useApi(() =>
    billing()
      .newCameras()
      .then(r => r.cameras)
  );

  const [customer] = useApi(() =>
    billing()
      .customer()
      .then(r => r.customer)
  );

  const ready = Boolean(cameras) && Boolean(customer);

  const selectedCameras = cameras
    ? cameras.filter(c => selectedIds.includes(c.cameraId))
    : null;

  const [term, setTerm] = React.useState('yearly');

  const [pricing] = useApi(() => billing().pricing());

  const monthlyPrice = pricing ? pricing.monthly : null;
  const yearlyPrice = pricing ? pricing.yearly : null;
  const price = term === 'monthly' ? monthlyPrice : yearlyPrice;

  const history = useHistory();

  const [doSubmitSubscription, isWorking] = useApiUpdate<string[], string>(
    async (cameraIds) => {
      const api = billing();
      const invoiceId = await api.subscribe({
        cameraIds: cameraIds,
        subscriptionType: term,
      });
      await api.payInvoice(invoiceId);
      return invoiceId;
    },
    invoiceId => history.push('/billing/invoice/' + invoiceId)
  );

  if (!ready) {
    return null;
  }

  return (
    <Grid container justify="center" spacing={2}>
      <Grid item xs={12} md={8}>
        <Card>
          <CardHeader title="New service subscription" subheader="" />
          <CardContent>
            <RadioGroup value={term} onChange={(_, value) => setTerm(value)}>
              <FormControlLabel
                value="yearly"
                control={<Radio />}
                label={`Yearly ($${yearlyPrice} per camera per year)`}
              />
              <FormControlLabel
                value="monthly"
                control={<Radio />}
                label={`Monthly ($${monthlyPrice} per camera per month)`}
              />
            </RadioGroup>
            <br />
            <br />
            <Grid container spacing={1}>
              {selectedCameras.map(c => (
                <React.Fragment key={c.cameraId}>
                  <Grid item xs={10}>
                    One {term === 'monthly' ? 'month' : 'year'} subscription for{' '}
                    {c.imei}
                  </Grid>
                  <Grid item xs={2} alignContent="flex-end">
                    ${price}
                  </Grid>
                </React.Fragment>
              ))}
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={10}>
                TOTAL
              </Grid>
              <Grid item xs={2} alignContent="flex-end">
                ${price * selectedCameras.length}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={8}>
        <PaymentCollection
          customer={customer}
          onSubmit={() =>
            doSubmitSubscription(selectedCameras.map(c => c.cameraId))
          }
          disabled={isWorking}
        />
      </Grid>
    </Grid>
  );
};
