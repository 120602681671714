import { makeStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles(theme => ({
  card: {
    boxShadow: 'none',
  },
  cardContent: {
    padding: '0',
  },
  cardActions: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  listItem: {
    padding: 0,
    display: 'block',
  },
}));
