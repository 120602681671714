import * as React from 'react'

import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Switch from '@material-ui/core/Switch'
import { makeStyles } from '@material-ui/core/styles'
import {SettingsToggleItem, GenericSettingsValue, SettingsAutoUpdater} from '../../schema';
import { ItemProps } from './types';

interface ToggleProps extends ItemProps {
  item: SettingsToggleItem;
  onValueChanges: (value: GenericSettingsValue, updates?: SettingsAutoUpdater) => void;
}

const useStyles = makeStyles(() => ({
  valueDisplay: {
    fontSize: "1.25rem",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 700,
    lineHeight: "1.6",
    letterSpacing: "0.0075em"
  }
}))

export const Toggle = (props: ToggleProps) => {
  const classes  = useStyles(props);

  const handleClick = () => {
    props.onValueChanges(!props.value, props.updates)
  }

  return (
    <ListItem button onClick={handleClick}>
      <ListItemText
        primary={props.item.label}
        primaryTypographyProps={{ variant: 'overline' }}
        secondary={props.value ? 'Enabled' : 'Disabled'}
        secondaryTypographyProps={{ className: classes.valueDisplay, color: 'primary' }}
      />
      <ListItemSecondaryAction>
        <Switch
          onClick={handleClick}
          edge="end"
          checked={Boolean(props.value)}
        />
      </ListItemSecondaryAction>
    </ListItem>
  )
}
