import React, {useContext} from 'react';

import List from '@material-ui/core/List';
import {
  Billing,
  CamerasPortal,
  HelpCircle,
  Logout,
  Settings,
  UserPortal,
  Users,
} from '../../../../components/mui-icons';

import { hasPermission, UserPermissions, canManageUsersOrGroups } from '../../../../access';
import { SideMenuItem } from './SideMenuItem';
import { AuthOrganizationsGetResponseData } from '../../../../api/isc-api';
import { useStyles } from './useStyles';
import ChangeOrganizations from "../../../../components/mui-icons/ChangeOrganizations";
import AppContext, {AppContextType} from "../../../../context";

type MobileMenuProps = {
  organization: AuthOrganizationsGetResponseData;
  onLogout: () => void;
  setOpen: (value: React.SetStateAction<boolean>) => void;
};

export const MobileMenu = ({
  organization,
  onLogout,
  setOpen,
}: MobileMenuProps) => {
  const classes = useStyles();
  const context = useContext<AppContextType>(AppContext);

  if (!organization) {
    return null;
  }

  const changeOrganizations = () => {
    context.organizationId = '';
  }

  return (
    <List>
      <SideMenuItem
        href="/"
        icon={<CamerasPortal />}
        primary="Cameras"
        classes={classes.navLink}
        setOpen={setOpen}
      />
      <SideMenuItem
        href="/users"
        icon={<Users />}
        primary="Users"
        classes={classes.navLink}
        setOpen={setOpen}
        hide={!canManageUsersOrGroups()}
      />

      <SideMenuItem
        href="/organization-settings"
        icon={<Settings />}
        primary="Settings"
        classes={classes.navLink}
        setOpen={setOpen}
        hide={!hasPermission(UserPermissions.CanManageOrganization)}
      />

      {/* <SideMenuItem
        href="/billing"
        icon={<Billing />}
        primary="Billing"
        classes={classes.navLink}
        setOpen={setOpen}
        hide={!organization.organizationFeatures.billing.isEnabled}
      /> */}

      <SideMenuItem
        href="/profile"
        icon={<UserPortal className={classes.profileIcon} />}
        primary={organization.organizationName}
        classes={classes.profile}
        setOpen={setOpen}
      />

      <SideMenuItem
        href="/help"
        icon={<HelpCircle />}
        primary="Help"
        classes={classes.navLink}
        setOpen={setOpen}
      />

      {context.organizations?.length > 1 ?
        <SideMenuItem
          href="/login"
          icon={<ChangeOrganizations />}
          primary="Change Organizations"
          classes={classes.navLink}
          onClick={changeOrganizations}
          setOpen={setOpen}
        />
        : null
      }

      <SideMenuItem
        icon={<Logout />}
        primary={'Log out'}
        onClick={onLogout}
        classes={classes.navLink}
        setOpen={setOpen}
      />
    </List>
  );
};
