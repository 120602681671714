import { makeStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme) => ({
  options: {
    paddingLeft: theme.spacing(4),
  },
  fileUpload: {
    height: "150px",
    minHeight: "0",
    width: "300px",
    border: "2px dashed #979797",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  fileUploadText: {
    padding: theme.spacing(2),
    fontSize: "16px",
    margin: "0"
  },
  trainingImagesContainer: {
    display: "flex",
    // TODO: update to use theme.spacing(3) once the extra paper component is removed 
    padding: "25px",
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
      alignItems: "center"
    }
  },
  dropZoneContainer: {
    marginRight: "24px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    [theme.breakpoints.down('sm')]: {
      marginRight: "0",
      paddingBottom: "16px"
    }
  },
  imagesContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
    gap: "24px",
    alignItems: "center",
    justifyItems: "center",
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: "1fr 1fr 1fr 1fr"
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: "1fr 1fr"
    }
  },
  helpContainer: {
    display: "flex",
    marginTop: "8px",
  },
  helpLabel: {
    margin: "0",
    cursor: "pointer",
  },
  noSubjectMessage: {
    // TODO: update to use theme.spacing(3) once the extra paper component is removed 
    padding: "25px"
  }
}));
