import React, { useState, useEffect } from 'react';
import { CamerasAPI } from '../../api/cameras';
import {
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Badge,
} from '@material-ui/core';
import { MediaImage, CameraMediaLogGetResponseData } from '../../api/isc-api';
import { InfiniteListBox } from './infinite-list-box';
import { ItemText } from './item-text';
import { useStyles } from './camera-details-styles';
import { useAppContext } from '../app/useAppContext';
import { Image } from '../../components/image';
import { getMediaThumbnailSize } from './helpers';
import { FilterList } from '@material-ui/icons';
import { AlertFilter } from './alert-filter';
import { useTrackInfiniteListOffset } from './useTrackOffset';
type MediaLogProps = {
  limit: number;
  cameraId: string;
};

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

const MediaLogComponent = ({ limit, cameraId }: MediaLogProps) => {
  const classes = useStyles();
  const context = useAppContext();

  const [showFilter, setShowFilter] = useState(false);

  let mediaLog: CameraMediaLogGetResponseData;
  type MediaLogProps = {
    limit: number;
    start: number;
    startDate?: string;
    endDate?: string;
    timeZone?: string;
  };
  const handleApi = async (body: MediaLogProps) => {
    try {
      mediaLog = await CamerasAPI.getMediaLog(cameraId, body);
      return mediaLog;
    } catch (err) {
      context.onAlert(
        `Unable to load alerts for camera is ${cameraId}`,
        'error'
      );
    }
  };

  const {
    items,
    hasNextPage,
    isNextPageLoading,
    loadNextPage,
    handleScroll,
    scrollToItem,
    handleApplyFilters,
    filters,
  } = useTrackInfiniteListOffset({
    cameraId,
    session: 'mediaLog',
    limit,
    handleApi,
  });

  const getCameraThumbnail = (media: MediaImage) => {
    if (!media.url) {
      return null;
    }

    return (
      <ListItemAvatar className={classes.thumbnailWrapper}>
        <Image
          className={classes.thumbnailMedia}
          src={media.url}
          width={getMediaThumbnailSize('width')}
          height={getMediaThumbnailSize('height')}
        />
      </ListItemAvatar>
    );
  };

  const itemContents = (media: MediaImage) => {
    return (
      <>
        {getCameraThumbnail(media)}
        <ItemText
          dateTime={media.date?.dateTimeLocal.toString()}
          timeBold={true}
          hasSecondaryAction={false}
        />
      </>
    );
  };

  const renderItem = (
    media: any,
    isItemLoaded: boolean,
    style: object,
    index: number
  ) => {
    if (!isItemLoaded) {
      return <ListItem style={style}>Loading...</ListItem>;
    }

    return (
      <ListItemLink style={style} href={`/media/${media.mediaId}`}>
        {itemContents(media)}
      </ListItemLink>
    );
  };

  const header = (
    <>
      <ListItem className={classes.listHeader}>
        <ListItemText
          primaryTypographyProps={{
            className: classes.listHeaderText,
            color: 'primary',
          }}
        >
          Camera Media
        </ListItemText>
        <IconButton edge="end" onClick={() => setShowFilter(!showFilter)}>
          <Badge
            badgeContent={
              Object.keys(filters) ? Object.keys(filters).length : null
            }
            color="primary"
          >
            {' '}
            <FilterList />
          </Badge>{' '}
        </IconButton>
      </ListItem>
      <AlertFilter
        menuType={'media'}
        open={showFilter}
        handleClose={() => setShowFilter(!showFilter)}
        filters={filters}
        handleSubmit={handleApplyFilters}
      />
    </>
  );

  const listProps = {
    header,
    renderItem,
    emptyText: 'No Alerts Found',
    items,
    hasNextPage,
    isNextPageLoading,
    loadNextPage,
    handleScroll,
    scrollToItem,
    itemSize: getMediaThumbnailSize('containerHeight'),
    headerSize: 75,
    limit,
  };

  return <InfiniteListBox {...listProps} />;
};

export const MediaLog = MediaLogComponent;
