import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
  profileList: {
    paddingLeft: theme.spacing(4),
  },
  profileListActive: {
    fontWeight: 'bold',
  },
  multiSelectWrapper: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    "& .MuiInputBase-root": {
      margin: '-2rem -1.5rem -0.5rem',
      padding: '2rem 1.5rem 0.5rem',
      transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      "&:hover": {
        backgroundColor: 'rgba(0, 0, 0, 0.04)'
      },
    },
  },
  multiSelect: {
    width: '100%',
  },
  multiSelectLabel: {
    marginTop: '0.5em',
    textTransform: 'uppercase',
    letterSpacing: '0.5px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
  },
  secondaryAction: {
    pointerEvents: 'none'
  },
  multiSelectInput: {

    "& .MuiSelect-select.MuiSelect-select": {
      fontWeight: 'bold',
      textTransform: 'uppercase',
      fontSize: '1.25em',
      margin: '-2rem -3rem -0.5rem -1.5rem',
      padding: '2.5rem 3rem 0.5rem 1.5rem',
    },
    "& .MuiSvgIcon-root": {
      position: 'relative',
      top: '-8px',
      right: '-8px',
      pointerEvents: 'none'
    },
    "& .MuiSelect-icon": {
      display: 'none'
    },
    "& .MuiSelect-select:focus": {
      background: 'transparent'
    }
  },
  checkboxListItem: {
    "& .MuiListItemText-root, & .MuiCheckbox-root": {
      color: theme.palette.common.black
    },
  }
}));
