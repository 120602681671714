import React, { useEffect, useState, useContext } from 'react';
import { Button, Typography } from '@material-ui/core';
import { CheckCircleOutline, Help, PermMedia } from '@material-ui/icons';
import { ConfirmDialogModal } from '../../components/confirm-dialog-modal';
import { RecognitionAPI } from '../../api/recognition';
import AppContext from '../../context';
import { useStyles } from './details-styles';
import { MediaItem } from './types';
import {
  SharedItemGetResponseDataSharedMedia,
  Incident,
} from '../../api/isc-api';
import { DetailsLocation } from './details-location';
import { DetailsDate } from './details-date';
import IncidentTypeEnum = Incident.IncidentTypeEnum;
import { IncidentTypeLabels } from '../../schema';

type DetailProps = {
  mediaOrganization: string;
  hasProfile: boolean;
  media: MediaItem;
  downloadMedia: MediaItem;
  incidentType: IncidentTypeEnum;
  addedImages: string[];
  sharedMedia: SharedItemGetResponseDataSharedMedia;
  subjectId: string;
  warrantNumber: string;
  name: string;
  score: string | number;
  onSuccessfulAdd: (mediaId: string) => void;
};

export const Details = ({
  mediaOrganization,
  hasProfile,
  media,
  downloadMedia,
  incidentType,
  addedImages,
  sharedMedia,
  subjectId,
  warrantNumber,
  name,
  score,
  onSuccessfulAdd,
}: DetailProps) => {
  const classes = useStyles();
  const [isHelpOpen, setIsHelpOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [canAddToProfile, setCanAddToProfile] = useState(
    mediaOrganization && hasProfile && media?.type === 'Image'
  );
  const [isAddedToProfile, setIsAddedToProfile] = useState(
    addedImages.includes(sharedMedia?.detailImage?.mediaId)
  );
  const context = useContext(AppContext);

  useEffect(() => {
    setCanAddToProfile(
      context.organizationId === mediaOrganization &&
        hasProfile &&
        media?.type === 'Image'
    );
    setIsAddedToProfile(
      addedImages.includes(sharedMedia?.detailImage?.mediaId)
    );
  }, [hasProfile, media, sharedMedia]);

  const handleAddImageToProfile = async () => {
    setIsLoading(true);
    try {
      // TODO: confirm that this media field is correct
      const response: any = await RecognitionAPI.addMediaImage(subjectId, {
        mediaImageId: sharedMedia.detailImage.mediaId,
      });
      if (response) {
        context.onAlert('The subject has been updated', 'success');
        onSuccessfulAdd(sharedMedia.detailImage.mediaId);
      } else {
        throw new Error();
      }
    } catch (e) {
      console.log(e);
      context.onAlert(
        'Sorry, there was a problem trying to add this image to the recognition profile.',
        'error'
      );
    }
    setIsLoading(false);
  };

  const getDetailPanelTitle = () => {
    return incidentType === IncidentTypeEnum.MediaCaptured
      ? 'Media Details'
      : 'Alert Details';
  };

  const getIncidentLabel = () => {
    return IncidentTypeLabels[incidentType] ?? 'Unknown Alert Type';
  };

  const getMediaLocation = sharedMedia => {
    let hasLocation = false;
    let location = null;

    if (sharedMedia.location?.coordinates) {
      hasLocation = true;
      location = sharedMedia.location;
    } else if (sharedMedia.cameraPlacement?.placedCoordinates) {
      hasLocation = true;
      location = {
        coordinates: sharedMedia.cameraPlacement?.placedCoordinates,
      };
    }

    return {
      hasLocation,
      location,
    };
  };

  if (!sharedMedia) return null;
  const hasDate = sharedMedia.date;
  const { hasLocation, location } = getMediaLocation(sharedMedia);

  return (
    <>
      <div className={classes.detailPanelTitle}>
        <Typography variant="h2">{getDetailPanelTitle()}</Typography>
      </div>
      <div>
        {(hasProfile || warrantNumber) && (
          <div className={classes.detailRow}>
            {hasProfile && (
              <div className={classes.detailContent}>
                <Typography
                  variant="body1"
                  className={classes.detailContentHeader}
                >
                  {getIncidentLabel()}
                </Typography>
                <Typography>{name}</Typography>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography className={classes.confidenceScore}>
                    {score}% Confidence
                  </Typography>
                  <Help
                    className={classes.confidenceScoreHelp}
                    fontSize="inherit"
                    onClick={() => setIsHelpOpen(true)}
                  />
                </div>
              </div>
            )}
            {warrantNumber && (
              <div className={classes.detailContent}>
                <Typography
                  variant="body1"
                  className={classes.detailContentHeader}
                >
                  Warrant Number:
                </Typography>
                <Typography>{warrantNumber}</Typography>
              </div>
            )}
          </div>
        )}
        {hasDate && <DetailsDate date={sharedMedia.date} />}
        {hasLocation && <DetailsLocation location={location} />}
      </div>
      <div className={classes.actionContainer}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<PermMedia />}
          href={`${downloadMedia?.url}`}
        >
          Download {media?.type}
        </Button>
        {canAddToProfile && !isAddedToProfile && (
          <Button variant="text" onClick={handleAddImageToProfile}>
            {isLoading ? 'Loading...' : 'Add to Subject Profile'}
          </Button>
        )}
        {canAddToProfile && isAddedToProfile && (
          <div className={classes.subjectAddedMessage}>
            <Typography variant="body1">Added to Subject Profile</Typography>
            <CheckCircleOutline />
          </div>
        )}
      </div>
      <ConfirmDialogModal
        open={isHelpOpen}
        dialogTitle="What is a Confidence Score?"
        message="License Plate Recognition in Spotlight makes use of Artificial Intelligence image recognition models. A confidence score is a number from 0 to 100, indicating how confident the AI model is that the image contains the subject being searched for. Please make note of the confidence number when using this image recognition result, as your organization or jurisdiction may have a minimum required confidence threshold."
        confirmText="Close"
        handleClose={() => setIsHelpOpen(false)}
      />
    </>
  );
};
