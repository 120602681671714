import React from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import ListItem from '@material-ui/core/ListItem';
import LinearProgress from '@material-ui/core/LinearProgress';

import { AddUserStep } from './step';
import { isPhoneValid, transformPhone } from '../../util/validations';
// import { useCreateUser } from './useCreateUser';
import { useExistingUser } from './useExistingUser';
import {
  UserDetailsGetResponseData,
  UserDetailsPostPutBody,
} from '../../api/isc-api';
import { UsersAPI } from '../../api/users';
import { useApiUpdate } from '../../hooks';

type Step2Props = {
  email: string;
  organizationId: string;

  onCancel: () => void;
  onBack: () => void;
  onNext: (user: UserDetailsGetResponseData) => void;
};

export const Step2 = (props: Step2Props) => {
  const [name, setName] = React.useState('');
  const [nameError, setNameError] = React.useState<string>(null);
  const [phone, setPhone] = React.useState('');
  const [phoneError, setPhoneError] = React.useState<string>(null);

  const [existingUser, isLookingUp] = useExistingUser(props.email);
  const [createUser] = useApiUpdate<
    Partial<UserDetailsPostPutBody>,
    UserDetailsGetResponseData | void
  >(
    body => UsersAPI.create(body),
    (newUser: UserDetailsGetResponseData) => {
      props.onNext(newUser);
    }
  );

  const subheaderInfo = (): [string, boolean] => {
    if (isLookingUp) {
      return [`Looking for the user with email ${props.email}`, false];
    }
    if (existingUser) {
      return [
        `User (${existingUser.userDetails.email}) already has access to this organization.`,
        true,
      ];
    }
    return ["Please provide the rest of the user's contact information", false];
  };

  const [subheader, isUserExistsError] = subheaderInfo();

  const handleNext = () => {
    if (existingUser) {
      props.onNext(existingUser);
      return;
    }

    const transformedPhone = transformPhone(phone);
    setPhone(transformedPhone);

    const nameValid = name.trim() !== '';
    const phoneValid = isPhoneValid(transformedPhone);
    setPhoneError(
      phoneValid
        ? null
        : "Please enter user's phone number, including the country code"
    );
    setNameError(nameValid ? null : 'Please enter the name');

    if (nameValid && phoneValid) {
      createUser({
        email: props.email,
        name,
        phone: transformedPhone,
        userGroups: [],
        organizationalRoleIds: [],
      });
    }
  };

  return (
    <AddUserStep
      subtitle={subheader}
      isError={isUserExistsError}
      onCancel={props.onCancel}
      onSubmit={handleNext}
      actions={
        <React.Fragment>
          <Button onClick={props.onBack}>Back</Button>
          <Button type="submit" color="primary" disabled={isUserExistsError}>
            Next
          </Button>
        </React.Fragment>
      }
    >
      {isLookingUp && (
        <LinearProgress style={{ marginTop: '40px', marginBottom: '40px' }} />
      )}

      {isUserExistsError && (
        <Button
          variant="contained"
          color="primary"
          disableElevation
          href={`/user/${existingUser.userDetails.userId}`}
        >
          View User Profile
        </Button>
      )}

      {!isLookingUp && !isUserExistsError && (
        <React.Fragment>
          <ListItem>
            <TextField
              label="Email"
              fullWidth
              autoFocus
              value={props.email}
              disabled
            />
          </ListItem>
          <ListItem>
            <TextField
              label="Name"
              fullWidth
              autoFocus
              value={name}
              onChange={ev => setName(ev.target.value)}
              disabled={Boolean(existingUser)}
              error={Boolean(nameError)}
              helperText={nameError}
            />
          </ListItem>
          <ListItem>
            <TextField
              label="Phone"
              fullWidth
              value={phone}
              onBlur={() => {
                setPhone(transformPhone(phone));
              }}
              onChange={ev => setPhone(ev.target.value)}
              disabled={Boolean(existingUser)}
              error={Boolean(phoneError)}
              helperText={phoneError}
            />
          </ListItem>
        </React.Fragment>
      )}
    </AddUserStep>
  );
};
