import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { DashboardSearchBox } from '../../../components/dashboard-search-box';
import { UsersAPI } from '../../../api/users';
import { useApi } from '../../../hooks';
import {
  UsersListGetResponseDataUsers,
  UserGroupUserItem,
  UserDetails,
} from '../../../api/isc-api';
import { ProgressBar } from '../../../components/progress-bar';
import { useStyles } from './add-user-modal-styles';
import { useAddItemModal } from "../../../components/add-camera-modal/useAddItemModal";

type AddUserModalProps = {
  open: boolean;
  userGroupId: string;
  existingUsers: UserDetails[];
  handleClose: () => void;
  handleSave: (users: UserDetails[]) => void;
};

export default (props: AddUserModalProps) => {
  const classes = useStyles();
  const [filteredUsers, setFilteredUsers] = useState<UsersListGetResponseDataUsers[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<UserGroupUserItem[]>([]);

  const {
    chipData,
    filter,
    reset,
    updateChipData,
    handleFilterChange,
    isAllSelected,
    setIsAllSelected,
    setAllSelected,
    handleChangeItem,
    handleDeleteItem,
    handleFormSubmit,
    isItemChecked
  } = useAddItemModal({
    handleClose: props.handleClose
  });

  const [data, , isLoading] = useApi(() => UsersAPI.list(props.userGroupId));

  const userExcludeFilter = u =>
    !props.existingUsers.find(eu => u.userId === eu.userId);

  const filterExistingUsers = () => {
    const usersNotAdded = data.filter(userExcludeFilter);
    setFilteredUsers(usersNotAdded);
  };

  useEffect(() => {
    if (data && !filter) {
      filterExistingUsers();
    }
  }, [data, props.existingUsers]);

  useEffect(() => {
    if (!data) return;

    if (filter) {
      const query = new RegExp(filter, "igm")
      const filterUser = (el: any) => {
        return el.name.match(query);
      };

      setFilteredUsers(filteredUsers.filter(filterUser));
    } else {
      filterExistingUsers();
    }
  }, [filter]);

  useEffect(() => {
    updateChipData(selectedUsers, 'userId', 'name')
    setIsAllSelected(selectedUsers.length === filteredUsers.length && selectedUsers.length > 0);
  }, [selectedUsers]);

  const updateUserAdminMapping = (
    user: UserGroupUserItem,
    isAdmin: boolean
  ) => {
    const tmpUsers = JSON.parse(JSON.stringify(selectedUsers));
    const selectedUser = tmpUsers.find(u => u.userId === user.userId);
    if (selectedUser) {
      selectedUser.isGroupAdmin = isAdmin;
    }
    setSelectedUsers(tmpUsers);
  };

  const customHandleFormSubmit = () => {
    props.handleSave(selectedUsers);
    reset(setSelectedUsers);
  };

  const getFilteredUserObjects = () => {
    const userObjects = filteredUsers.map(user => {
      return {
        name: user.name,
        userId: user.userId,
        isGroupAdmin: false
      }
    });

    return userObjects;
  }

  const userItems = () => (
    <Table stickyHeader>
      <TableHead>
        <TableRow>
          <TableCell className={classes.headerCell}>
            Select
            <div className={classes.selectAll}>
              {isAllSelected ?
                <a href="#" onClick={e => setAllSelected(e, false, setSelectedUsers)}>(Deselect
                  All)</a> :
                <a href="#" onClick={e => setAllSelected(e, true, setSelectedUsers, getFilteredUserObjects())}>(Select
                  All)</a>
              }
            </div>
          </TableCell>
          <TableCell className={classes.headerCell}>User</TableCell>
          <TableCell className={classes.headerCell}>Set as Admin</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {filteredUsers.length ? (
          filteredUsers.map((user: UserDetails) => (
            <TableRow
              key={user.userId}
              className={classes.item}
              onClick={() => handleChangeItem(
                !isItemChecked(user, selectedUsers, ['userId']),
                user,
                selectedUsers,
                setSelectedUsers, ['userId', 'name'],
                (user: UserDetails) => {
                  return {
                    name: user.name,
                    userId: user.userId,
                    isGroupAdmin: false
                  }
                }
              )}
            >
              <TableCell>
                <Checkbox
                  checked={isItemChecked(user, selectedUsers, ['userId'])}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </TableCell>
              <TableCell>
                <Typography>{user.name}</Typography>
              </TableCell>
              <TableCell>
                <Switch
                  disabled={!isItemChecked(user, selectedUsers, ['userId'])}
                  onChange={e => updateUserAdminMapping(user, e.target.checked)}
                  onClick={e => e.stopPropagation()}
                />
              </TableCell>
            </TableRow>
          ))
        ) : (
          <Typography variant="body1" className={classes.noDataText}>
            {data?.length
              ? 'No users match the search parameters.'
              : 'There are no users available to add.'}
          </Typography>
        )}
      </TableBody>
    </Table>
  );

  return (
    <React.Fragment>
      <Dialog
        open={props.open}
        aria-labelledby="form-dialog-title"
        maxWidth={'sm'}
        fullWidth={true}
      >
        <DialogContent className={classes.dialogContent}>
          <Grid container className={classes.panelHeader}>
            <Grid item xs={12} md={8}>
              <Typography variant="h2">Add User</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <DashboardSearchBox
                placeholderText={'Search'}
                value={''}
                onFilterChange={handleFilterChange}
              />
            </Grid>
          </Grid>

          <form action="#" onSubmit={(ev: any) => {
            handleFormSubmit(ev);
          }}>



            <Grid xs={12} md={12} item>
              <Paper component="ul" className={classes.root}>
                {chipData.map(cData => {
                  return (
                    <li key={cData.key}>
                      <Chip
                        size="small"
                        label={cData.label}
                        onDelete={() => {
                          handleDeleteItem(cData, selectedUsers, setSelectedUsers, 'userID', 'name');
                        }}
                      />
                    </li>
                  );
                })}
              </Paper>
            </Grid>

            <Grid item style={{ maxHeight: 500, overflow: 'auto' }}>
              {isLoading ? <ProgressBar /> : userItems()}
            </Grid>

          </form>

        </DialogContent>

        <DialogActions className={classes.actionContainer}>
          <Button
            size="small"
            onClick={() => {
              reset(setSelectedUsers);
              props.handleClose();
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            size="small"
            disabled={!selectedUsers.length}
            onClick={customHandleFormSubmit}
          >
            Save
          </Button>
        </DialogActions>


      </Dialog>
    </React.Fragment>
  );
};
