import {
  UnauthorizedException,
  NotFoundException,
  ForbiddenException,
  ApiException,
  BadRequestException,
  GoneException,
  AmplifyAPIError,
  ErrorModels,
} from './domain';
import {
  BadRequestModel,
  ForbiddenModel,
  GoneModel,
  NotFoundModel,
  UnauthorizedModel,
} from '../isc-api';
import { Auth } from 'aws-amplify';
import { RequestType, GenericAPIResponse } from './types';
import { ENABLE_DEV_CONSOLE } from '../../util/globals';

export interface AuthOptions {
  headers: AuthHeaders;
}

export interface AuthHeaders {
  Authorization: string;
  'x-organization'?: string;
}

export async function getAuthOptions(
  organizationId?: string
): Promise<AuthOptions> {
  let accessToken;

  try {
    const user = await Auth.currentAuthenticatedUser();
    accessToken = await user
      .getSignInUserSession()
      .getAccessToken()
      .getJwtToken();
  } catch (e) {
    throw new UnauthorizedException('');
  }

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  if (organizationId) {
    headers['x-organization'] = organizationId;
  }

  return { headers };
}

export async function processResponse<T>(response: Response): Promise<T> {
  if (response.ok) {
    return response.status !== 204 ? await response.json() : null;
  }

  const logId = response.headers.get('x-error-log-id');
  if (logId) {
    console.log('Error log ID: ', logId);
  }

  switch (response.status) {
    case 400:
      throw new BadRequestException('', await response.json());
    case 401:
      throw new UnauthorizedException('');
    case 403:
      throw new ForbiddenException('');
    case 404:
      throw new NotFoundException('');
    default: {
      const body = await response.text();
      throw new ApiException(response.status, body);
    }
  }
}

export const getAmplifyError = (path: string, error: AmplifyAPIError) => {
  const data = error?.response?.data;

  if (!data) {
    console.error(
      'API Response missing error information, throwing generic 500'
    );
    return new ApiException(500, path, 'No response from server');
  }

  let errorObject;
  switch (data.status) {
    case 400:
      errorObject = new BadRequestException(path, data);
      break;
    case 401:
      errorObject = new UnauthorizedException(path);
      break;
    case 403:
      errorObject = new ForbiddenException(path);
      break;
    case 404:
      errorObject = new NotFoundException(path);
      break;
    case 410:
      errorObject = new GoneException(path);
      break;
    default: {
      errorObject = new ApiException(
        data.status,
        path,
        'Unexpected API Error',
        data.detail
      );
    }
  }

  printError(data, errorObject);
  return errorObject;
};

const printError = (data: ErrorModels, errorObject: ErrorModels) => {
  let message = `Error:
======
Status: ${data.status}
Title: "${data.title}"
Type: "${data.type}"
`;

  if (data?.detail) {
    message += `
Message:
${data.detail}
`;
  }

  console.group('API Error');
  console.log(message);
  console.log('Normalized Error Object:');
  console.log(errorObject);
  console.groupEnd();
};

export async function handleErrorResponse<T>(
  error:
    | BadRequestModel
    | UnauthorizedModel
    | ForbiddenModel
    | NotFoundModel
    | GoneModel
): Promise<T> {
  switch (error.status) {
    case 400:
      throw new BadRequestException('/', error);
    case 401:
      throw new UnauthorizedException('');
    case 403:
      throw new ForbiddenException('');
    case 404:
      throw new NotFoundException('');
    case 410:
      throw new GoneException('');
    default: {
      throw new ApiException(error.status, error.detail);
    }
  }
}

export const printDebug = (
  verb: RequestType,
  path: string,
  response: GenericAPIResponse
) => {
  if (!ENABLE_DEV_CONSOLE) return;
  console.groupCollapsed(`[${verb}] ${path}`);
  console.log(response);
  console.groupEnd();
};