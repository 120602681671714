import React from 'react';
import { CameraBasic, UserGroup } from '../../../api/isc-api';
import { CameraSettingsApiResponse } from '../../../api/cameras';
import { UserGroupPending } from '../item-components/UserGroupsComponent';

type SettingsContextType = {
  camera: CameraBasic;
  fullSettings: CameraSettingsApiResponse;
  updateSettings: (settings: CameraSettingsApiResponse) => void;
  updateCount: number;
  setPendingUserGroups?: (userGroups: UserGroupPending[]) => void;
  pendingUserGroups?: UserGroupPending[];
  setFilteredUserGroups?: (userGroups: UserGroup[]) => void;
  filteredUserGroups?: UserGroup[];
  hasUpdatedSettings: boolean;
  setHasUpdatedSettings: React.Dispatch<React.SetStateAction<boolean>>;
};

export const SettingsContext = React.createContext<SettingsContextType>(null);
