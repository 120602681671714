import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useStyles } from './useStyles';

export const Empty = ({ children }: { children: React.ReactNode }) => {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
      py={8}
    >
      <Typography className={classes.emptyText}>
        No subgroups have been created.
      </Typography>
      {children}
    </Box>
  );
};
