import React from 'react'
import { Payment } from '../../../api/billing'
import { Card, CardHeader, Grid, CardContent } from '@material-ui/core'
import { formatDate } from '../../../formats'

type PaymentCardProps = {
  payment: Payment
}

export const PaymentCard = (props:PaymentCardProps) => {
  return (
    <Card>
      <CardHeader title='Payment Information' />
      <CardContent>
        <Grid container justify='space-between'>
          <Grid item>
            {props.payment.source}
          </Grid>
          <Grid item>
            {formatDate(props.payment.created)}
          </Grid>
          <Grid item>
            ${props.payment.amount}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
