import {
  SettingsGroup,
  SettingsTextItem,
  SettingsSelectItem,
} from '../../../../../schema';
import { usStates } from './select-states';

export const personProfileFields = {
  group: <SettingsGroup>{
    name: 'subject_profile',
    label: 'Subject Profile',
    items: [],
  },
  subjectName: <SettingsTextItem>{
    type: 'text',
    name: 'subject_name',
    label: 'Subject Name',
  },
  subjectLocation: <SettingsSelectItem>{
    type: 'select',
    name: 'state',
    label: 'Subject State',
    options: usStates,
  },
  subjectNotes: <SettingsTextItem>{
    type: 'text',
    name: 'subject_notes',
    label: 'Subject Notes (optional)',
  },
};
