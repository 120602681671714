import React, { useState, useEffect, Suspense } from 'react';
import { CircularProgress, Switch, Typography } from '@material-ui/core';
import { PanelHeader } from '../panel/panel-header';
import { ConfirmDialogModal } from '../confirm-dialog-modal';
import { UserGroup } from '../../api/isc-api';
import { Panel } from '../panel/panel';
import { EmptyFallback } from '../empty-fallback';

const AddUserGroupModal = React.lazy(() => import('./add-modal'));

type UserGroupPanelProps = {
  target?: string;
  userGroups: UserGroup[];
  isLoading: boolean;
  showAdminToggle?: boolean;
  onUpdate: (userGroups: UserGroup[]) => void;
  preContent?: React.ReactNode;
  postListContent?: React.ReactNode;
  excludeGroupIdsFromAdd?: string[];
  updateKey?: number;
};

export const UserGroupPanel = ({
  target = 'user',
  userGroups,
  isLoading,
  showAdminToggle,
  onUpdate,
  preContent = null,
  postListContent = null,
  excludeGroupIdsFromAdd = [],
  updateKey = 0,
}: UserGroupPanelProps) => {
  const [filter, setFilter] = useState('');
  const [filteredUserGroups, setFilteredUserGroups] = useState<UserGroup[]>([]);
  const [updatedUserGroups, setUpdatedUserGroups] = useState<UserGroup[]>([]);
  const [isPendingAddToUserGroup, setIsPendingAddToUserGroup] = useState(false);
  const [isPendingDelete, setIsPendingDelete] = useState(false);
  const [toDelete, setToDelete] = useState<string[]>();

  useEffect(() => {
    if (userGroups) {
      setUpdatedUserGroups(userGroups);
    }
  }, [userGroups]);

  const handleCloseUserGroupModal = () => {
    setIsPendingAddToUserGroup(false);
  };

  const handleUpdateUserGroups = async (userGroups: UserGroup[]) => {
    if (isPendingDelete) {
      handleRemoveUserGroup(toDelete);
    } else {
      handleAddToUserGroup(userGroups);
    }
  };

  const deleteUserGroup = (userGroupId: string) => {
    setToDelete([userGroupId]);
    setIsPendingDelete(true);
  };

  const handleRemoveUserGroup = async (userGroupIdsToDelete: string[]) => {
    if (userGroupIdsToDelete.length > 0) {
      const removeUserGroup = (el: UserGroup) =>
        !userGroupIdsToDelete.some(
          userGroupId => userGroupId === el.userGroupId
        );
      let newUserGroups = updatedUserGroups.filter(removeUserGroup);
      onUpdate(newUserGroups);
    }
    setIsPendingDelete(false);
  };

  const handleAddToUserGroup = async (userGroups: UserGroup[]) => {
    let userGroupsToAdd = [];
    for (let userGroup of userGroups) {
      userGroupsToAdd.push(userGroup);
    }
    let newUserGroups = [...updatedUserGroups, ...userGroupsToAdd];
    onUpdate(newUserGroups);
    setIsPendingAddToUserGroup(false);
  };

  const handleFilterChange = async (filter: string) => {
    setFilter(filter ? filter.toLowerCase() : '');
  };

  useEffect(() => {
    if (updatedUserGroups && !filter) {
      setFilteredUserGroups(updatedUserGroups);
    }
  });

  useEffect(() => {
    const query = new RegExp(filter, "igm")
    const filterUser = (el: any) => {
      return el.name.match(query);
    };
    setFilteredUserGroups(updatedUserGroups.filter(filterUser));
  }, [filter]);

  const handleAdminChange = (userGroupId: string) => {
    const elementIndex = updatedUserGroups.findIndex(
      userGroup => userGroup.userGroupId === userGroupId
    );
    let newUserGroups = [...updatedUserGroups];
    newUserGroups[elementIndex] = {
      ...newUserGroups[elementIndex],
      isGroupAdmin: !newUserGroups[elementIndex].isGroupAdmin,
    };
    onUpdate(newUserGroups);
  };

  const getExcludeGroupIds = () => {
    return excludeGroupIdsFromAdd.concat(
      updatedUserGroups.map(g => g.userGroupId)
    );
  };

  const columnTitles = showAdminToggle ? ['Name', 'Admin', ''] : ['Name', ''];
  const onAddNew =
    filteredUserGroups.length < 100 && (() => setIsPendingAddToUserGroup(true));

  return (
    <>
      <Panel
        emptyComponent={
          <EmptyFallback
            text={`This ${target} does not belong to any groups.`}
            onAction={() => setIsPendingAddToUserGroup(true)}
            buttonText="Add To Groups"
          />
        }
        panelHeader={
          <>
            <PanelHeader
              title="User Groups"
              buttonText="Add"
              placeholderText="Filter user groups"
              onAddNew={onAddNew}
              onFilterChange={
                filteredUserGroups.length || filter ? handleFilterChange : null
              }
            />
            {preContent}
          </>
        }
        columnTitles={columnTitles}
        filteredItems={filteredUserGroups}
        updatedItems={updatedUserGroups}
      >
        {filteredUserGroups.map((userGroup, index) => (
          <Panel.BodyRow key={`${userGroup.userGroupId}-${updateKey}-${index}`}>
            <Panel.BodyCell
              name="tcellName"
              index={index}
              length={filteredUserGroups.length}
            >
              <Typography>{userGroup.name}</Typography>
            </Panel.BodyCell>
            {showAdminToggle && (
              <Panel.BodyCellToggle
                title="Admin"
                index={index}
                length={filteredUserGroups.length}
              >
                <Switch
                  value={userGroup.isGroupAdmin}
                  checked={!!userGroup.isGroupAdmin}
                  name={userGroup.name}
                  onClick={() => handleAdminChange(userGroup.userGroupId)}
                  inputProps={{
                    'aria-label': `${userGroup.name} isAdmin Toggle`,
                  }}
                  disabled={isLoading}
                />
              </Panel.BodyCellToggle>
            )}
            <Panel.BodyCellDelete
              index={index}
              length={filteredUserGroups.length}
              disabled={isLoading}
              onClick={() => deleteUserGroup(userGroup.userGroupId)}
            />
          </Panel.BodyRow>
        ))}
        {postListContent}
      </Panel>
      <Suspense fallback={<CircularProgress />}>
        {isPendingAddToUserGroup && (
          <AddUserGroupModal
            open={isPendingAddToUserGroup}
            handleSave={handleUpdateUserGroups}
            handleClose={handleCloseUserGroupModal}
            existingUserGroupIds={getExcludeGroupIds()}
            showAdminToggle={showAdminToggle}
          />
        )}
      </Suspense>
      <ConfirmDialogModal
        open={isPendingDelete}
        value={isPendingDelete}
        handleClose={handleUpdateUserGroups}
        dialogTitle={`Remove ${target} from User Group`}
        message={`Are you sure you want to remove the ${target} from this user group?`}
        confirmText="Remove"
      />
    </>
  );
};
