import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  cardHeader: {
    [theme.breakpoints.down('xs')]: {
      '& .MuiCardHeader-content': {
        overflow: 'hidden',
      },
      '& .MuiTypography-root': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  },
  tabWrapper: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginBottom: theme.spacing(2.5),
    boxShadow: '0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2)',
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));
