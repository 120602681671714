import React, {
  useContext,
  Fragment,
  useState,
  MouseEvent,
  useEffect,
} from 'react';
import {
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  List,
  ListItem,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { OrganizationSettingsAPI } from '../../../api/organization-settings';
import AppContext from '../../../context';
import { Toggle, Text } from '../../../components/form-fields';
import Divider from '@material-ui/core/Divider';
import isEqual from 'lodash/isEqual';
import { useStyles } from './media-sharing-form-styles';
import {
  OrganizationSettingsSharingTestEmailPutBody,
  OrganizationSettingsSharingPutBody,
  FeatureOrganizationAdminSettings,
} from '../../../api/isc-api';
import { getOrganization } from '../../../access';

/**
 * The sharing feature response objects are typed with & any so we're type hinting using
 * this interface
 */
interface SharingFeatureOrganizationAdminSettings
  extends FeatureOrganizationAdminSettings {
  /**
   * comma separated pattern string to test against, if empty all domains are permitted
   * @type {string}
   * @memberof SharingFeatureOrganizationAdminSettings
   */
  domainPattern?: string;
}

enum EmailValidity {
  Unknown,
  Valid,
  Invalid,
}

export const MediaSharingForm = (props: any) => {
  const { setFormDirtyState } = props;
  const classes = useStyles();

  const context = useContext(AppContext);

  const {
    organizationFeatures: {
      sharing: { organizationAdminSettings },
    },
  } = getOrganization();

  const [form, setForm] = useState<SharingFeatureOrganizationAdminSettings>(
    // Type hint here with proper interface,
    organizationAdminSettings as SharingFeatureOrganizationAdminSettings
  );
  const [testEmail, setTestEmail] = useState('');
  const [checkingEmail, setCheckingEmail] = useState(false);
  const [validEmail, setValidEmail] = useState<EmailValidity>(
    EmailValidity.Unknown
  );
  const [canVerifyEmail, setCanVerifyEmail] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    setCanVerifyEmail(testEmail.length > 0);
    setValidEmail(EmailValidity.Unknown);
  }, [testEmail]);

  const handleVerifyEmailClick = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    doVerifyEmail();
  };

  const doVerifyEmail = async () => {
    setCheckingEmail(true);
    setValidEmail(EmailValidity.Unknown);

    const update: OrganizationSettingsSharingTestEmailPutBody = {
      testEmail,
      domainPattern: form.domainPattern,
    };

    try {
      const response = await OrganizationSettingsAPI.testEmail(update);
      console.log(response);
      setCheckingEmail(false);

      setValidEmail(
        response === undefined ? EmailValidity.Valid : EmailValidity.Invalid
      );
    } catch (e) {
      setValidEmail(EmailValidity.Invalid);
      setCheckingEmail(false);
      context.onAlert(e.detail, 'error');
    }
  };

  const handleEmailInputKeydown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      e.stopPropagation();

      canVerifyEmail && doVerifyEmail();
    }
  };

  const doSave = async (form: OrganizationSettingsSharingPutBody) => {
    try {
      setIsSaving(true);
      await OrganizationSettingsAPI.updateMediaSharing(form);
      setFormDirtyState(false);
      await context.refreshOrgData();
      context.onAlert('Media sharing settings updated.', 'success');
    } catch (e) {
      context.onAlert(e.detail, 'error');
    }
    setIsSaving(false);
  };

  const updateForm = (form: SharingFeatureOrganizationAdminSettings) => {
    setForm(form);

    if (isEqual(form, organizationAdminSettings)) {
      setFormDirtyState(false);
    } else {
      setFormDirtyState(true);
    }
  };

  const handleCancel = () => {
    updateForm(organizationAdminSettings);
    setTestEmail('');
    setIsSaving(false);
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    doSave(form);
  }

  const getForm = () => (
    <>
      <Divider />
      <Text
        group={{
          name: 'media-sharing',
          label: 'Media Sharing',
          items: [],
        }}
        item={{
          label: 'White Listed Domains',
          name: 'white-listed-domains',
          type: 'text',
          placeholder: 'ex: toledopolice.gov,cityoftoledo.gov',
        }}
        value={form.domainPattern}
        onValueChanges={(value: any) =>
          updateForm({ ...form, domainPattern: value })
        }
      />
      <Divider />
      <Grid container spacing={2} justify="center" alignItems="center">
        {validEmail === EmailValidity.Valid && (
          <Grid item xs={12}>
            <Alert severity="success">Test email address is valid!</Alert>
          </Grid>
        )}

        {validEmail === EmailValidity.Invalid && (
          <Grid item xs={12}>
            <Alert severity="error">Test email address is not valid!</Alert>
          </Grid>
        )}

        <Grid item xs={12} sm={8} md={9} lg={10} spacing={2}>
          <Text
            group={{
              name: 'media-sharing',
              label: 'Media Sharing',
              items: [],
            }}
            item={{
              label: 'Test Email',
              name: 'test-email',
              type: 'text',
              placeholder: 'ex: myname@email.com',
            }}
            value={testEmail}
            onValueChanges={(value: any) => setTestEmail(value)}
            onKeyDown={handleEmailInputKeydown}
          />
        </Grid>

        <Grid item xs={12} sm={4} md={3} lg={2} spacing={2}>
          <div className={classes.verifyEmail}>
            <Button
              disabled={!canVerifyEmail}
              color="secondary"
              variant="outlined"
              onClick={handleVerifyEmailClick}
              fullWidth
            >
              {checkingEmail ? 'Testing...' : 'Test Email'}
            </Button>
          </div>
        </Grid>
      </Grid>
    </>
  );

  return (
    <>
      <Card className={classes.card}>
        <form action="" onSubmit={onSubmit}>
          <CardContent className={classes.cardContent}>
            <List dense disablePadding>
              <ListItem className={classes.listItem}>
                {form.isEnabled && (
                  <div className={classes.displayBlock}>
                    <Alert severity="info">
                      Users with the Media Sharing role will now be able to
                      share media and incident alerts with users outside your
                      organization
                    </Alert>
                  </div>
                )}
              </ListItem>
              <Toggle
                group={{
                  name: 'media-sharing',
                  label: 'Media Sharing',
                  items: [],
                }}
                item={{
                  label: 'Allow external sharing',
                  name: 'allow-sharing',
                  type: 'toggle',
                }}
                value={form.isEnabled}
                onValueChanges={(value: any) =>
                  updateForm({ ...form, isEnabled: value })
                }
              />
              {form.isEnabled && getForm()}
            </List>
          </CardContent>
          <Divider />
          <CardActions className={classes.cardActions}>
            <Button type="submit" color="primary">
              {isSaving ? 'Saving...' : 'Save'}
            </Button>
            <Button onClick={handleCancel}>Cancel</Button>
          </CardActions>
        </form>
      </Card>
    </>
  );
};
