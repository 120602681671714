import { Alert, AlertTitle } from '@material-ui/lab';
import React from 'react';
import { Typography, Box, Link } from '@material-ui/core';
import { useStyles } from './settings-panel-styles';
import { SettingsPanelProps } from './settings-panel';
import { SettingsGroup } from '../../../schema';

type SettingsPanelAlertProps = Pick<SettingsPanelProps, 'handleLink'> & {
  errors: SettingsGroup[];
};

export const SettingsPanelAlert = ({
  handleLink,
  errors,
}: SettingsPanelAlertProps) => {
  const classes = useStyles();

  return (
    <Alert severity="warning" className={classes.updatedGroupError}>
      <AlertTitle>Information Needed:</AlertTitle>
      <Typography variant="body1" color="error">
        The following values need to be provided before the settings can be sent
        to the camera:
      </Typography>
      {errors.map(group => (
        <Box className={classes.updatedGroup} key={group.name}>
          <Typography variant="h6" color="primary">
            {group.label}
          </Typography>
          <Box className={classes.updatedItems}>
            {group.items.map(item => (
              <div>
                <Link
                  className={classes.itemLink}
                  variant="body1"
                  key={item.name}
                  onClick={() => handleLink(group.name)}
                >
                  {item.label}
                </Link>
              </div>
            ))}
          </Box>
        </Box>
      ))}
    </Alert>
  );
};
