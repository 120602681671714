import React, { useState } from 'react';
import { Grid, Fade } from '@material-ui/core';
import { match } from 'react-router';
import { PinnedCameras } from './pinned-cameras';
import { CameraCollections } from '../../components/camera-collections';
import { ProgressBar } from '../../components/progress-bar';
import { MyPinnedCamera } from '../../api/isc-api';

type DashboardPageProps = {
  match: match<{
    cameraCollectionId?: string;
  }>;
};

export const DashboardPage = ({ match }: DashboardPageProps) => {
  const { cameraCollectionId } = match.params;
  const [pinnedCameras, setPinnedCameras] = useState<MyPinnedCamera[]>([]);
  const [firstLoad, setIsFirstLoad] = useState(true);

  return (
    <Grid container spacing={2}>
      {pinnedCameras.length > 0 && (
        <Grid item xs={12}>
          <PinnedCameras pinnedCameras={pinnedCameras} />
        </Grid>
      )}
      <Grid item xs={12}>
        {firstLoad && <ProgressBar />}
        <Fade in={!firstLoad}>
          <CameraCollections
            setPinnedCameras={setPinnedCameras}
            cameraCollectionId={cameraCollectionId}
            setIsFirstLoad={setIsFirstLoad}
            firstLoad={firstLoad}
          />
        </Fade>
      </Grid>
    </Grid>
  );
};
