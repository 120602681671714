import React, { useContext } from 'react';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import AppContext from '../../context';
import { AlertSnack } from '../../components/alert-snack';
import { AuthAPI, CodeInfo } from '../../api/auth';
import { ApiException, NotFoundException } from '../../api/lib/domain';
import { PasswordForm } from './password-form';

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  welcomeBox: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  progress: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
    width: '100%',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

type ActivatePageProps = {
  match: {
    params: {
      code: string;
    };
  };
};

type PageState = 'initial' | 'welcome' | 'not-found' | 'code-sent';

export const ActivatePage = (props: ActivatePageProps) => {
  const classes = useStyles({});
  const context = useContext(AppContext);
  const code = props.match.params.code;

  const [state, setState] = React.useState<PageState>('initial');
  const [info, setInfo] = React.useState<CodeInfo>(null);
  const [isWorking, setWorking] = React.useState(false);
  const [challenge, setChallenge] = React.useState<string>(null);

  React.useEffect(() => {
    AuthAPI.codeInfo(code)
      .then(result => {
        setInfo(result.info);
        setState('welcome');
      })
      .catch(err => {
        if (err instanceof NotFoundException) {
          setState('not-found');
          return;
        }
        if (err instanceof ApiException) {
          console.error(err);
          context.onAlert('Server returned an unexpected response', 'error');
          return;
        }
        throw err;
      });
  }, [code]);

  const handleSendCode = () => {
    setWorking(true);
    AuthAPI.sendSmsCode(code)
      .then(result => {
        setChallenge(result.challenge);
        setState('code-sent');
      })
      .catch(err => {
        if (err instanceof NotFoundException) {
          setState('not-found');
          return;
        }
        if (err instanceof ApiException) {
          console.error(err);
          context.onAlert('Server returned an unexpected response', 'error');
          return;
        }
        throw err;
      })
      .finally(() => setWorking(false));
  };

  const handleActivate = (challengeCode: string, password: string) => {
    setWorking(true);
    AuthAPI.activate({
      activationCode: code,
      challengeCode,
      challengeId: challenge,
      password,
    })
      .then(session => {
        context.onLogin(session, '/');
      })
      .catch(err => {
        if (err instanceof NotFoundException) {
          setState('not-found');
          return;
        }
        if (err instanceof ApiException) {
          console.error(err);
          context.onAlert('Server returned an unexpected response', 'error');
          return;
        }
        throw err;
      })
      .finally(() => setWorking(false));
  };

  function display<T>(ifState: PageState, fn: () => T): T | void {
    if (state === ifState) {
      return fn();
    } else {
      return null;
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Activate your account
        </Typography>
        {display('initial', () => (
          <LinearProgress className={classes.progress} />
        ))}
        {display('not-found', () => (
          <Typography className={classes.welcomeBox} color="secondary">
            Unfortunately, this link is either expired, or not valid. Please
            contact us to get the correct activation link.
          </Typography>
        ))}
        {display('welcome', () => (
          <React.Fragment>
            <Typography className={classes.welcomeBox}>
              Welcome to the ISC Portal, {info.name}. To verify your identity,
              we will send a text message to your phone {info.phone}. Please
              have it ready.
            </Typography>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              disabled={isWorking}
              onClick={handleSendCode}
            >
              Send the code
            </Button>
          </React.Fragment>
        ))}
        {display('code-sent', () => (
          <PasswordForm
            classes={classes}
            isWorking={isWorking}
            onSubmit={handleActivate}
          />
        ))}
      </div>
      <AlertSnack
        message={context.alert?.message}
        type={context.alert?.type}
        onClose={context.onAlert}
      />
    </Container>
  );
};
