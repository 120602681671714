import { Alert } from '@material-ui/lab';
import React from 'react';

export const MultipleOrgAlert = () => {
  return (
    <Alert severity="warning">
      Careful! This user belongs to multiple organizations. Changing email,
      address, or phone number will affect all of their Spotlight accounts.
    </Alert>
  );
};
