import React from 'react';
import { useHistory } from 'react-router';
import { CognitoUser } from 'amazon-cognito-identity-js';
import {
  AppContextType,
  SessionInfo,
  AuthenticationFlow,
  SessionStorageKeys,
  LocalStorageKeys,
} from '../../context';
import { AuthAPI } from '../../api/auth';
import { AlertTypes } from '../../components/alert-snack';
import { API } from '../../api/lib/api';
import { ENABLE_DEV_CONSOLE } from '../../util/globals';

/**
 * App Context handles is imperitive, handling the different
 * steps of our login flow and managing sessions details.
 */

// For local operations we need to store context outside of the function scope
let _context: AppContextType, amplifyLoginTimeout;

export const useAppContext = () => {
  const history = useHistory();

  const organizationId =
    window.sessionStorage.getItem(SessionStorageKeys.OrganizationId) || null;

  const authStatus =
    window.sessionStorage.getItem(SessionStorageKeys.AuthState) ||
    AuthenticationFlow.LoggedOut;

  const [context, setContext] = React.useState<AppContextType>(<AppContextType>{
    sessionInfo: null,
    organizationId,
    organizations: [],
    user: null,
    error: null,
    authStatus,
    authChecked: false,

    onAlert: (message: string = '', type?: AlertTypes) =>
      setContext(context => ({ ...context, alert: { message, type } })),

    refreshOrgData: async () => {
      await AuthAPI.session().then(sessionInfo => {
        _context.onLogin({
          ...sessionInfo,
          organizationId: context.organizationId || _context.organizationId,
        });
      });
    },

    onLogin: async (sessionInfo: SessionInfo, path?: string) => {
      await window.sessionStorage.setItem(
        SessionStorageKeys.OrganizationId,
        sessionInfo.organizationId
      );

      // Bootstrap API instance -
      await API.bootStrap({
        context: _context,
        organizationId: sessionInfo.organizationId,
      });

      setContext(context => ({
        ...context,
        sessionInfo,
        organizationId: sessionInfo.organizationId,
        organizations: sessionInfo.organizations,
        user: sessionInfo.user,
        authStatus: AuthenticationFlow.LoggedIn,
        authChecked: true,
      }));

      if (ENABLE_DEV_CONSOLE) {
        console.groupCollapsed('context.onLogin():');
        console.log(sessionInfo);
        console.groupEnd();
      }

      if (path) {
        window.localStorage.removeItem(LocalStorageKeys.PathAfterLogin);
        history.push(path);
      }
    },

    onLogout: () => {
      // TODO: JP- Remove this after API migration is complete, this is a convenience
      // so that you don't get stuck in a redirect loop:
      window.localStorage.removeItem(LocalStorageKeys.PathAfterLogin);
      // TODO: Jp- Remove above after API migration is complete.

      window.sessionStorage.removeItem(SessionStorageKeys.OrganizationId);

      setContext(context => ({
        ...context,
        sessionInfo: null,
        organizationId: null,
        organizations: [],
        authStatus: AuthenticationFlow.LoggedOut,
        authChecked: true,
      }));
    },

    onAmplifyAuthenticated: (user: CognitoUser) => {
      if (_context.authStatus === AuthenticationFlow.AttemptingOIDCLogin) {
        return;
      }

      setContext(context => ({
        ...context,
        authStatus: AuthenticationFlow.AttemptingOIDCLogin,
      }));

      AuthAPI.loginOidc(user)
        .then(sessionInfo => {
          setContext(context => ({
            ...context,
            sessionInfo,
            organizations: sessionInfo.organizations,
            authStatus: AuthenticationFlow.SelectOrganization,
          }));
        })
        .catch(err => {
          context.onAlert(err.message || err, 'error');

          setContext(context => ({
            ...context,
            authStatus: AuthenticationFlow.LoggedOut,
          }));
        })
        .finally(() => {
          window.sessionStorage.removeItem(SessionStorageKeys.AuthState);
        });
    },

    switchOrganizationId: (organizationId: string) => {
      setContext(context => ({ ...context, organizationId }));
    },

    setAuthChecked: (authChecked: boolean) => {
      setContext(context => ({
        ...context,
        authChecked,
      }));
    },

    setAuthStatus: (authStatus: AuthenticationFlow) => {
      setContext(context => ({
        ...context,
        authStatus,
      }));
    },

    startAmplifyTimeoutCheck: () => {
      clearTimeout(amplifyLoginTimeout);
      amplifyLoginTimeout = setTimeout(() => {
        if (
          _context.authStatus ===
            AuthenticationFlow.AttemptingFederatedSignIn ||
          _context.authStatus === AuthenticationFlow.AttemptingOIDCLogin
        ) {
          _context.onAlert(
            'Authentication time out. Please try again.',
            'error'
          );
          _context.onLogout();
        }
      }, 6500);
    },
  });

  // Assigns context to a variable outside the function scope so that internal
  // context API methods have access to the most recent copy of context
  _context = context;

  return context;
};
