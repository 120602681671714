import React, { useState, useEffect } from 'react';
import { CamerasAPI } from '../../api/cameras';
import { Grid } from '@material-ui/core';
import { useStyles } from './camera-details-styles';
import { AlertLog } from './alert-log';
import { MediaLog } from './media-alerts';
import { DetailRow } from './detail-row';
import { ProgressBar } from '../../components/progress-bar';
import { useAppContext } from '../app/useAppContext';
import { CollectionCameraDetailSearchGetResponseDataCameras } from '../../api/isc-api';
import { SessionStorageKeys } from '../../context';

type CameraDetailsProps = {
  match: {
    params: {
      cameraId: string;
      organizationId?: string;
    };
  };
};

export const CameraDetailsPage = (props: CameraDetailsProps) => {
  const classes = useStyles();
  const context = useAppContext();

  const { cameraId, organizationId } = props.match.params;

  if (organizationId) {
    console.log(organizationId);
    context.switchOrganizationId(organizationId);
  }
  const [cameraDetail, setCameraDetail] =
    useState<CollectionCameraDetailSearchGetResponseDataCameras>();
  const [isLoading, setIsLoading] = useState(true);

  const limit = 25;

  const getData = async () => {
    try {
      const camera = await CamerasAPI.get(cameraId);
      const searchResult = await CamerasAPI.searchCameras({
        filter: camera.camera.imei,
      });

      setCameraDetail(searchResult.cameras[0]);
    } catch (e) {
      context.onAlert(
        `Unable to load camera data for id: ${cameraId}`,
        'error'
      );
    }

    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  if (isLoading) {
    return (
      <div className={classes.loading}>
        <ProgressBar />
      </div>
    );
  }

  return (
    <>
      <DetailRow cameraDetail={cameraDetail} />

      <Grid container spacing={4} className={classes.alertMediaLog}>
        <Grid item xs={12} md={6}>
          <div className={classes.listBox}>
            <AlertLog cameraId={cameraId} limit={limit} />
          </div>
        </Grid>

        <Grid item xs={12} md={6}>
          <div className={classes.listBox}>
            <MediaLog cameraId={cameraId} limit={limit} />
          </div>
        </Grid>
      </Grid>
    </>
  );
};
