import React  from 'react'
import { TextField } from '@material-ui/core'

declare const Payload:any

type ErrorContainer = {
  [key:string]: boolean
}

export type PaymentFormComponentProps = {
  focused: string
  errors: ErrorContainer
}

type PaymentFormWrapperProps = {
  component: React.FunctionComponentFactory<PaymentFormComponentProps>
  formKey: string
  customerId: string

  onSetCallback: (fn: () => void) => void
  onSuccess: () => void
  onError: (err: any) => void
}

export const PaymentFormWrapper = (props:PaymentFormWrapperProps) => {

  const [form, setForm] = React.useState<HTMLFormElement>(null)
  const [focused, setFocused] = React.useState<string>(null)
  const [errors, setErrors] = React.useState<ErrorContainer>({})

  const ref = (node: any) => {
    if (node && node !== form) {
      setForm(node)
    }
  }

  React.useLayoutEffect(
    () => {
      if (!Boolean(form)) return

      Payload('test_client_key_3c2UDxu6C5N0mulr1gYXH')
      const plForm = new Payload.Form({
        form,
        autosubmit: false
      })

      props.onSetCallback(() => plForm.submit())

      plForm.on(
        'focus',
        (evt: React.FocusEvent) => {
          setFocused(evt.target.parentElement.id)
        }
      )

      plForm.on(
        'blur',
        (evt: React.FocusEvent) => {
          setFocused((current:string) => current === evt.target.parentElement.id ? null : current)
        }
      )

      plForm.on(
        'invalid',
        (evt: React.FocusEvent) => {
          setErrors(errors => ({
            ...errors,
            [evt.target.parentElement.id]: true
          }))
        }
      )

      plForm.on(
        'valid',
        (evt: React.FocusEvent) => {
          setErrors(errors => ({
            ...errors,
            [evt.target.parentElement.id]: false
          }))
        }
      )

      plForm.on(
        'created',
        () => props.onSuccess()
      )

      plForm.on(
        'error',
        (err:any) => props.onError(err)
      )
    },
    [form]
  )

  return (
    <form pl-form='payment_method' ref={ref} key={props.formKey}>
      <input type='hidden' pl-input="customer_id" value={props.customerId} />
      <input type='hidden' pl-input="default_payment_method" value='1' />
      <div style={{display:'none'}}><TextField/></div>
      <props.component errors={errors} focused={focused} />
    </form>
  )
}
