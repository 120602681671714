import { makeStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles(theme => ({
  container: {
    background: 'transparent',
    color: theme.palette.primary.light,
  },
  headerBar: {
    background: theme.palette.primary.main,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    marginLeft: 'calc(-50vw + 50%)',
    marginRight: 'calc(-50vw + 50%)',
    marginBottom: theme.spacing(5),
  },
  heading: {
    marginTop: theme.spacing(1),
    marginBottom: '5px',
    color: theme.palette.primary.contrastText,
    textTransform: 'uppercase',
    fontFamily: '"Roboto Condensed", "Helvetica", "Arial", sans-serif',
    fontWeight: 'bold',
    fontSize: '1.38rem',
  },
  breadcrumb: {
    '& li': {
      marginLeft: '1px',
      marginRight: '1px',

      '&:first-child': {
        marginLeft: '0px',
      },
    },
  },
  breadcrumbLink: {
    color: theme.palette.grey[100],
    fontSize: '0.8rem',
    marginLeft: 0,
    marginRight: 0,
  },
  breadcrumbIcon: {
    color: theme.palette.grey[500],
    fontSize: '1.2rem',

    '& svg': {
      marginLeft: '5px',
      marginRight: '5px',
    },
  },
  buttonContainer: {
    [theme.breakpoints.up('sm')]: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'flex-end',
      justifyItems: 'center',
    },
  },
}));
