import { UserGroupsAPI } from '../../../../api/user-groups';
import { UserGroup, UserGroupDetails } from '../../../../api/isc-api';

export const deleteGroups = async function (
  cameraId: string,
  groups: UserGroup[]
) {
  for (let group of groups) {
    const groupSettings = await UserGroupsAPI.get(group.userGroupId);
    const cameras =
      groupSettings.cameras?.filter(c => c.cameraId !== cameraId) || [];
    const cameraIds = cameras.map(c => c.cameraId);

    await UserGroupsAPI.update(
      group.userGroupId,
      getPutBody(groupSettings, cameraIds)
    );
  }
};

export const addGroups = async function (
  cameraId: string,
  groups: UserGroup[]
) {
  for (let group of groups) {
    const groupSettings = await UserGroupsAPI.get(group.userGroupId);
    const cameraIds = groupSettings.cameras?.map(c => c.cameraId) || [];

    // Prevent duplicates
    if (cameraIds.includes(cameraId)) continue;

    // Add camera
    cameraIds.push(cameraId);

    await UserGroupsAPI.update(
      group.userGroupId,
      getPutBody(groupSettings, cameraIds)
    );
  }
};

const getPutBody = (details: UserGroupDetails, cameraIds: string[]) => {
  return {
    name: details.name,
    users: details.users.map(u => ({
      userId: u.userId,
      isGroupAdmin: u.isGroupAdmin,
    })),
    subGroups: details.subGroups.map(g => g.userGroupId),
    cameraIds,
  };
};
