import { useState, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { LocalStorageKeys } from '../../context';

export const usePathAfterLogin = () => {
  const history = useHistory();
  const router = useRouteMatch();
  const [pathAfterLogin, setPathAfterLogin] = useState('/');

  useEffect(() => {
    const pathFromLocalStorage = window.localStorage.getItem(
      LocalStorageKeys.PathAfterLogin
    );

    // Determine path after login
    if (pathFromLocalStorage) {
      if (pathFromLocalStorage === '/login') {
        setPathAfterLogin('/');
      } else {
        setPathAfterLogin(pathFromLocalStorage);
      }
    } else if (
      history.location?.state &&
      history.location?.state[LocalStorageKeys.PathAfterLogin]
    ) {
      window.localStorage.setItem(
        LocalStorageKeys.PathAfterLogin,
        history.location.state[LocalStorageKeys.PathAfterLogin]
      );
      setPathAfterLogin(pathFromLocalStorage);
    } else {
      setPathAfterLogin('/');
    }
  }, []);

  return pathAfterLogin;
};
