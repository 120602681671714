import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@material-ui/core';

type AddItemModalProps = {
  title: string;
  heading: string;
  open: boolean;
  handleSubmit: (itemName: string) => void;
  handleClose: () => void;
};

export const AddItemModal = ({
  title,
  heading,
  open,
  handleSubmit,
  handleClose,
}: AddItemModalProps) => {
  const [itemName, setItemName] = useState('');
  // resets item name
  useEffect(() => {
    setItemName('');
  }, [open])

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleSubmit(itemName);
  };

  return (
    <React.Fragment>
      <Dialog open={open} aria-labelledby="form-dialog-title" maxWidth="sm">
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <form action="#" onSubmit={handleFormSubmit}>
          <DialogContent>
            <DialogContentText>{heading}</DialogContentText>
            <TextField
              fullWidth
              autoFocus
              value={itemName}
              id="itemName"
              name="itemName"
              onChange={(ev: any) => setItemName(ev.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button size="small" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              size="small"
            >
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
};
