import React from 'react';
import {LicensePlateRecognitionResult} from "../../../api/isc-api";
import {IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Typography} from "@material-ui/core";
import {useStyles} from "./recognition-results-styles";
import {getVehicleBestMatchSummary} from "./helpers";
import {Info} from "@material-ui/icons";

export type LicensePlateRecognitionResultsProps = {
  results: LicensePlateRecognitionResult[],
  onOpenDetail: (LicensePlateRecognitionResult) => void
}

export const LicensePlateRecognitionResults = ({results, onOpenDetail}: LicensePlateRecognitionResultsProps) => {
  const classes = useStyles();

  const getItemTitle = (result: LicensePlateRecognitionResult, index) => {
    return `${index + 1}: ${result.region.toUpperCase()} ${result.plate}
    (${result.confidence.toFixed(2)}% Confidence)`;
  }

  const getItemDescription = (result: LicensePlateRecognitionResult) => {
    let vehicleSummary = '';
    if (result.vehicle) {
      vehicleSummary = `Vehicle: ${getVehicleBestMatchSummary(result.vehicle)}`;
    }
    return vehicleSummary;
  }

  return (
    results.length ?
      <>
        <Typography className={classes.recognitionTypeSubhead}>
          {results.length} License {results.length === 1 ? 'Plate' : 'Plates'} Detected
        </Typography>
        <List>
          {results.map((result, index) => (
            <ListItem key={result.plate} className={classes.summaryListItem}>
              <ListItemText disableTypography primary={
                  <>
                    <strong className={classes.detailListItemTitle}>{getItemTitle(result, index)}</strong>
                    {getItemDescription(result)}
                  </>}
                />
              <ListItemSecondaryAction>
                <IconButton color="primary" edge="end" onClick={() => onOpenDetail(result)}>
                  <Info/>
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </>
      :null
  )
}
