import React, { useContext, useState } from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  List,
  ListItem,
  TextField,
  CardActions,
  Button, Typography,
} from '@material-ui/core';
import { Auth } from 'aws-amplify';
import AppContext from '../../context';
import { useSendConfirmationCode } from './useSendConfirmationCode';
import { AlertDialogModal } from '../../components/alert-dialog-modal';
import { useChangePassword } from './useChangePassword';
import { useHistory } from 'react-router';

type ProfileChangePasswordProps = {
  email: string;
};

export const ProfileChangePassword = ({
  email,
}: ProfileChangePasswordProps) => {
  const context = useContext(AppContext);
  const history = useHistory();
  const [codeSent, setCodeSent] = useState(false);
  const [confirmationCode, setConfirmationCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordError, setPasswordError] = useState<string>(null);
  const [confirmation, setConfirmation] = useState('');
  const [confirmationError, setConfirmationError] = useState<string>(null);
  const [showChangedModal, setShowChangedModal] = useState(false);
  const [showSendCodeModal, setShowSendCodeModal] = useState(false);

  const { sendingCode, handleSendCode } = useSendConfirmationCode(
    () => {
      setShowSendCodeModal(true);
      setCodeSent(true);
    },
    (error: Error) => {
      context.onAlert(error.message, 'error');
    }
  );

  const { isChangingPassword, handlePasswordChange } = useChangePassword(
    () => {
      setShowChangedModal(true);
    },
    (error: Error) => {
      context.onAlert(error.message, 'error');
    }
  );

  const handleChangedModalClose = () => {
    setShowChangedModal(false)
    context.onLogout();
    history.push('/login');
  }

  const handleSave = () => {
    const passwordValid =
      /[0-9]+/.test(newPassword) &&
      /[A-Z]+/.test(newPassword) &&
      /[a-z]+/.test(newPassword) &&
      newPassword.length >= 8;

    setPasswordError(
      passwordValid ? null : `Password doesn't meet the requirements.`
    );

    const passwordConfirmed = newPassword === confirmation;
    setConfirmationError(
      passwordConfirmed ? null : 'Password fields do not match.'
    );

    if (passwordValid && passwordConfirmed) {
      handlePasswordChange(confirmationCode, newPassword);
    }
  };

  const sendCodeBtnDisabled = codeSent || sendingCode;
  const sendCodeBtnLabel = () => {
    if (codeSent) return 'Code Sent';
    return sendingCode ? 'Sending' : 'Send Confirmation Code';
  };
  const pwInputDisabled = confirmationCode.length === 0;
  const savingBtnDisabled = pwInputDisabled || isChangingPassword;

  return (
    <Card>
      <CardHeader title="Change Password" />
      <CardContent>
        <List disablePadding>
          {!codeSent ?
            <div>
              <Typography paragraph>
                To reset your password, you must send a confirmation code to your registered email address.
                Click the &ldquo;Send Confirmation Code&rdquo; button to start.
              </Typography>
              <Button
                color="primary"
                onClick={handleSendCode}
                disabled={sendCodeBtnDisabled}
              >
                {sendCodeBtnLabel()}
              </Button>
            </div>
            :
            <>
              <ListItem style={{ display: 'flex' }}>
                <TextField
                  type="password"
                  autoComplete="false"
                  label="Confirmation Code"
                  fullWidth
                  value={confirmationCode}
                  onChange={ev => setConfirmationCode(ev.target.value)}
                />
              </ListItem>
              <ListItem>
                <TextField
                  type="password"
                  label="New Password"
                  fullWidth
                  value={newPassword}
                  onChange={ev => setNewPassword(ev.target.value)}
                  error={Boolean(newPasswordError)}
                  helperText={newPasswordError}
                  disabled={pwInputDisabled}
                />
              </ListItem>
              <ListItem>
                <TextField
                  type="password"
                  label="Confirm Password"
                  fullWidth
                  value={confirmation}
                  onChange={ev => setConfirmation(ev.target.value)}
                  error={Boolean(confirmationError)}
                  helperText={confirmationError}
                  disabled={pwInputDisabled}
                />
              </ListItem>
            </>
          }
        </List>
      </CardContent>
      {codeSent &&
        <CardActions>
          <Button
            color="primary"
            onClick={handleSave}
            disabled={savingBtnDisabled}
          >
            {isChangingPassword ? 'Changing...' : 'Change'}
          </Button>
        </CardActions>
      }
      <AlertDialogModal
        open={showSendCodeModal}
        handleClose={() => setShowSendCodeModal(false)}
        dialogTitle="Check your email"
        message={`A link to change your password has been sent to ${email}`}
      />
      <AlertDialogModal
        open={showChangedModal}
        handleClose={handleChangedModalClose}
        dialogTitle="Password successfully changed."
        message={`After closing this dialog you will need to login again with your new credentials.`}
      />
    </Card>
  );
};
